.subscribe .tc-15-rich-dialog-hd strong {
  font-size: 16px; }

.subscribe .title {
  font-size: 16px;
  padding: 25px 0;
  font-weight: 700;
  color: #000; }

.subscribe .bottom-bar {
  text-align: center; }
  .subscribe .bottom-bar .btn-ok {
    margin-top: 20px; }
