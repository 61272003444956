.WriteOffPolicy .tc-15-table-box .template-status {
  display: flex; }
  .WriteOffPolicy .tc-15-table-box .template-status span {
    color: #D0021B;
    padding-left: 20px; }

.WriteOffPolicyForm .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
  background: #444;
  color: #FFF; }
  .WriteOffPolicyForm .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
    border-top-color: #000; }

.WriteOffPolicyForm .more-label > .form-input {
  padding-bottom: 0; }
  .WriteOffPolicyForm .more-label > .form-input .form-label label {
    padding-right: 0; }

.WriteOffPolicyForm .more-label .tc-15-rich-radio {
  margin-bottom: 0; }

.WriteOffPolicyForm .more-label .tc-15-dropdown-link {
  max-width: 180px !important; }

.WriteOffPolicyForm .more-label .tc-input-group {
  min-width: 0 !important; }

.WriteOffPolicyForm .prize-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #F2F2F2;
  border: 1px solid #D9D9D9;
  text-align: center;
  vertical-align: middle; }
  .WriteOffPolicyForm .prize-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .WriteOffPolicyForm .prize-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }
  .WriteOffPolicyForm .prize-image span {
    width: 150px;
    color: #666666;
    display: inline-block;
    position: absolute;
    left: 90px;
    top: -6px; }

.WriteOffPolicyForm .prize-image-upload {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom; }
