.anticounterfeiting-wrap .ty-reset-select.tc-15-select {
  min-width: 90px !important; }

.anticounterfeiting-wrap input.tc-15-input-text {
  width: 60px; }

.anticounterfeiting-wrap .icon-down {
  border: 5px solid transparent;
  border-bottom-color: #888;
  width: 0;
  height: 0;
  margin-left: 10px;
  margin-top: -5px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }

.anticounterfeiting-wrap .icon-up {
  border: 5px solid transparent;
  border-top-color: #888;
  margin-top: 2px;
  width: 0;
  height: 0;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }

.anticounterfeiting-wrap .tc-15-table-fixed-body, .anticounterfeiting-wrap .tc-15-table-fixed-head {
  padding: 0 10px; }

.anticounterfeiting-wrap .page-padding20 {
  padding-top: 10px; }
  .anticounterfeiting-wrap .page-padding20 > div > div {
    display: inline-block; }
  .anticounterfeiting-wrap .page-padding20 .qiuqiu {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    background: red;
    margin-right: 5px; }
  .anticounterfeiting-wrap .page-padding20 .qiuqiuSpan {
    font-size: 12px;
    color: #888;
    margin-right: 5px; }
  .anticounterfeiting-wrap .page-padding20 .hjliz-ml30 {
    margin-left: 15px; }
  .anticounterfeiting-wrap .page-padding20 .hjlz-list-total-font {
    margin-left: 20px;
    color: #ff7800;
    font-size: 16px; }

.anticounterfeiting-wrap .tc-15-dropdown-btn-style .tc-15-dropdown-link em {
  color: #000; }

.anticounterfeiting-wrap .tc-15-dropdown-btn-style a.tc-15-dropdown-link {
  width: 60px; }

.anticounterfeiting-wrap .tc-15-th-sort-btn {
  display: inline-block !important;
  padding-right: 15px !important; }

.anticounterfeiting-wrap .ty-reset-date-range-picker {
  margin-left: 0;
  margin-right: 10px; }

.anticounterfeiting-wrap .order-param > div {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle; }

.anticounterfeiting-wrap .mt0 {
  margin-top: 0 !important; }

.anticounterfeiting-wrap .mb20 {
  margin-bottom: 20px; }

.anticounterfeiting-wrap .echarts-wrap {
  padding: 20px;
  margin-right: 0;
  background: #fff;
  overflow: hidden; }
  .anticounterfeiting-wrap .echarts-wrap .map {
    width: 50%;
    float: left;
    height: 500px; }
  .anticounterfeiting-wrap .echarts-wrap .area {
    width: 50%;
    float: left;
    height: 500px; }
  .anticounterfeiting-wrap .echarts-wrap .title {
    font-weight: bold;
    color: #000; }

.anticounterfeiting-wrap .fleeingTrend {
  width: 100%;
  float: left;
  height: 500px; }

.anticounterfeiting-wrap .mt20 {
  margin-top: 20px; }

.anticounterfeiting-wrap .area-title {
  margin-top: 20px; }

.downBtn {
  width: 66px; }

.handBtn {
  cursor: default; }
