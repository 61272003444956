.extraction-file .hint {
  line-height: 40px;
  color: #B43537;
  background: #FCECEC;
  border: 1px solid #F6B5B5;
  padding-left: 20px;
  margin-top: 20px; }

.extraction-file .bt-group {
  margin-top: 20px; }
  .extraction-file .bt-group .default {
    margin-left: 10px; }

.extraction-file a {
  font-size: 12px; }
