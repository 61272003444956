.screenActiveManage .tc-15-dropdown-link {
  min-width: 180px; }

.screenActiveManage .screenActiveManage-box {
  min-width: 600px;
  width: 100%;
  border: 1px solid #dddddd;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 20px; }
  .screenActiveManage .screenActiveManage-box .tc-15-tablist {
    border-bottom: none; }

.screenActiveManage .range-picker-align-right .tc-15-calendar {
  left: auto;
  right: 0; }
