.screenActiveManage-container1 * {
  font-size: 12px !important;
  box-sizing: border-box; }

.screenActiveManage-container1 {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px 0;
  padding-top: 17px; }
  .screenActiveManage-container1 .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0px; }
  .screenActiveManage-container1 .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .screenActiveManage-container1 .tc-15-step li.current .tc-15-step-num:before,
  .screenActiveManage-container1 .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .screenActiveManage-container1 .step1form-container .tc-15-dropdown,
  .screenActiveManage-container1 .step1form-container .tc-15-input-text,
  .screenActiveManage-container1 .step1form-container .tc-15-simulate-select,
  .screenActiveManage-container1 .step1form-container .tc-15-select {
    width: 330px; }
  .screenActiveManage-container1 .step1form-container .searchbar-box-input {
    width: 180px;
    float: right; }
  .screenActiveManage-container1 .step1form-container .step1-view {
    margin-bottom: 20px;
    height: 100%; }
    .screenActiveManage-container1 .step1form-container .step1-view .tc-15-table-panel {
      border: 1px solid #ddd;
      width: calc(100% - 40px);
      max-width: inherit;
      border-bottom: none; }
    .screenActiveManage-container1 .step1form-container .step1-view .tc-15-page {
      font-size: 0 !important; }
      .screenActiveManage-container1 .step1form-container .step1-view .tc-15-page .tc-15-page-select {
        font-size: 0 !important; }
  .screenActiveManage-container1 .step1form-container .btnBar {
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px; }
  .screenActiveManage-container1 .mb10 {
    margin-bottom: 10px; }
  .screenActiveManage-container1 .mb20 {
    margin-bottom: 20px; }
  .screenActiveManage-container1 .ml10 {
    margin-left: 10px; }
  .screenActiveManage-container1 .ml20 {
    margin-left: 20px; }
  .screenActiveManage-container1 .mr10 {
    margin-right: 10px; }
  .screenActiveManage-container1 .mr20 {
    margin-right: 20px; }
  .screenActiveManage-container1 .flr {
    float: right; }
  .screenActiveManage-container1 .valignm {
    vertical-align: middle; }
  .screenActiveManage-container1 .w100 {
    width: 100% !important; }
  .screenActiveManage-container1 .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .screenActiveManage-container1 .cblue {
    color: #006eff !important; }
  .screenActiveManage-container1 .cursorp {
    cursor: pointer; }
  .screenActiveManage-container1 .dinline b.icon-valid-flag {
    display: none; }
  .screenActiveManage-container1 .dinline li {
    vertical-align: top;
    display: inline-block;
    min-height: 50px; }
    .screenActiveManage-container1 .dinline li > .form-label {
      display: none; }
    .screenActiveManage-container1 .dinline li .form-ctrl-label {
      margin-right: 0; }
  .screenActiveManage-container1 .dinline li + div {
    display: inline-block;
    vertical-align: top; }
    .screenActiveManage-container1 .dinline li + div > div {
      display: inline-block;
      vertical-align: top; }

.import-screenActive-dialog {
  position: relative !important; }
  .import-screenActive-dialog .button-after {
    height: 30px;
    position: relative !important; }
  .import-screenActive-dialog .form-list {
    position: relative !important; }
  .import-screenActive-dialog .button-after > .form-input {
    position: relative !important; }
  .import-screenActive-dialog .button-after > .form-input .button-after-text2 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 100px;
    min-width: 60px;
    color: #006eff; }

.screenActiveManage-view1 * {
  font-size: 12px; }

.screenActiveManage-view1 .outside {
  background: white;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 20;
  padding: 20px 0; }

.screenActiveManage-view1 .tc-15-page {
  font-size: 0 !important; }
  .screenActiveManage-view1 .tc-15-page .tc-15-page-select {
    font-size: 0 !important; }

.screenActiveManage-view1 .tc-15-table-panel {
  border: 1px solid #ddd;
  width: calc(100% - 40px);
  max-width: inherit;
  border-bottom: none; }
