.wechatCodePackageApplication {
  width: 100%;
  height: 100%; }
  .wechatCodePackageApplication .main-block {
    background: #fff;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #e2e2e2; }
    .wechatCodePackageApplication .main-block .search-bar button {
      float: left;
      margin-right: 10px; }
    .wechatCodePackageApplication .main-block .green {
      color: #00dcdc; }
    .wechatCodePackageApplication .main-block .black {
      color: black; }
  .wechatCodePackageApplication .noExpand .icon-arrow-right {
    display: none; }

.ViewDialog {
  font-size: 12px; }
  .ViewDialog .item {
    margin-top: 20px; }

.tc-15-rich-dialog-ft-btn-wrap .blue {
  background-color: #006eff;
  color: #fff;
  border: 1px solid #006eff; }

.develop-hint {
  font-size: 12px;
  color: #003B80;
  padding: 12px 0 12px 14px;
  background: #E5F0FF;
  border: 1px solid #97C7FF;
  margin-top: 20px;
  margin-bottom: 20px; }
