.screenAwardManage .searchbar-box .searchbar-box-input {
  width: 180px;
  float: right; }

.screenAwardManage .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }

.screenAward-form .prize-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #F2F2F2;
  border: 1px solid #D9D9D9;
  text-align: center;
  vertical-align: middle; }
  .screenAward-form .prize-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .screenAward-form .prize-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }
  .screenAward-form .prize-image span {
    width: 156px;
    color: #666666;
    display: inline-block;
    position: absolute;
    left: 90px;
    top: -6px; }

.screenAward-form .prize-image-upload {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom; }
