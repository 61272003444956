.dict-tabbar {
  height: 31px; }
  .dict-tabbar .tc-15-tablist {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0 20px; }
    .dict-tabbar .tc-15-tablist li {
      margin-right: 10px; }

.ModifyDict .box-body {
  overflow: auto; }
  .ModifyDict .box-body li {
    float: left;
    line-height: 30px; }
  .ModifyDict .box-body li:first-child .form-unit.is-error, .ModifyDict .box-body li:nth-child(2) .form-unit.is-error {
    margin-right: 18px; }
  .ModifyDict .box-body li:first-child .form-unit, .ModifyDict .box-body li:nth-child(2) .form-unit {
    margin-right: 44px; }
  .ModifyDict .box-body input,
  .ModifyDict .box-body select,
  .ModifyDict .box-body input.tc-15-input-text,
  .ModifyDict .box-body select.tc-15-input-text {
    width: 124px; }
