.Step2EditPageMemberSystem {
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  min-width: 900px; }
  .Step2EditPageMemberSystem .memberSystem-more-label > .form-input {
    padding-bottom: 0; }
    .Step2EditPageMemberSystem .memberSystem-more-label > .form-input .form-label label {
      padding-right: 0; }
  .Step2EditPageMemberSystem .memberSystem-more-label .tc-15-rich-radio {
    margin-bottom: 0; }
  .Step2EditPageMemberSystem .memberSystem-more-label .tc-15-dropdown-link {
    max-width: 180px !important; }
  .Step2EditPageMemberSystem .memberSystem-more-label .tc-input-group {
    min-width: 0 !important; }
  .Step2EditPageMemberSystem .memberSystemLabel {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #000000; }
  .Step2EditPageMemberSystem .outside {
    font-size: 12px;
    background: white;
    height: 100%; }
    .Step2EditPageMemberSystem .outside .inside {
      background: #F8F8F8;
      padding-left: 33px;
      padding-top: 19px;
      padding-bottom: 19px; }
      .Step2EditPageMemberSystem .outside .inside .prize-image {
        width: 80px;
        height: 80px;
        position: relative;
        display: inline-block;
        background: #F2F2F2;
        border: 1px solid #D9D9D9;
        text-align: center;
        vertical-align: middle; }
        .Step2EditPageMemberSystem .outside .inside .prize-image img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .Step2EditPageMemberSystem .outside .inside .prize-image .n-loading-icon {
          position: relative;
          margin-top: 30px;
          z-index: 10; }
        .Step2EditPageMemberSystem .outside .inside .prize-image span {
          width: 156px;
          color: #666666;
          display: inline-block;
          position: absolute;
          left: 90px;
          top: -6px; }
      .Step2EditPageMemberSystem .outside .inside .row {
        margin-top: 19px;
        display: flex; }
        .Step2EditPageMemberSystem .outside .inside .row > span:first-child {
          margin-right: 20px; }
        .Step2EditPageMemberSystem .outside .inside .row .widthSpan {
          width: calc(100% - 88px);
          word-break: break-all; }
