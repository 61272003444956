.wine-template {
  padding: 0 0 20px 0;
  user-select: none; }
  .wine-template > div:first-child {
    display: flex; }
  .wine-template .sider {
    width: 160px;
    border-right: 2px solid #e5e5e5;
    padding-top: 5px; }
    .wine-template .sider .item {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 23px;
      display: flex;
      margin-bottom: 10px; }
      .wine-template .sider .item .icon {
        width: 4px;
        height: 23px;
        margin-right: 12px; }
      .wine-template .sider .item.active {
        color: #006eff; }
        .wine-template .sider .item.active .icon {
          background-color: #006eff; }
  .wine-template .main {
    flex: 1;
    background-color: #fff !important;
    padding-top: 20px; }
    .wine-template .main .header .tc-15-rich-radio .tc-15-btn {
      width: 80px; }
    .wine-template .main .header .action {
      display: flex;
      align-items: center;
      margin: 20px 0; }
      .wine-template .main .header .action > div,
      .wine-template .main .header .action button {
        margin-right: 20px; }
      .wine-template .main .header .action .tc-15-switch-input {
        width: 35px; }
    .wine-template .main .content {
      display: flex; }
      .wine-template .main .content .iphone {
        width: 375px;
        min-height: 667px;
        box-shadow: 0 0 6px rgba(34, 34, 34, 0.2);
        margin-right: 30px;
        align-self: flex-start; }
        .wine-template .main .content .iphone .top-navbar {
          width: 100%; }
      .wine-template .main .content .sketchpad {
        width: 100%;
        min-height: 603px;
        position: relative;
        overflow: hidden;
        white-space: pre; }
        .wine-template .main .content .sketchpad .median {
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border-left: 0.5px dashed #000;
          border-right: 0.5px dashed #000; }
      .wine-template .main .content .toolkit {
        display: flex;
        flex: 1;
        min-height: 603px;
        border: 1px solid #ddd; }
        .wine-template .main .content .toolkit .configure {
          padding: 20px; }
          .wine-template .main .content .toolkit .configure .input {
            margin-right: 15px; }
          .wine-template .main .content .toolkit .configure .margin-right .input {
            margin: 0 15px; }
          .wine-template .main .content .toolkit .configure .tc-15-dropdown-link {
            width: 100%; }
          .wine-template .main .content .toolkit .configure .btn-opration {
            color: #006eff;
            cursor: pointer; }
          .wine-template .main .content .toolkit .configure .image-system {
            width: 100px;
            height: 100px;
            border: 1px solid #D9D9D9;
            margin: 15px 15px 0 0; }
          .wine-template .main .content .toolkit .configure .item {
            display: flex;
            padding-bottom: 20px; }
  .wine-template .form-edit-border {
    padding-left: 20px; }
