.LabelSynchronization-wrapper .pushSettingList-wapper .form-list {
  display: flex; }
  .LabelSynchronization-wrapper .pushSettingList-wapper .form-list .form-label label {
    padding-right: 0px; }
  .LabelSynchronization-wrapper .pushSettingList-wapper .form-list .form-input {
    padding-right: 10px; }

.LabelSynchronization-wrapper .pushSettingList-wapper .selectLabelMenu {
  margin-right: 10px; }
  .LabelSynchronization-wrapper .pushSettingList-wapper .selectLabelMenu .active {
    display: block;
    top: 30px;
    max-width: fit-content !important; }

.LabelSynchronization-wrapper .pushSettingList-wapper .selectLabelMenu > ul {
  border: 1px solid #ddd; }

.LabelSynchronization-wrapper .pushSettingList-wapper .selectLabelMenu ul {
  background-color: white; }

.LabelSynchronization-wrapper .pushSettingList-wapper .selectLabelMenu > ul > li {
  padding-right: 15px; }

.LabelSynchronization-wrapper .pushSettingList-wapper .delete,
.LabelSynchronization-wrapper .pushSettingList-wapper .add {
  font-size: 12px;
  color: #006eff;
  cursor: pointer;
  display: inline-block; }

.LabelSynchronization-wrapper .pushSettingList-wapper .delete {
  padding-top: 5px; }

.LabelSynchronization-wrapper .pushSettingList-wapper .not-delete {
  font-size: 12px;
  color: #bbb;
  padding-top: 5px;
  cursor: not-allowed; }
