.DepartmentManage .block {
  display: flex; }

.DepartmentManage .table-block {
  width: 66.5%;
  padding-right: 20px; }
  .DepartmentManage .table-block > div {
    min-height: 504px;
    background: #fff;
    border: 1px solid #E2E2E2;
    padding: 20px 20px 10px 20px; }
  .DepartmentManage .table-block .data-table {
    min-height: 417px; }
    .DepartmentManage .table-block .data-table .depart-name svg {
      display: none;
      cursor: pointer;
      margin-left: 3px; }
    .DepartmentManage .table-block .data-table .depart-name:hover svg {
      display: inline-block; }
    .DepartmentManage .table-block .data-table .text-weak a {
      padding-left: 10px; }
  .DepartmentManage .table-block .tc-15-table-panel {
    border: 1px solid #E2E2E2; }
  .DepartmentManage .table-block .add-disable {
    color: #bbb;
    position: relative;
    vertical-align: middle;
    margin-right: 10px; }
    .DepartmentManage .table-block .add-disable p {
      width: 139px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      position: absolute;
      top: 28px;
      left: 24px;
      z-index: 10;
      color: #fff;
      display: none;
      background-color: #444; }
    .DepartmentManage .table-block .add-disable:hover p {
      display: block; }

.DepartmentManage .tree-block {
  width: 33.5%;
  min-height: 504px;
  background: #fff;
  padding: 10px; }
  .DepartmentManage .tree-block .ant-tree-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
    display: block; }

.ModifyDepartmentName .tc-15-edit-in-place {
  padding: 20px; }

.ModifyDepartmentName .form-list {
  margin-bottom: 15px; }

.ModifyDepartmentName .form-label {
  display: none; }

.ModifyDepartmentName .form-unit > div {
  line-height: 30px; }

.ModifyDepartmentName .tc-15-input-text {
  width: 190px; }

.ModifyDepartmentName .line {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px; }
