.modify-template .section {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0; }
  .modify-template .section .iphone {
    width: 375px;
    height: 667px;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    box-shadow: 0 0 6px rgba(34, 34, 34, 0.2);
    position: relative; }
    .modify-template .section .iphone pre {
      margin: 0; }
    .modify-template .section .iphone .top {
      width: 100%; }
    .modify-template .section .iphone .title {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      font-weight: 600;
      color: #000; }
    .modify-template .section .iphone .magic-sketchpad {
      width: 100%;
      height: 603px;
      position: relative; }
      .modify-template .section .iphone .magic-sketchpad .selector {
        position: absolute;
        z-index: 999;
        background: none;
        list-style: none !important;
        margin: 0 !important;
        padding: 0;
        transition: padding .5s;
        border: 2px dashed #000;
        display: none;
        pointer-events: none; }
    .modify-template .section .iphone .official-account {
      width: 320px;
      height: 84px;
      position: absolute;
      bottom: 72px;
      left: 50%;
      transform: translateX(-50%); }
    .modify-template .section .iphone .bg-record {
      width: 346px;
      position: absolute;
      top: 184px;
      left: 10px; }
    .modify-template .section .iphone .tab-bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 49px;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 11px; }
      .modify-template .section .iphone .tab-bar .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1; }
        .modify-template .section .iphone .tab-bar .item img {
          width: 21px;
          height: 21px; }
