.cardType-form {
  font-size: 12px !important; }
  .cardType-form .clear {
    clear: both; }
  .cardType-form .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .cardType-form .inline .form-label {
      width: 0 !important; }
    .cardType-form .inline .form-label label {
      display: none; }
    .cardType-form .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .cardType-form .inline .icon-valid-flag {
      display: none; }
    .cardType-form .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .cardType-form .inline .tc-15-select,
    .cardType-form .inline .tc-input-group {
      min-width: 0px; }
  .cardType-form .form-label {
    width: 100px; }
  .cardType-form .tc-15-input-text {
    width: 320px; }
  .cardType-form .form-input {
    position: relative; }
    .cardType-form .form-input .extra-text {
      font-size: 12px;
      color: #888888;
      line-height: 18px;
      margin-top: 5px; }
    .cardType-form .form-input .tc-15-checkbox {
      margin-top: 8px; }
    .cardType-form .form-input .checkbox-text2 {
      font-size: 12px;
      line-height: 36px;
      position: absolute;
      top: 0;
      left: 20px;
      color: #888888;
      width: 240px; }
      .cardType-form .form-input .checkbox-text2 span {
        color: #000000;
        margin: auto 10px; }
  .cardType-form .allowHandsel .form-label,
  .cardType-form .allowHandsel .form-input {
    padding-bottom: 5px; }
  .cardType-form .button-box {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    text-align: center; }
    .cardType-form .button-box button {
      margin-right: 5px; }
