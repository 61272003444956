.factory-container {
  padding: 20px auto;
  margin-top: 20px; }
  .factory-container .factory-topbar {
    min-height: 30px;
    margin-bottom: 10px;
    overflow: hidden; }
    .factory-container .factory-topbar .factory-search-input {
      width: 180px;
      float: right; }
  .factory-container .moreline-text-ellipsis {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }
  .factory-container .cursor-handle {
    color: #006EFF;
    cursor: pointer; }
  .factory-container .gray-row {
    color: #BEBEBE; }
  .factory-container .normal-row {
    color: #000; }
