.OpenBottleRebate {
  background-color: #ffffff;
  padding: 5px 10px 0;
  margin-top: 20px; }
  .OpenBottleRebate .link-button {
    color: #006eff;
    background-color: transparent;
    margin-right: 8px; }

.OpenBottleRebate-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }
