.codelabel-tags-step0-box li:nth-of-type(3) .form-input {
  position: relative; }
  .codelabel-tags-step0-box li:nth-of-type(3) .form-input .tc-15-rich-radio {
    height: 35px;
    line-height: 30px; }

.codelabel-tags-step0-box .tc-15-input-text {
  width: 237px; }

.codelabel-tags-step0-box .basic-item-render {
  position: absolute;
  left: -35px;
  top: 5px; }

.codelabel-tags-step0-box .basic-item-render .tc-15-bubble-bottom .tc-15-bubble-inner:after {
  margin-left: -35px;
  border-top-color: #000; }

.codelabel-tags-step0-box .basic-item-render .tc-15-bubble-bottom .tc-15-bubble-inner:before {
  margin-left: -35px;
  border-top-color: #000; }

.codelabel-tags-step0-box .basic-item-render .tc-15-bubble-icon .tc-15-bubble-bottom,
.codelabel-tags-step0-box .basic-item-render .tc-15-bubble-icon .tc-15-bubble-top {
  margin-left: -50px;
  margin-bottom: 0; }

.codelabel-tags-step0-box .tc-15-bubble {
  background: #000;
  width: 240px; }
  .codelabel-tags-step0-box .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #fff; }

.codelabel-tags-step0-box .tags-list-box {
  margin-left: 69px;
  margin-top: -50px; }
  .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: start; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .form-list {
      width: 100%; }
      .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .form-list .form-label {
        width: 55px; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li .form-label {
      padding-bottom: 0; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li .form-input {
      padding-bottom: 0; }
      .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li .form-input .tc-15-input-text {
        width: 237px; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li:nth-of-type(1) {
      margin-right: 20px; }
      .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li:nth-of-type(1) .form-label {
        display: none; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li:nth-of-type(2) {
      margin-right: 20px; }
      .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box li:nth-of-type(2) .form-label {
        display: none; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .effectiveDayRadio {
      margin-right: 10px; }
      .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .effectiveDayRadio .tc-15-rich-radio {
        height: 35px;
        line-height: 30px;
        width: 111px; }
        .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .effectiveDayRadio .tc-15-rich-radio .form-ctrl-label:last-of-type {
          margin-right: 0; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .effectiveDayInput label {
      padding: 0; }
    .codelabel-tags-step0-box .tags-list-box .step0-labelitem-box .effectiveDayInput .form-input .tc-15-input-text {
      width: 160px; }

.codelabel-tags-step0-box .btnBar {
  border-top: 1px solid #ddd;
  padding-top: 15px; }
  .codelabel-tags-step0-box .btnBar .weak-button {
    margin-left: 10px; }
