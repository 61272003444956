.screenActiveManage-container0 * {
  font-size: 12px !important;
  box-sizing: border-box; }

.screenActiveManage-container0 {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px 0;
  padding-top: 17px; }
  .screenActiveManage-container0 .tc-15-step {
    padding-left: 20px;
    padding-bottom: 5px; }
  .screenActiveManage-container0 .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .screenActiveManage-container0 .tc-15-step li.current .tc-15-step-num:before,
  .screenActiveManage-container0 .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .screenActiveManage-container0 .step0form-container .tc-15-dropdown,
  .screenActiveManage-container0 .step0form-container .tc-15-input-text,
  .screenActiveManage-container0 .step0form-container .tc-15-simulate-select,
  .screenActiveManage-container0 .step0form-container .tc-15-select {
    width: 330px; }
  .screenActiveManage-container0 .step0form-container .regular-more-label > .form-input {
    padding-bottom: 0; }
    .screenActiveManage-container0 .step0form-container .regular-more-label > .form-input .form-label label {
      padding-right: 0; }
  .screenActiveManage-container0 .step0form-container .regular-more-label .tc-15-rich-radio {
    margin-bottom: 0; }
  .screenActiveManage-container0 .step0form-container .regular-more-label .tc-15-dropdown-link {
    max-width: 180px !important; }
  .screenActiveManage-container0 .step0form-container .regular-more-label .tc-input-group {
    min-width: 0 !important; }
  .screenActiveManage-container0 .step0form-container .order-text {
    padding: 10px 15px;
    width: 330px;
    min-height: 97px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9; }
  .screenActiveManage-container0 .step0form-container .prize-image {
    width: 330px;
    height: 330px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .screenActiveManage-container0 .step0form-container .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .screenActiveManage-container0 .step0form-container .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .screenActiveManage-container0 .step0form-container .prize-image span {
      width: 150px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .screenActiveManage-container0 .step0form-container .btnBar {
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px; }
    .screenActiveManage-container0 .step0form-container .btnBar .weak-button {
      margin-left: 10px; }
  .screenActiveManage-container0 .step0form-container .open-lottery-show-content .form-input {
    vertical-align: top; }
  .screenActiveManage-container0 .mb10 {
    margin-bottom: 10px; }
  .screenActiveManage-container0 .mb20 {
    margin-bottom: 20px; }
  .screenActiveManage-container0 .ml10 {
    margin-left: 10px; }
  .screenActiveManage-container0 .ml20 {
    margin-left: 20px; }
  .screenActiveManage-container0 .mr10 {
    margin-right: 10px; }
  .screenActiveManage-container0 .mr20 {
    margin-right: 20px; }
  .screenActiveManage-container0 .flr {
    float: right; }
  .screenActiveManage-container0 .valignm {
    vertical-align: middle; }
  .screenActiveManage-container0 .w100 {
    width: 100% !important; }
  .screenActiveManage-container0 .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .screenActiveManage-container0 .cblue {
    color: #006eff !important; }
  .screenActiveManage-container0 .cursorp {
    cursor: pointer; }
  .screenActiveManage-container0 .dinline b.icon-valid-flag {
    display: none; }
  .screenActiveManage-container0 .dinline li {
    vertical-align: top;
    display: inline-block;
    min-height: 50px; }
    .screenActiveManage-container0 .dinline li > .form-label {
      display: none; }
    .screenActiveManage-container0 .dinline li .form-ctrl-label {
      margin-right: 0; }
  .screenActiveManage-container0 .dinline li + div {
    display: inline-block;
    vertical-align: top; }
    .screenActiveManage-container0 .dinline li + div > div {
      display: inline-block;
      vertical-align: top; }

.screenActiveManage-view0 * {
  font-size: 12px; }

.screenActiveManage-view0 .outside {
  background: white;
  height: 100%;
  margin-top: 15px;
  padding: 20px; }
  .screenActiveManage-view0 .outside .inside {
    background: #F8F8F8;
    padding-left: 33px;
    padding-top: 19px;
    padding-bottom: 19px; }
    .screenActiveManage-view0 .outside .inside .prize-image {
      width: 80px;
      height: 80px;
      position: relative;
      display: inline-block;
      background: #F2F2F2;
      border: 1px solid #D9D9D9;
      text-align: center;
      vertical-align: middle; }
      .screenActiveManage-view0 .outside .inside .prize-image img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .screenActiveManage-view0 .outside .inside .prize-image .n-loading-icon {
        position: relative;
        margin-top: 30px;
        z-index: 10; }
      .screenActiveManage-view0 .outside .inside .prize-image span {
        width: 156px;
        color: #666666;
        display: inline-block;
        position: absolute;
        left: 90px;
        top: -6px; }
    .screenActiveManage-view0 .outside .inside .row {
      margin-top: 19px;
      display: flex; }
      .screenActiveManage-view0 .outside .inside .row > span:first-child {
        margin-right: 20px; }
      .screenActiveManage-view0 .outside .inside .row .widthSpan {
        width: calc(100% - 88px);
        word-break: break-all; }
