.blue {
  color: #1469FB;
  cursor: pointer; }

.dmlinkUploadlist-box {
  width: 100%;
  overflow: auto;
  background: #fff;
  text-align: center; }
  .dmlinkUploadlist-box .tc-15-table-fixed-head, .dmlinkUploadlist-box .tc-15-table-fixed-body {
    border: none; }
  .dmlinkUploadlist-box .tc-15-table-box tr {
    border-bottom: 1px solid #ddd; }

.opration span {
  margin-left: 2px;
  cursor: pointer; }

.opration span:first-of-type {
  margin-left: 0; }

.codedata-list .searchbar-box .tc-15-select,
.codedata-list .searchbar-box .tc-15-input-text,
.codedata-list .searchbar-box .tc-15-dropdown,
.codedata-list .searchbar-box .tc-15-btn {
  margin-right: 10px;
  height: 30px;
  line-height: 30px; }

.codedata-list .input .icon-valid-flag {
  display: none; }
