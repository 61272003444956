.CustomTemplate {
  width: 100%;
  height: 100%; }
  .CustomTemplate .tabList {
    height: 100%; }
  .CustomTemplate .svgClass {
    width: 16px;
    height: 16px;
    color: aqua;
    fill: aliceblue; }
  .CustomTemplate .dataTable {
    height: 450px;
    margin-top: 10px; }
  .CustomTemplate .tc-15-table-panel {
    max-width: 100%; }
  .CustomTemplate .dialog-select {
    width: 100%;
    margin-top: 10px; }
    .CustomTemplate .dialog-select .selectClass {
      width: 100% !important; }
  .CustomTemplate .searchbar-box .tc-15-dropdown,
  .CustomTemplate .searchbar-box .tc-15-input-text,
  .CustomTemplate .searchbar-box .tc-15-simulate-select,
  .CustomTemplate .searchbar-box .tc-15-select {
    width: 180px; }
  .CustomTemplate .searchbar-box .tc-15-dropdown-link {
    max-width: 180px; }

.customTemplateModal .checkboxGroupBox .form-label,
.customTemplateModal .checkboxGroupBox .form-input {
  padding-bottom: 40px; }

.customTemplateModal .prize-image-out {
  width: 80px;
  position: relative;
  display: inline-block; }

.customTemplateModal .prize-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #F2F2F2;
  border: 1px solid #D9D9D9;
  text-align: center;
  vertical-align: middle; }
  .customTemplateModal .prize-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .customTemplateModal .prize-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }
  .customTemplateModal .prize-image span {
    width: 150px;
    color: #666666;
    display: inline-block;
    position: absolute;
    left: 90px;
    top: -6px; }

.customTemplateModal .prize-image-upload {
  display: inline-block;
  vertical-align: bottom; }

.customTemplateModal .button-after > .form-input {
  position: relative; }
  .customTemplateModal .button-after > .form-input .tc-15-dropdown,
  .customTemplateModal .button-after > .form-input .tc-15-input-text,
  .customTemplateModal .button-after > .form-input .tc-15-simulate-select,
  .customTemplateModal .button-after > .form-input .tc-15-select {
    width: 330px; }
  .customTemplateModal .button-after > .form-input .tc-15-dropdown-link {
    max-width: 330px; }
  .customTemplateModal .button-after > .form-input .button-after-text2 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 340px;
    min-width: 30px;
    color: #006eff; }
    .customTemplateModal .button-after > .form-input .button-after-text2 span {
      color: #000000; }
  .customTemplateModal .button-after > .form-input .button-after-text3 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 370px;
    min-width: 60px;
    color: #006eff; }
    .customTemplateModal .button-after > .form-input .button-after-text3 span {
      color: #000000; }
  .customTemplateModal .button-after > .form-input .button-after-text2:nth-of-type(2) {
    left: 375px; }
  .customTemplateModal .button-after > .form-input .is-error ~ .button-after-text2 {
    left: 365px; }
  .customTemplateModal .button-after > .form-input .is-error ~ .button-after-text3 {
    left: 395px; }
  .customTemplateModal .button-after > .form-input .is-error ~ .rc-upload .button-after-text2 {
    left: 365px; }
