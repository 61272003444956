.ModifyFieldForm {
  width: 530px; }
  .ModifyFieldForm .box-body {
    overflow: auto; }
    .ModifyFieldForm .box-body li {
      float: left;
      line-height: 30px; }
    .ModifyFieldForm .box-body li:first-child {
      padding-right: 18px; }
    .ModifyFieldForm .box-body li:nth-child(2) {
      padding-right: 9px; }
    .ModifyFieldForm .box-body input,
    .ModifyFieldForm .box-body select,
    .ModifyFieldForm .box-body input.tc-15-input-text,
    .ModifyFieldForm .box-body select.tc-15-input-text {
      width: 124px; }
