.presents-management .search-bar {
  display: flex;
  justify-content: flex-end; }
  .presents-management .search-bar .tc-15-input-text,
  .presents-management .search-bar .tc-15-select {
    width: 180px;
    margin-right: 10px; }

.presents-modify .presents-name .form-input {
  display: flex;
  justify-content: flex-start; }

.presents-modify .presents-name .button-after-text {
  font-size: 12px;
  line-height: 30px;
  padding-left: 10px;
  min-width: 30px;
  color: #006eff; }

.presents-modify .form-list .form-input {
  position: relative; }

.presents-modify .prize-overview .form-input {
  width: 100%; }

.presents-modify .prize-overview .item-list {
  display: flex;
  flex-wrap: wrap;
  min-width: 920px; }

.presents-modify .prize-overview .item-box {
  height: 110px;
  min-width: 410px;
  font-size: 12px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding: 0 20px;
  margin-right: 20px;
  margin-bottom: 36px;
  border: 1px solid #dddddd; }
  .presents-modify .prize-overview .item-box > div:first-child span {
    font-size: 16px; }
  .presents-modify .prize-overview .item-box span {
    font-size: 14px;
    font-weight: 600;
    color: #FF7800;
    padding: 0 20px; }
  .presents-modify .prize-overview .item-box .progress-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .presents-modify .prize-overview .item-box .progress-box .progress {
      height: 5px;
      flex: 1;
      background: #e2e5ec;
      position: relative;
      margin-right: 8px; }
      .presents-modify .prize-overview .item-box .progress-box .progress .progress-bar {
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        background: #006eff; }

.presents-modify .tc-15-table-panel {
  margin-bottom: 20px; }
  .presents-modify .tc-15-table-panel .form-ctrl-label {
    margin-right: 5px; }
  .presents-modify .tc-15-table-panel .form-label {
    display: none; }
  .presents-modify .tc-15-table-panel .form-input {
    padding-bottom: 0; }
  .presents-modify .tc-15-table-panel .checkbox-inline .form-label {
    display: none; }
  .presents-modify .tc-15-table-panel .checkbox-inline .form-input {
    font-size: 12px; }
  .presents-modify .tc-15-table-panel .checkbox-inline .form-unit {
    float: left; }
    .presents-modify .tc-15-table-panel .checkbox-inline .form-unit .form-ctrl-label {
      margin-right: 5px; }
  .presents-modify .tc-15-table-panel.history tr th:first-child,
  .presents-modify .tc-15-table-panel.history tr th:nth-child(6) label,
  .presents-modify .tc-15-table-panel.history tr th:nth-child(8),
  .presents-modify .tc-15-table-panel.history tr td:first-child,
  .presents-modify .tc-15-table-panel.history tr td:nth-child(8) {
    visibility: hidden; }

.presents-modify .confirm-popEditor {
  position: absolute;
  width: 330px;
  bottom: 33px;
  height: 144px; }

.PresentsLimit .inline {
  display: flex; }
  .PresentsLimit .inline > span {
    line-height: 30px;
    padding-right: 8px; }
  .PresentsLimit .inline > li {
    margin-right: 8px; }

.PresentsLimit .form-list .form-label label {
  padding-right: 0; }

.PresentsLimit .tc-input-group {
  min-width: 0; }
