.Step1EditPageMemberSystem {
  color: #000000;
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  min-width: 900px; }
  .Step1EditPageMemberSystem .cblue {
    color: #006eff !important; }
  .Step1EditPageMemberSystem .cursorp {
    cursor: pointer; }
  .Step1EditPageMemberSystem .memberSystem-more-label > .form-input {
    padding-bottom: 0; }
    .Step1EditPageMemberSystem .memberSystem-more-label > .form-input .form-label label {
      padding-right: 0; }
  .Step1EditPageMemberSystem .memberSystem-more-label .tc-15-rich-radio {
    margin-bottom: 0; }
  .Step1EditPageMemberSystem .memberSystem-more-label .tc-15-dropdown-link {
    max-width: 180px !important; }
  .Step1EditPageMemberSystem .memberSystem-more-label .tc-input-group {
    min-width: 0 !important; }
  .Step1EditPageMemberSystem .memberSystemLabel {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #000000; }
  .Step1EditPageMemberSystem .box {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 20px;
    line-height: 23px;
    color: #262626;
    margin-bottom: 20px; }
    .Step1EditPageMemberSystem .box .inline {
      display: inline-block;
      float: left;
      margin-top: 20px;
      margin-left: 10px; }
      .Step1EditPageMemberSystem .box .inline .form-label label {
        display: none; }
      .Step1EditPageMemberSystem .box .inline .form-input-help {
        float: left;
        margin-bottom: 0;
        line-height: inherit !important; }
      .Step1EditPageMemberSystem .box .inline.inline3 .form-input-help {
        position: absolute;
        width: 200px; }
      .Step1EditPageMemberSystem .box .inline .icon-valid-flag {
        display: none; }
      .Step1EditPageMemberSystem .box .inline .form-ctrl-label {
        display: block;
        height: 40px;
        line-height: 40px; }
      .Step1EditPageMemberSystem .box .inline .tc-15-select,
      .Step1EditPageMemberSystem .box .inline .tc-input-group {
        min-width: 0px; }
    .Step1EditPageMemberSystem .box .tc-15-dropdown-link {
      width: 100%; }
  .Step1EditPageMemberSystem .btnBar {
    border-top: 1px solid #ddd;
    padding-top: 15px; }
    .Step1EditPageMemberSystem .btnBar .weak-button {
      margin-left: 10px; }
  .Step1EditPageMemberSystem div.box-style {
    width: 100%;
    border: 1px solid #E2E2E2;
    padding: 20px;
    margin-top: -10px;
    margin-bottom: 20px; }
    .Step1EditPageMemberSystem div.box-style h1 {
      font-family: MicrosoftYaHei-Bold;
      font-size: 12px;
      color: #333333; }
  .Step1EditPageMemberSystem div.box-table {
    padding: 0;
    border-bottom: none; }
    .Step1EditPageMemberSystem div.box-table .head-box,
    .Step1EditPageMemberSystem div.box-table .body-box {
      border-bottom: 1px solid #E2E2E2;
      font-family: MicrosoftYaHei-Bold;
      font-size: 12px;
      color: #333333; }
      .Step1EditPageMemberSystem div.box-table .head-box > div,
      .Step1EditPageMemberSystem div.box-table .body-box > div {
        display: inline-block; }
        .Step1EditPageMemberSystem div.box-table .head-box > div:nth-child(1),
        .Step1EditPageMemberSystem div.box-table .body-box > div:nth-child(1) {
          padding-left: 20px; }
        .Step1EditPageMemberSystem div.box-table .head-box > div:nth-child(1), .Step1EditPageMemberSystem div.box-table .head-box > div:nth-child(2),
        .Step1EditPageMemberSystem div.box-table .body-box > div:nth-child(1),
        .Step1EditPageMemberSystem div.box-table .body-box > div:nth-child(2) {
          width: 22%; }
    .Step1EditPageMemberSystem div.box-table .head-box > div {
      height: 42px;
      line-height: 42px; }
    .Step1EditPageMemberSystem div.box-table .body-box > div {
      padding: 10px 0;
      line-height: 30px; }
    .Step1EditPageMemberSystem div.box-table .btn-remove {
      color: #006EFF;
      cursor: pointer;
      position: relative; }
    .Step1EditPageMemberSystem div.box-table .no-data-box {
      font-size: 12px;
      width: 100%;
      text-align: center;
      line-height: 30px;
      padding: 10px 0;
      border-bottom: 1px solid #E2E2E2; }
    .Step1EditPageMemberSystem div.box-table .edit-input-box .form-input,
    .Step1EditPageMemberSystem div.box-table .edit-input-box .form-label,
    .Step1EditPageMemberSystem div.box-table .edit-input-box .form-output {
      padding-bottom: 0; }
  .Step1EditPageMemberSystem .edit-input-box {
    display: flex;
    justify-content: flex-start; }
    .Step1EditPageMemberSystem .edit-input-box div {
      line-height: 30px; }
    .Step1EditPageMemberSystem .edit-input-box div.text {
      padding: 0 10px;
      color: #333333; }
    .Step1EditPageMemberSystem .edit-input-box .form-label label {
      padding-right: 0; }
    .Step1EditPageMemberSystem .edit-input-box .form-input input {
      width: 119px; }
    .Step1EditPageMemberSystem .edit-input-box b.icon-valid-flag {
      display: none; }
    .Step1EditPageMemberSystem .edit-input-box .PR10 {
      padding-right: 10px; }
    .Step1EditPageMemberSystem .edit-input-box .PL10 {
      padding-left: 10px; }
