body {
  background-color: #f2f2f2;
  overflow-y: auto; }

.App .main-container {
  min-height: 100%;
  transition: margin-left .28s;
  margin-left: 200px;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden; }
  .App .main-container .border-bottom {
    border-bottom: 1px solid #dddddd; }

.App .sidebar-container {
  transition: width .28s;
  width: 200px !important;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow: hidden; }

.App .main-sidebar-button {
  position: absolute;
  left: 200px;
  top: 0;
  height: 50px;
  width: 20px;
  z-index: 999; }

.App .hideSidebar .sidebar-container {
  width: 0 !important; }

.App .hideSidebar .main-sidebar-button {
  left: 0; }

.App .hideSidebar .main-container {
  margin-left: 0;
  overflow: hidden; }

.App .qc-menu-fold {
  line-height: 100;
  position: absolute;
  overflow: hidden;
  width: 18px;
  height: 38px;
  border: 1px solid #4d4d4d;
  border-right: none;
  z-index: 100;
  background-color: #4d4d4d;
  top: 9px;
  cursor: pointer; }

.App .qc-menu-fold-right {
  border-radius: 0 2px 2px 0;
  left: 0; }

.App .qc-menu-fold-left {
  border-radius: 2px 0 0 2px;
  right: 0; }

.App .menu-arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  margin-left: -8px;
  cursor: pointer; }

.container {
  background-color: #fff;
  border: 1px solid #E2E2E2;
  margin: 20px 0;
  padding: 20px; }
  .container .btn-group .tc-15-btn {
    margin-right: 10px; }

button.default {
  color: #000;
  background-color: #fff;
  border: 1px solid #D9D9D9; }

input,
select,
input.tc-15-input-text,
select.tc-15-input-text {
  width: 330px;
  color: #000000; }

.form-list {
  margin-bottom: 20px; }

.form-input,
.form-label,
.form-output {
  padding-bottom: 20px; }

.form-input {
  vertical-align: middle; }

.form-unit .form-input-help {
  margin-top: 5px;
  margin-bottom: 0;
  max-width: 330px; }

.tc-15-rich-dialog {
  padding: 25px; }
  .tc-15-rich-dialog .form-list {
    margin-bottom: 0; }

.tc-15-rich-dialog-ft {
  margin-top: 20px; }

.tc-15-rich-dialog-hd strong {
  font-size: 12px; }

.tc-15-rich-dialog-hd .tc-15-btn-close {
  width: 29px;
  height: 29px;
  right: 0; }

.tc-15-btn-close:before {
  width: 20px;
  height: 20px; }

.table-thead .tc-15-th-sort-btn {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap; }

.table-gray-row div {
  color: #BEBEBE; }

.table-gray-row td:last-child div {
  color: #006eff; }

.confirm-popEditor > div {
  z-index: 10; }

.confirm-popEditor .tc-15-edit-in-place {
  min-width: 330px;
  border: 1px solid #ddd;
  border-radius: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 20px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  max-width: inherit;
  right: 0; }
  .confirm-popEditor .tc-15-edit-in-place p {
    margin-bottom: 20px; }
  .confirm-popEditor .tc-15-edit-in-place strong {
    font-size: 14px;
    color: #000;
    display: inline-block;
    margin-bottom: 10px; }

.tc-multi-level-selector .search-btn {
  top: 50%;
  transform: translateY(-50%);
  right: 7px; }

.tc-15-dropdown-btn-style .search-btn {
  margin-top: 6px;
  margin-right: 2px; }

.row-columns .form-unit {
  display: inline-block; }

.row-columns input.tc-15-input-text {
  width: 225px; }

.row-columns .btn-column {
  font-size: 12px;
  line-height: 30px;
  color: #006eff;
  padding-left: 10px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer; }

.list-selector-dialog {
  width: 780px; }

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%; }

.app-main {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  background: #f2f2f2; }
  .app-main .main {
    padding-left: 20px;
    padding-right: 20px; }

.davinci-main .main {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.without-tab .withtab {
  height: calc(100vh - 50px); }

.withtab {
  height: calc(100vh - 81px); }

.navbar {
  position: relative;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  background: #fff; }
  .navbar h3 {
    margin: 0; }

.tabbar {
  height: 31px;
  line-height: 31px;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #ddd; }
  .tabbar .tc-15-tablist li {
    margin-right: 10px; }

.sidebar {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 13px;
  background: #333333;
  overflow-y: auto;
  overflow-x: hidden; }
  .sidebar .headline {
    font-size: 20px;
    line-height: 1.2;
    display: block;
    padding: 0 20px;
    color: #fff;
    height: 50px;
    box-sizing: border-box;
    width: 200px;
    margin: 10px 0 -10px 0;
    font-weight: bold; }
    .sidebar .headline .head-title {
      display: inline-block;
      vertical-align: middle; }
  .sidebar .head-titleImg {
    width: 64%;
    margin: 15px 0 0 17%; }
  .sidebar .headline:before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle; }

.table-operate-btn {
  color: #006eff; }
  .table-operate-btn span {
    cursor: pointer; }
  .table-operate-btn span {
    margin-right: 10px; }

.table-tr-height50 .tc-15-table-panel .tc-15-table-box td {
  vertical-align: top;
  padding-top: 7px;
  padding-bottom: 7px; }

.table-tr-middle .tc-15-table-panel .tc-15-table-box td {
  vertical-align: middle; }

.page-padding20 {
  background: #fff;
  padding-top: 20px; }

.text-weak span {
  color: #006EFF;
  margin-left: 10px;
  cursor: pointer; }

.table-padding-bottom {
  padding-bottom: 20px; }

.search-bar {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 10px; }

.search-input-block {
  position: relative;
  float: right;
  width: 180px; }

.table-search-select {
  margin-right: 8px;
  width: 111px;
  min-width: 111px;
  float: left; }

.tc-15-btn {
  line-height: 28px; }

.tc-15-table-panel {
  width: 100%;
  max-width: 100%; }

.tc-15-page {
  width: 100%;
  min-width: 100%; }

.dialog-has-ok .tc-15-rich-dialog-ft-btn-wrap button:first-child {
  display: none; }

.active .tc-time-picker-combobox {
  width: 166px !important; }

.tc-15-btn {
  line-height: 28px; }

.tc-15-simulate-select {
  -webkit-transition: none !important;
  transition: none !important; }

.tc-15-simulate-select.disabled {
  border-color: #ddd;
  background-color: #f2f2f2;
  color: #bbb;
  background-image: none; }

.is-error .tc-15-simulate-select {
  color: #e1504a !important;
  border-color: #e1504a !important; }

.is-error .tc-15-input-text {
  color: #e1504a;
  border-color: #e1504a; }

.tc-15-table-panel .tc-15-table-fixed-body {
  overflow-y: visible; }

.tc-15-step {
  border-bottom: 0;
  margin-left: 0; }

.tc-15-table-box .full-cols-hint {
  height: 50px; }

.tc-15-table-panel .tc-15-table-box td {
  border-bottom: 1px solid #f2f2f2; }

.tc-15-page input.page-num {
  width: 43px !important;
  z-index: 8 !important; }

.tip-info {
  word-break: break-all; }

.tc-15-msg {
  max-width: unset; }

.tc-15-table-panel div.text-overflow,
.tc-15-table-panel p.text-overflow,
.tc-15-table-panel span.text-overflow {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  white-space: unset; }

.tc-15-table-panel th div.text-overflow,
.tc-15-table-panel th p.text-overflow,
.tc-15-table-panel th span.text-overflow {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-box-orient: unset;
  -webkit-line-clamp: unset;
  word-break: unset; }

.tc-15-calendar table caption {
  padding-top: 0;
  padding-bottom: 0; }

.bubble-style .tc-15-bubble-bottom .tc-15-bubble-inner:after {
  border-top-color: #000; }

.bubble-style .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
  background: #000; }

.bubble-style .tc-15-bubble-inner {
  color: #FFF; }

.tc-15-filtrateu.multipleSelect {
  min-width: 100%;
  max-width: 100%; }

.dropdown-list-wrap {
  min-width: 100% !important;
  max-width: 100% !important; }

.tc-15-filtrate-menu {
  margin-bottom: 0; }

.tc-15-dropdown-link {
  max-width: 100% !important; }

/* form-style */
.ant-form .ant-form-item-label, .ant-form.ant-form-horizontal .ant-form-item-label {
  text-align: left; }
  .ant-form .ant-form-item-label label, .ant-form.ant-form-horizontal .ant-form-item-label label {
    font-size: 12px;
    color: #888; }

.ant-form label:after, .ant-form.ant-form-horizontal label:after {
  content: ''; }

.ant-form .ant-form-item-required:before, .ant-form.ant-form-horizontal .ant-form-item-required:before {
  content: ''; }

.ant-form label.ant-form-item-required:after, .ant-form.ant-form-horizontal label.ant-form-item-required:after {
  display: inline-block;
  content: '*';
  vertical-align: middle;
  color: #e1504a;
  margin-left: 3px; }

.ant-form .ant-input, .ant-form.ant-form-horizontal .ant-input {
  border-radius: 0; }
  .ant-form .ant-input:hover, .ant-form.ant-form-horizontal .ant-input:hover {
    border-color: #bbb; }
  .ant-form .ant-input:focus, .ant-form.ant-form-horizontal .ant-input:focus {
    border-color: #fff;
    -webkit-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0; }

.ant-form .ant-time-picker-input, .ant-form.ant-form-horizontal .ant-time-picker-input {
  border-radius: 0; }

.ant-form .ant-form-extra, .ant-form .ant-form-explain, .ant-form.ant-form-horizontal .ant-form-extra, .ant-form.ant-form-horizontal .ant-form-explain {
  font-size: 12px; }

.ant-time-picker-input {
  padding: 0px 0px 0px 11px; }

.ant-menu {
  background: #333333 !important;
  color: #fff !important; }

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none !important; }

.ant-menu-submenu > .ant-menu {
  background-color: #262626 !important; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #4093ff !important;
  font-weight: bold !important;
  background-color: #262626 !important; }

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  background-color: #4c4c4c !important;
  color: #fff !important; }

/* antd */
.antd-tree-style .ant-tree {
  font-size: 12px;
  overflow: hidden; }
  .antd-tree-style .ant-tree .ant-tree-checkbox-inner {
    border-radius: 0;
    border: 1px solid #bbb; }
  .antd-tree-style .ant-tree li:first-child {
    padding: 0; }
  .antd-tree-style .ant-tree li {
    position: relative;
    padding: 0 !important; }
    .antd-tree-style .ant-tree li ul {
      padding: 0 0 0 34px; }
    .antd-tree-style .ant-tree li span i {
      font-size: 13px !important;
      color: #888; }
    .antd-tree-style .ant-tree li > span:first-child {
      width: 16px;
      padding-left: 15px; }
    .antd-tree-style .ant-tree li > span:first-child, .antd-tree-style .ant-tree li > span:nth-child(2) {
      z-index: 1; }
      .antd-tree-style .ant-tree li > span:first-child:hover ~ span.ant-tree-node-content-wrapper, .antd-tree-style .ant-tree li > span:nth-child(2):hover ~ span.ant-tree-node-content-wrapper {
        background-color: #f2f2f2; }
    .antd-tree-style .ant-tree li > span:first-child, .antd-tree-style .ant-tree li > span:last-child {
      height: 36px;
      line-height: 36px; }
    .antd-tree-style .ant-tree li span.ant-tree-switcher {
      margin-right: 10px;
      vertical-align: middle; }
    .antd-tree-style .ant-tree li span.ant-tree-checkbox {
      margin: 0 0 0 10px; }
    .antd-tree-style .ant-tree li span.ant-tree-node-content-wrapper {
      border-radius: 0;
      width: 100%;
      position: absolute;
      left: 0; }
      .antd-tree-style .ant-tree li span.ant-tree-node-content-wrapper span {
        padding-left: 72px; }
      .antd-tree-style .ant-tree li span.ant-tree-node-content-wrapper:hover {
        background-color: #f2f2f2; }
    .antd-tree-style .ant-tree li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: #f2f2f2; }
    .antd-tree-style .ant-tree li .ant-tree-node-content-wrapper {
      padding: 0;
      color: #666666;
      height: 36px;
      line-height: 36px; }
    .antd-tree-style .ant-tree li.tree-item > .ant-tree-node-content-wrapper, .antd-tree-style .ant-tree li.tree-item > .ant-tree-switcher {
      height: 42px;
      line-height: 42px; }
    .antd-tree-style .ant-tree li.tree-item-url > .ant-tree-node-content-wrapper, .antd-tree-style .ant-tree li.tree-item-url > .ant-tree-switcher {
      height: 35px;
      line-height: 35px; }
    .antd-tree-style .ant-tree li.tree-item-url > .ant-tree-node-content-wrapper span {
      padding-left: 52px; }
    .antd-tree-style .ant-tree li.tree-item-url > span:first-child, .antd-tree-style .ant-tree li.tree-item-url > span:nth-child(2) {
      margin: 0; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox:hover {
      border-color: #888; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox-checked::after {
      border: 1px solid transparent; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
      background-color: #006eff;
      border-color: #006eff; }
      .antd-tree-style .ant-tree li .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
        transition: no;
        transition: all 0 ease 0; }
      .antd-tree-style .ant-tree li .ant-tree-checkbox-checked .ant-tree-checkbox-inner:focus {
        outline-color: transparent; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
      background-color: #f2f2f2; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
      border-color: #888; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox-checked:hover .ant-tree-checkbox-inner, .antd-tree-style .ant-tree li .ant-tree-checkbox-indeterminate:hover .ant-tree-checkbox-inner {
      border-color: transparent; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox-indeterminate:hover .ant-tree-checkbox-inner::after {
      background-color: #006eff; }
    .antd-tree-style .ant-tree li .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
      background-color: #006eff;
      border-color: #006eff; }
      .antd-tree-style .ant-tree li .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: #ffffff; }
    .antd-tree-style .ant-tree li .ant-tree-switcher_open .ant-tree-switcher-icon svg, .antd-tree-style .ant-tree li .ant-tree-switcher_close .ant-select-switcher-icon svg {
      transition: no;
      transition: all 0 ease 0; }

.ant-cascader-picker.antdCascader {
  border-radius: 0;
  width: 330px; }
  .ant-cascader-picker.antdCascader .ant-cascader-input {
    border-radius: 0;
    box-shadow: 0 0 0 0 transparent; }
  .ant-cascader-picker.antdCascader .ant-input:focus {
    border-color: #006eff;
    box-shadow: 0 0 0 0 transparent; }
  .ant-cascader-picker.antdCascader .ant-input:hover {
    border-color: #bbb;
    box-shadow: 0 0 0 0 transparent; }
  .ant-cascader-picker.antdCascader .anticon.anticon-down.ant-cascader-picker-arrow {
    display: none; }

.ant-cascader-menus {
  box-shadow: 0 0 0 0;
  border: 1px solid #ddd;
  border-radius: 0 !important; }

.ant-cascader-menu:first-child,
.ant-cascader-menu:last-child {
  border-radius: 0 !important; }

.ant-cascader-menu {
  border-radius: 0; }
  .ant-cascader-menu .ant-cascader-menu-item {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ant-cascader-menu .ant-cascader-menu-item .ant-cascader-menu-item-expand-icon {
      display: none; }
  .ant-cascader-menu .ant-cascader-menu-item:hover {
    background: #006eff !important;
    color: #fff;
    font-weight: normal; }

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #006eff !important;
  color: #fff;
  font-weight: normal !important; }

.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #006eff;
  box-shadow: 0 0 0 0; }

.table-operate-btn {
  color: #006eff; }
  .table-operate-btn span {
    cursor: pointer; }
  .table-operate-btn span {
    margin-right: 10px; }

.table-tr-height50 .tc-15-table-panel .tc-15-table-box td {
  vertical-align: top;
  padding-top: 7px;
  padding-bottom: 7px; }

.table-tr-middle .tc-15-table-panel .tc-15-table-box td {
  vertical-align: middle; }

.page-padding20 {
  background: #fff;
  padding-top: 20px; }

.text-weak span {
  color: #006EFF;
  margin-left: 10px;
  cursor: pointer; }

.table-padding-bottom {
  padding-bottom: 20px; }

.ucode-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  height: 100vh !important; }

body {
  height: 100%;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Roboto,PingFangSC-Light,'helvetica neue','hiragino sans gb',tahoma,'microsoft yahei ui','microsoft yahei',simsun,sans-serif;
  overflow-y: auto;
  font-variant: unset !important; }

html {
  height: 100%;
  box-sizing: border-box; }

.app {
  height: 100%; }

.weak-button {
  background: #fff;
  border: 1px solid #ddd;
  color: #000; }

.weak-button:hover {
  border-color: #ddd;
  color: #000;
  background-color: #f2f2f2; }

.weak-button:active {
  border-color: #ddd;
  color: #000;
  background-color: #f2f2f2; }

.weak-button:focus {
  border-color: #ddd;
  color: #000;
  background-color: #f2f2f2; }

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.moreline-text-ellipsis {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }

.no-overflow {
  overflow: unset; }

.form-edit-border {
  padding-top: 20px;
  border-top: 1px solid #DDD;
  position: relative; }
  .form-edit-border .weak {
    margin-left: 10px; }

.modify-poolname-btn {
  width: 80px;
  font-size: 12px;
  color: #006EFF;
  cursor: pointer;
  position: absolute;
  top: 6px;
  left: 345px; }

.searchbar-box {
  margin: 20px 0 10px;
  overflow: hidden; }
  .searchbar-box .search-input-block {
    width: 180px;
    height: 30px;
    float: right; }

.tc-15-dropdown-btn-style .dropdown-list-wrap {
  max-width: inherit; }

.tc-15-select[disabled] {
  background-color: #f2f2f2;
  color: #bbb; }

.search-input.tc-15-input-text {
  width: 100% !important; }

.fixedHeadWarp .tc-15-table-panel .tc-15-table-box th > div {
  text-align: center; }

.fixedHeadWarp .tc-15-table-panel .tc-15-table-box td {
  text-align: center; }

.fixedHeadWarp .tc-15-table-panel .tc-15-table-fixed-body {
  height: 500px;
  overflow-y: auto; }

.reportWarp .tc-15-table-box .full-cols-hint td .text-center {
  text-align: left !important;
  padding-left: 600px; }

.reportWarp .tc-15-table-panel .tc-15-table-box .disabled .text-center {
  text-align: left !important;
  padding-left: 600px; }

.autoScrollWarp .autoScrollHead .tc-15-dropdown .tc-15-dropdown-menu li a {
  overflow: inherit;
  padding-right: 10px;
  box-sizing: content-box; }

.autoScrollWarp .autoScrollHead .tc-15-filtrate-menu {
  overflow: auto; }

.autoScrollWarp .autoScrollHead .tc-15-filtrateu .tc-15-filtrate-menu .tc-15-checkbox-wrap {
  overflow: inherit; }

button.link-button {
  background-color: transparent;
  color: #006eff;
  outline: none; }
  button.link-button[disabled] {
    color: #888888;
    cursor: not-allowed; }
