.step0 {
  height: 100%;
  font-size: 12px; }
  .step0 .wechatTitle {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px; }
  .step0 .wechat-block {
    width: 699px; }
    .step0 .wechat-block .wechat-box {
      display: inline-block;
      width: 327px;
      height: 72px;
      margin-bottom: 10px;
      border: 1px solid #DDDDDD;
      font-size: 12px;
      color: #888888;
      cursor: pointer; }
      .step0 .wechat-block .wechat-box .wechat-box-text {
        width: 100%;
        padding: 0 15px;
        margin-top: 10px;
        height: 17px;
        line-height: 17px; }
        .step0 .wechat-block .wechat-box .wechat-box-text .appId-text {
          margin-left: 15px; }
        .step0 .wechat-block .wechat-box .wechat-box-text .auth-text {
          float: right; }
    .step0 .wechat-block .wechat-box-checked {
      border: 1px solid #006EFF;
      color: #333333; }
  .step0 .auth-title {
    width: 115px;
    font-size: 12px;
    color: #006EFF;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px; }
  .step0 .outside {
    background: white;
    height: 100%;
    margin-top: 15px;
    padding: 20px; }
    .step0 .outside .inside {
      background: #F8F8F8;
      padding-left: 33px;
      padding-top: 19px;
      padding-bottom: 19px; }
      .step0 .outside .inside .prize-image {
        width: 80px;
        height: 80px;
        position: relative;
        display: inline-block;
        background: #F2F2F2;
        border: 1px solid #D9D9D9;
        text-align: center;
        vertical-align: middle; }
        .step0 .outside .inside .prize-image img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .step0 .outside .inside .prize-image .n-loading-icon {
          position: relative;
          margin-top: 30px;
          z-index: 10; }
        .step0 .outside .inside .prize-image span {
          width: 156px;
          color: #666666;
          display: inline-block;
          position: absolute;
          left: 90px;
          top: -6px; }
      .step0 .outside .inside .row {
        margin-top: 19px;
        display: flex; }
        .step0 .outside .inside .row > span:first-child {
          margin-right: 20px; }
        .step0 .outside .inside .row .widthSpan {
          width: calc(100% - 88px);
          word-break: break-all; }
  .step0 .rightSpan50 {
    margin-left: 10px; }

.step0Edit {
  font-size: 12px;
  background: white;
  margin: 20px 0;
  padding: 20px; }
  .step0Edit .wechatTitle {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #000000; }
  .step0Edit .wechat-block {
    width: 699px; }
    .step0Edit .wechat-block .wechat-box {
      display: inline-block;
      width: 327px;
      height: 72px;
      margin-bottom: 10px;
      border: 1px solid #DDDDDD;
      font-size: 12px;
      color: #888888;
      cursor: pointer; }
      .step0Edit .wechat-block .wechat-box .wechat-box-text {
        width: 100%;
        padding: 0 15px;
        margin-top: 10px;
        height: 17px;
        line-height: 17px; }
        .step0Edit .wechat-block .wechat-box .wechat-box-text .appId-text {
          margin-left: 15px; }
        .step0Edit .wechat-block .wechat-box .wechat-box-text .auth-text {
          float: right; }
    .step0Edit .wechat-block .wechat-box-checked {
      border: 1px solid #006EFF;
      color: #333333; }
  .step0Edit .auth-title {
    width: 115px;
    font-size: 12px;
    color: #006EFF;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px; }
  .step0Edit .tc-15-switch input {
    width: 35px !important; }
  .step0Edit .button-after .base-bubble ~ .is-error .icon-valid-flag {
    display: none !important; }
  .step0Edit .button-after .base-bubble {
    font-size: 12px;
    line-height: 61px;
    position: absolute;
    top: 0;
    left: 336px;
    min-width: 30px;
    color: #006eff; }
    .step0Edit .button-after .base-bubble span {
      color: #000000; }
    .step0Edit .button-after .base-bubble i.icon-what {
      margin: 0 0 3px 4px; }
    .step0Edit .button-after .base-bubble .bubble-inner {
      background: #000;
      border: 1px solid #DDDDDD;
      box-shadow: 1px 1px 4px 0 rgba(187, 187, 187, 0.5); }
    .step0Edit .button-after .base-bubble .tc-15-bubble-bottom .tc-15-bubble-inner:after {
      border-top-color: #000; }
    .step0Edit .button-after .base-bubble .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
      background: #000; }
      .step0Edit .button-after .base-bubble .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
        border-right-color: #000; }
    .step0Edit .button-after .base-bubble .tc-15-bubble-inner {
      color: #FFF; }
  .step0Edit .button-after .button-after-text4 {
    font-size: 12px;
    line-height: 61px;
    position: absolute;
    top: 0;
    left: 370px;
    min-width: 80px;
    color: #006eff; }
  .step0Edit .button-after > .form-input {
    position: relative; }
    .step0Edit .button-after > .form-input .tc-15-select {
      width: 330px; }
    .step0Edit .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .step0Edit .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .step0Edit .button-after > .form-input .button-after-text2-more {
      left: 240px !important; }
    .step0Edit .button-after > .form-input .button-after-text3 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 370px;
      min-width: 60px;
      color: #006eff; }
      .step0Edit .button-after > .form-input .button-after-text3 span {
        color: #000000; }
    .step0Edit .button-after > .form-input .button-after-text3-more {
      left: 270px; }
    .step0Edit .button-after > .form-input .button-after-text2:nth-of-type(2) {
      left: 375px; }
    .step0Edit .button-after > .form-input .button-after-text2-more:nth-of-type(2) {
      left: 275px; }
    .step0Edit .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
    .step0Edit .button-after > .form-input .is-error ~ .button-after-text2-more {
      left: 265px; }
    .step0Edit .button-after > .form-input .is-error ~ .button-after-text3 {
      left: 395px; }
    .step0Edit .button-after > .form-input .is-error ~ .button-after-text3-more {
      left: 295px; }
    .step0Edit .button-after > .form-input .is-error ~ .rc-upload .button-after-text2 {
      left: 365px; }
    .step0Edit .button-after > .form-input .is-error ~ .rc-upload .button-after-text2-more {
      left: 265px; }
  .step0Edit .regular-label-too-long label {
    white-space: normal;
    width: 92px; }
  .step0Edit .regular-more-label > .form-input {
    padding-bottom: 0; }
    .step0Edit .regular-more-label > .form-input .form-label label {
      padding-right: 0; }
  .step0Edit .regular-more-label .tc-15-rich-radio {
    margin-bottom: 0; }
  .step0Edit .regular-more-label .tc-15-dropdown-link {
    max-width: 180px !important; }
  .step0Edit .regular-more-label .tc-input-group {
    min-width: 0 !important; }
  .step0Edit a:focus {
    text-decoration: none !important; }
  .step0Edit .tc-15-dropdown,
  .step0Edit .tc-15-input-text,
  .step0Edit .tc-15-simulate-select,
  .step0Edit .tc-15-select {
    width: 330px; }
  .step0Edit .tc-15-dropdown-link {
    max-width: 230px !important; }
  .step0Edit .form-input {
    position: relative; }
    .step0Edit .form-input .btnCreate {
      right: -64px;
      position: absolute;
      top: 7px; }
  .step0Edit .btnBar {
    border-top: 1px solid #ddd;
    padding-top: 15px; }
    .step0Edit .btnBar .weak-button {
      margin-left: 10px; }
  .step0Edit .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .step0Edit .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .step0Edit .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .step0Edit .prize-image span {
      width: 150px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .step0Edit .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .step0Edit .checked-array-box {
    width: 900px;
    min-width: 900px;
    margin-bottom: 20px; }
    .step0Edit .checked-array-box > .form-input {
      padding-bottom: 0; }
    .step0Edit .checked-array-box .form-list {
      background: #fff;
      margin: 0;
      padding: 0;
      display: flex; }
      .step0Edit .checked-array-box .form-list .form-label label {
        padding-right: 0; }
      .step0Edit .checked-array-box .form-list .tc-time-picker {
        margin-left: 0; }
      .step0Edit .checked-array-box .form-list .tc-15-input-text {
        width: 165px !important; }
    .step0Edit .checked-array-box .add-btn {
      width: 30%;
      color: #006EFF;
      cursor: pointer; }
    .step0Edit .checked-array-box .set-color {
      color: #006EFF;
      cursor: pointer;
      line-height: 30px;
      margin-left: 10px; }
  .step0Edit .inputnolike > .form-input {
    padding-bottom: 0; }
    .step0Edit .inputnolike > .form-input .form-label {
      display: none; }
    .step0Edit .inputnolike > .form-input > li {
      display: inline-block;
      vertical-align: top; }
  .step0Edit .box2 {
    width: 440px;
    border: 1px solid #ddd;
    padding: 5px 20px;
    margin-bottom: 20px;
    line-height: 33px;
    color: #262626; }
    .step0Edit .box2 .box3 {
      padding: 5px 10px;
      border: 1px solid #ddd;
      display: inline-block;
      height: 26px;
      line-height: 14px; }
  .step0Edit .box2-view {
    width: 440px;
    border: 1px solid #ddd;
    padding: 10px 20px;
    margin-bottom: 20px;
    line-height: 23px;
    color: #262626;
    background: #F8F8F8;
    border-radius: 2px;
    display: inline-block; }
  .step0Edit .ml10 {
    margin-left: 10px; }

.dialog {
  font-size: 12px;
  width: 750px; }
  .dialog .left {
    height: 350px;
    border: 1px solid lightgray; }
    .dialog .left .consumptionItems {
      padding: 0 20px 20px;
      height: 320px;
      overflow-y: auto;
      overflow-x: auto; }
      .dialog .left .consumptionItems .consumptionItem {
        margin-top: 20px; }
        .dialog .left .consumptionItems .consumptionItem .item-name {
          display: inline-block;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
        .dialog .left .consumptionItems .consumptionItem .item-btns {
          float: right; }
          .dialog .left .consumptionItems .consumptionItem .item-btns .btn-del {
            margin-left: 10px; }
    .dialog .left .weak-button {
      width: 100%;
      border-top: 1px solid #ddd;
      color: #000;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent; }
  .dialog .right {
    padding: 20px;
    height: 350px;
    border: 1px solid lightgray;
    overflow-y: auto;
    overflow-x: auto; }
  .dialog .right-make-sure {
    position: absolute;
    bottom: 20px;
    right: 20px; }
  .dialog .bottom-bar {
    text-align: center; }
    .dialog .bottom-bar .btn-ok {
      margin-top: 20px; }

.consumptiom-form .form-flex {
  display: flex;
  line-height: 30px; }
  .consumptiom-form .form-flex .form-ctrl-label {
    line-height: 30px;
    width: 60px; }
  .consumptiom-form .form-flex li .tc-input-group {
    min-width: 10px; }
  .consumptiom-form .form-flex li .form-label {
    display: none; }

.consumptiom-form .form-flex75 .tc-15-dropdown-link {
  max-width: 180px !important; }

.consumptiom-form .form-flex75 .form-ctrl-label {
  width: 75px !important; }

.consumptiom-form .is-error .icon-valid-flag {
  display: none; }

.consumptiom-form .tc-15-input-text,
.consumptiom-form .tc-15-select {
  width: 191px; }

.consumptiom-form .details {
  display: flex; }
  .consumptiom-form .details .tc-15-select {
    width: 90px;
    min-width: 90px; }
  .consumptiom-form .details .detailType {
    margin-right: 10px; }
  .consumptiom-form .details .tc-15-input-text {
    width: 50px;
    min-width: 50px; }
  .consumptiom-form .details .btn-del {
    float: right; }

.consumptiom-form .details .btn-del {
  padding-top: 6px;
  padding-left: 10px; }

.cblue {
  color: #006eff !important; }

.cursorp {
  cursor: pointer; }
