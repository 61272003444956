.commonModal {
  width: 430px !important;
  min-width: 430px !important; }
  .commonModal * {
    font-size: 12px !important; }
  .commonModal .cblue {
    color: #006eff !important; }
  .commonModal .cursorp {
    cursor: pointer; }
  .commonModal .clear {
    clear: both; }
  .commonModal .mr5 {
    margin-right: 5px; }
  .commonModal .mr10 {
    margin-right: 10px; }
  .commonModal .valignm {
    vertical-align: middle; }
  .commonModal .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 190px; }

.commonModal.LAST_MEMBER_TAG {
  width: 770px !important;
  min-width: 770px !important; }
  .commonModal.LAST_MEMBER_TAG .form-label label {
    display: none; }

.commonModal.LOTTERY_NUM,
.commonModal.BANQUET_POSITION {
  min-width: 275px !important;
  width: 275px !important; }
  .commonModal.LOTTERY_NUM .inline,
  .commonModal.BANQUET_POSITION .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.LOTTERY_NUM .inline .form-label label,
    .commonModal.BANQUET_POSITION .inline .form-label label {
      display: none; }
    .commonModal.LOTTERY_NUM .inline .form-input-help,
    .commonModal.BANQUET_POSITION .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.LOTTERY_NUM .inline .icon-valid-flag,
    .commonModal.BANQUET_POSITION .inline .icon-valid-flag {
      display: none; }
    .commonModal.LOTTERY_NUM .inline .form-ctrl-label,
    .commonModal.BANQUET_POSITION .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.LOTTERY_NUM .inline .tc-15-select,
    .commonModal.LOTTERY_NUM .inline .tc-input-group,
    .commonModal.BANQUET_POSITION .inline .tc-15-select,
    .commonModal.BANQUET_POSITION .inline .tc-input-group {
      min-width: 0px; }

.commonModal.TAG_LUM {
  min-width: 305px !important;
  width: 305px !important; }
  .commonModal.TAG_LUM .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.TAG_LUM .inline .form-label label {
      display: none; }
    .commonModal.TAG_LUM .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.TAG_LUM .inline .icon-valid-flag {
      display: none; }
    .commonModal.TAG_LUM .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.TAG_LUM .inline .tc-15-select,
    .commonModal.TAG_LUM .inline .tc-input-group {
      min-width: 0px; }

.commonModal.BOX_POSITION {
  min-width: 360px !important;
  width: 360px !important; }
  .commonModal.BOX_POSITION .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.BOX_POSITION .inline .form-label label {
      display: none; }
    .commonModal.BOX_POSITION .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.BOX_POSITION .inline .icon-valid-flag {
      display: none; }
    .commonModal.BOX_POSITION .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.BOX_POSITION .inline .tc-15-select,
    .commonModal.BOX_POSITION .inline .tc-input-group {
      min-width: 0px; }

.commonModal.PRODUCTdaoru,
.commonModal.PARTICIPATING_DEALERSdaoru,
.commonModal.PARTICIPATING_SHOPdaoru,
.commonModal.VERIFY_SHOPdaoru {
  min-width: 520px !important;
  width: 520px !important; }
  .commonModal.PRODUCTdaoru .button-after > .form-input,
  .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input,
  .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input,
  .commonModal.VERIFY_SHOPdaoru .button-after > .form-input {
    position: relative; }
    .commonModal.PRODUCTdaoru .button-after > .form-input .button-after-text2,
    .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .button-after-text2,
    .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .button-after-text2,
    .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .commonModal.PRODUCTdaoru .button-after > .form-input .button-after-text2 span,
      .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .button-after-text2 span,
      .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .button-after-text2 span,
      .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .commonModal.PRODUCTdaoru .button-after > .form-input .button-after-text3,
    .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .button-after-text3,
    .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .button-after-text3,
    .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .button-after-text3 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 370px;
      min-width: 60px;
      color: #006eff; }
      .commonModal.PRODUCTdaoru .button-after > .form-input .button-after-text3 span,
      .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .button-after-text3 span,
      .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .button-after-text3 span,
      .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .button-after-text3 span {
        color: #000000; }
    .commonModal.PRODUCTdaoru .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
    .commonModal.PRODUCTdaoru .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .is-error ~ .button-after-text3 {
      left: 395px; }
    .commonModal.PRODUCTdaoru .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal.PARTICIPATING_DEALERSdaoru .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal.PARTICIPATING_SHOPdaoru .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal.VERIFY_SHOPdaoru .button-after > .form-input .is-error ~ .rc-upload .button-after-text2 {
      left: 365px; }

.commonModal.TIME_RANGE_PROMOTIONS_LUM {
  min-width: 480px !important;
  width: 480px !important; }
  .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline .form-label label {
      display: none; }
    .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline .icon-valid-flag {
      display: none; }
    .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline .tc-15-select,
    .commonModal.TIME_RANGE_PROMOTIONS_LUM .inline .tc-input-group {
      min-width: 0px; }

.commonModal.TIME_PERIOD_PROMOTIONS_LUM,
.commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM {
  min-width: 335px !important;
  width: 335px !important; }
  .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline,
  .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline .form-label label,
    .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline .form-label label {
      display: none; }
    .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline .form-input-help,
    .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline .icon-valid-flag,
    .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline .icon-valid-flag {
      display: none; }
    .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline .form-ctrl-label,
    .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline .tc-15-select,
    .commonModal.TIME_PERIOD_PROMOTIONS_LUM .inline .tc-input-group,
    .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline .tc-15-select,
    .commonModal.TIME_PERIOD_DAYS_PROMOTIONS_LUM .inline .tc-input-group {
      min-width: 0px; }

.commonModal.COUNTER {
  min-width: 335px !important;
  width: 335px !important; }
  .commonModal.COUNTER .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.COUNTER .inline .form-label label {
      display: none; }
    .commonModal.COUNTER .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.COUNTER .inline .icon-valid-flag {
      display: none; }
    .commonModal.COUNTER .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.COUNTER .inline .tc-15-select,
    .commonModal.COUNTER .inline .tc-input-group {
      min-width: 0px; }

.commonModal.GPS_CITY,
.commonModal.GPS_DISTRICT,
.commonModal.ACCESS_GPS_CITY,
.commonModal.ACCESS_GPS_DISTRICT,
.commonModal.POSITION_SCENE_SMALL,
.commonModal.CITY_SHOP {
  width: 770px !important;
  min-width: 770px !important; }
  .commonModal.GPS_CITY .form-label label,
  .commonModal.GPS_DISTRICT .form-label label,
  .commonModal.ACCESS_GPS_CITY .form-label label,
  .commonModal.ACCESS_GPS_DISTRICT .form-label label,
  .commonModal.POSITION_SCENE_SMALL .form-label label,
  .commonModal.CITY_SHOP .form-label label {
    display: none; }

.commonModal.CODE_TAGVALUE,
.commonModal.CODE_TAGLABEL {
  width: 770px !important;
  min-width: 770px !important; }
  .commonModal.CODE_TAGVALUE .form-label label,
  .commonModal.CODE_TAGLABEL .form-label label {
    display: none; }

.commonModal.ACCESS_GPS_POSITION,
.commonModal.GPS_POSITION,
.commonModal.CHECKIN_GPS_POSITION {
  width: 630px !important;
  min-width: 630px !important; }
  .commonModal.ACCESS_GPS_POSITION .button-after > .form-input,
  .commonModal.GPS_POSITION .button-after > .form-input,
  .commonModal.CHECKIN_GPS_POSITION .button-after > .form-input {
    position: relative; }
  .commonModal.ACCESS_GPS_POSITION .inline,
  .commonModal.GPS_POSITION .inline,
  .commonModal.CHECKIN_GPS_POSITION .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.ACCESS_GPS_POSITION .inline .form-label label,
    .commonModal.GPS_POSITION .inline .form-label label,
    .commonModal.CHECKIN_GPS_POSITION .inline .form-label label {
      display: none; }
    .commonModal.ACCESS_GPS_POSITION .inline .form-input-help,
    .commonModal.GPS_POSITION .inline .form-input-help,
    .commonModal.CHECKIN_GPS_POSITION .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.ACCESS_GPS_POSITION .inline .icon-valid-flag,
    .commonModal.GPS_POSITION .inline .icon-valid-flag,
    .commonModal.CHECKIN_GPS_POSITION .inline .icon-valid-flag {
      display: none; }
    .commonModal.ACCESS_GPS_POSITION .inline .form-ctrl-label,
    .commonModal.GPS_POSITION .inline .form-ctrl-label,
    .commonModal.CHECKIN_GPS_POSITION .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.ACCESS_GPS_POSITION .inline .tc-15-select,
    .commonModal.ACCESS_GPS_POSITION .inline .tc-input-group,
    .commonModal.GPS_POSITION .inline .tc-15-select,
    .commonModal.GPS_POSITION .inline .tc-input-group,
    .commonModal.CHECKIN_GPS_POSITION .inline .tc-15-select,
    .commonModal.CHECKIN_GPS_POSITION .inline .tc-input-group {
      min-width: 0px; }

.commonModal.TIME_RANGE {
  min-width: 432px !important;
  width: 432px !important; }
  .commonModal.TIME_RANGE .tc-15-calendar-footer {
    box-sizing: content-box; }
  .commonModal.TIME_RANGE .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.TIME_RANGE .inline .form-label label {
      display: none; }
    .commonModal.TIME_RANGE .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.TIME_RANGE .inline .icon-valid-flag {
      display: none; }
    .commonModal.TIME_RANGE .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.TIME_RANGE .inline .tc-15-select,
    .commonModal.TIME_RANGE .inline .tc-input-group {
      min-width: 0px; }

.commonModal.TIME_PERIOD {
  width: 400px !important;
  min-width: 400px !important; }
  .commonModal.TIME_PERIOD .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.TIME_PERIOD .inline .form-label label {
      display: none; }
    .commonModal.TIME_PERIOD .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.TIME_PERIOD .inline .icon-valid-flag {
      display: none; }
    .commonModal.TIME_PERIOD .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.TIME_PERIOD .inline .tc-15-select,
    .commonModal.TIME_PERIOD .inline .tc-input-group {
      min-width: 0px; }
  .commonModal.TIME_PERIOD .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 80px; }
  .commonModal.TIME_PERIOD .timePeriodView .inline3 .form-input-help {
    margin-left: 10px; }

.commonModal.NEW_MEMBER_TAG .inline {
  display: inline-block;
  float: left;
  margin-bottom: 20px; }
  .commonModal.NEW_MEMBER_TAG .inline .form-label label {
    display: none; }

.commonModal.copyModal {
  width: 340px !important;
  min-width: 340px !important; }

.templateSortModal {
  width: 467px !important;
  min-width: 467px !important; }
  .templateSortModal .box {
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none; }
    .templateSortModal .box .box-head,
    .templateSortModal .box .box-body {
      border-bottom: 1px solid #ddd; }
      .templateSortModal .box .box-head > div,
      .templateSortModal .box .box-body > div {
        padding: 0 10px;
        width: 33.33%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
    .templateSortModal .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .templateSortModal .box .box-body:hover {
      background-color: #f7f7f7; }
    .templateSortModal .box .box-body > div {
      font-weight: normal; }
  .templateSortModal .close {
    color: #bbb !important; }

.awardAddModal {
  width: 770px !important;
  min-width: 770px !important; }

.rightPoolModal,
.awardModal {
  width: 535px !important;
  min-width: 535px !important; }
  .rightPoolModal .prize-image,
  .awardModal .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .rightPoolModal .prize-image img,
    .awardModal .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .rightPoolModal .prize-image .n-loading-icon,
    .awardModal .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .rightPoolModal .prize-image span,
    .awardModal .prize-image span {
      width: 156px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .rightPoolModal .prize-image-upload,
  .awardModal .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .rightPoolModal .extra-text,
  .awardModal .extra-text {
    color: #666666;
    line-height: 30px;
    width: 276px; }
  .rightPoolModal .ant-upload,
  .awardModal .ant-upload {
    vertical-align: bottom; }
  .rightPoolModal .inline,
  .awardModal .inline {
    display: inline-block;
    float: left; }
    .rightPoolModal .inline .form-label label,
    .awardModal .inline .form-label label {
      display: none; }
    .rightPoolModal .inline .form-input-help,
    .awardModal .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .rightPoolModal .inline .icon-valid-flag,
    .awardModal .inline .icon-valid-flag {
      display: none; }
    .rightPoolModal .inline .form-ctrl-label,
    .awardModal .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .rightPoolModal .inline .tc-15-select,
    .rightPoolModal .inline .tc-input-group,
    .awardModal .inline .tc-15-select,
    .awardModal .inline .tc-input-group {
      min-width: 0px; }
  .rightPoolModal .inline2,
  .awardModal .inline2 {
    margin-top: 60px; }
    .rightPoolModal .inline2 .form-list,
    .awardModal .inline2 .form-list {
      position: relative;
      left: -40px; }
    .rightPoolModal .inline2 .tc-15-select,
    .awardModal .inline2 .tc-15-select {
      width: 100px; }
  .rightPoolModal .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner,
  .awardModal .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .rightPoolModal .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after,
    .awardModal .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .rightPoolModal .form-list,
  .awardModal .form-list {
    margin-bottom: 20px !important; }
  .rightPoolModal .formlistLast .form-list,
  .awardModal .formlistLast .form-list {
    margin-bottom: 0px !important; }

.rightPoolModal .formlistLast .icon-valid-flag {
  display: none; }

.rightZuHePoolModal {
  width: 660px !important;
  min-width: 660px !important; }
  .rightZuHePoolModal .box {
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none;
    width: 500px;
    margin-left: 42px; }
    .rightZuHePoolModal .box .box-head,
    .rightZuHePoolModal .box .box-body {
      border-bottom: 1px solid #ddd;
      position: relative; }
      .rightZuHePoolModal .box .box-head > div,
      .rightZuHePoolModal .box .box-body > div {
        padding: 0 10px;
        width: 28%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .rightZuHePoolModal .box .box-head > div:last-child,
      .rightZuHePoolModal .box .box-body > div:last-child {
        width: 15%; }
    .rightZuHePoolModal .box .box-head2,
    .rightZuHePoolModal .box .box-body2 {
      display: flex;
      justify-content: space-between; }
      .rightZuHePoolModal .box .box-head2 > div,
      .rightZuHePoolModal .box .box-body2 > div {
        flex: 1; }
    .rightZuHePoolModal .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .rightZuHePoolModal .box .box-body:hover {
      background-color: #f7f7f7; }
    .rightZuHePoolModal .box .box-body > div {
      font-weight: normal; }
    .rightZuHePoolModal .box .is-error .form-ctrl-label,
    .rightZuHePoolModal .box .is-error .form-input-help {
      line-height: 0;
      margin-bottom: 20px; }
    .rightZuHePoolModal .box .form-input,
    .rightZuHePoolModal .box .form-label,
    .rightZuHePoolModal .box .form-output {
      padding-bottom: 0; }
    .rightZuHePoolModal .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
      width: 130px; }
    .rightZuHePoolModal .box .icon-valid-flag {
      display: none; }
    .rightZuHePoolModal .box .tc-input-group {
      line-height: 28px; }
  .rightZuHePoolModal .confirm-popEditor {
    position: absolute;
    top: -100px;
    height: 200px;
    width: 120% !important; }
    .rightZuHePoolModal .confirm-popEditor .tc-15-edit-in-place {
      overflow: visible; }
    .rightZuHePoolModal .confirm-popEditor .inline {
      display: inline-block;
      float: left; }
  .rightZuHePoolModal .tc-15-select,
  .rightZuHePoolModal .tc-input-group {
    min-width: 0px; }
  .rightZuHePoolModal .form-list {
    margin-bottom: 20px !important; }
  .rightZuHePoolModal .box-specify {
    display: flex;
    margin-top: 66px;
    position: relative; }
    .rightZuHePoolModal .box-specify .confirm-popEditor {
      width: 140% !important; }
    .rightZuHePoolModal .box-specify .specify-poolId {
      margin-right: 20px; }
      .rightZuHePoolModal .box-specify .specify-poolId .form-label {
        display: none; }
    .rightZuHePoolModal .box-specify .icon-valid-flag {
      display: none; }
  .rightZuHePoolModal .specify-label .form-label label {
    width: 92px; }
  .rightZuHePoolModal .specify-label .tc-15-rich-radio .form-ctrl-label {
    display: block;
    height: 40px;
    line-height: 40px; }

.AcurateModal {
  width: 370px !important;
  min-width: 370px !important; }
  .AcurateModal .extra-text {
    color: #666666;
    line-height: 30px;
    width: 276px; }
  .AcurateModal .ant-upload {
    vertical-align: bottom; }
  .AcurateModal .inline {
    display: inline-block;
    float: left; }
    .AcurateModal .inline .form-label label {
      display: none; }
    .AcurateModal .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .AcurateModal .inline .icon-valid-flag {
      display: none; }
    .AcurateModal .inline .form-ctrl-label {
      display: block;
      height: 50px;
      line-height: 50px; }
    .AcurateModal .inline .tc-15-select,
    .AcurateModal .inline .tc-input-group {
      min-width: 0px; }
  .AcurateModal .inline2 {
    margin-top: 63px; }
    .AcurateModal .inline2 .form-list {
      position: relative;
      left: -40px; }
    .AcurateModal .inline2 .tc-15-select {
      width: 100px; }
  .AcurateModal .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .AcurateModal .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .AcurateModal .form-list {
    margin-bottom: 20px !important; }
  .AcurateModal .formlistLast .form-list {
    margin-bottom: 0px !important; }

.prizeSiteModal {
  width: 800px; }
  .prizeSiteModal .form-list .form-label .label,
  .prizeSiteModal .form-list .form-label label {
    max-width: 490px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.commonModalJNC {
  width: 430px !important;
  min-width: 430px !important; }

.commonModal.ASSOCIATED_SCANNED {
  min-width: 345px !important;
  width: 345px !important; }

.commonModal.ASSOCIATED_LEVEL_SCANNED .inline .form-label label {
  display: none; }

.commonModal.TOTAL_SCAN,
.commonModal.TOTAL_PEOPLE,
.commonModal.TOTAL_CITY {
  min-width: 275px !important;
  width: 275px !important; }
  .commonModal.TOTAL_SCAN .inline,
  .commonModal.TOTAL_PEOPLE .inline,
  .commonModal.TOTAL_CITY .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.TOTAL_SCAN .inline .form-label label,
    .commonModal.TOTAL_PEOPLE .inline .form-label label,
    .commonModal.TOTAL_CITY .inline .form-label label {
      display: none; }
    .commonModal.TOTAL_SCAN .inline .form-input-help,
    .commonModal.TOTAL_PEOPLE .inline .form-input-help,
    .commonModal.TOTAL_CITY .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.TOTAL_SCAN .inline .icon-valid-flag,
    .commonModal.TOTAL_PEOPLE .inline .icon-valid-flag,
    .commonModal.TOTAL_CITY .inline .icon-valid-flag {
      display: none; }
    .commonModal.TOTAL_SCAN .inline .form-ctrl-label,
    .commonModal.TOTAL_PEOPLE .inline .form-ctrl-label,
    .commonModal.TOTAL_CITY .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.TOTAL_SCAN .inline .tc-15-select,
    .commonModal.TOTAL_SCAN .inline .tc-input-group,
    .commonModal.TOTAL_PEOPLE .inline .tc-15-select,
    .commonModal.TOTAL_PEOPLE .inline .tc-input-group,
    .commonModal.TOTAL_CITY .inline .tc-15-select,
    .commonModal.TOTAL_CITY .inline .tc-input-group {
      min-width: 0px; }

.commonModal.RECENTLY_SCAN,
.commonModal.RECENTLY_PEOPLE,
.commonModal.RECENTLY_CITY {
  min-width: 375px !important;
  width: 375px !important; }
  .commonModal.RECENTLY_SCAN .inline,
  .commonModal.RECENTLY_PEOPLE .inline,
  .commonModal.RECENTLY_CITY .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.RECENTLY_SCAN .inline .form-label label,
    .commonModal.RECENTLY_PEOPLE .inline .form-label label,
    .commonModal.RECENTLY_CITY .inline .form-label label {
      display: none; }
    .commonModal.RECENTLY_SCAN .inline .form-input-help,
    .commonModal.RECENTLY_PEOPLE .inline .form-input-help,
    .commonModal.RECENTLY_CITY .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.RECENTLY_SCAN .inline .icon-valid-flag,
    .commonModal.RECENTLY_PEOPLE .inline .icon-valid-flag,
    .commonModal.RECENTLY_CITY .inline .icon-valid-flag {
      display: none; }
    .commonModal.RECENTLY_SCAN .inline .form-ctrl-label,
    .commonModal.RECENTLY_PEOPLE .inline .form-ctrl-label,
    .commonModal.RECENTLY_CITY .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.RECENTLY_SCAN .inline .tc-15-select,
    .commonModal.RECENTLY_SCAN .inline .tc-input-group,
    .commonModal.RECENTLY_PEOPLE .inline .tc-15-select,
    .commonModal.RECENTLY_PEOPLE .inline .tc-input-group,
    .commonModal.RECENTLY_CITY .inline .tc-15-select,
    .commonModal.RECENTLY_CITY .inline .tc-input-group {
      min-width: 0px; }
