.basictag-container {
  margin: 20px auto;
  padding: 20px;
  background: #fff; }
  .basictag-container .tc-15-bubble.tc-15-bubble-bottom {
    margin-bottom: 0; }
    .basictag-container .tc-15-bubble.tc-15-bubble-bottom .tc-15-bubble-inner {
      background: #000;
      color: #fff; }
      .basictag-container .tc-15-bubble.tc-15-bubble-bottom .tc-15-bubble-inner:after {
        border-top-color: #000; }
  .basictag-container .time-none .tc-15-bubble-inner {
    display: none; }
  .basictag-container .basictag-item {
    margin: 0 20px 20px 0; }
