.BanquetOrder {
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  padding-top: 14px; }

.BanquetOrder-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

.BanquetOrderEdit {
  background-color: white;
  padding: 20px;
  margin-top: 20px; }

.BanquetOrderEdit-footer {
  padding-top: 20px;
  margin-top: 20px;
  border-top: solid 1px #e2e2e2; }

.BanquetOrder-WatchDialog .-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  margin-bottom: 10px; }
  .BanquetOrder-WatchDialog .-list .-list-item {
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px; }
    .BanquetOrder-WatchDialog .-list .-list-item > div:first-child {
      width: 6em;
      flex-shrink: 0;
      color: #888888; }
    .BanquetOrder-WatchDialog .-list .-list-item > div:last-child {
      word-break: break-all;
      padding: 0 8px; }

.BanquetOrder-WatchDialog table tr th {
  font-weight: normal;
  border: solid 1px #dddddd;
  padding: 5px; }

.BanquetOrder-WatchDialog table tr td {
  border: solid 1px #dddddd;
  padding: 5px; }
