.codeBatchQuery-dialog .tabPage1 .line input.tc-15-input-text {
  width: 100px !important; }

.codeBatchQuery-dialog .content {
  font-size: 12px;
  margin-top: 20px;
  color: #888; }
  .codeBatchQuery-dialog .content .line {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 20px; }
    .codeBatchQuery-dialog .content .line .tc-15-input-text {
      width: auto; }
    .codeBatchQuery-dialog .content .line .left {
      width: 90px;
      text-align: left;
      line-height: 30px;
      flex-shrink: 0; }
    .codeBatchQuery-dialog .content .line .left.left60 {
      width: 60px; }
    .codeBatchQuery-dialog .content .line .right {
      width: calc(100% - 90px); }
      .codeBatchQuery-dialog .content .line .right .ty-reset-date-range-picker {
        margin-left: 0 !important; }
      .codeBatchQuery-dialog .content .line .right .tc-15-dropdown-btn-style .tc-15-dropdown-link {
        width: 100%; }
      .codeBatchQuery-dialog .content .line .right .top {
        display: flex;
        justify-content: space-between; }
      .codeBatchQuery-dialog .content .line .right .txt {
        height: 24px;
        line-height: 24px;
        padding-left: 9px; }
      .codeBatchQuery-dialog .content .line .right .txt.mb10 {
        margin-bottom: 10px; }

.code-batch-query .blue {
  color: #006eff;
  cursor: pointer; }

.awtagBathDownload-showparamsdialog div {
  margin-bottom: 5px; }
