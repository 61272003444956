.screenOrderManage .searchbar-box .searchbar-box-input {
  width: 180px;
  float: right; }

.screenOrderManage .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }

.screenOrder-form .tc-15-dropdown,
.screenOrder-form .tc-15-input-text,
.screenOrder-form .tc-15-simulate-select,
.screenOrder-form .tc-15-select {
  width: 330px; }

.screenOrder-form .prize-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #F2F2F2;
  border: 1px solid #D9D9D9;
  text-align: center;
  vertical-align: middle; }
  .screenOrder-form .prize-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .screenOrder-form .prize-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }
  .screenOrder-form .prize-image span {
    width: 156px;
    color: #666666;
    display: inline-block;
    position: absolute;
    left: 90px;
    top: -6px; }

.screenOrder-form .prize-image-upload {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom; }

.screenOrder-form .box {
  width: calc(100% - 20px);
  border: 1px solid #ddd;
  border-top: none;
  line-height: 23px;
  color: #262626;
  margin-top: 20px;
  margin-bottom: 20px; }
  .screenOrder-form .box * {
    font-size: 12px; }
  .screenOrder-form .box .box-head,
  .screenOrder-form .box .box-body {
    border-bottom: 1px solid #ddd; }
    .screenOrder-form .box .box-head > div,
    .screenOrder-form .box .box-body > div {
      padding: 0 10px;
      width: 33%;
      vertical-align: middle;
      line-height: 48px;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .screenOrder-form .box .box-head .form-input,
    .screenOrder-form .box .box-head .form-label,
    .screenOrder-form .box .box-head .form-output,
    .screenOrder-form .box .box-body .form-input,
    .screenOrder-form .box .box-body .form-label,
    .screenOrder-form .box .box-body .form-output {
      display: block;
      /* vertical-align: top; */
      padding-bottom: 0px; }
    .screenOrder-form .box .box-head .icon-valid-flag,
    .screenOrder-form .box .box-body .icon-valid-flag {
      display: none; }
    .screenOrder-form .box .box-head .tc-15-select,
    .screenOrder-form .box .box-body .tc-15-select {
      min-width: 0; }
    .screenOrder-form .box .box-head .tc-input-group,
    .screenOrder-form .box .box-body .tc-input-group {
      width: 100%;
      min-width: 0; }
    .screenOrder-form .box .box-head .tc-input-group .tc-input-group-addon,
    .screenOrder-form .box .box-body .tc-input-group .tc-input-group-addon {
      width: 33px; }
  .screenOrder-form .box .box-head > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    min-height: 40px; }
  .screenOrder-form .box .box-body:hover {
    background-color: #f7f7f7; }
  .screenOrder-form .box .wd50 > div {
    width: 50%; }
  .screenOrder-form .box .box-body > div {
    font-weight: normal; }
  .screenOrder-form .box .box-body:last-child {
    border-bottom: none; }
  .screenOrder-form .box .form-input {
    padding-bottom: 0; }
  .screenOrder-form .box .form-input-help {
    line-height: 0;
    margin-bottom: 12px; }
  .screenOrder-form .box .tc-input-group {
    line-height: 28px; }
  .screenOrder-form .box .boxPROBABILITY {
    margin-left: 10px;
    margin-right: 10px; }
    .screenOrder-form .box .boxPROBABILITY span {
      color: rgba(0, 0, 0, 0.65); }
    .screenOrder-form .box .boxPROBABILITY .inline {
      display: inline-block;
      margin-top: 20px;
      margin-left: 10px; }
      .screenOrder-form .box .boxPROBABILITY .inline .form-label label {
        display: none; }
      .screenOrder-form .box .boxPROBABILITY .inline .form-input-help {
        float: left;
        margin-bottom: 0;
        line-height: inherit !important; }
      .screenOrder-form .box .boxPROBABILITY .inline .icon-valid-flag {
        display: none; }
      .screenOrder-form .box .boxPROBABILITY .inline .form-ctrl-label {
        display: block;
        height: 40px;
        line-height: 40px; }
      .screenOrder-form .box .boxPROBABILITY .inline .tc-15-select,
      .screenOrder-form .box .boxPROBABILITY .inline .tc-input-group {
        min-width: 0px; }

.screenOrder-form .cblue {
  color: #006eff !important; }

.screenOrder-form .cursorp {
  cursor: pointer; }
