.MinProgramServiceMedia .container {
  margin: 0; }

.MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.miniModifyServiceMedia {
  width: 520px; }
  .miniModifyServiceMedia .tc-15-dropdown-link {
    width: 100%; }
  .miniModifyServiceMedia .tc-15-simulate-select,
  .miniModifyServiceMedia .tc-15-dropdown {
    width: 330px; }
  .miniModifyServiceMedia .upload {
    width: 330px;
    display: inline-block;
    font-size: 12px;
    color: #666; }
    .miniModifyServiceMedia .upload .block {
      margin-bottom: 5px; }
      .miniModifyServiceMedia .upload .block > div:first-child {
        width: 80px;
        height: 80px;
        float: left;
        background: #F2F2F2;
        margin-right: 5px; }
      .miniModifyServiceMedia .upload .block .hint {
        padding-bottom: 32px; }
    .miniModifyServiceMedia .upload .prize-image {
      width: 80px;
      height: 80px;
      position: relative;
      display: inline-block;
      background: #F2F2F2;
      border: 1px solid #D9D9D9;
      text-align: center;
      vertical-align: middle; }
      .miniModifyServiceMedia .upload .prize-image img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .miniModifyServiceMedia .upload .prize-image .n-loading-icon {
        position: relative;
        margin-top: 30px;
        z-index: 10; }
      .miniModifyServiceMedia .upload .prize-image span {
        width: 156px;
        color: #666666;
        display: inline-block;
        position: absolute;
        left: 90px;
        top: -6px; }
