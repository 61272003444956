.ty-amount-bill-panel.real-time h3 {
  margin: 0; }

.ty-amount-bill-panel.real-time .bm {
  margin-top: 20px; }
  .ty-amount-bill-panel.real-time .bm .count {
    font-size: 32px;
    color: #FF7643; }
  .ty-amount-bill-panel.real-time .bm .unit {
    margin-left: 4px;
    font-size: 12px;
    color: #454A51; }

.ty-form {
  display: inline-block;
  position: relative;
  color: #000; }
  .ty-form .tc-15-input-text {
    color: #888; }
  .ty-form .tc-15-dropdown {
    pointer-events: none; }
    .ty-form .tc-15-dropdown .tc-15-dropdown-link {
      color: #888; }
      .ty-form .tc-15-dropdown .tc-15-dropdown-link em {
        color: #888 !important; }
    .ty-form .tc-15-dropdown a {
      pointer-events: none; }
    .ty-form .tc-15-dropdown:before {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
      content: '';
      height: 100%;
      width: 100%;
      z-index: 9999; }
  .ty-form.active {
    color: #000; }
    .ty-form.active .tc-15-input-text {
      color: #000; }
    .ty-form.active .tc-15-dropdown-link {
      color: #000; }
      .ty-form.active .tc-15-dropdown-link em {
        color: #000 !important; }
    .ty-form.active .tc-15-dropdown {
      pointer-events: auto; }
      .ty-form.active .tc-15-dropdown a {
        pointer-events: auto; }
      .ty-form.active .tc-15-dropdown:before {
        content: none; }

.RealTimeManagement .tc-15-tablist {
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #fff; }

.RealTimeManagement .tc-15-dropdown {
  width: 180px; }

.RealTimeManagement ul {
  margin-bottom: 0; }

.RealTimeManagement .search-btn {
  margin-top: 5px !important; }

.m-l-10 {
  margin-left: 10px; }

.width-180 {
  width: 180px; }

.RealTimeManagement .tc-15-dropdown.tc-15-dropdown-btn-style a.tc-15-dropdown-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px; }

.echartWarpReal {
  position: relative; }
  .echartWarpReal .lengedBottom {
    position: absolute;
    bottom: -10px;
    width: 16%;
    left: 29%;
    display: flex;
    justify-content: space-between; }
    .echartWarpReal .lengedBottom span {
      display: inline-block;
      width: 46px;
      height: 20px;
      background: rgba(0, 0, 0, 0); }
  .echartWarpReal .User {
    width: 10%;
    left: 46%; }
  .echartWarpReal .num {
    width: 14%;
    left: 44%; }
    .echartWarpReal .num span {
      width: 74px; }
  .echartWarpReal .peopleNo {
    position: absolute;
    left: 43%;
    width: 15%;
    height: 30px;
    top: 22%;
    background: rgba(0, 0, 0, 0); }
  .echartWarpReal .scanNo {
    width: 14%;
    left: 44%; }
  .echartWarpReal .wxChart {
    width: 11%;
    left: 46%; }
    .echartWarpReal .wxChart span {
      width: 64px; }
