.labelBatchQuery-container .header {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; }
  .labelBatchQuery-container .header .ml {
    margin-left: 10px; }
  .labelBatchQuery-container .header .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: inherit; }

.labelBatchQuery-container .body {
  background-color: white;
  padding: 20px;
  font-size: 12px;
  margin-bottom: 20px; }
  .labelBatchQuery-container .body .box {
    width: 400px;
    height: 480px;
    border: 1px solid #ddd;
    font-size: 12px;
    margin-right: 20px; }
    .labelBatchQuery-container .body .box .box-head {
      font-weight: bold;
      height: 49px;
      line-height: 49px;
      border-bottom: 1px solid #ddd;
      padding-left: 20px; }
    .labelBatchQuery-container .body .box .box-body {
      height: 429px;
      overflow-y: auto; }
      .labelBatchQuery-container .body .box .box-body .boxItem {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ddd;
        height: 30px;
        line-height: 30px;
        font-size: 12px; }
        .labelBatchQuery-container .body .box .box-body .boxItem .name {
          width: 330px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 20px; }
        .labelBatchQuery-container .body .box .box-body .boxItem .delete {
          padding-right: 20px;
          cursor: pointer;
          color: #006eff; }
      .labelBatchQuery-container .body .box .box-body .no-content {
        color: #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit; }
  .labelBatchQuery-container .body .box:nth-last-of-type(1) {
    margin-right: 0px; }
  .labelBatchQuery-container .body .form-label {
    width: 92px; }
  .labelBatchQuery-container .body .codeLevleItem .form-label {
    width: 10px; }
