.Step2EditPage_tip {
  margin-top: 20px; }

.Step2EditPage * {
  box-sizing: border-box;
  font-size: 12px !important; }

.Step2EditPage {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 17px; }
  .Step2EditPage .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .Step2EditPage .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0; }
  .Step2EditPage .tc-15-step li.current .tc-15-step-num:before,
  .Step2EditPage .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .Step2EditPage .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .Step2EditPage .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .Step2EditPage .step2-view {
    width: 100%;
    min-height: 600px;
    display: table;
    height: 100%; }
    .Step2EditPage .step2-view .left-view {
      padding-top: 10px;
      width: 160px;
      min-width: 160px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 2px solid #e5e5e5;
      vertical-align: top; }
      .Step2EditPage .step2-view .left-view .list {
        width: 158px;
        margin-bottom: 14px;
        cursor: pointer; }
        .Step2EditPage .step2-view .left-view .list .list-icon {
          display: inline-block;
          width: 4px;
          height: 23px;
          vertical-align: middle; }
        .Step2EditPage .step2-view .left-view .list .list-icon2 {
          overflow: hidden;
          margin-left: 4px;
          min-width: 18px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid #aaa;
          border-radius: 2px;
          color: #aaa;
          line-height: 16px;
          display: inline-block;
          height: 18px;
          margin-top: 2px; }
        .Step2EditPage .step2-view .left-view .list .list-title {
          margin-left: 6px;
          line-height: 23px;
          vertical-align: middle;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px !important;
          width: calc(100% - 34px); }
        .Step2EditPage .step2-view .left-view .list .list-close {
          color: #bbb; }
        .Step2EditPage .step2-view .left-view .list .icon-select {
          background-color: #006eff; }
        .Step2EditPage .step2-view .left-view .list .title-select {
          font-weight: bold;
          color: #006eff; }
    .Step2EditPage .step2-view .right-view {
      position: relative;
      padding-top: 10px;
      height: 100%;
      width: 100%;
      display: table-cell;
      vertical-align: top; }
      .Step2EditPage .step2-view .right-view .title {
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5; }
        .Step2EditPage .step2-view .right-view .title .nolabel {
          display: inline-block;
          padding: 0px;
          vertical-align: top; }
          .Step2EditPage .step2-view .right-view .title .nolabel .form-input {
            padding-bottom: 0; }
        .Step2EditPage .step2-view .right-view .title .nolabelspan {
          vertical-align: top;
          margin-left: -15px; }
        .Step2EditPage .step2-view .right-view .title > p {
          line-height: 23px;
          padding-left: 25px;
          margin-bottom: 5px; }
        .Step2EditPage .step2-view .right-view .title .box {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          line-height: 23px;
          color: #262626; }
        .Step2EditPage .step2-view .right-view .title .box-carveUp {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          line-height: 23px;
          color: #262626; }
        .Step2EditPage .step2-view .right-view .title .box2 {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          margin-bottom: 15px;
          line-height: 33px;
          color: #262626;
          display: inline-block; }
          .Step2EditPage .step2-view .right-view .title .box2 .box3 {
            padding: 5px 10px;
            border: 1px solid #ddd;
            display: inline-block;
            height: 26px;
            line-height: 14px; }
        .Step2EditPage .step2-view .right-view .title .box2-view {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 10px 20px;
          margin-bottom: 15px;
          line-height: 23px;
          color: #262626;
          background: #F8F8F8;
          padding-bottom: 0;
          border-radius: 2px;
          display: inline-block; }
        .Step2EditPage .step2-view .right-view .title .second-title-new .box2-view,
        .Step2EditPage .step2-view .right-view .title .second-title-new .box2 {
          margin-left: 0 !important;
          padding: 0 !important;
          width: calc(100% - 130px) !important; }
      .Step2EditPage .step2-view .right-view .normal {
        padding-top: 15px;
        border-bottom: none; }
        .Step2EditPage .step2-view .right-view .normal .box {
          padding: 0; }
          .Step2EditPage .step2-view .right-view .normal .box .box-head,
          .Step2EditPage .step2-view .right-view .normal .box .box-body {
            border-bottom: 1px solid #ddd; }
            .Step2EditPage .step2-view .right-view .normal .box .box-head > div,
            .Step2EditPage .step2-view .right-view .normal .box .box-body > div {
              padding: 0 10px;
              width: 20%;
              vertical-align: middle;
              line-height: 48px;
              display: inline-block;
              min-height: 50px;
              vertical-align: top;
              color: #444;
              font-weight: bold;
              text-overflow: ellipsis;
              white-space: nowrap; }
            .Step2EditPage .step2-view .right-view .normal .box .box-head .form-input,
            .Step2EditPage .step2-view .right-view .normal .box .box-head .form-label,
            .Step2EditPage .step2-view .right-view .normal .box .box-head .form-output,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .form-input,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .form-label,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .form-output {
              display: block;
              /* vertical-align: top; */
              padding-bottom: 0px; }
            .Step2EditPage .step2-view .right-view .normal .box .box-head .icon-valid-flag,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .icon-valid-flag {
              display: none; }
            .Step2EditPage .step2-view .right-view .normal .box .box-head .tc-15-select,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .tc-15-select {
              min-width: 0; }
            .Step2EditPage .step2-view .right-view .normal .box .box-head .tc-input-group,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .tc-input-group {
              width: 100%;
              min-width: 0; }
            .Step2EditPage .step2-view .right-view .normal .box .box-head .tc-input-group .tc-input-group-addon,
            .Step2EditPage .step2-view .right-view .normal .box .box-body .tc-input-group .tc-input-group-addon {
              width: 33px; }
          .Step2EditPage .step2-view .right-view .normal .box .box-head > div {
            height: 40px;
            line-height: 40px;
            color: #888;
            min-height: 40px; }
          .Step2EditPage .step2-view .right-view .normal .box .box-body:hover {
            background-color: #f7f7f7; }
          .Step2EditPage .step2-view .right-view .normal .box .wd16 > div {
            width: 16.6% !important; }
          .Step2EditPage .step2-view .right-view .normal .box .wd25 > div {
            width: 25%; }
          .Step2EditPage .step2-view .right-view .normal .box .wd33 > div {
            width: 33.3%; }
          .Step2EditPage .step2-view .right-view .normal .box .box-body > div {
            font-weight: normal; }
          .Step2EditPage .step2-view .right-view .normal .box .box-body:last-child {
            border-bottom: none; }
          .Step2EditPage .step2-view .right-view .normal .box .form-input {
            padding-bottom: 0; }
          .Step2EditPage .step2-view .right-view .normal .box .form-input-help {
            line-height: 0;
            margin-bottom: 12px; }
          .Step2EditPage .step2-view .right-view .normal .box .lastNumperNum {
            line-height: 0 !important; }
            .Step2EditPage .step2-view .right-view .normal .box .lastNumperNum .form-input-help {
              margin-top: 12px !important;
              margin-bottom: 10px !important; }
            .Step2EditPage .step2-view .right-view .normal .box .lastNumperNum .form-input-help2 {
              color: #888;
              display: inline-block;
              margin-top: 10px;
              margin-bottom: 15px;
              vertical-align: middle; }
          .Step2EditPage .step2-view .right-view .normal .box .tc-input-group {
            line-height: 28px; }
          .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY {
            margin-left: 10px;
            margin-right: 10px; }
            .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY span {
              color: rgba(0, 0, 0, 0.65); }
            .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .fenduan ul {
              margin-bottom: 10px; }
            .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline {
              display: inline-block;
              float: left;
              margin-top: 20px;
              margin-left: 10px; }
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-label label {
                display: none; }
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-input-help {
                float: left;
                margin-bottom: 0;
                line-height: inherit !important; }
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline.inline3 .form-input-help {
                position: absolute;
                width: 200px; }
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline .icon-valid-flag {
                display: none; }
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-ctrl-label {
                display: block;
                height: 40px;
                line-height: 40px; }
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-15-select,
              .Step2EditPage .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-input-group {
                min-width: 0px; }
        .Step2EditPage .step2-view .right-view .normal .boxopenRebate {
          padding: 20px 10px !important; }
        .Step2EditPage .step2-view .right-view .normal .boxsponsor {
          padding: 20px 10px 0px !important; }
          .Step2EditPage .step2-view .right-view .normal .boxsponsor .form-input {
            padding-bottom: 20px; }
          .Step2EditPage .step2-view .right-view .normal .boxsponsor .nolabel {
            display: inline-block;
            padding: 0px 5px;
            vertical-align: middle; }
            .Step2EditPage .step2-view .right-view .normal .boxsponsor .nolabel .form-input {
              padding-bottom: 0; }
            .Step2EditPage .step2-view .right-view .normal .boxsponsor .nolabel .icon-valid-flag {
              display: none; }
            .Step2EditPage .step2-view .right-view .normal .boxsponsor .nolabel .form-label {
              display: none; }
          .Step2EditPage .step2-view .right-view .normal .boxsponsor .form-input-help {
            line-height: 20px;
            margin-bottom: -15px; }
        .Step2EditPage .step2-view .right-view .normal .second-title {
          padding-left: 20px;
          font-size: 14px !important;
          margin-top: 20px; }
          .Step2EditPage .step2-view .right-view .normal .second-title span {
            font-size: 14px !important; }
          .Step2EditPage .step2-view .right-view .normal .second-title .span2 {
            color: #EE6F1E;
            font-size: 24px !important; }
          .Step2EditPage .step2-view .right-view .normal .second-title .span3 {
            color: #EE6F1E; }
        .Step2EditPage .step2-view .right-view .normal .second-title-new {
          border: 1px solid #ddd;
          height: 45px;
          line-height: 45px;
          color: #888;
          margin: 0 20px;
          padding: 0 10px; }
      .Step2EditPage .step2-view .right-view .accurate-title {
        padding-top: 10px;
        padding-left: 25px;
        margin-bottom: 15px !important; }
      .Step2EditPage .step2-view .right-view .accurate {
        padding-bottom: 10px; }
        .Step2EditPage .step2-view .right-view .accurate .tc-15-rich-radio {
          line-height: 25px;
          width: calc(100% - 100px);
          vertical-align: top; }
        .Step2EditPage .step2-view .right-view .accurate .box {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          padding-top: 15px;
          line-height: 23px;
          color: #262626; }
        .Step2EditPage .step2-view .right-view .accurate .btn-group {
          margin-bottom: 10px; }
        .Step2EditPage .step2-view .right-view .accurate .accurate-title2 {
          margin-bottom: 15px;
          padding-top: 10px;
          padding-left: 25px; }
        .Step2EditPage .step2-view .right-view .accurate .box2 {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          margin-bottom: 15px;
          line-height: 33px;
          color: #262626; }
          .Step2EditPage .step2-view .right-view .accurate .box2 .box3 {
            padding: 5px 10px;
            border: 1px solid #ddd;
            display: inline-block;
            height: 26px;
            line-height: 14px; }
        .Step2EditPage .step2-view .right-view .accurate .box2-view {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 10px 20px;
          margin-bottom: 15px;
          line-height: 23px;
          color: #262626;
          background: #F8F8F8;
          padding-bottom: 0;
          border-radius: 2px; }
      .Step2EditPage .step2-view .right-view .btn-group {
        margin-bottom: 20px;
        padding-left: 20px; }
    .Step2EditPage .step2-view .tc-15-switch input {
      width: 35px !important; }
  .Step2EditPage .mb10 {
    margin-bottom: 10px; }
  .Step2EditPage .mb20 {
    margin-bottom: 20px; }
  .Step2EditPage .ml10 {
    margin-left: 10px; }
  .Step2EditPage .ml20 {
    margin-left: 20px; }
  .Step2EditPage .mr10 {
    margin-right: 10px; }
  .Step2EditPage .mr20 {
    margin-right: 20px; }
  .Step2EditPage .flr {
    float: right; }
  .Step2EditPage .valignm {
    vertical-align: middle; }
  .Step2EditPage .w100 {
    width: 100% !important; }
  .Step2EditPage .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .Step2EditPage .cblue {
    color: #006eff !important; }
  .Step2EditPage .cursorp {
    cursor: pointer; }
  .Step2EditPage .lh0 {
    line-height: 0; }
  .Step2EditPage .lh25 {
    line-height: 25px; }
  .Step2EditPage .clear {
    clear: both; }
  .Step2EditPage .checked-rule-box {
    padding-left: 20px; }
  .Step2EditPage .checked-array-box {
    border: 1px solid #ddd;
    width: 900px;
    min-width: 900px;
    margin-bottom: 20px; }
    .Step2EditPage .checked-array-box .form-list {
      margin: 10px 0 0 0;
      display: flex;
      border-bottom: 1px solid #ddd; }
      .Step2EditPage .checked-array-box .form-list:last-child {
        border: none; }
      .Step2EditPage .checked-array-box .form-list li {
        margin-right: 20px; }
      .Step2EditPage .checked-array-box .form-list .form-input,
      .Step2EditPage .checked-array-box .form-list .form-label,
      .Step2EditPage .checked-array-box .form-list .form-output {
        padding-bottom: 10px; }
      .Step2EditPage .checked-array-box .form-list li.pure-text-row .form-input {
        line-height: 30px; }
      .Step2EditPage .checked-array-box .form-list .form-input .tc-input-group {
        min-width: 100px; }
      .Step2EditPage .checked-array-box .form-list .form-input input {
        width: 80px; }
      .Step2EditPage .checked-array-box .form-list .form-input select {
        width: 120px; }
    .Step2EditPage .checked-array-box .add-btn {
      width: 15%;
      padding-left: 20px;
      margin: 0;
      line-height: 51px;
      color: #006EFF;
      cursor: pointer; }
    .Step2EditPage .checked-array-box .set-color {
      color: #006EFF;
      cursor: pointer;
      line-height: 30px; }
  .Step2EditPage .checked-view {
    padding-left: 20px; }
    .Step2EditPage .checked-view .form-list {
      margin: 0; }
    .Step2EditPage .checked-view li {
      line-height: 51px; }
  .Step2EditPage .mb-color {
    margin-bottom: 20px;
    color: #000; }
    .Step2EditPage .mb-color .form-ctrl-label {
      margin-right: 0; }
    .Step2EditPage .mb-color .tc-15-switch {
      margin-left: 20px;
      vertical-align: top; }
  .Step2EditPage .radio-box .tc-15-rich-radio {
    vertical-align: inherit; }
    .Step2EditPage .radio-box .tc-15-rich-radio label {
      margin-left: 15px;
      vertical-align: initial; }
  .Step2EditPage .bottom-btn {
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
    padding-top: 20px; }
    .Step2EditPage .bottom-btn > :first-child {
      margin-right: 10px; }

.Step2SortActive .sortContent {
  font-size: 12px;
  background: white;
  margin: 20px 0;
  padding: 20px;
  margin-top: 0; }

.Step2SortActive .sortlist {
  user-select: none;
  display: block;
  width: 100%;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: visible; }
  .Step2SortActive .sortlist > div {
    padding: 0 25px;
    float: left;
    position: relative;
    height: 82px;
    width: 250px;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd; }
  .Step2SortActive .sortlist > div.disabledd {
    background-color: rgba(0, 0, 0, 0.3); }
  .Step2SortActive .sortlist .prize-p {
    margin-top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .Step2SortActive .sortlist .prize-index {
    padding: 0 2px;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    border: 1px solid #006eff;
    font-weight: bold;
    color: #006eff; }
  .Step2SortActive .sortlist .prize-image {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 10px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border-right: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .Step2SortActive .sortlist .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .Step2SortActive .sortlist .prize-remove {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 10px;
    bottom: 8px; }
  .Step2SortActive .sortlist .prize-up {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 90px;
    bottom: 8px; }
  .Step2SortActive .sortlist .prize-close {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 50px;
    bottom: 8px; }

.Step2SortActive .btnBar {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 15px; }
  .Step2SortActive .btnBar .weak-button {
    margin-left: 10px; }

.helperC-active {
  font-size: 12px !important;
  position: relative;
  height: 82px;
  width: 250px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: move;
  pointer-events: auto !important;
  padding: 0 25px; }
  .helperC-active .prize-p {
    margin-top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .helperC-active .prize-index {
    padding: 0 2px;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    border: 1px solid #006eff;
    font-weight: bold;
    color: #006eff; }
  .helperC-active .prize-image {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 10px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border-right: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .helperC-active .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .helperC-active .prize-remove {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 10px;
    bottom: 8px; }
  .helperC-active .prize-up {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 90px;
    bottom: 8px; }
  .helperC-active .prize-close {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 50px;
    bottom: 8px; }

.helperC-active.disabledd {
  background-color: rgba(0, 0, 0, 0.3); }
