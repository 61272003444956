.input-activity-exception-rule-log .high-search {
  background-color: white;
  padding: 20px 20px 0;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap; }
  .input-activity-exception-rule-log .high-search > .search-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;
    margin-bottom: 20px; }
    .input-activity-exception-rule-log .high-search > .search-item > span:first-child {
      width: 6em;
      font-size: 12px; }
    .input-activity-exception-rule-log .high-search > .search-item .tc-15-input-text {
      width: 200px; }
