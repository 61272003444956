.member-labelmanage-item-container {
  position: relative;
  width: 310px;
  height: 240px;
  display: inline-block; }
  .member-labelmanage-item-container .member-labelmanage-item-box {
    width: 100%;
    height: 100%;
    border: 1px solid #e2e2e2;
    background: #fff; }
    .member-labelmanage-item-container .member-labelmanage-item-box.mask {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5); }
    .member-labelmanage-item-container .member-labelmanage-item-box .box-header {
      height: 40px;
      line-height: 40px; }
      .member-labelmanage-item-container .member-labelmanage-item-box .box-header .header-text {
        margin-left: 17px;
        font-size: 12px;
        color: #000000;
        display: inline-block; }
      .member-labelmanage-item-container .member-labelmanage-item-box .box-header .on-off {
        height: 100%;
        float: right;
        margin-right: 10px; }
    .member-labelmanage-item-container .member-labelmanage-item-box .box-body {
      width: 100%;
      height: calc(240px - 80px);
      overflow: auto;
      word-break: break-all;
      word-wrap: break-word;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 10px 20px; }
    .member-labelmanage-item-container .member-labelmanage-item-box .box-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px; }
  .member-labelmanage-item-container .tc-15-switch-input {
    width: 35px; }

.item-render svg {
  margin: 12px 20px 0 0; }

.item-render .tc-15-bubble-inner {
  border: none; }

.item-render .tc-15-bubble-icon .tc-15-bubble-bottom, .item-render .tc-15-bubble-icon .tc-15-bubble-top {
  margin-left: -80px;
  margin-bottom: 0; }

.item-render .tc-15-bubble-inner::before, .item-render .tc-15-bubble-inner::after {
  left: 70px; }

.defined-item-render svg {
  margin: 12px 20px 0 0; }

.defined-item-render .tc-15-bubble-inner {
  border: none; }

.defined-item-render .tc-15-bubble-icon .tc-15-bubble-bottom, .defined-item-render .tc-15-bubble-icon .tc-15-bubble-top {
  margin-left: -106px;
  margin-bottom: 0; }

.defined-item-render .tc-15-bubble-inner::before, .defined-item-render .tc-15-bubble-inner::after {
  left: 96px; }

.basic-item-render {
  display: inline-block; }
  .basic-item-render svg {
    margin: 12px 20px 0 0; }
  .basic-item-render .tc-15-bubble-inner {
    border: none; }
  .basic-item-render .tc-15-bubble-icon .tc-15-bubble-bottom, .basic-item-render .tc-15-bubble-icon .tc-15-bubble-top {
    margin-left: -80px;
    margin-bottom: 0; }
  .basic-item-render .tc-15-bubble-inner::before, .basic-item-render .tc-15-bubble-inner::after {
    left: 81px; }
