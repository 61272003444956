.out-report-statistics-config .search-bar {
  min-height: 30px;
  height: auto; }

.out-report-statistics-config .tc-15-dropdown.tc-15-dropdown-btn-style a.tc-15-dropdown-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px; }

.out-report-statistics-config .tc-15-table-panel {
  overflow: auto; }
  .out-report-statistics-config .tc-15-table-panel > div {
    min-width: 1000px; }

.out-report-statistics-config .tc-15-table-fixed-head,
.out-report-statistics-config .tc-15-table-fixed-body {
  padding: 0 10px; }

.out-report-statistics-config .order-param {
  position: relative;
  top: -10px;
  left: 100px;
  width: calc(100% - 100px); }

.out-report-statistics-config .order-param > * {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle; }

.out-report-statistics-config .order-param + button {
  margin-top: 10px; }

.out-report-statistics-config .ty-reset-date-range-picker {
  margin-right: 10px; }

.out-report-statistics-config .tc-15-rich-radio {
  margin-right: 10px; }

.out-report-statistics-config .tc-15-dropdown-btn-style .tc-15-dropdown-link em {
  color: #000; }

.out-report-statistics-config .hjlz-date {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px; }

.out-report-statistics-config section .menu-tablist {
  margin-top: 20px; }

.out-report-statistics-config section .tc-15-btn.btn-primary {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  color: #000;
  margin-right: 10px; }

.out-report-statistics-config section .ty-reset-select.tc-15-select {
  min-width: 90px !important; }

.out-report-statistics-config .last-bubble .tc-15-bubble {
  width: 93px !important;
  margin-left: -45px !important; }

.out-report-statistics-config .liuchengtuP {
  position: absolute;
  width: 178px;
  line-height: 30px;
  text-align: center; }

.out-report-statistics-config .hint {
  position: relative; }
  .out-report-statistics-config .hint:hover .text {
    display: block; }
  .out-report-statistics-config .hint .icon-hint {
    cursor: pointer;
    vertical-align: text-bottom; }
  .out-report-statistics-config .hint .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .out-report-statistics-config .hint .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-right-color: #000; }
  .out-report-statistics-config .hint .text {
    width: 300px;
    position: absolute;
    top: -13px;
    left: -30px;
    transform: translateY(-100%);
    z-index: 2; }
    .out-report-statistics-config .hint .text div {
      font-size: 12px;
      color: #fff;
      text-align: left;
      min-width: 74px;
      min-height: 34px;
      padding: 8px 16px;
      background: #333;
      display: inline-block; }
      .out-report-statistics-config .hint .text div p {
        word-break: break-all;
        margin: 0; }
    .out-report-statistics-config .hint .text span {
      position: absolute;
      top: 34px;
      left: 30px;
      border: 6px solid transparent;
      border-top-color: #333; }

.out-report-statistics-config .page-padding20 {
  padding-top: 10px; }
  .out-report-statistics-config .page-padding20 .qiuqiu {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    background: red;
    margin-right: 5px; }
  .out-report-statistics-config .page-padding20 .qiuqiuSpan {
    font-size: 12px;
    color: #888;
    margin-right: 5px; }

.out-report-statistics-config .main-block {
  background: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #e2e2e2;
  min-height: 64px; }
  .out-report-statistics-config .main-block .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .out-report-statistics-config .main-block .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .out-report-statistics-config .main-block .tc-input-group-addon {
    background: none !important;
    border: none !important; }
  .out-report-statistics-config .main-block .form-unit .form-input-help {
    max-width: 400px; }
  .out-report-statistics-config .main-block .button-after .is-error .icon-valid-flag {
    display: none !important; }
  .out-report-statistics-config .main-block .button-after .form-input {
    position: relative; }
    .out-report-statistics-config .main-block .button-after .form-input .tc-15-bubble-icon {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px; }
  .out-report-statistics-config .main-block .set-frequency {
    display: inline-block;
    vertical-align: text-top;
    margin-left: 5px; }
    .out-report-statistics-config .main-block .set-frequency .item {
      color: #888888;
      padding-right: 10px;
      display: inline-table; }
    .out-report-statistics-config .main-block .set-frequency ul {
      margin-bottom: 0;
      padding-bottom: 0; }
    .out-report-statistics-config .main-block .set-frequency input,
    .out-report-statistics-config .main-block .set-frequency input.tc-15-input-text {
      width: 60px; }
    .out-report-statistics-config .main-block .set-frequency select {
      width: 60px;
      min-width: 60px; }
    .out-report-statistics-config .main-block .set-frequency .tc-15-simulate-select.m {
      min-width: 180px; }
    .out-report-statistics-config .main-block .set-frequency .form-list .form-label .label,
    .out-report-statistics-config .main-block .set-frequency .form-list .form-label label {
      padding-right: 10px; }
    .out-report-statistics-config .main-block .set-frequency .button-after .is-error .icon-valid-flag {
      display: none !important; }
  .out-report-statistics-config .main-block .btnBar {
    border-top: 1px solid #ddd;
    padding-top: 15px; }
    .out-report-statistics-config .main-block .btnBar .weak-button {
      margin-left: 10px; }

.hjliz-ml30 {
  margin-left: 15px; }

.hjlz-list-total-font {
  margin-left: 20px;
  color: #FF7800;
  font-size: 16px; }

.hjlz-multipleselect span {
  display: block;
  width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.linkCirculation-config .hjlz-pic .hjlz-icon-wrap {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  width: 60px; }

.linkCirculation-config .hjlz-pic .hjlz-arrow-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: -14px;
  width: calc(20% - 92px);
  min-width: 150px;
  margin-right: 20px; }
  .linkCirculation-config .hjlz-pic .hjlz-arrow-wrap div {
    position: absolute;
    top: -15px;
    width: 100%;
    text-align: center; }

.linkCirculation-config .hjlz-pic .hjlz-pic-wrap {
  white-space: nowrap; }

.linkCirculation-config .hjlz-pic .hjlz-icon {
  width: 60px;
  cursor: pointer;
  opacity: 0; }

.linkCirculation-config .hjlz-pic .hjlz-arrow {
  width: 100%; }

.linkCirculation-config th .tc-15-dropdown-btn-style .tc-15-dropdown-link {
  border: none; }

.linkCirculation-config th .tc-15-dropdown-btn-style .tc-15-dropdown-link:hover {
  background: transparent; }

.linkCirculation-config th .tc-15-table-fixed-head .tc-15-dropdown .tc-15-dropdown-link:hover .caret {
  background-position: 26.96% 99.49% !important; }

.linkCirculation-config th .tc-15-dropdown .tc-15-dropdown-link .caret,
.linkCirculation-config th .tc-15-dropdown-allow-hover.tc-15-dropdown-in-hd.tc-15-menu-active .tc-15-dropdown-link:hover .caret,
.linkCirculation-config th .tc-15-dropdown-allow-hover.tc-15-dropdown-in-hd:hover .tc-15-dropdown-link .caret,
.linkCirculation-config th .tc-15-dropdown-allow-hover.tc-15-menu-active .tc-15-dropdown-link:hover .caret,
.linkCirculation-config th .tc-15-dropdown-allow-hover:hover .tc-15-dropdown-link .caret,
.linkCirculation-config .tc-15-dropdown-in-hd.tc-15-menu-active .tc-15-dropdown-link .caret,
.linkCirculation-config th .tc-15-table-fixed-head .tc-15-dropdown.tc-15-menu-active .tc-15-dropdown-link:hover .caret {
  background-position: 26.96% 99.49% !important; }

.linkCirculation-config .linkCirculation-config .tc-15-table-panel a:hover {
  text-decoration: none; }

.linkCirculation-config .hjlz-multipleselect.unoverhidden span {
  display: inline; }

.linkCirculation-config .hjlz-label {
  display: inline-block;
  vertical-align: 5px;
  margin-right: 5px;
  height: 30px;
  line-height: 30px; }

.linkCirculation-config th .tc-15-dropdown-btn-style .tc-15-dropdown-link .caret {
  left: 66px; }

.download-dialog .download-wrap li {
  display: flex;
  height: 36px;
  line-height: 36px;
  border: 1px solid #ddd;
  margin-bottom: -1px; }
  .download-dialog .download-wrap li > div:nth-child(1) {
    width: 65%; }
  .download-dialog .download-wrap li > div:nth-child(2) {
    width: 35%; }
  .download-dialog .download-wrap li .cw {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
    text-align: center; }
    .download-dialog .download-wrap li .cw .form-ctrl-label {
      margin-right: 0; }
  .download-dialog .download-wrap li .cc {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 90px); }
