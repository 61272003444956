.MinProgramServiceMedia .container {
  margin: 0; }

.MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }

.MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.CodingMedia {
  width: 520px; }
  .CodingMedia .tc-15-simulate-select,
  .CodingMedia .tc-15-dropdown {
    width: 330px; }
  .CodingMedia .upload {
    width: 330px;
    display: inline-block;
    font-size: 12px;
    color: #666; }
    .CodingMedia .upload .block {
      width: 100%;
      height: 100%;
      margin-bottom: 5px; }
      .CodingMedia .upload .block .prize-image {
        display: flex; }
        .CodingMedia .upload .block .prize-image .prize-image1 {
          width: 80px;
          height: 80px;
          background: #F2F2F2;
          margin-right: 5px; }
          .CodingMedia .upload .block .prize-image .prize-image1 img {
            width: 100%;
            height: 100%; }
      .CodingMedia .upload .block .hint {
        height: 25px;
        padding-bottom: 16px; }
      .CodingMedia .upload .block .hint1 {
        padding-bottom: 33px; }
    .CodingMedia .upload img,
    .CodingMedia .upload video {
      width: 80px;
      height: 80px; }

.elementTemplate > .form-list > .form-list:nth-child(2) {
  padding: 0 10px; }

.elementTemplate .elementImgbotton > .form-label {
  display: none; }

.elementTemplate .elementImgbotton > .form-input {
  padding-bottom: 0; }

.elementTemplate .elementImg {
  display: block; }

.elementTemplate .ememtnt-content {
  background-color: #fff;
  position: relative; }
  .elementTemplate .ememtnt-content .form-list-felx.form-table > .form-label label {
    line-height: 31px; }
  .elementTemplate .ememtnt-content .form-list-felx.form-table > .form-input {
    padding-bottom: 0; }
  .elementTemplate .ememtnt-content .form-list-felx.form-table > .form-input > .form-list {
    padding: 0;
    margin-top: 20px;
    position: relative; }
  .elementTemplate .ememtnt-content .form-list-felx.form-table > .form-input > .form-list:first-child {
    margin-top: 0px; }

.elementTemplate .elementText {
  width: 26px;
  color: #006EFF;
  cursor: pointer; }

.elementTemplate .elementText-btn {
  position: absolute;
  right: 0;
  top: 0; }

.elementTemplate .form-list {
  background-color: #fff;
  display: inline-block; }
  .elementTemplate .form-list .form-list {
    background-color: #fff; }
  .elementTemplate .form-list .form-label .form-output {
    display: inline-block; }

.PaletteName .palette .color-picker {
  width: 330px; }

.PaletteName .Palettetitle {
  margin-top: 20px; }
