.dealergroup-form .tc-15-select[disabled] {
  cursor: not-allowed; }

.dealergroup-form .dealergroup-add {
  margin-left: 80px;
  color: #006eff;
  margin-top: 3px;
  cursor: pointer;
  display: inline-block; }

.dealergroup-form .dealergroup-del {
  margin-left: 10px;
  color: #006eff;
  margin-top: 3px;
  cursor: pointer;
  display: inline-block; }

.dealergroup-form .factoryArea-box {
  display: inline-block;
  vertical-align: top;
  margin: 20px 0;
  margin-bottom: 0; }
  .dealergroup-form .factoryArea-box .form-label {
    padding-bottom: 0; }
  .dealergroup-form .factoryArea-box .form-input .tc-15-select {
    width: 100px;
    min-width: 100px; }
  .dealergroup-form .factoryArea-box .icon-valid-flag {
    display: none; }

.dealergroup-form .factoryArea-box:not(:first-child) {
  margin-left: 15px; }
  .dealergroup-form .factoryArea-box:not(:first-child) .form-label {
    display: none; }

.dealergroup-form .button-box {
  width: 100%;
  text-align: center;
  margin-top: 15px; }
  .dealergroup-form .button-box button {
    margin: 5px; }

.dealergroup-form .is-error + .remark-text {
  display: none; }

.dealergroup-form .remark-text {
  display: inline-block;
  color: #666666;
  line-height: 18px;
  margin-top: 5px; }
