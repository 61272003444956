.Step3EditPage {
  color: #000000;
  margin: 20px 0;
  padding: 20px;
  background-color: #fff; }
  .Step3EditPage .size12 {
    margin-top: 20px; }
    .Step3EditPage .size12 label {
      font-size: 12px;
      margin-right: 20px; }
  .Step3EditPage .tc-15-switch-input {
    width: auto; }
  .Step3EditPage .tc-15-dropdown-link {
    width: 151px; }
  .Step3EditPage .has-error .ant-input,
  .Step3EditPage .has-error .ant-input:hover {
    border-color: #f5222d !important; }
  .Step3EditPage .is-error .icon-valid-flag {
    display: none; }
  .Step3EditPage .form-unit .form-input-help {
    margin-left: 10px; }
  .Step3EditPage .ant-form-explain {
    width: 100%;
    text-align: center; }
  .Step3EditPage .top-title {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #000000; }
  .Step3EditPage .box-form .limit-block {
    width: 699px; }
  .Step3EditPage .box-form .short {
    min-height: 155px !important; }
  .Step3EditPage .box-form .many {
    min-height: 150px !important; }
  .Step3EditPage .box-form .view-many {
    min-height: 200px !important; }
  .Step3EditPage .box-form .edit-box {
    width: 327px;
    min-height: 310px;
    border: 1px solid #DDDDDD;
    font-size: 12px;
    color: #888888;
    display: inline-block; }
    .Step3EditPage .box-form .edit-box .box-header {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #DDDDDD; }
      .Step3EditPage .box-form .edit-box .box-header .header-text {
        margin-left: 17px;
        font-size: 12px;
        color: #000000; }
      .Step3EditPage .box-form .edit-box .box-header .on-off {
        height: 100%;
        float: right;
        margin-right: 20px; }
    .Step3EditPage .box-form .edit-box .line-block-view {
      margin-top: 10px; }
    .Step3EditPage .box-form .edit-box .line-block-padding {
      padding-left: 15px;
      margin-top: 10px; }
    .Step3EditPage .box-form .edit-box .itemClass {
      font-size: 12px;
      color: #888888 !important;
      display: inline-table; }
      .Step3EditPage .box-form .edit-box .itemClass .form-ctrl-label {
        margin-right: 10px; }
      .Step3EditPage .box-form .edit-box .itemClass .form-unit {
        color: #888888 !important; }
    .Step3EditPage .box-form .edit-box .check-box {
      margin-left: 15px; }
    .Step3EditPage .box-form .edit-box .input {
      width: 55px;
      height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 2px 2px;
      border: 1px solid #DDDDDD;
      font-size: 12px;
      text-align: center; }
    .Step3EditPage .box-form .edit-box .radio {
      font-size: 12px;
      color: #888888;
      display: inline-block; }
  .Step3EditPage .wechatTitle {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #000000; }
  .Step3EditPage .wechat-block {
    width: 699px; }
    .Step3EditPage .wechat-block .wechat-box {
      display: inline-block;
      width: 327px;
      height: 72px;
      margin-bottom: 10px;
      border: 1px solid #DDDDDD;
      font-size: 12px;
      color: #888888;
      cursor: pointer; }
      .Step3EditPage .wechat-block .wechat-box .wechat-box-text {
        width: 100%;
        padding: 0 15px;
        margin-top: 10px;
        height: 17px;
        line-height: 17px; }
        .Step3EditPage .wechat-block .wechat-box .wechat-box-text .appId-text {
          margin-left: 15px; }
        .Step3EditPage .wechat-block .wechat-box .wechat-box-text .auth-text {
          float: right; }
    .Step3EditPage .wechat-block .wechat-box-checked {
      border: 1px solid #006EFF;
      color: #333333; }
  .Step3EditPage .auth-title {
    width: 115px;
    font-size: 12px;
    color: #006EFF;
    font-weight: bold;
    cursor: pointer; }
  .Step3EditPage .save-title {
    font-size: 12px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px; }
  .Step3EditPage .save-block {
    width: 699px;
    border: 1px solid #DDDDDD; }
  .Step3EditPage .save-text {
    margin-top: 10px;
    font-size: 12px;
    color: #888888;
    padding: 0 15px; }
  .Step3EditPage .check-icon {
    position: relative;
    top: 5px; }
  .Step3EditPage .check-text {
    width: 17px;
    color: #006EFF;
    font-weight: bold;
    cursor: pointer; }
  .Step3EditPage .check-right {
    float: right; }
  .Step3EditPage .check-margin-right {
    margin-right: 23px; }
  .Step3EditPage .button-block {
    margin-top: 20px; }
  .Step3EditPage .button-margin {
    margin-left: 10px; }
