.ty-overview-panel {
  padding: 15px;
  border: 1px solid #DDDDDD;
  margin: 15px 0px;
  background-color: #fff; }
  .ty-overview-panel h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0 0 0; }
  .ty-overview-panel div[class^="ant-col-"] {
    border-left: 1px solid #DDDDDD;
    padding: 0 20px; }
    .ty-overview-panel div[class^="ant-col-"] b {
      display: block;
      font-family: MicrosoftYaHei-Bold;
      font-size: 12px;
      color: #888888;
      margin-bottom: 10px; }
    .ty-overview-panel div[class^="ant-col-"].reset0 {
      padding-left: 0;
      border-left: none;
      border-left-width: 0; }
    .ty-overview-panel div[class^="ant-col-"] .num {
      font-family: MicrosoftYaHei;
      font-size: 28px;
      color: #444444; }
  .ty-overview-panel-item {
    display: flex !important;
    flex-direction: column;
    min-height: 200px !important; }
    .ty-overview-panel-item-top {
      flex: 1; }
    .ty-overview-panel-item-bottom {
      font-size: 14px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      color: #444444; }
      .ty-overview-panel-item-bottom-num {
        font-size: 24px; }
    .ty-overview-panel-item-child {
      color: #444444;
      line-height: 25px;
      font-size: 12px;
      font-family: MicrosoftYaHei; }
      .ty-overview-panel-item-child:before, .ty-overview-panel-item-child:after {
        clear: both;
        content: '';
        display: table; }
      .ty-overview-panel-item-child > span.tip-left {
        float: left; }
      .ty-overview-panel-item-child > span.tip-right {
        float: right; }
      .ty-overview-panel-item-child .tc-15-bubble-icon .tc-15-bubble {
        display: none; }
      .ty-overview-panel-item-child .tc-15-bubble-icon.hover .tc-15-bubble, .ty-overview-panel-item-child .tc-15-bubble-icon:hover .tc-15-bubble {
        display: block; }

table.ty-overview-table {
  width: 100%; }
  table.ty-overview-table.city th, table.ty-overview-table.city td {
    width: 25%; }
  table.ty-overview-table thead tr {
    border-bottom: 2px solid #DDDDDD; }
    table.ty-overview-table thead tr th {
      font-family: MicrosoftYaHei-Bold;
      font-size: 12px;
      color: #888888;
      padding: 10px;
      text-align: center; }
      table.ty-overview-table thead tr th.name, table.ty-overview-table thead tr th.pool {
        text-align: left; }
  table.ty-overview-table tbody tr {
    border-bottom: 1px solid #e6e6e6; }
    table.ty-overview-table tbody tr.active {
      background-color: #F2F2F2; }
    table.ty-overview-table tbody tr td {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      padding: 12px;
      color: #444444;
      position: relative;
      text-align: center; }
      table.ty-overview-table tbody tr td.name, table.ty-overview-table tbody tr td.pool {
        text-align: left; }
      table.ty-overview-table tbody tr td .item {
        display: inline-block;
        width: 10px;
        position: relative;
        height: 10px;
        margin-right: 3px;
        background: #1E7BDB; }

.ty-overview-convert-item {
  margin: 10px 0;
  line-height: 42px; }

.ty-overview-convert .left {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #606060;
  letter-spacing: 0; }

.ty-overview-convert .code, .ty-overview-convert .exchange, .ty-overview-convert .winner {
  background-color: #338BFF;
  text-align: center;
  color: #fff; }

.ty-overview-convert .down {
  width: 80px;
  background-color: #E6E6E6;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #000;
  letter-spacing: 0;
  text-align: center;
  line-height: 32px;
  position: relative; }
  .ty-overview-convert .down:before {
    width: 0;
    height: 0;
    content: '';
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 18px solid #E6E6E6;
    position: absolute;
    top: 32px;
    left: 0; }

.ty-overview-convert .code {
  flex: 1; }

.ty-overview-convert .winner {
  width: 70%; }

.ty-overview-convert .exchange {
  width: 50%; }

.ty-overview-convert .right {
  display: flex;
  justify-content: center; }

.DataOverviewManagement .tc-15-tablist {
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #fff; }

.DataOverviewManagement ul {
  margin-bottom: 0; }

.DataOverviewManagement .pie-prize-top-10 h3 {
  text-align: center; }

.DataOverviewManagement .pie-prize-top-10 table.ty-overview-table tbody tr td {
  padding: 12px; }

.echartWarpOver {
  position: relative; }
  .echartWarpOver .lengedBottom {
    position: absolute;
    bottom: 10px;
    width: 16%;
    left: 43%;
    display: flex;
    justify-content: space-between; }
    .echartWarpOver .lengedBottom span {
      display: inline-block;
      width: 46px;
      height: 20px;
      background: rgba(0, 0, 0, 0); }
  .echartWarpOver .jinE {
    width: 22%;
    left: 41%; }
  .echartWarpOver .peopleNo {
    position: absolute;
    left: 43%;
    width: 15%;
    height: 30px;
    top: 22%;
    background: rgba(0, 0, 0, 0); }
  .echartWarpOver .scanNo {
    width: 14%;
    left: 44%; }
  .echartWarpOver .wxChart {
    width: 11%;
    left: 46%; }
    .echartWarpOver .wxChart span {
      width: 64px; }

.ty-overview-panel .ty-overview-panel-item-top-new, .ty-overview-panel .ty-overview-panel-item-bottom-new {
  padding: 0 10px;
  border-right: 1px solid #ddd; }

.ty-overview-panel .ty-overview-panel-item-top-new, .ty-overview-panel .ty-overview-panel-item-bottom-new {
  padding: 0 10px;
  border-right: 1px solid #ddd; }
