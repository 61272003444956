.promitionCode .tc-15-dropdown-link {
  width: 180px; }

.promitionCode-form .palette {
  position: relative;
  width: 330px; }
  .promitionCode-form .palette .color-picker {
    width: 100%;
    height: 30px;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); }
  .promitionCode-form .palette .cover {
    position: absolute;
    z-index: 2;
    top: 35px; }
  .promitionCode-form .palette .cover-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.promitionCode-form .promitionCode-more-label > .form-input .form-label label {
  display: none; }

.promitionCode-form .promitionCode-more-label .form-input {
  padding-bottom: 10px; }
