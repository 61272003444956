.BatchEntry, .BatchEntryMore {
  width: 100%;
  height: 100%; }
  .BatchEntry .search-bar button, .BatchEntryMore .search-bar button {
    float: right;
    margin-right: 10px; }
  .BatchEntry .main-block, .BatchEntryMore .main-block {
    background: #fff;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #e2e2e2; }

.ViewDialog {
  font-size: 12px; }
  .ViewDialog .tc-15-input-text {
    width: 260px; }
  .ViewDialog .search-input.tc-15-input-text {
    width: 100%; }
  .ViewDialog .dialog_formlabel_68 .form-label label {
    width: 68px;
    white-space: normal; }
  .ViewDialog .item {
    margin-top: 20px; }
  .ViewDialog .tc-15-select,
  .ViewDialog .tc-input-group {
    min-width: 0px; }
  .ViewDialog .form-ctrl-label {
    margin-right: 0px; }
  .ViewDialog .nolabel {
    display: inline-block;
    padding: 0px 5px;
    vertical-align: middle; }
    .ViewDialog .nolabel .icon-valid-flag {
      display: none; }
    .ViewDialog .nolabel .form-label {
      display: none; }
