.label-field-edit-modal .label-wrap .l-box {
  display: flex; }
  .label-field-edit-modal .label-wrap .l-box .name {
    font-size: 12px;
    color: #888;
    flex-basis: 36px !important;
    flex-shrink: 0;
    margin-right: 20px; }
  .label-field-edit-modal .label-wrap .l-box .content {
    flex-grow: 1; }
  .label-field-edit-modal .label-wrap .l-box .mr10 {
    margin-right: 10px; }
  .label-field-edit-modal .label-wrap .l-box .ml10 {
    margin-left: 10px; }

.label-field-edit-modal .label-inp {
  width: 180px; }

.label-field-edit-modal .labelfield-name {
  width: 180px;
  margin-right: 10px; }

.label-field-edit-modal .labelfield-code {
  width: 180px; }

.label-field-edit-modal .label-field-del {
  color: #006eff;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer; }

.label-field-edit-modal .mt10 {
  margin-top: 10px; }

.label-field-edit-modal .bottom-add {
  margin-left: 70px;
  color: #006eff;
  cursor: pointer; }

.label-field-edit-modal .labelfield-wrap {
  margin-bottom: 10px; }
