.commonModal3 {
  width: 287px !important;
  min-width: 287px !important; }
  .commonModal3 * {
    font-size: 12px !important; }
  .commonModal3 .cblue {
    color: #006eff !important; }
  .commonModal3 .cursorp {
    cursor: pointer; }
  .commonModal3 .clear {
    clear: both; }
  .commonModal3 .mr5 {
    margin-right: 5px; }
  .commonModal3 .mr10 {
    margin-right: 10px; }
  .commonModal3 .valignm {
    vertical-align: middle; }
  .commonModal3 .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 190px; }

.commonModal3.productID,
.commonModal3.franchiserID,
.commonModal3.terminalID {
  min-width: 360px !important;
  width: 360px !important; }
  .commonModal3.productID .button-after > .form-input,
  .commonModal3.franchiserID .button-after > .form-input,
  .commonModal3.terminalID .button-after > .form-input {
    position: relative; }
    .commonModal3.productID .button-after > .form-input .button-after-text2,
    .commonModal3.franchiserID .button-after > .form-input .button-after-text2,
    .commonModal3.terminalID .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 200px;
      min-width: 30px;
      color: #006eff; }
      .commonModal3.productID .button-after > .form-input .button-after-text2 span,
      .commonModal3.franchiserID .button-after > .form-input .button-after-text2 span,
      .commonModal3.terminalID .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .commonModal3.productID .button-after > .form-input .button-after-text3,
    .commonModal3.franchiserID .button-after > .form-input .button-after-text3,
    .commonModal3.terminalID .button-after > .form-input .button-after-text3 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 230px;
      min-width: 60px;
      color: #006eff; }
      .commonModal3.productID .button-after > .form-input .button-after-text3 span,
      .commonModal3.franchiserID .button-after > .form-input .button-after-text3 span,
      .commonModal3.terminalID .button-after > .form-input .button-after-text3 span {
        color: #000000; }
    .commonModal3.productID .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal3.franchiserID .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal3.terminalID .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 225px; }
    .commonModal3.productID .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal3.franchiserID .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal3.terminalID .button-after > .form-input .is-error ~ .button-after-text3 {
      left: 255px; }
    .commonModal3.productID .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal3.franchiserID .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal3.terminalID .button-after > .form-input .is-error ~ .rc-upload .button-after-text2 {
      left: 225px; }

.commonModal3.planSortModal {
  width: 467px !important;
  min-width: 467px !important; }
  .commonModal3.planSortModal .box {
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none; }
    .commonModal3.planSortModal .box .box-head,
    .commonModal3.planSortModal .box .box-body {
      border-bottom: 1px solid #ddd; }
      .commonModal3.planSortModal .box .box-head > div,
      .commonModal3.planSortModal .box .box-body > div {
        padding: 0 10px;
        width: 33.33%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
    .commonModal3.planSortModal .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .commonModal3.planSortModal .box .box-body:hover {
      background-color: #f7f7f7; }
    .commonModal3.planSortModal .box .box-body > div {
      font-weight: normal; }
  .commonModal3.planSortModal .close {
    color: #bbb !important; }

.commonModal3.mallGroupModal {
  width: 385px !important;
  min-width: 385px !important; }
  .commonModal3.mallGroupModal .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .commonModal3.mallGroupModal .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .commonModal3.mallGroupModal .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .commonModal3.mallGroupModal .prize-image span {
      width: 156px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .commonModal3.mallGroupModal .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .commonModal3.mallGroupModal .extra-text {
    color: #666666;
    line-height: 30px;
    width: 276px; }
  .commonModal3.mallGroupModal .ant-upload {
    vertical-align: bottom; }
  .commonModal3.mallGroupModal .box {
    margin-top: 20px;
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none;
    width: 320px; }
    .commonModal3.mallGroupModal .box .box-head,
    .commonModal3.mallGroupModal .box .box-body {
      border-bottom: 1px solid #ddd;
      position: relative; }
      .commonModal3.mallGroupModal .box .box-head > div,
      .commonModal3.mallGroupModal .box .box-body > div {
        padding: 0 10px;
        width: 60%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .commonModal3.mallGroupModal .box .box-head > div:last-child,
      .commonModal3.mallGroupModal .box .box-body > div:last-child {
        width: 15%; }
    .commonModal3.mallGroupModal .box .box-head2,
    .commonModal3.mallGroupModal .box .box-body2 {
      display: flex;
      justify-content: space-between; }
      .commonModal3.mallGroupModal .box .box-head2 > div,
      .commonModal3.mallGroupModal .box .box-body2 > div {
        flex: 1; }
    .commonModal3.mallGroupModal .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .commonModal3.mallGroupModal .box .box-body:hover {
      background-color: #f7f7f7; }
    .commonModal3.mallGroupModal .box .box-body > div {
      font-weight: normal; }
    .commonModal3.mallGroupModal .box .is-error .form-ctrl-label,
    .commonModal3.mallGroupModal .box .is-error .form-input-help {
      line-height: 0;
      margin-bottom: 20px; }
    .commonModal3.mallGroupModal .box .form-input,
    .commonModal3.mallGroupModal .box .form-label,
    .commonModal3.mallGroupModal .box .form-output {
      padding-bottom: 0; }
    .commonModal3.mallGroupModal .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
      width: 130px; }
    .commonModal3.mallGroupModal .box .icon-valid-flag {
      display: none; }
    .commonModal3.mallGroupModal .box .tc-input-group {
      line-height: 28px; }

.commonModal3.LOTTERY_NUM {
  min-width: 275px !important;
  width: 275px !important; }
  .commonModal3.LOTTERY_NUM .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.LOTTERY_NUM .inline .form-label label {
      display: none; }
    .commonModal3.LOTTERY_NUM .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.LOTTERY_NUM .inline .icon-valid-flag {
      display: none; }
    .commonModal3.LOTTERY_NUM .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.LOTTERY_NUM .inline .tc-15-select,
    .commonModal3.LOTTERY_NUM .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.BOX_POSITION {
  min-width: 360px !important;
  width: 360px !important; }
  .commonModal3.BOX_POSITION .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.BOX_POSITION .inline .form-label label {
      display: none; }
    .commonModal3.BOX_POSITION .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.BOX_POSITION .inline .icon-valid-flag {
      display: none; }
    .commonModal3.BOX_POSITION .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.BOX_POSITION .inline .tc-15-select,
    .commonModal3.BOX_POSITION .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.TIME_RANGE_PROMOTIONS_LUM {
  min-width: 480px !important;
  width: 480px !important; }
  .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline .form-label label {
      display: none; }
    .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline .icon-valid-flag {
      display: none; }
    .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline .tc-15-select,
    .commonModal3.TIME_RANGE_PROMOTIONS_LUM .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.TIME_PERIOD_PROMOTIONS_LUM {
  min-width: 335px !important;
  width: 335px !important; }
  .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline .form-label label {
      display: none; }
    .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline .icon-valid-flag {
      display: none; }
    .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline .tc-15-select,
    .commonModal3.TIME_PERIOD_PROMOTIONS_LUM .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.COUNTER {
  min-width: 335px !important;
  width: 335px !important; }
  .commonModal3.COUNTER .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.COUNTER .inline .form-label label {
      display: none; }
    .commonModal3.COUNTER .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.COUNTER .inline .icon-valid-flag {
      display: none; }
    .commonModal3.COUNTER .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.COUNTER .inline .tc-15-select,
    .commonModal3.COUNTER .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.GPS_CITY,
.commonModal3.GPS_DISTRICT,
.commonModal3.ACCESS_GPS_CITY,
.commonModal3.ACCESS_GPS_DISTRICT,
.commonModal3.POSITION_SCENE_SMALL,
.commonModal3.CITY_SHOP {
  width: 770px !important;
  min-width: 770px !important; }
  .commonModal3.GPS_CITY .form-label label,
  .commonModal3.GPS_DISTRICT .form-label label,
  .commonModal3.ACCESS_GPS_CITY .form-label label,
  .commonModal3.ACCESS_GPS_DISTRICT .form-label label,
  .commonModal3.POSITION_SCENE_SMALL .form-label label,
  .commonModal3.CITY_SHOP .form-label label {
    display: none; }

.commonModal3.ACCESS_GPS_POSITION,
.commonModal3.GPS_POSITION {
  width: 630px !important;
  min-width: 630px !important; }
  .commonModal3.ACCESS_GPS_POSITION .button-after > .form-input,
  .commonModal3.GPS_POSITION .button-after > .form-input {
    position: relative; }
  .commonModal3.ACCESS_GPS_POSITION .inline,
  .commonModal3.GPS_POSITION .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.ACCESS_GPS_POSITION .inline .form-label label,
    .commonModal3.GPS_POSITION .inline .form-label label {
      display: none; }
    .commonModal3.ACCESS_GPS_POSITION .inline .form-input-help,
    .commonModal3.GPS_POSITION .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.ACCESS_GPS_POSITION .inline .icon-valid-flag,
    .commonModal3.GPS_POSITION .inline .icon-valid-flag {
      display: none; }
    .commonModal3.ACCESS_GPS_POSITION .inline .form-ctrl-label,
    .commonModal3.GPS_POSITION .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.ACCESS_GPS_POSITION .inline .tc-15-select,
    .commonModal3.ACCESS_GPS_POSITION .inline .tc-input-group,
    .commonModal3.GPS_POSITION .inline .tc-15-select,
    .commonModal3.GPS_POSITION .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.TIME_RANGE {
  min-width: 432px !important;
  width: 432px !important; }
  .commonModal3.TIME_RANGE .tc-15-calendar-footer {
    box-sizing: content-box; }
  .commonModal3.TIME_RANGE .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.TIME_RANGE .inline .form-label label {
      display: none; }
    .commonModal3.TIME_RANGE .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.TIME_RANGE .inline .icon-valid-flag {
      display: none; }
    .commonModal3.TIME_RANGE .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.TIME_RANGE .inline .tc-15-select,
    .commonModal3.TIME_RANGE .inline .tc-input-group {
      min-width: 0px; }

.commonModal3.TIME_PERIOD {
  width: 400px !important;
  min-width: 400px !important; }
  .commonModal3.TIME_PERIOD .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.TIME_PERIOD .inline .form-label label {
      display: none; }
    .commonModal3.TIME_PERIOD .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal3.TIME_PERIOD .inline .icon-valid-flag {
      display: none; }
    .commonModal3.TIME_PERIOD .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal3.TIME_PERIOD .inline .tc-15-select,
    .commonModal3.TIME_PERIOD .inline .tc-input-group {
      min-width: 0px; }
  .commonModal3.TIME_PERIOD .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 80px; }
  .commonModal3.TIME_PERIOD .timePeriodView .inline3 .form-input-help {
    margin-left: 10px; }

.commonModal3.MEMBER_TAG {
  width: 505px !important;
  min-width: 505px !important; }
  .commonModal3.MEMBER_TAG .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal3.MEMBER_TAG .inline .form-label label {
      display: none; }

.commonModal3.key1,
.commonModal3.key2,
.commonModal3.key3,
.commonModal3.moreselect {
  min-width: 320px !important;
  width: 320px !important; }
  .commonModal3.key1 .inline,
  .commonModal3.key2 .inline,
  .commonModal3.key3 .inline,
  .commonModal3.moreselect .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
