.label-draft-container .head-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .label-draft-container .head-wrapper .head-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px; }
    .label-draft-container .head-wrapper .head-right .ml {
      margin-left: 10px; }
