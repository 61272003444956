.add-tag-dialog .form-list {
  display: table-row; }
  .add-tag-dialog .form-list li .form-label {
    width: 70px; }
  .add-tag-dialog .form-list .form-input {
    position: relative; }
  .add-tag-dialog .form-list input {
    width: 260px; }

.add-tag-dialog .modify-poolname-btn {
  left: 270px; }

.tag-group-itme .is-error .icon-valid-flag {
  display: none; }
