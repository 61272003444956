.Step1EditPage * {
  box-sizing: border-box;
  font-size: 12px !important; }

.Step1EditPage {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 17px; }
  .Step1EditPage .tc-15-page {
    font-size: 0 !important; }
    .Step1EditPage .tc-15-page .tc-15-page-select {
      font-size: 0 !important; }
  .Step1EditPage .tc-15-table-panel .tc-15-filtrate-btn .filtrate-icon {
    top: 12px; }
  .Step1EditPage .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0; }
  .Step1EditPage .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .Step1EditPage .tc-15-step li.current .tc-15-step-num:before,
  .Step1EditPage .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .Step1EditPage .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .Step1EditPage .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .Step1EditPage .step1-view {
    margin-bottom: 20px;
    height: 100%; }
    .Step1EditPage .step1-view .tc-15-table-panel {
      border: 1px solid #ddd;
      width: calc(100% - 40px);
      max-width: inherit;
      border-bottom: none; }
  .Step1EditPage .mb10 {
    margin-bottom: 10px; }
  .Step1EditPage .mb20 {
    margin-bottom: 20px; }
  .Step1EditPage .ml10 {
    margin-left: 10px; }
  .Step1EditPage .ml20 {
    margin-left: 20px; }
  .Step1EditPage .mr10 {
    margin-right: 10px; }
  .Step1EditPage .mr20 {
    margin-right: 20px; }
  .Step1EditPage .flr {
    float: right; }
  .Step1EditPage .valignm {
    vertical-align: middle; }
  .Step1EditPage .w100 {
    width: 100% !important; }
  .Step1EditPage .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .Step1EditPage .cblue {
    color: #006eff !important; }
  .Step1EditPage .cursorp {
    cursor: pointer; }
  .Step1EditPage .lh0 {
    line-height: 0; }
  .Step1EditPage .lh25 {
    line-height: 25px; }
  .Step1EditPage .clear {
    clear: both; }
