.MinParameter .tc-15-table-box .template-status {
  display: flex; }
  .MinParameter .tc-15-table-box .template-status span {
    color: #D0021B;
    padding-left: 20px; }

.parameterForm-container {
  width: 100%;
  height: 100%;
  min-width: 900px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #e2e2e2;
  background: #fff; }
  .parameterForm-container .nolabel {
    display: inline-block;
    vertical-align: middle; }
    .parameterForm-container .nolabel .icon-valid-flag {
      display: none; }
    .parameterForm-container .nolabel .form-label {
      display: none; }
    .parameterForm-container .nolabel ul {
      margin-bottom: 0; }
  .parameterForm-container .box {
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none;
    width: 750px; }
    .parameterForm-container .box .box-head,
    .parameterForm-container .box .box-body {
      border-bottom: 1px solid #ddd;
      position: relative; }
      .parameterForm-container .box .box-head > div,
      .parameterForm-container .box .box-body > div {
        padding: 0 10px;
        width: 25%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .parameterForm-container .box .box-head > div:last-child,
      .parameterForm-container .box .box-body > div:last-child {
        width: 25%; }
    .parameterForm-container .box .box-head2,
    .parameterForm-container .box .box-body2 {
      display: flex;
      justify-content: space-between; }
      .parameterForm-container .box .box-head2 > div,
      .parameterForm-container .box .box-body2 > div {
        flex: 1; }
    .parameterForm-container .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .parameterForm-container .box .box-body:hover {
      background-color: #f7f7f7; }
    .parameterForm-container .box .box-body > div {
      font-weight: normal; }
    .parameterForm-container .box .is-error .form-ctrl-label,
    .parameterForm-container .box .is-error .form-input-help {
      line-height: 0;
      margin-bottom: 20px; }
    .parameterForm-container .box .form-input,
    .parameterForm-container .box .form-label,
    .parameterForm-container .box .form-output {
      padding-bottom: 0; }
    .parameterForm-container .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
      width: 130px; }
    .parameterForm-container .box .icon-valid-flag {
      display: none; }
    .parameterForm-container .box .tc-input-group {
      line-height: 28px; }
  .parameterForm-container .cblue {
    color: #006eff !important; }
  .parameterForm-container .cursorp {
    cursor: pointer; }
  .parameterForm-container .mr10 {
    margin-right: 10px; }

.parameterForm .imagetable {
  margin: 0 auto;
  width: 740px;
  column-count: 4;
  column-width: 150px;
  column-gap: 20px; }

.parameterForm .imagebox {
  position: relative;
  border: 1px solid #ddd;
  cursor: pointer;
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
  break-inside: avoid; }
  .parameterForm .imagebox .imagezhezhao {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
  .parameterForm .imagebox .imagetitle {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 25px;
    color: #fff;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 14;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.4); }

.parameterForm .imageboxSel {
  border: 2px solid #006eff; }
  .parameterForm .imageboxSel .imagezhezhao {
    display: none; }

.parameterForm .imagebox img {
  width: 100%;
  vertical-align: middle; }

.parameterForm .prize-image-upload2 {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom; }

.parameterForm .prize-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #F2F2F2;
  border: 1px solid #D9D9D9;
  text-align: center;
  vertical-align: middle; }
  .parameterForm .prize-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .parameterForm .prize-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }
  .parameterForm .prize-image span {
    width: 90px;
    color: #666666;
    display: inline-block;
    position: absolute;
    left: 90px;
    top: -6px; }

.MinParameterPic img {
  margin: auto; }
