.OperationLinkForm_tip {
  margin-top: 20px; }

.OperationLinkForm2 * {
  font-size: 12px !important; }

.OperationLinkForm2 {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 17px; }
  .OperationLinkForm2 .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    max-width: 1000px; }
  .OperationLinkForm2 .tc-15-bubble-icon .tc-15-bubble-bottom {
    z-index: 999; }
  .OperationLinkForm2 .checkbox-inline1 {
    width: 180px;
    display: inline-block; }
  .OperationLinkForm2 .show-data-validation {
    border: 1px solid #ddd;
    border-bottom: 0;
    line-height: 31px;
    padding: 3px 20px;
    width: 100%;
    height: 40px; }
    .OperationLinkForm2 .show-data-validation .border50 .tc-15-checkbox {
      border-radius: 50%; }
    .OperationLinkForm2 .show-data-validation > span {
      vertical-align: top;
      display: inline-block;
      width: 300px; }
    .OperationLinkForm2 .show-data-validation .form-input,
    .OperationLinkForm2 .show-data-validation .form-label {
      padding: 0 !important;
      vertical-align: top !important; }
    .OperationLinkForm2 .show-data-validation .tc-15-dropdown-menu li {
      float: none; }
    .OperationLinkForm2 .show-data-validation button.tc-15-simulate-select {
      width: 200px; }
    .OperationLinkForm2 .show-data-validation .label-show {
      width: 400px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .OperationLinkForm2 .form-list100 {
    padding-right: 0; }
    .OperationLinkForm2 .form-list100 .form-list {
      width: 100%; }
      .OperationLinkForm2 .form-list100 .form-list .section {
        width: 100%; }
        .OperationLinkForm2 .form-list100 .form-list .section .writeInLabelTable {
          border: 1px solid #dddddd;
          margin-top: 5px; }
          .OperationLinkForm2 .form-list100 .form-list .section .writeInLabelTable .label {
            display: flex;
            height: 30px;
            line-height: 30px;
            border-bottom: 1px solid #dddddd;
            padding-left: 10px; }
            .OperationLinkForm2 .form-list100 .form-list .section .writeInLabelTable .label .text {
              width: 50%; }
            .OperationLinkForm2 .form-list100 .form-list .section .writeInLabelTable .label .code {
              width: 50%; }
          .OperationLinkForm2 .form-list100 .form-list .section .writeInLabelTable .label:nth-last-of-type() {
            border-bottom: none; }
      .OperationLinkForm2 .form-list100 .form-list .form-input40 .form-input {
        padding-bottom: 0; }
      .OperationLinkForm2 .form-list100 .form-list .form-input40 .form-label {
        padding-bottom: 0; }
    .OperationLinkForm2 .form-list100 .noleft li {
      float: none !important; }
  .OperationLinkForm2 .conditionForm {
    width: auto !important; }
    .OperationLinkForm2 .conditionForm .box2 {
      width: calc(100% - 110px) !important; }
  .OperationLinkForm2 .tab-block {
    margin-left: 20px;
    width: calc(100% - 40px);
    margin-bottom: 20px; }
  .OperationLinkForm2 .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .OperationLinkForm2 .tc-15-step {
    padding-bottom: 0; }
  .OperationLinkForm2 .tc-15-step li.current .tc-15-step-num:before,
  .OperationLinkForm2 .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .OperationLinkForm2 .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #fff; }
    .OperationLinkForm2 .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .OperationLinkForm2 .step2-view {
    min-height: 600px;
    display: table;
    height: 100%; }
    .OperationLinkForm2 .step2-view .left-view {
      padding-top: 10px;
      width: 160px;
      min-width: 160px;
      height: 100%;
      min-height: 600px;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 2px solid #e5e5e5;
      vertical-align: top; }
      .OperationLinkForm2 .step2-view .left-view .list {
        width: 158px;
        margin-bottom: 14px;
        cursor: pointer; }
        .OperationLinkForm2 .step2-view .left-view .list .list-icon {
          display: inline-block;
          width: 4px;
          height: 23px;
          vertical-align: middle; }
        .OperationLinkForm2 .step2-view .left-view .list .list-icon2 {
          overflow: hidden;
          margin-left: 4px;
          width: 18px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid #aaa;
          border-radius: 2px;
          color: #aaa;
          line-height: 16px;
          display: inline-block; }
        .OperationLinkForm2 .step2-view .left-view .list .list-title {
          margin-left: 6px;
          line-height: 23px;
          vertical-align: middle;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px !important;
          width: calc(100% - 34px); }
        .OperationLinkForm2 .step2-view .left-view .list .list-close {
          color: #bbb; }
        .OperationLinkForm2 .step2-view .left-view .list .icon-select {
          background-color: #006eff; }
        .OperationLinkForm2 .step2-view .left-view .list .title-select {
          font-weight: bold;
          color: #006eff; }
    .OperationLinkForm2 .step2-view .right-view {
      position: relative;
      padding-top: 10px;
      height: 100%;
      width: 100%;
      display: table-cell;
      vertical-align: top; }
      .OperationLinkForm2 .step2-view .right-view .title {
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5; }
        .OperationLinkForm2 .step2-view .right-view .title > p {
          line-height: 23px;
          padding-left: 25px;
          margin-bottom: 5px; }
        .OperationLinkForm2 .step2-view .right-view .title .box {
          margin-left: 20px;
          width: calc(100% - 20px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          line-height: 23px;
          color: #262626; }
        .OperationLinkForm2 .step2-view .right-view .title .box2 {
          margin-left: 20px;
          width: calc(100% - 20px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          margin-bottom: 15px;
          line-height: 33px;
          color: #262626; }
          .OperationLinkForm2 .step2-view .right-view .title .box2 .box3 {
            padding: 5px 10px;
            border: 1px solid #ddd;
            display: inline-block;
            height: 26px;
            line-height: 14px; }
        .OperationLinkForm2 .step2-view .right-view .title .box2-view {
          margin-left: 20px;
          width: calc(100% - 20px);
          border: 1px solid #ddd;
          padding: 10px 20px;
          margin-bottom: 15px;
          line-height: 23px;
          color: #262626;
          background: #f8f8f8;
          padding-bottom: 0;
          border-radius: 2px; }
      .OperationLinkForm2 .step2-view .right-view .normal {
        padding-top: 15px;
        border-bottom: none; }
        .OperationLinkForm2 .step2-view .right-view .normal .box {
          padding: 0; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .box-head,
          .OperationLinkForm2 .step2-view .right-view .normal .box .box-body {
            border-bottom: 1px solid #ddd; }
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head > div,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body > div {
              padding: 0 10px;
              width: 20%;
              vertical-align: middle;
              line-height: 48px;
              display: inline-block;
              min-height: 50px;
              vertical-align: top;
              color: #444;
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; }
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head .form-input,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head .form-label,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head .form-output,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body .form-input,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body .form-label,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body .form-output {
              display: block;
              /* vertical-align: top; */
              padding-bottom: 0px; }
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head .tc-15-select,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body .tc-15-select {
              min-width: 0; }
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head .tc-input-group,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body .tc-input-group {
              width: 100%;
              min-width: 0; }
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-head .tc-input-group .tc-input-group-addon,
            .OperationLinkForm2 .step2-view .right-view .normal .box .box-body .tc-input-group .tc-input-group-addon {
              width: 33px; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .box-head > div {
            height: 40px;
            line-height: 40px;
            color: #888;
            min-height: 40px; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .box-body:hover {
            background-color: #f7f7f7; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .wd25 > div {
            width: 25%; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .box-body > div {
            font-weight: normal; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .box-body:last-child {
            border-bottom: none; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .form-input {
            padding-bottom: 0; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .form-input-help {
            line-height: 0;
            margin-bottom: 12px; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .tc-input-group {
            line-height: 28px; }
          .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY {
            margin-left: 10px;
            margin-right: 10px; }
            .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY span {
              color: rgba(0, 0, 0, 0.65); }
            .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline {
              display: inline-block;
              float: left;
              margin-top: 20px;
              margin-left: 10px; }
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-label label {
                display: none; }
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-input-help {
                float: left;
                margin-bottom: 0;
                line-height: inherit !important; }
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline.inline3 .form-input-help {
                position: absolute;
                width: 200px; }
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline .icon-valid-flag {
                display: none; }
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-ctrl-label {
                display: block;
                height: 40px;
                line-height: 40px; }
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-15-select,
              .OperationLinkForm2 .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-input-group {
                min-width: 0px; }
        .OperationLinkForm2 .step2-view .right-view .normal .second-title {
          padding-left: 20px;
          font-size: 14px !important;
          margin-top: 20px; }
          .OperationLinkForm2 .step2-view .right-view .normal .second-title span {
            font-size: 14px !important; }
          .OperationLinkForm2 .step2-view .right-view .normal .second-title .span2 {
            color: #ee6f1e;
            font-size: 24px !important; }
          .OperationLinkForm2 .step2-view .right-view .normal .second-title .span3 {
            color: #ee6f1e; }
      .OperationLinkForm2 .step2-view .right-view .accurate-title {
        padding-top: 10px;
        padding-left: 25px;
        margin-bottom: 15px !important; }
      .OperationLinkForm2 .step2-view .right-view .accurate {
        padding-bottom: 10px; }
        .OperationLinkForm2 .step2-view .right-view .accurate .box {
          margin-left: 20px;
          width: calc(100% - 20px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          padding-top: 15px;
          line-height: 23px;
          color: #262626; }
          .OperationLinkForm2 .step2-view .right-view .accurate .box .tc-15-rich-radio {
            line-height: 25px;
            width: calc(100% - 100px);
            vertical-align: top; }
        .OperationLinkForm2 .step2-view .right-view .accurate .btn-group {
          margin-bottom: 10px; }
        .OperationLinkForm2 .step2-view .right-view .accurate .accurate-title2 {
          margin-bottom: 15px;
          padding-top: 10px;
          padding-left: 25px; }
        .OperationLinkForm2 .step2-view .right-view .accurate .box2 {
          margin-left: 20px;
          width: calc(100% - 20px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          margin-bottom: 15px;
          line-height: 33px;
          color: #262626; }
          .OperationLinkForm2 .step2-view .right-view .accurate .box2 .box3 {
            padding: 5px 10px;
            border: 1px solid #ddd;
            display: inline-block;
            height: 26px;
            line-height: 14px; }
        .OperationLinkForm2 .step2-view .right-view .accurate .box2-view {
          margin-left: 20px;
          width: calc(100% - 20px);
          border: 1px solid #ddd;
          padding: 10px 20px;
          margin-bottom: 15px;
          line-height: 23px;
          color: #262626;
          background: #f8f8f8;
          padding-bottom: 0;
          border-radius: 2px; }
      .OperationLinkForm2 .step2-view .right-view .btn-group {
        margin-bottom: 20px;
        padding-left: 20px; }
    .OperationLinkForm2 .step2-view .tc-15-switch input {
      width: 35px !important; }
  .OperationLinkForm2 .mb10 {
    margin-bottom: 10px; }
  .OperationLinkForm2 .mb20 {
    margin-bottom: 20px; }
  .OperationLinkForm2 .ml10 {
    margin-left: 10px; }
  .OperationLinkForm2 .ml20 {
    margin-left: 20px; }
  .OperationLinkForm2 .mr10 {
    margin-right: 10px; }
  .OperationLinkForm2 .mr20 {
    margin-right: 20px; }
  .OperationLinkForm2 .flr {
    float: right; }
  .OperationLinkForm2 .valignm {
    vertical-align: middle; }
  .OperationLinkForm2 .w100 {
    width: 100% !important; }
  .OperationLinkForm2 .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .OperationLinkForm2 .cblue {
    color: #006eff !important; }
  .OperationLinkForm2 .cursorp {
    cursor: pointer; }
  .OperationLinkForm2 .lh0 {
    line-height: 0; }
  .OperationLinkForm2 .lh25 {
    line-height: 25px; }
  .OperationLinkForm2 .clear {
    clear: both; }
  .OperationLinkForm2 .checked-rule-box {
    padding-left: 20px; }
  .OperationLinkForm2 .checked-array-box {
    border: 1px solid #ddd;
    width: 900px;
    min-width: 900px;
    margin-bottom: 20px; }
    .OperationLinkForm2 .checked-array-box .form-list {
      margin: 10px 0 0 0;
      display: flex;
      border-bottom: 1px solid #ddd; }
      .OperationLinkForm2 .checked-array-box .form-list:last-child {
        border: none; }
      .OperationLinkForm2 .checked-array-box .form-list li {
        margin-right: 20px; }
      .OperationLinkForm2 .checked-array-box .form-list .form-input,
      .OperationLinkForm2 .checked-array-box .form-list .form-label,
      .OperationLinkForm2 .checked-array-box .form-list .form-output {
        padding-bottom: 10px; }
      .OperationLinkForm2 .checked-array-box .form-list li.pure-text-row .form-input {
        line-height: 30px; }
      .OperationLinkForm2 .checked-array-box .form-list .form-input .tc-input-group {
        min-width: 100px; }
      .OperationLinkForm2 .checked-array-box .form-list .form-input input {
        width: 80px; }
      .OperationLinkForm2 .checked-array-box .form-list .form-input select {
        width: 120px; }
    .OperationLinkForm2 .checked-array-box .add-btn {
      width: 15%;
      padding-left: 20px;
      margin: 0;
      line-height: 51px;
      color: #006eff;
      cursor: pointer; }
    .OperationLinkForm2 .checked-array-box .set-color {
      color: #006eff;
      cursor: pointer;
      line-height: 30px; }
  .OperationLinkForm2 .checked-view {
    padding-left: 20px; }
    .OperationLinkForm2 .checked-view .form-list {
      margin: 0; }
    .OperationLinkForm2 .checked-view li {
      line-height: 51px; }
  .OperationLinkForm2 .mb-color {
    margin-bottom: 20px;
    color: #000; }
    .OperationLinkForm2 .mb-color .form-ctrl-label {
      margin-right: 0; }
    .OperationLinkForm2 .mb-color .tc-15-switch {
      margin-left: 20px;
      vertical-align: top; }
  .OperationLinkForm2 .radio-box .tc-15-rich-radio {
    vertical-align: inherit; }
    .OperationLinkForm2 .radio-box .tc-15-rich-radio label {
      margin-left: 15px;
      vertical-align: initial; }
  .OperationLinkForm2 .bottom-btn {
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
    padding-top: 20px; }
    .OperationLinkForm2 .bottom-btn > :first-child {
      margin-right: 10px; }
  .OperationLinkForm2 .RightStep1Edit {
    color: #888;
    margin: 20px;
    margin-top: 0; }
    .OperationLinkForm2 .RightStep1Edit .prize-image {
      width: 80px;
      height: 80px;
      position: relative;
      display: inline-block;
      background: #f2f2f2;
      border: 1px solid #d9d9d9;
      text-align: center;
      vertical-align: middle; }
      .OperationLinkForm2 .RightStep1Edit .prize-image img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .OperationLinkForm2 .RightStep1Edit .prize-image .n-loading-icon {
        position: relative;
        margin-top: 30px;
        z-index: 10; }
      .OperationLinkForm2 .RightStep1Edit .prize-image span {
        width: 156px;
        color: #666666;
        display: inline-block;
        position: absolute;
        left: 90px;
        top: -6px; }
    .OperationLinkForm2 .RightStep1Edit .prize-image-upload {
      display: inline-block;
      margin-left: 10px;
      vertical-align: bottom; }
    .OperationLinkForm2 .RightStep1Edit .extra-text {
      color: #666666;
      line-height: 30px;
      width: 276px; }
    .OperationLinkForm2 .RightStep1Edit .ant-upload {
      vertical-align: bottom; }
    .OperationLinkForm2 .RightStep1Edit .inline {
      display: inline-block;
      float: left; }
      .OperationLinkForm2 .RightStep1Edit .inline .form-label label {
        display: none; }
      .OperationLinkForm2 .RightStep1Edit .inline .form-input-help {
        float: left;
        margin-bottom: 0; }
      .OperationLinkForm2 .RightStep1Edit .inline .icon-valid-flag {
        display: none; }
      .OperationLinkForm2 .RightStep1Edit .inline .form-ctrl-label {
        display: block;
        height: 40px;
        line-height: 40px; }
      .OperationLinkForm2 .RightStep1Edit .inline .tc-15-select,
      .OperationLinkForm2 .RightStep1Edit .inline .tc-input-group {
        min-width: 0px; }
    .OperationLinkForm2 .RightStep1Edit .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
      background: #000;
      color: #fff; }
      .OperationLinkForm2 .RightStep1Edit .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
        border-top-color: #000; }
    .OperationLinkForm2 .RightStep1Edit .form-list {
      margin-bottom: 20px !important; }
    .OperationLinkForm2 .RightStep1Edit .formlistLast .form-list {
      margin-bottom: 0px !important; }

.mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.OperationLinkForm .section .ml-10 {
  margin-left: -15px !important; }

.OperationLinkForm .section .width60 {
  width: 60px !important; }

.OperationLinkForm .section .time-limit {
  margin-left: -60px; }
  .OperationLinkForm .section .time-limit .tc-15-simulate-select,
  .OperationLinkForm .section .time-limit .tc-15-dropdown-link,
  .OperationLinkForm .section .time-limit .tc-15-dropdown {
    width: 180px !important; }

.OperationLinkForm .section .time-limit-up {
  margin-left: -40px;
  margin-top: -2px; }
  .OperationLinkForm .section .time-limit-up .tc-15-simulate-select,
  .OperationLinkForm .section .time-limit-up .tc-15-dropdown-link,
  .OperationLinkForm .section .time-limit-up .tc-15-dropdown {
    width: 170px !important; }

.OperationLinkForm .section .limit .tc-15-dropdown-link {
  max-width: 100%; }

.OperationLinkForm .section .limit .tc-15-dropdown-menu li {
  width: 100%; }

.OperationLinkForm .section .limit1 label {
  width: 190px; }

.OperationLinkForm .section .limit0 label {
  width: 0px; }

.OperationLinkForm .section .radio-limit .tc-15-dropdown-menu,
.OperationLinkForm .section .radio-limit a.tc-15-dropdown-link {
  width: 180px !important; }

.phrase2-link-style {
  position: relative; }

@media screen and (max-width: 1366px) {
  .wless1366 {
    margin-left: 0 !important; } }

.OperationLinkForm .ml20 {
  margin-left: 20px; }

.OperationLinkForm .chooselabel-btn {
  cursor: pointer; }

.OperationLinkForm .chooselabel-select-wrap .tc-15-dropdown-link {
  min-width: 180px !important; }

.OperationLinkForm .chooselabel-select-wrap .tc-15-input-text {
  width: auto !important; }

.OperationLinkForm .chooselabel-select-wrap .tc-15-dropdown {
  width: 180px !important; }

.OperationLinkForm .chooselabel-select-wrap .tc-15-dropdown-menu li {
  float: none; }

.OperationLinkForm .chooselabel-select-wrap .label-show {
  width: 400px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
