.Step2EditPage_tip {
  margin-top: 20px; }

.Step2EditPageMall {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 17px; }
  .Step2EditPageMall .tab-block {
    margin-left: 20px;
    width: calc(100% - 40px);
    margin-bottom: 20px; }
  .Step2EditPageMall .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .Step2EditPageMall .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0; }
    .Step2EditPageMall .tc-15-step * {
      font-size: 12px !important; }
  .Step2EditPageMall .tc-15-step li.current .tc-15-step-num:before,
  .Step2EditPageMall .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .Step2EditPageMall .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .Step2EditPageMall .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .Step2EditPageMall .step2-view {
    min-height: 600px;
    display: table;
    height: 100%; }
    .Step2EditPageMall .step2-view .left-view {
      padding-top: 10px;
      width: 220px;
      min-width: 220px;
      height: calc(100% - 84px);
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 2px solid #e5e5e5;
      vertical-align: top; }
      .Step2EditPageMall .step2-view .left-view * {
        font-size: 12px !important; }
      .Step2EditPageMall .step2-view .left-view .list {
        width: calc(100% - 20px);
        margin-bottom: 14px;
        cursor: pointer;
        justify-content: flex-start; }
        .Step2EditPageMall .step2-view .left-view .list .list-icon {
          display: inline-block;
          width: 4px;
          height: 23px;
          vertical-align: middle; }
        .Step2EditPageMall .step2-view .left-view .list .list-icon2 {
          overflow: hidden;
          margin-left: 4px;
          min-width: 18px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid #aaa;
          border-radius: 2px;
          color: #aaa;
          line-height: 16px;
          display: inline-block;
          height: 18px;
          margin-top: 2px; }
        .Step2EditPageMall .step2-view .left-view .list .list-title-hasimg {
          display: inline-block;
          vertical-align: text-top;
          width: calc(100% - 95px); }
        .Step2EditPageMall .step2-view .left-view .list .list-title {
          margin-left: 6px;
          line-height: 23px;
          vertical-align: middle;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px !important;
          width: 100%; }
        .Step2EditPageMall .step2-view .left-view .list .list-close {
          color: #bbb; }
        .Step2EditPageMall .step2-view .left-view .list .icon-select {
          background-color: #006eff; }
        .Step2EditPageMall .step2-view .left-view .list .title-select {
          font-weight: bold;
          color: #006eff; }
      .Step2EditPageMall .step2-view .left-view .prize-image {
        margin-left: 5px;
        width: 60px;
        height: 60px;
        position: relative;
        display: inline-block;
        background: #F2F2F2;
        border: 1px solid #D9D9D9;
        text-align: center;
        vertical-align: text-top;
        min-width: 60px; }
        .Step2EditPageMall .step2-view .left-view .prize-image img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
    .Step2EditPageMall .step2-view .poolPager {
      position: relative;
      border-right: 2px solid #ddd; }
      .Step2EditPageMall .step2-view .poolPager * {
        font-size: auto !important; }
      .Step2EditPageMall .step2-view .poolPager .tc-15-page {
        padding-left: 10px !important;
        padding-right: 10px !important; }
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-operate {
        float: inherit !important; }
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-state {
        margin-left: 10px;
        margin-top: 4px; }
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-first {
        margin-left: 10px; }
      .Step2EditPageMall .step2-view .poolPager .page-num {
        padding-left: 6px;
        padding-right: 6px;
        width: 35px !important; }
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-num {
        padding-left: 6px;
        padding-right: 6px; }
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-first,
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-pre,
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-pre + .tc-15-page-select,
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-next,
      .Step2EditPageMall .step2-view .poolPager .tc-15-page-last {
        margin-top: 10px; }
    .Step2EditPageMall .step2-view .right-view {
      position: relative;
      padding-top: 10px;
      height: 100%;
      width: 100%;
      display: table-cell;
      vertical-align: top; }
      .Step2EditPageMall .step2-view .right-view * {
        font-size: 12px !important; }
      .Step2EditPageMall .step2-view .right-view .title {
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5; }
        .Step2EditPageMall .step2-view .right-view .title > p {
          line-height: 23px;
          padding-left: 25px;
          margin-bottom: 5px; }
        .Step2EditPageMall .step2-view .right-view .title .box {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          line-height: 23px;
          color: #262626; }
        .Step2EditPageMall .step2-view .right-view .title .box2 {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          margin-bottom: 15px;
          line-height: 33px;
          color: #262626; }
          .Step2EditPageMall .step2-view .right-view .title .box2 .box3 {
            padding: 5px 10px;
            border: 1px solid #ddd;
            display: inline-block;
            height: 26px;
            line-height: 14px; }
        .Step2EditPageMall .step2-view .right-view .title .box2-view {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 10px 20px;
          margin-bottom: 15px;
          line-height: 23px;
          color: #262626;
          background: #F8F8F8;
          padding-bottom: 0;
          border-radius: 2px; }
      .Step2EditPageMall .step2-view .right-view .normal {
        padding-top: 15px;
        border-bottom: none; }
        .Step2EditPageMall .step2-view .right-view .normal .box {
          padding: 0; }
          .Step2EditPageMall .step2-view .right-view .normal .box .box-head,
          .Step2EditPageMall .step2-view .right-view .normal .box .box-body {
            border-bottom: 1px solid #ddd; }
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head > div,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body > div {
              padding: 0 10px;
              width: 20%;
              vertical-align: middle;
              line-height: 48px;
              display: inline-block;
              min-height: 50px;
              vertical-align: top;
              color: #444;
              font-weight: bold;
              text-overflow: ellipsis;
              white-space: nowrap; }
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .form-input,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .form-label,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .form-output,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .form-input,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .form-label,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .form-output {
              display: block;
              /* vertical-align: top; */
              padding-bottom: 0px; }
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .icon-valid-flag,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .icon-valid-flag {
              display: none; }
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .tc-15-select,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .tc-15-select {
              min-width: 0; }
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .tc-input-group,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .tc-input-group {
              width: 100%;
              min-width: 0; }
            .Step2EditPageMall .step2-view .right-view .normal .box .box-head .tc-input-group .tc-input-group-addon,
            .Step2EditPageMall .step2-view .right-view .normal .box .box-body .tc-input-group .tc-input-group-addon {
              width: 33px; }
          .Step2EditPageMall .step2-view .right-view .normal .box .box-head > div {
            height: 40px;
            line-height: 40px;
            color: #888;
            min-height: 40px; }
          .Step2EditPageMall .step2-view .right-view .normal .box .box-body:hover {
            background-color: #f7f7f7; }
          .Step2EditPageMall .step2-view .right-view .normal .box .wd25 > div {
            width: 25%; }
          .Step2EditPageMall .step2-view .right-view .normal .box .box-body > div {
            font-weight: normal; }
          .Step2EditPageMall .step2-view .right-view .normal .box .box-body:last-child {
            border-bottom: none; }
          .Step2EditPageMall .step2-view .right-view .normal .box .form-input {
            padding-bottom: 0; }
          .Step2EditPageMall .step2-view .right-view .normal .box .form-input-help {
            line-height: 0;
            margin-bottom: 12px; }
          .Step2EditPageMall .step2-view .right-view .normal .box .tc-input-group {
            line-height: 28px; }
          .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY {
            margin-left: 10px;
            margin-right: 10px; }
            .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY span {
              color: rgba(0, 0, 0, 0.65); }
            .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline {
              display: inline-block;
              margin-top: 20px;
              margin-left: 10px; }
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-label label {
                display: none; }
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-input-help {
                float: left;
                margin-bottom: 0;
                line-height: inherit !important; }
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline.inline3 .form-input-help {
                position: absolute;
                width: 200px; }
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline .icon-valid-flag {
                display: none; }
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-ctrl-label {
                display: block;
                height: 40px;
                line-height: 40px; }
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-15-select,
              .Step2EditPageMall .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-input-group {
                min-width: 0px; }
        .Step2EditPageMall .step2-view .right-view .normal .second-title {
          padding-left: 20px;
          font-size: 14px !important;
          margin-top: 20px; }
          .Step2EditPageMall .step2-view .right-view .normal .second-title span {
            font-size: 14px !important; }
          .Step2EditPageMall .step2-view .right-view .normal .second-title .span2 {
            color: #EE6F1E;
            font-size: 24px !important; }
          .Step2EditPageMall .step2-view .right-view .normal .second-title .span3 {
            color: #EE6F1E; }
      .Step2EditPageMall .step2-view .right-view .accurate-title {
        padding-top: 10px;
        padding-left: 25px;
        margin-bottom: 15px !important; }
      .Step2EditPageMall .step2-view .right-view .accurate {
        padding-bottom: 10px; }
        .Step2EditPageMall .step2-view .right-view .accurate .tc-15-rich-radio {
          line-height: 25px;
          width: calc(100% - 100px);
          vertical-align: top; }
        .Step2EditPageMall .step2-view .right-view .accurate .box {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          padding-top: 15px;
          line-height: 23px;
          color: #262626; }
        .Step2EditPageMall .step2-view .right-view .accurate .btn-group {
          margin-bottom: 10px; }
        .Step2EditPageMall .step2-view .right-view .accurate .accurate-title2 {
          margin-bottom: 15px;
          padding-top: 10px;
          padding-left: 25px; }
        .Step2EditPageMall .step2-view .right-view .accurate .box2 {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 5px 20px;
          margin-bottom: 15px;
          line-height: 33px;
          color: #262626; }
          .Step2EditPageMall .step2-view .right-view .accurate .box2 .box3 {
            padding: 5px 10px;
            border: 1px solid #ddd;
            display: inline-block;
            height: 26px;
            line-height: 14px; }
        .Step2EditPageMall .step2-view .right-view .accurate .box2-view {
          margin-left: 20px;
          width: calc(100% - 40px);
          border: 1px solid #ddd;
          padding: 10px 20px;
          margin-bottom: 15px;
          line-height: 23px;
          color: #262626;
          background: #F8F8F8;
          padding-bottom: 0;
          border-radius: 2px; }
      .Step2EditPageMall .step2-view .right-view .btn-group {
        margin-bottom: 20px;
        padding-left: 20px; }
    .Step2EditPageMall .step2-view .tc-15-switch input {
      width: 35px !important; }
  .Step2EditPageMall .mb10 {
    margin-bottom: 10px; }
  .Step2EditPageMall .mb20 {
    margin-bottom: 20px; }
  .Step2EditPageMall .ml10 {
    margin-left: 10px; }
  .Step2EditPageMall .ml20 {
    margin-left: 20px; }
  .Step2EditPageMall .mr10 {
    margin-right: 10px; }
  .Step2EditPageMall .mr20 {
    margin-right: 20px; }
  .Step2EditPageMall .flr {
    float: right; }
  .Step2EditPageMall .valignm {
    vertical-align: middle; }
  .Step2EditPageMall .w100 {
    width: 100% !important; }
  .Step2EditPageMall .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .Step2EditPageMall .cblue {
    color: #006eff !important; }
  .Step2EditPageMall .cursorp {
    cursor: pointer; }
  .Step2EditPageMall .lh0 {
    line-height: 0; }
  .Step2EditPageMall .lh25 {
    line-height: 25px; }
  .Step2EditPageMall .clear {
    clear: both; }
  .Step2EditPageMall .checked-rule-box {
    padding-left: 20px; }
  .Step2EditPageMall .checked-array-box {
    border: 1px solid #ddd;
    width: 900px;
    min-width: 900px;
    margin-bottom: 20px; }
    .Step2EditPageMall .checked-array-box .form-list {
      margin: 10px 0 0 0;
      display: flex;
      border-bottom: 1px solid #ddd; }
      .Step2EditPageMall .checked-array-box .form-list:last-child {
        border: none; }
      .Step2EditPageMall .checked-array-box .form-list li {
        margin-right: 20px; }
      .Step2EditPageMall .checked-array-box .form-list .form-input,
      .Step2EditPageMall .checked-array-box .form-list .form-label,
      .Step2EditPageMall .checked-array-box .form-list .form-output {
        padding-bottom: 10px; }
      .Step2EditPageMall .checked-array-box .form-list li.pure-text-row .form-input {
        line-height: 30px; }
      .Step2EditPageMall .checked-array-box .form-list .form-input .tc-input-group {
        min-width: 100px; }
      .Step2EditPageMall .checked-array-box .form-list .form-input input {
        width: 80px; }
      .Step2EditPageMall .checked-array-box .form-list .form-input select {
        width: 120px; }
    .Step2EditPageMall .checked-array-box .add-btn {
      width: 15%;
      padding-left: 20px;
      margin: 0;
      line-height: 51px;
      color: #006EFF;
      cursor: pointer; }
    .Step2EditPageMall .checked-array-box .set-color {
      color: #006EFF;
      cursor: pointer;
      line-height: 30px; }
  .Step2EditPageMall .checked-view {
    padding-left: 20px; }
    .Step2EditPageMall .checked-view .form-list {
      margin: 0; }
    .Step2EditPageMall .checked-view li {
      line-height: 51px; }
  .Step2EditPageMall .mb-color {
    margin-bottom: 20px;
    color: #000; }
    .Step2EditPageMall .mb-color .form-ctrl-label {
      margin-right: 0; }
    .Step2EditPageMall .mb-color .tc-15-switch {
      margin-left: 20px;
      vertical-align: top; }
  .Step2EditPageMall .radio-box .tc-15-rich-radio {
    vertical-align: inherit; }
    .Step2EditPageMall .radio-box .tc-15-rich-radio label {
      margin-left: 15px;
      vertical-align: initial; }
  .Step2EditPageMall .bottom-btn {
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
    padding-top: 20px; }
    .Step2EditPageMall .bottom-btn > :first-child {
      margin-right: 10px; }
  .Step2EditPageMall .RightStep1Edit {
    color: #888;
    margin: 20px;
    margin-top: 0; }
    .Step2EditPageMall .RightStep1Edit .prize-image {
      width: 80px;
      height: 80px;
      position: relative;
      display: inline-block;
      background: #F2F2F2;
      border: 1px solid #D9D9D9;
      text-align: center;
      vertical-align: middle; }
      .Step2EditPageMall .RightStep1Edit .prize-image img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .Step2EditPageMall .RightStep1Edit .prize-image .n-loading-icon {
        position: relative;
        margin-top: 30px;
        z-index: 10; }
      .Step2EditPageMall .RightStep1Edit .prize-image span {
        width: 156px;
        color: #666666;
        display: inline-block;
        position: absolute;
        left: 90px;
        top: -6px; }
    .Step2EditPageMall .RightStep1Edit .prize-image-upload {
      display: inline-block;
      margin-left: 10px;
      vertical-align: bottom; }
    .Step2EditPageMall .RightStep1Edit .extra-text {
      color: #666666;
      line-height: 30px;
      min-width: 276px; }
    .Step2EditPageMall .RightStep1Edit .ant-upload {
      vertical-align: bottom; }
    .Step2EditPageMall .RightStep1Edit .inline {
      display: inline-block;
      float: left; }
      .Step2EditPageMall .RightStep1Edit .inline .form-label label {
        display: none; }
      .Step2EditPageMall .RightStep1Edit .inline .form-input-help {
        float: left;
        margin-bottom: 0; }
      .Step2EditPageMall .RightStep1Edit .inline .icon-valid-flag {
        display: none; }
      .Step2EditPageMall .RightStep1Edit .inline .form-ctrl-label {
        display: block;
        height: 40px;
        line-height: 40px; }
      .Step2EditPageMall .RightStep1Edit .inline .tc-15-select,
      .Step2EditPageMall .RightStep1Edit .inline .tc-input-group {
        min-width: 0px; }
    .Step2EditPageMall .RightStep1Edit .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
      background: #000;
      color: #FFF; }
      .Step2EditPageMall .RightStep1Edit .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
        border-top-color: #000; }
    .Step2EditPageMall .RightStep1Edit .form-list {
      margin-bottom: 20px !important; }
    .Step2EditPageMall .RightStep1Edit .formlistLast .form-list {
      margin-bottom: 0px !important; }

.mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .mallGroup .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.commonModal2.mall_prizeModal.point_mall {
  width: 400px !important; }
  .commonModal2.mall_prizeModal.point_mall .status-box {
    display: inline-flex; }
  .commonModal2.mall_prizeModal.point_mall .checkbox-inline .form-label {
    display: none; }
  .commonModal2.mall_prizeModal.point_mall .checkbox-inline .form-input {
    display: inline-flex; }
    .commonModal2.mall_prizeModal.point_mall .checkbox-inline .form-input .form-ctrl-label {
      margin-right: 0; }

.Step2SortMall .sortContent {
  font-size: 12px;
  background: white;
  margin: 20px 0;
  padding: 20px;
  margin-top: 0; }

.Step2SortMall .sortlist {
  user-select: none;
  display: block;
  width: 100%;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: visible; }
  .Step2SortMall .sortlist > div {
    float: left;
    position: relative;
    height: 82px;
    width: 250px;
    background: #fff;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd; }
  .Step2SortMall .sortlist .prize-p {
    margin-top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .Step2SortMall .sortlist .prize-index {
    padding: 0 2px;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    border: 1px solid #006eff;
    font-weight: bold;
    color: #006eff; }
  .Step2SortMall .sortlist .prize-image {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 10px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border-right: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .Step2SortMall .sortlist .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .Step2SortMall .sortlist .prize-remove {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 10px;
    bottom: 8px; }

.Step2SortMall .btnBar {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 15px; }
  .Step2SortMall .btnBar .weak-button {
    margin-left: 10px; }

.helperC-mall {
  font-size: 12px !important;
  position: relative;
  height: 82px;
  width: 250px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: move;
  pointer-events: auto !important; }
  .helperC-mall .prize-p {
    margin-top: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .helperC-mall .prize-index {
    padding: 0 2px;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    border: 1px solid #006eff;
    font-weight: bold;
    color: #006eff; }
  .helperC-mall .prize-image {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 10px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border-right: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .helperC-mall .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .helperC-mall .prize-remove {
    cursor: pointer;
    color: #006eff;
    position: absolute;
    right: 10px;
    bottom: 8px; }
