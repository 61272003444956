.choose-label-modal .tc-15-input-text {
  width: auto; }

.choose-label-modal .content-wrap {
  border: 1px solid #dddddd;
  padding: 10px 20px;
  margin-top: 5px; }

.choose-label-modal .content-box {
  margin-top: 5px;
  margin-bottom: 10px; }
  .choose-label-modal .content-box > span {
    color: #666;
    font-size: 12px; }

.choose-label-modal .text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000 !important; }

.choose-label-modal .tc-15-dropdown-btn-style .tc-15-dropdown-link {
  max-width: 330px; }

.choose-label-modal .content-box {
  position: relative;
  z-index: 0; }
  .choose-label-modal .content-box .name {
    border: 1px solid #ddd;
    display: inline-block;
    padding: 3px 10px;
    margin-right: 5px;
    font-size: 12px;
    color: #666;
    margin-bottom: 8px; }
  .choose-label-modal .content-box .pos {
    position: relative;
    z-index: 1;
    background-color: #006eff;
    color: white;
    border: 1px solid #006eff;
    cursor: pointer; }
    .choose-label-modal .content-box .pos .tips {
      position: absolute;
      left: 0;
      top: 30px;
      padding: 8px 10px;
      min-width: 200px;
      font-size: 12px;
      color: #666;
      border: 1px solid #ddd;
      background: #fff;
      cursor: default; }
    .choose-label-modal .content-box .pos .show-name {
      min-width: 300px;
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px; }
  .choose-label-modal .content-box .hidden {
    display: none; }

.choose-label-modal .no-content {
  text-align: center;
  font-size: 12px;
  color: #666; }

.choose-label-modal .x {
  margin-left: -5px;
  cursor: pointer;
  margin-right: 10px !important; }

.choose-label-modal .x:hover {
  color: #006eff; }

.choose-label-modal .search-wrap {
  position: relative;
  z-index: 99; }
