.ModifyLinkForm {
  width: 880px; }
  .ModifyLinkForm .section {
    display: table-row;
    overflow: auto; }
    .ModifyLinkForm .section .form-label {
      vertical-align: top; }
    .ModifyLinkForm .section li {
      float: left; }
      .ModifyLinkForm .section li:first-of-type .form-label {
        display: none; }
    .ModifyLinkForm .section .checkbox-inline .form-unit {
      float: left; }
      .ModifyLinkForm .section .checkbox-inline .form-unit .form-ctrl-label {
        margin-right: 3px; }
    .ModifyLinkForm .section .checkbox-inline .form-input {
      line-height: 30px;
      color: #888; }
    .ModifyLinkForm .section.condition li {
      margin-right: 20px; }
    .ModifyLinkForm .section.condition .tc-15-dropdown-link,
    .ModifyLinkForm .section.condition input.tc-15-input-text,
    .ModifyLinkForm .section.condition select.tc-15-select {
      width: 126px;
      min-width: 126px; }
  .ModifyLinkForm .fields .tc-multi-level-selector {
    display: inline-block; }
  .ModifyLinkForm .fields .icon-valid-flag {
    vertical-align: top; }
