.ucode-login {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  text-align: center;
  background-color: #fff; }
  .ucode-login .block {
    font-size: 14px;
    color: #002D00;
    line-height: 26px;
    text-align: left;
    background-color: #fff;
    position: relative;
    top: 21%;
    display: flex;
    justify-content: center; }
    .ucode-login .block > div {
      max-width: 694px; }
      .ucode-login .block > div > div:first-child {
        font-size: 16px;
        font-weight: bold;
        line-height: initial;
        padding-bottom: 30px; }
      .ucode-login .block > div svg {
        margin-left: 9px; }
    .ucode-login .block .bt-group {
      padding-top: 20px; }
      .ucode-login .block .bt-group a {
        color: #000;
        background-color: #fff;
        margin-left: 20px;
        border: 1px solid #CCCCCC; }
  .ucode-login .icon-ucode {
    width: 227px;
    height: 205px;
    margin-right: 37px; }

.ucode-login-dialog .tc-15-rich-dialog-hd {
  margin-bottom: 19px; }

.ucode-login-dialog .tc-15-rich-dialog-ft {
  margin-top: 0;
  padding-bottom: 24px; }

.ucode-login-dialog .form-input-invitationCode,
.ucode-login-dialog a {
  display: table-cell; }

.ucode-login-dialog a {
  font-size: 12px;
  color: #006EFF;
  text-align: left;
  line-height: 18px;
  padding-left: 10px; }

.ucode-login-dialog .form-label {
  position: relative; }

.ucode-login-dialog label.invitation {
  padding: 0; }

.ucode-login-dialog .hint:hover .text {
  display: block; }

.ucode-login-dialog .hint .icon-hint {
  margin-left: 6.5px;
  vertical-align: text-bottom; }

.ucode-login-dialog .hint .text {
  display: none;
  position: absolute;
  top: -51px;
  left: -11px;
  z-index: 2; }
  .ucode-login-dialog .hint .text div {
    font-size: 12px;
    color: #fff;
    line-height: 47px;
    width: 406px;
    padding-left: 15px;
    background: #333;
    border: 1px solid #DDDDDD;
    box-shadow: 1px 1px 4px 0 rgba(187, 187, 187, 0.5); }
  .ucode-login-dialog .hint .text span {
    position: absolute;
    left: 58px;
    top: 47px;
    border: 6px solid transparent;
    border-top-color: #333; }

.ucode-login-dialog .block {
  padding-left: 81px; }
  .ucode-login-dialog .block.active {
    padding-left: 60px; }
