.role-form-bg {
  min-width: 800px;
  margin: 20px 0;
  padding: 20px 20px 40px;
  background: #fff; }

.role-tree-title {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #888888;
  letter-spacing: 0; }

.role-tree {
  display: flex; }
  .role-tree > div {
    width: 330px;
    height: 509px;
    border: 1px solid #ddd;
    overflow-y: scroll;
    color: #666666; }
  .role-tree > div:first-child {
    margin-right: 10px; }
