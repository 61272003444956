.ty-marketing-table-footer {
  background-color: #fff;
  margin-top: 10px;
  padding-left: 10px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #888888;
  text-align: left;
  line-height: 18px;
  margin-bottom: -10px;
  padding-bottom: 10px; }
  .ty-marketing-table-footer .ant-row {
    margin-top: 10px;
    padding-left: 5px; }
  .ty-marketing-table-footer div[class^="ant-col"] {
    padding: 5px 0; }
  .ty-marketing-table-footer:last-child {
    margin-bottom: 0; }
  .ty-marketing-table-footer-num {
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #ff7800;
    text-align: left;
    line-height: 18px;
    margin: 0 15px 0 10px; }

.ty-reset-date-range-picker {
  margin-left: 10px; }
  .ty-reset-date-range-picker .tc-15-calendar-footer {
    height: auto;
    overflow: hidden; }
  .ty-reset-date-range-picker .tc-15-input-text-wrap {
    margin-left: 10px; }
  .ty-reset-date-range-picker .tc-15-calendar-input > span {
    margin-left: 5px; }

.m-l-0 {
  margin-left: 0 !important; }

.MarketingManagement article.ty-marketing-select {
  display: inline-block;
  position: relative; }
  .MarketingManagement article.ty-marketing-select.disabled a.tc-15-dropdown-link {
    background-color: #ddd; }

.MarketingManagement .extra-search-menu {
  display: inline-block; }

@media screen and (max-width: 1470px) {
  .MarketingManagement .extra-search-menu {
    display: block;
    margin-top: 15px; }
    .MarketingManagement .extra-search-menu .ty-reset-date-range-picker {
      margin-left: 0px; } }

.MarketingManagement > ul.tc-15-tablist {
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #fff; }

.MarketingManagement ul {
  margin-bottom: 0; }

.MarketingManagement .tc-15-table-panel .tc-15-table-fixed-body {
  border: none !important; }

.MarketingManagement .tc-15-table-panel {
  margin-top: 15px;
  border: 1px solid #ddd; }

.MarketingManagement .tc-15-select {
  margin-left: 10px; }

.MarketingManagement .form-ctrl-label {
  margin-right: 0px !important; }

.MarketingManagement .btn-download {
  position: absolute;
  right: 5px;
  bottom: 16px; }

.MarketingManagement .tc-15-dropdown.tc-15-dropdown-btn-style.product-select a.tc-15-dropdown-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px; }

.MarketingManagement .market-section > div, .MarketingManagement .market-section > button, .MarketingManagement .market-section > article {
  margin-bottom: 10px; }

.MarketingManagement .tc-15-rich-radio, .MarketingManagement .tc-15-calendar-select.tc-15-calendar-select-wrap.tc-15-calendar2-hook.ty-reset-date-range-picker {
  margin-bottom: 0 !important;
  height: 30px; }
