.warn-record {
  margin-bottom: 20px; }
  .warn-record .section-chart {
    font-size: 14px;
    color: #000;
    background-color: #fff;
    padding: 14px 23px 14px 23px;
    margin: 14px 0 20px 0;
    display: flex; }
    .warn-record .section-chart .data {
      width: 280px;
      min-width: 280px;
      border-right: 1px solid #DADADA;
      margin-right: 50px; }
      .warn-record .section-chart .data .time {
        font-size: 12px;
        color: #333;
        padding: 20px 0 13px 0; }
      .warn-record .section-chart .data .num {
        font-size: 32px; }
        .warn-record .section-chart .data .num span {
          font-size: 14px; }
      .warn-record .section-chart .data .num2 {
        margin-bottom: 10px;
        font-size: 12px; }
        .warn-record .section-chart .data .num2 span {
          color: #000; }
    .warn-record .section-chart .chart {
      flex: 1; }
  .warn-record .section-table {
    background-color: #fff; }
  .warn-record .page-padding20 {
    padding-top: 10px; }
    .warn-record .page-padding20 .qiuqiu {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      display: inline-block;
      background: red;
      margin-right: 5px; }
    .warn-record .page-padding20 .qiuqiuSpan {
      font-size: 12px;
      color: #888;
      margin-right: 5px; }
