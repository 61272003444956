.OperationLinkForm .noEditFromLinkHistoryView .form-input .tc-mls-hd-op a {
  display: none; }

.OperationLinkForm .noEditFromLinkHistoryView .form-input .tc-mls-li-op {
  display: none !important; }

.OperationLinkForm .button-after > .form-input {
  position: relative; }
  .OperationLinkForm .button-after > .form-input .tc-15-select {
    width: 330px; }
  .OperationLinkForm .button-after > .form-input .button-after-text2 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 340px;
    min-width: 30px;
    color: #006eff; }
    .OperationLinkForm .button-after > .form-input .button-after-text2 span {
      color: #000000; }
  .OperationLinkForm .button-after > .form-input .button-after-text2:nth-of-type(2) {
    left: 375px; }
  .OperationLinkForm .button-after > .form-input .is-error ~ .button-after-text2 {
    left: 365px; }

.OperationLinkForm .section {
  display: table-row; }
  .OperationLinkForm .section .form-label {
    vertical-align: top; }
  .OperationLinkForm .section li {
    float: left; }
    .OperationLinkForm .section li:first-of-type .form-label {
      display: none; }
  .OperationLinkForm .section .show-data-validation {
    border: 1px solid #ddd;
    border-bottom: 0;
    line-height: 31px;
    padding: 3px 20px;
    width: 100%;
    height: 40px; }
    .OperationLinkForm .section .show-data-validation .form-input,
    .OperationLinkForm .section .show-data-validation .form-label {
      padding: 0 !important;
      vertical-align: top !important; }
    .OperationLinkForm .section .show-data-validation .tc-15-dropdown-menu li {
      float: none; }
  .OperationLinkForm .section .checkbox-inline .form-unit,
  .OperationLinkForm .section .checkbox-inline1 .form-unit {
    float: left; }
    .OperationLinkForm .section .checkbox-inline .form-unit .form-ctrl-label,
    .OperationLinkForm .section .checkbox-inline1 .form-unit .form-ctrl-label {
      margin-right: 3px; }
    .OperationLinkForm .section .checkbox-inline .form-unit .tc-15-rich-radio .form-ctrl-label,
    .OperationLinkForm .section .checkbox-inline1 .form-unit .tc-15-rich-radio .form-ctrl-label {
      margin-right: 20px; }
  .OperationLinkForm .section .checkbox-inline .form-input,
  .OperationLinkForm .section .checkbox-inline1 .form-input {
    line-height: 30px;
    color: #888; }
  .OperationLinkForm .section .checkbox-inline1 {
    width: 180px; }
    .OperationLinkForm .section .checkbox-inline1 .form-label {
      display: table-cell !important; }
    .OperationLinkForm .section .checkbox-inline1 .form-input {
      width: 50px; }
    .OperationLinkForm .section .checkbox-inline1 .tc-15-filtrateu,
    .OperationLinkForm .section .checkbox-inline1 .dropdown-list-wrap {
      position: absolute;
      z-index: 9; }
    .OperationLinkForm .section .checkbox-inline1 .tc-15-dropdown,
    .OperationLinkForm .section .checkbox-inline1 .tc-15-input-text,
    .OperationLinkForm .section .checkbox-inline1 .tc-15-simulate-select,
    .OperationLinkForm .section .checkbox-inline1 .tc-15-dropdown-link,
    .OperationLinkForm .section .checkbox-inline1 .tc-15-select {
      width: 50px; }
    .OperationLinkForm .section .checkbox-inline1 .tc-15-optgroup {
      float: none !important; }
  .OperationLinkForm .section.condition li {
    margin-right: 20px; }
  .OperationLinkForm .section.condition .tc-15-dropdown-link,
  .OperationLinkForm .section.condition input.tc-15-input-text,
  .OperationLinkForm .section.condition select.tc-15-select {
    width: 126px;
    min-width: 126px; }
  .OperationLinkForm .section .tc-15-rich-radio {
    vertical-align: top; }
  .OperationLinkForm .section .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #fff; }
    .OperationLinkForm .section .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .OperationLinkForm .section .tc-15-dropdown,
  .OperationLinkForm .section .tc-15-input-text,
  .OperationLinkForm .section .tc-15-simulate-select,
  .OperationLinkForm .section .tc-15-select {
    width: 330px; }
  .OperationLinkForm .section .tc-15-dropdown-link {
    max-width: 330px; }
  .OperationLinkForm .section .tc-15-optgroup {
    float: none !important; }

.OperationLinkForm .up-cascade .checkbox-inline1 {
  width: 170px; }

.OperationLinkForm .up-cascade .tc-15-dropdown,
.OperationLinkForm .up-cascade .tc-15-dropdown-menu {
  min-width: 85px; }

.OperationLinkForm .up-cascade .ml-10 {
  margin-left: -10px !important; }

.OperationLinkForm .up-cascade .ml-20 {
  margin-left: -30px !important; }

.OperationLinkForm .cascade-flag {
  margin-left: 20px;
  margin-right: 0px; }

.OperationLinkForm .fields .tc-multi-level-selector {
  display: inline-block; }

.OperationLinkForm .fields .icon-valid-flag {
  vertical-align: top; }

.OperationLinkForm .btnBar {
  border-top: 1px solid #ddd;
  padding-top: 15px; }
  .OperationLinkForm .btnBar .weak-button {
    margin-left: 10px; }

.OperationLinkForm .conditionForm {
  width: 630px;
  border: 1px solid #ddd;
  padding: 0 20px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px; }
  .OperationLinkForm .conditionForm .form-list {
    background: none !important; }
  .OperationLinkForm .conditionForm .nosection .form-label {
    display: table-cell !important; }
  .OperationLinkForm .conditionForm .box2 {
    margin-left: 0 !important;
    width: 450px !important;
    border: 1px solid #ddd !important;
    padding: 5px !important;
    margin-bottom: 15px !important;
    line-height: 33px !important;
    color: #262626 !important;
    float: left !important;
    min-height: 45px !important; }
    .OperationLinkForm .conditionForm .box2 .box3 {
      padding: 5px 10px;
      border: 1px solid #ddd;
      display: inline-block;
      height: 26px;
      line-height: 14px; }
  .OperationLinkForm .conditionForm .ml10 {
    margin-left: 10px; }
  .OperationLinkForm .conditionForm .cblue {
    color: #006eff !important; }
  .OperationLinkForm .conditionForm .cursorp {
    cursor: pointer; }
  .OperationLinkForm .conditionForm .btn-group {
    margin-bottom: 10px; }

.OperationLinkForm .step2-view {
  min-height: 600px;
  display: table;
  height: 100%; }
  .OperationLinkForm .step2-view .left-view {
    padding-top: 10px;
    width: 160px;
    min-width: 160px;
    height: 100%;
    min-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 2px solid #e5e5e5;
    vertical-align: top; }
    .OperationLinkForm .step2-view .left-view .list {
      width: 158px;
      margin-bottom: 14px;
      cursor: pointer; }
      .OperationLinkForm .step2-view .left-view .list .list-icon {
        display: inline-block;
        width: 4px;
        height: 23px;
        vertical-align: middle; }
      .OperationLinkForm .step2-view .left-view .list .list-icon2 {
        overflow: hidden;
        margin-left: 4px;
        width: 18px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #aaa;
        border-radius: 2px;
        color: #aaa;
        line-height: 16px;
        display: inline-block; }
      .OperationLinkForm .step2-view .left-view .list .list-title {
        margin-left: 6px;
        line-height: 23px;
        vertical-align: middle;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px !important;
        width: calc(100% - 34px); }
      .OperationLinkForm .step2-view .left-view .list .list-close {
        color: #bbb; }
      .OperationLinkForm .step2-view .left-view .list .icon-select {
        background-color: #006eff; }
      .OperationLinkForm .step2-view .left-view .list .title-select {
        font-weight: bold;
        color: #006eff; }
  .OperationLinkForm .step2-view .right-view {
    position: relative;
    padding-top: 10px;
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: top; }
    .OperationLinkForm .step2-view .right-view .title {
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e5e5; }
      .OperationLinkForm .step2-view .right-view .title > p {
        line-height: 23px;
        padding-left: 25px;
        margin-bottom: 5px; }
      .OperationLinkForm .step2-view .right-view .title .box {
        margin-left: 20px;
        width: calc(100% - 40px);
        border: 1px solid #ddd;
        padding: 5px 20px;
        line-height: 23px;
        color: #262626; }
      .OperationLinkForm .step2-view .right-view .title .box2 {
        margin-left: 20px;
        width: calc(100% - 40px);
        border: 1px solid #ddd;
        padding: 5px 20px;
        margin-bottom: 15px;
        line-height: 33px;
        color: #262626; }
        .OperationLinkForm .step2-view .right-view .title .box2 .box3 {
          padding: 5px 10px;
          border: 1px solid #ddd;
          display: inline-block;
          height: 26px;
          line-height: 14px; }
      .OperationLinkForm .step2-view .right-view .title .box2-view {
        margin-left: 20px;
        width: calc(100% - 40px);
        border: 1px solid #ddd;
        padding: 10px 20px;
        margin-bottom: 15px;
        line-height: 23px;
        color: #262626;
        background: #f8f8f8;
        padding-bottom: 0;
        border-radius: 2px; }
    .OperationLinkForm .step2-view .right-view .normal {
      padding-top: 15px;
      border-bottom: none; }
      .OperationLinkForm .step2-view .right-view .normal .box {
        padding: 0; }
        .OperationLinkForm .step2-view .right-view .normal .box .box-head,
        .OperationLinkForm .step2-view .right-view .normal .box .box-body {
          border-bottom: 1px solid #ddd; }
          .OperationLinkForm .step2-view .right-view .normal .box .box-head > div,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body > div {
            padding: 0 10px;
            width: 20%;
            vertical-align: middle;
            line-height: 48px;
            display: inline-block;
            min-height: 50px;
            vertical-align: top;
            color: #444;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
          .OperationLinkForm .step2-view .right-view .normal .box .box-head .form-input,
          .OperationLinkForm .step2-view .right-view .normal .box .box-head .form-label,
          .OperationLinkForm .step2-view .right-view .normal .box .box-head .form-output,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body .form-input,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body .form-label,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body .form-output {
            display: block;
            /* vertical-align: top; */
            padding-bottom: 0px; }
          .OperationLinkForm .step2-view .right-view .normal .box .box-head .tc-15-select,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body .tc-15-select {
            min-width: 0; }
          .OperationLinkForm .step2-view .right-view .normal .box .box-head .tc-input-group,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body .tc-input-group {
            width: 100%;
            min-width: 0; }
          .OperationLinkForm .step2-view .right-view .normal .box .box-head .tc-input-group .tc-input-group-addon,
          .OperationLinkForm .step2-view .right-view .normal .box .box-body .tc-input-group .tc-input-group-addon {
            width: 33px; }
        .OperationLinkForm .step2-view .right-view .normal .box .box-head > div {
          height: 40px;
          line-height: 40px;
          color: #888;
          min-height: 40px; }
        .OperationLinkForm .step2-view .right-view .normal .box .box-body:hover {
          background-color: #f7f7f7; }
        .OperationLinkForm .step2-view .right-view .normal .box .wd25 > div {
          width: 25%; }
        .OperationLinkForm .step2-view .right-view .normal .box .box-body > div {
          font-weight: normal; }
        .OperationLinkForm .step2-view .right-view .normal .box .box-body:last-child {
          border-bottom: none; }
        .OperationLinkForm .step2-view .right-view .normal .box .form-input {
          padding-bottom: 0; }
        .OperationLinkForm .step2-view .right-view .normal .box .form-input-help {
          line-height: 0;
          margin-bottom: 12px; }
        .OperationLinkForm .step2-view .right-view .normal .box .tc-input-group {
          line-height: 28px; }
        .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY {
          margin-left: 10px;
          margin-right: 10px; }
          .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY span {
            color: rgba(0, 0, 0, 0.65); }
          .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline {
            display: inline-block;
            float: left;
            margin-top: 20px;
            margin-left: 10px; }
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-label label {
              display: none; }
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-input-help {
              float: left;
              margin-bottom: 0;
              line-height: inherit !important; }
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline.inline3 .form-input-help {
              position: absolute;
              width: 200px; }
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline .icon-valid-flag {
              display: none; }
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline .form-ctrl-label {
              display: block;
              height: 40px;
              line-height: 40px; }
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-15-select,
            .OperationLinkForm .step2-view .right-view .normal .box .boxPROBABILITY .inline .tc-input-group {
              min-width: 0px; }
      .OperationLinkForm .step2-view .right-view .normal .second-title {
        padding-left: 20px;
        font-size: 14px !important;
        margin-top: 20px; }
        .OperationLinkForm .step2-view .right-view .normal .second-title span {
          font-size: 14px !important; }
        .OperationLinkForm .step2-view .right-view .normal .second-title .span2 {
          color: #ee6f1e;
          font-size: 24px !important; }
        .OperationLinkForm .step2-view .right-view .normal .second-title .span3 {
          color: #ee6f1e; }
    .OperationLinkForm .step2-view .right-view .accurate-title {
      padding-top: 10px;
      padding-left: 25px;
      margin-bottom: 15px !important; }
    .OperationLinkForm .step2-view .right-view .accurate {
      padding-bottom: 10px; }
      .OperationLinkForm .step2-view .right-view .accurate .box {
        margin-left: 20px;
        width: calc(100% - 40px);
        border: 1px solid #ddd;
        padding: 5px 20px;
        padding-top: 15px;
        line-height: 23px;
        color: #262626; }
        .OperationLinkForm .step2-view .right-view .accurate .box .tc-15-rich-radio {
          line-height: 25px;
          width: calc(100% - 100px);
          vertical-align: top; }
      .OperationLinkForm .step2-view .right-view .accurate .btn-group {
        margin-bottom: 10px; }
      .OperationLinkForm .step2-view .right-view .accurate .accurate-title2 {
        margin-bottom: 15px;
        padding-top: 10px;
        padding-left: 25px; }
      .OperationLinkForm .step2-view .right-view .accurate .box2 {
        margin-left: 20px;
        width: calc(100% - 40px);
        border: 1px solid #ddd;
        padding: 5px 20px;
        margin-bottom: 15px;
        line-height: 33px;
        color: #262626; }
        .OperationLinkForm .step2-view .right-view .accurate .box2 .box3 {
          padding: 5px 10px;
          border: 1px solid #ddd;
          display: inline-block;
          height: 26px;
          line-height: 14px; }
      .OperationLinkForm .step2-view .right-view .accurate .box2-view {
        margin-left: 20px;
        width: calc(100% - 40px);
        border: 1px solid #ddd;
        padding: 10px 20px;
        margin-bottom: 15px;
        line-height: 23px;
        color: #262626;
        background: #f8f8f8;
        padding-bottom: 0;
        border-radius: 2px; }
    .OperationLinkForm .step2-view .right-view .btn-group {
      margin-bottom: 20px;
      padding-left: 20px; }
  .OperationLinkForm .step2-view .tc-15-switch input {
    width: 35px !important; }

.OperationLinkForm .radio-style {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  min-width: 900px; }
  .OperationLinkForm .radio-style .radio-inline {
    display: flex;
    min-width: 280px; }
  .OperationLinkForm .radio-style .radio-noleft {
    margin-top: -8px;
    margin-right: 10px; }
    .OperationLinkForm .radio-style .radio-noleft .tc-15-dropdown-menu ul li {
      width: 100%; }
    .OperationLinkForm .radio-style .radio-noleft .form-label {
      width: 0; }
    .OperationLinkForm .radio-style .radio-noleft .tc-15-dropdown,
    .OperationLinkForm .radio-style .radio-noleft .tc-15-dropdown-link {
      width: 150px !important; }

.commonModal.chanping,
.commonModal.jingxiaoshang,
.commonModal.mendian,
.commonModal.mazhuangtai {
  min-width: 288px !important;
  width: 288px !important; }
  .commonModal.chanping .tc-15-dropdown-btn-style .tc-15-dropdown-link,
  .commonModal.jingxiaoshang .tc-15-dropdown-btn-style .tc-15-dropdown-link,
  .commonModal.mendian .tc-15-dropdown-btn-style .tc-15-dropdown-link,
  .commonModal.mazhuangtai .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 190px; }
  .commonModal.chanping .inline,
  .commonModal.jingxiaoshang .inline,
  .commonModal.mendian .inline,
  .commonModal.mazhuangtai .inline {
    display: inline-block;
    float: left;
    margin-bottom: 20px; }
    .commonModal.chanping .inline .form-label label,
    .commonModal.jingxiaoshang .inline .form-label label,
    .commonModal.mendian .inline .form-label label,
    .commonModal.mazhuangtai .inline .form-label label {
      display: none; }
    .commonModal.chanping .inline .form-input-help,
    .commonModal.jingxiaoshang .inline .form-input-help,
    .commonModal.mendian .inline .form-input-help,
    .commonModal.mazhuangtai .inline .form-input-help {
      float: left;
      margin-bottom: 0; }
    .commonModal.chanping .inline .icon-valid-flag,
    .commonModal.jingxiaoshang .inline .icon-valid-flag,
    .commonModal.mendian .inline .icon-valid-flag,
    .commonModal.mazhuangtai .inline .icon-valid-flag {
      display: none; }
    .commonModal.chanping .inline .form-ctrl-label,
    .commonModal.jingxiaoshang .inline .form-ctrl-label,
    .commonModal.mendian .inline .form-ctrl-label,
    .commonModal.mazhuangtai .inline .form-ctrl-label {
      display: block;
      height: 40px;
      line-height: 40px; }
    .commonModal.chanping .inline .tc-15-select,
    .commonModal.chanping .inline .tc-input-group,
    .commonModal.jingxiaoshang .inline .tc-15-select,
    .commonModal.jingxiaoshang .inline .tc-input-group,
    .commonModal.mendian .inline .tc-15-select,
    .commonModal.mendian .inline .tc-input-group,
    .commonModal.mazhuangtai .inline .tc-15-select,
    .commonModal.mazhuangtai .inline .tc-input-group {
      min-width: 0px; }

.commonModal.chanping,
.commonModal.jingxiaoshang,
.commonModal.mendian {
  min-width: 360px !important;
  width: 360px !important; }
  .commonModal.chanping .button-after > .form-input,
  .commonModal.jingxiaoshang .button-after > .form-input,
  .commonModal.mendian .button-after > .form-input {
    position: relative; }
    .commonModal.chanping .button-after > .form-input .button-after-text2,
    .commonModal.jingxiaoshang .button-after > .form-input .button-after-text2,
    .commonModal.mendian .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 200px;
      min-width: 30px;
      color: #006eff; }
      .commonModal.chanping .button-after > .form-input .button-after-text2 span,
      .commonModal.jingxiaoshang .button-after > .form-input .button-after-text2 span,
      .commonModal.mendian .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .commonModal.chanping .button-after > .form-input .button-after-text3,
    .commonModal.jingxiaoshang .button-after > .form-input .button-after-text3,
    .commonModal.mendian .button-after > .form-input .button-after-text3 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 230px;
      min-width: 60px;
      color: #006eff; }
      .commonModal.chanping .button-after > .form-input .button-after-text3 span,
      .commonModal.jingxiaoshang .button-after > .form-input .button-after-text3 span,
      .commonModal.mendian .button-after > .form-input .button-after-text3 span {
        color: #000000; }
    .commonModal.chanping .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal.jingxiaoshang .button-after > .form-input .is-error ~ .button-after-text2,
    .commonModal.mendian .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 225px; }
    .commonModal.chanping .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal.jingxiaoshang .button-after > .form-input .is-error ~ .button-after-text3,
    .commonModal.mendian .button-after > .form-input .is-error ~ .button-after-text3 {
      left: 255px; }
    .commonModal.chanping .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal.jingxiaoshang .button-after > .form-input .is-error ~ .rc-upload .button-after-text2,
    .commonModal.mendian .button-after > .form-input .is-error ~ .rc-upload .button-after-text2 {
      left: 225px; }

.UploadFile .box {
  margin-left: 20px;
  width: calc(100% - 20px);
  border: 1px solid #ddd;
  padding: 5px 20px;
  line-height: 23px;
  color: #262626; }

.dict-tabbar {
  height: 31px; }
  .dict-tabbar .tc-15-tablist {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0 20px; }
