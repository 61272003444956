.BanquetPolicy {
  background: #fff;
  padding: 5px 10px 0; }

.special-activity .box2 {
  margin-left: 20px;
  width: calc(100% - 20px);
  border: 1px solid #ddd;
  padding: 5px 20px;
  margin-bottom: 15px;
  line-height: 33px;
  color: #262626; }
  .special-activity .box2 .box3 {
    padding: 5px 10px;
    border: 1px solid #ddd;
    display: inline-block;
    height: 26px;
    line-height: 14px; }

.special-activity .step0 {
  height: 100%;
  font-size: 12px; }
  .special-activity .step0 .outside {
    background: white;
    height: 100%;
    margin-top: 15px;
    padding: 20px; }
    .special-activity .step0 .outside .inside {
      background: #f8f8f8;
      padding-left: 33px;
      padding-top: 19px;
      padding-bottom: 19px; }
      .special-activity .step0 .outside .inside .prize-image {
        width: 80px;
        height: 80px;
        position: relative;
        display: inline-block;
        background: #f2f2f2;
        border: 1px solid #d9d9d9;
        text-align: center;
        vertical-align: middle; }
        .special-activity .step0 .outside .inside .prize-image img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .special-activity .step0 .outside .inside .prize-image .n-loading-icon {
          position: relative;
          margin-top: 30px;
          z-index: 10; }
        .special-activity .step0 .outside .inside .prize-image span {
          width: 156px;
          color: #666666;
          display: inline-block;
          position: absolute;
          left: 90px;
          top: -6px; }
      .special-activity .step0 .outside .inside .row {
        margin-top: 19px;
        display: flex; }
        .special-activity .step0 .outside .inside .row > span:first-child {
          margin-right: 20px; }
        .special-activity .step0 .outside .inside .row .widthSpan {
          width: calc(100% - 88px);
          word-break: break-all; }
      .special-activity .step0 .outside .inside > div {
        display: flex; }
        .special-activity .step0 .outside .inside > div:not(:last-child) {
          margin-bottom: 20px; }
        .special-activity .step0 .outside .inside > div > div:first-child {
          margin-right: 20px;
          flex-shrink: 0; }
  .special-activity .step0 .rightSpan50 {
    margin-left: 10px; }

.special-activity .step0Edit {
  font-size: 12px;
  background: white;
  margin: 20px 0;
  padding: 20px; }
  .special-activity .step0Edit .tc-15-switch input {
    width: 35px !important; }
  .special-activity .step0Edit .button-after > .form-input {
    position: relative; }
    .special-activity .step0Edit .button-after > .form-input .tc-15-select {
      width: 330px; }
    .special-activity .step0Edit .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .special-activity .step0Edit .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .special-activity .step0Edit .button-after > .form-input .button-after-text2:nth-of-type(2) {
      left: 375px; }
    .special-activity .step0Edit .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
  .special-activity .step0Edit a:focus {
    text-decoration: none !important; }
  .special-activity .step0Edit .tc-15-dropdown,
  .special-activity .step0Edit .tc-15-input-text,
  .special-activity .step0Edit .tc-15-simulate-select,
  .special-activity .step0Edit .tc-15-select {
    width: 330px; }
  .special-activity .step0Edit .tc-15-dropdown-link {
    max-width: 330px; }
  .special-activity .step0Edit .form-input {
    position: relative; }
    .special-activity .step0Edit .form-input .btnCreate {
      right: -64px;
      position: absolute;
      top: 7px; }
  .special-activity .step0Edit .btnBar {
    border-top: 1px solid #ddd;
    padding-top: 15px; }
    .special-activity .step0Edit .btnBar .weak-button {
      margin-left: 10px; }
  .special-activity .step0Edit .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    text-align: center;
    vertical-align: middle; }
    .special-activity .step0Edit .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .special-activity .step0Edit .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .special-activity .step0Edit .prize-image span {
      width: 150px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .special-activity .step0Edit .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .special-activity .step0Edit .checked-array-box {
    width: 900px;
    min-width: 900px;
    margin-bottom: 20px; }
    .special-activity .step0Edit .checked-array-box .form-list {
      background: #fff;
      margin: 0;
      padding: 0;
      display: flex; }
      .special-activity .step0Edit .checked-array-box .form-list .form-label label {
        padding-right: 0; }
      .special-activity .step0Edit .checked-array-box .form-list .tc-time-picker {
        margin-left: 0; }
      .special-activity .step0Edit .checked-array-box .form-list .tc-15-input-text {
        width: 165px !important; }
    .special-activity .step0Edit .checked-array-box .add-btn {
      width: 30%;
      color: #006eff;
      cursor: pointer; }
    .special-activity .step0Edit .checked-array-box .set-color {
      color: #006eff;
      cursor: pointer;
      line-height: 30px;
      margin-left: 10px; }

.special-activity .dialog {
  font-size: 12px;
  width: 750px; }
  .special-activity .dialog .left {
    height: 300px;
    border: 1px solid lightgray; }
    .special-activity .dialog .left .consumptionItems {
      padding: 0 20px 20px;
      height: 270px;
      overflow-y: auto;
      overflow-x: auto; }
      .special-activity .dialog .left .consumptionItems .consumptionItem {
        margin-top: 20px; }
        .special-activity .dialog .left .consumptionItems .consumptionItem .item-name {
          display: inline-block;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
        .special-activity .dialog .left .consumptionItems .consumptionItem .item-btns {
          float: right; }
          .special-activity .dialog .left .consumptionItems .consumptionItem .item-btns .btn-del {
            margin-left: 10px; }
    .special-activity .dialog .left .weak-button {
      width: 100%;
      border-top: 1px solid #ddd;
      color: #000;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent; }
  .special-activity .dialog .right {
    padding: 20px;
    height: 300px;
    border: 1px solid lightgray;
    overflow-y: auto;
    overflow-x: auto; }
  .special-activity .dialog .right-make-sure {
    position: absolute;
    bottom: 20px;
    right: 20px; }
  .special-activity .dialog .bottom-bar {
    text-align: center; }
    .special-activity .dialog .bottom-bar .btn-ok {
      margin-top: 20px; }

.special-activity .consumptiom-form .is-error .icon-valid-flag {
  display: none; }

.special-activity .consumptiom-form .tc-15-input-text,
.special-activity .consumptiom-form .tc-15-select {
  width: 191px; }

.special-activity .consumptiom-form .details {
  display: flex; }
  .special-activity .consumptiom-form .details .tc-15-select {
    width: 90px;
    min-width: 90px; }
  .special-activity .consumptiom-form .details .detailType {
    margin-right: 10px; }
  .special-activity .consumptiom-form .details .tc-15-input-text {
    width: 50px;
    min-width: 50px; }
  .special-activity .consumptiom-form .details .btn-del {
    float: right; }

.special-activity .stepName .form-label {
  padding-left: 15px; }

.special-activity .consumptiom-form .details .btn-del {
  padding-top: 6px;
  padding-left: 10px; }

.special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-btn-box {
  display: flex;
  justify-content: space-between; }

.special-activity .BanquetPolicyEdit .title {
  padding-bottom: 100px; }
  .special-activity .BanquetPolicyEdit .title .box {
    border: 1px solid #ddd;
    padding: 10px 10px 15px;
    margin-bottom: 20px; }
    .special-activity .BanquetPolicyEdit .title .box .BanquetPolicyEdit-rule-set {
      display: flex;
      align-items: center;
      height: 30px;
      line-height: 30px;
      margin-top: 10px; }

.special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule {
  border: 1px solid #ddd;
  padding: 10px 20px;
  margin-bottom: 20px; }
  .special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule .box2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 !important;
    border: none !important;
    padding: 0 !important; }
    .special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule .box2 .BanquetPolicyEdit-rule-symbol {
      margin-left: 10px; }
      .special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule .box2 .BanquetPolicyEdit-rule-symbol .form-input {
        padding-bottom: 0; }
  .special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule .BanquetPolicyEdit-rule-box3 {
    padding: 5px 10px;
    border: 1px solid #ddd;
    display: inline-block;
    height: 26px;
    line-height: 14px;
    margin-left: 10px; }
    .special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule .BanquetPolicyEdit-rule-box3 .cursorp {
      cursor: pointer; }
  .special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-box .BanquetPolicyEdit-rule .BanquetPolicyEdit-rule-add {
    cursor: pointer;
    color: #006eff;
    margin-left: 10px; }

.special-activity .BanquetPolicyEdit .BanquetPolicyEdit-rule-Dialog .form-input {
  margin-left: 10px; }

.special-activity .BanquetPolicyEdit-rule-Dialog-select .tc-15-dropdown-link {
  display: block !important; }

.special-activity .BanquetPolicyEdit-step2 .BanquetPolicyEdit-rule-set {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  position: relative; }

.special-activity .BanquetPolicyEdit-step2 .BanquetPolicyEdit-step2-form {
  display: flex;
  position: absolute;
  left: 50px;
  top: 16px; }

.special-activity .BanquetPolicyEdit-step2 .form-label {
  padding: 0 10px;
  padding-bottom: 0 !important; }

.special-activity .BanquetPolicyEdit-step2 .form-input {
  margin-right: 10px; }
  .special-activity .BanquetPolicyEdit-step2 .form-input .form-unit {
    text-align: center; }

.special-activity .BanquetRewardEdit .BanquetRewardEdit-set {
  position: absolute;
  left: 50px;
  top: 0px; }

.special-activity .PublishForm .nolabel {
  display: inline-block;
  padding: 0px 5px;
  vertical-align: text-top; }
  .special-activity .PublishForm .nolabel .form-input {
    padding-bottom: 0; }
  .special-activity .PublishForm .nolabel .icon-valid-flag {
    display: none; }
  .special-activity .PublishForm .nolabel .form-label {
    display: none; }
  .special-activity .PublishForm .nolabel .form-input-help {
    margin-left: 12px; }
