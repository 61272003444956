.CodePackageAudit {
  width: 100%;
  height: 100%; }
  .CodePackageAudit .main-block {
    background: #fff;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e2e2e2; }
    .CodePackageAudit .main-block .search-bar button {
      float: right;
      margin-right: 10px; }

.ViewDialog {
  font-size: 12px; }
  .ViewDialog .item {
    margin-top: 20px; }
