.modify-template .upload {
  display: flex; }
  .modify-template .upload .image {
    width: 80px;
    height: 80px;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    margin-right: 10px; }
  .modify-template .upload .btn-group {
    display: flex;
    align-items: flex-end; }

.modify-template.step2 .navbar-bg-color {
  display: flex;
  position: relative; }

.modify-template.step2 .navbar-color {
  display: flex; }
  .modify-template.step2 .navbar-color .white {
    width: 30px;
    height: 30px;
    background: white;
    border: 1px solid #0075ff;
    margin-right: 10px; }
  .modify-template.step2 .navbar-color .black {
    width: 30px;
    height: 30px;
    background: black;
    margin-right: 10px; }
