.NucleusRecord {
  background: #fff;
  padding: 5px 10px 0;
  margin: 20px 0 0; }

.recordSummary-store {
  display: flex;
  font-weight: bold;
  line-height: 36px;
  font-size: 16px;
  margin-bottom: 20px; }
  .recordSummary-store .recordSummary-store-title-text {
    font-size: 24px;
    color: #F9BD8C;
    margin-left: 20px;
    cursor: pointer; }

.recordSummary-page-padding20 .tc-15-page {
  padding-bottom: 10px !important;
  border: none;
  border-top: 1px solid #ddd; }

.NucleusManage-high-search-box {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 10px 20px;
  background-color: #fff; }
  .NucleusManage-high-search-box .NucleusManage-high-search-row {
    display: flex;
    flex-wrap: wrap; }

.NucleusRecord-prize .tc-15-dropdown-link {
  display: block !important; }
