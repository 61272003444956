.ty-amount-number-input {
  position: relative;
  line-height: 28px;
  display: inline-block;
  width: 110px; }
  .ty-amount-number-input input {
    border: 1px solid #ddd;
    line-height: inherit;
    padding-right: 30px;
    box-shadow: none;
    width: calc(100% - 37px);
    padding-left: 5px; }
    .ty-amount-number-input input::placeholder {
      color: #ddd; }
    .ty-amount-number-input input:focus {
      border-color: #1890ff; }
  .ty-amount-number-input .unit {
    background: #F2F2F2;
    border-left: 1px solid #DDDDDD;
    position: absolute;
    right: 0px;
    top: 0;
    margin: 1px;
    bottom: 0;
    width: 30px;
    line-height: inherit;
    height: inherit;
    text-align: center;
    display: inline-block; }
