.blessing-modal .blessing-form,
.blessing-modal .blessing-demo {
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  vertical-align: middle; }
  .blessing-modal .blessing-form h4,
  .blessing-modal .blessing-demo h4 {
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 15px; }

.blessing-modal .blessing-form {
  padding: 20px;
  position: relative;
  background: #FFFFFF; }
  .blessing-modal .blessing-form .form-list {
    background-color: #FFFFFF; }
    .blessing-modal .blessing-form .form-list .form-label label {
      width: 80px !important; }
  .blessing-modal .blessing-form .form-input {
    color: #888888; }
    .blessing-modal .blessing-form .form-input input {
      width: 330px; }
  .blessing-modal .blessing-form .button-box {
    width: 100%;
    text-align: center;
    margin-top: 15px; }
    .blessing-modal .blessing-form .button-box button {
      margin: 5px; }

.blessing-modal .blessing-demo {
  width: 560px;
  height: 400px;
  padding: 20px;
  margin-left: 20px; }
  .blessing-modal .blessing-demo img {
    width: 520px;
    height: 308px; }
