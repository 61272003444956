.ModifyMaterial .content {
  display: flex;
  flex-wrap: wrap; }

.ModifyMaterial .item-group {
  margin-right: 20px;
  margin-top: 20px; }
  .ModifyMaterial .item-group .item {
    width: 114px;
    height: 114px;
    position: relative;
    border: 2px solid #cccccc;
    margin-bottom: 6px; }
    .ModifyMaterial .item-group .item img {
      width: 100%;
      height: 100%; }
    .ModifyMaterial .item-group .item .check {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border-radius: 50%;
      position: absolute;
      bottom: 11px;
      right: 11px; }
    .ModifyMaterial .item-group .item .mask {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3); }
    .ModifyMaterial .item-group .item.active {
      border-color: transparent; }
      .ModifyMaterial .item-group .item.active .check {
        background-color: #01cbab; }
        .ModifyMaterial .item-group .item.active .check:after {
          content: '';
          display: block;
          width: 12px;
          height: 7px;
          border: 2px solid #fff;
          border-width: 0 0 2px 2px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -5px;
          margin-left: -6px;
          transform: rotate(-45deg); }
      .ModifyMaterial .item-group .item.active .mask {
        display: block; }

.ModifyMaterial .btn-opration {
  color: #006eff;
  cursor: pointer;
  float: right; }
