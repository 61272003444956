.order-management .tc-15-tablist {
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #fff; }

.order-management .search-bar {
  height: auto;
  display: flex;
  justify-content: flex-end; }
  .order-management .search-bar .export {
    flex: 1;
    min-width: 220px; }
    .order-management .search-bar .export .tc-15-btn {
      width: 90px; }
  .order-management .search-bar .search {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; }
    .order-management .search-bar .search > .tc-15-input-text,
    .order-management .search-bar .search > .tc-15-select {
      width: 180px;
      margin-left: 10px;
      margin-top: 20px; }
    .order-management .search-bar .search > .tc-15-dropdown {
      min-width: 180px;
      height: 30px;
      margin-left: 10px;
      margin-top: 20px; }

.order-management .tc-15-dropdown-link {
  width: 180px; }

.OrderExport {
  width: 470px; }
  .OrderExport .hint {
    font-size: 12px;
    color: #003B80;
    padding: 12px 0 12px 14px;
    background: #E5F0FF;
    border: 1px solid #97C7FF;
    margin-bottom: 20px; }
  .OrderExport .form-list {
    display: block; }
    .OrderExport .form-list > li {
      display: block; }
    .OrderExport .form-list .pure-text-row .form-label {
      padding-bottom: 10px; }
    .OrderExport .form-list .time-section {
      display: flex; }
      .OrderExport .form-list .time-section > div {
        padding: 0 10px;
        line-height: 30px; }
      .OrderExport .form-list .time-section .form-label label {
        padding-right: 0; }
    .OrderExport .form-list .checkbox-inline .form-label {
      display: none; }
    .OrderExport .form-list .checkbox-inline .form-unit {
      float: left; }
      .OrderExport .form-list .checkbox-inline .form-unit .form-ctrl-label {
        margin-right: 5px; }
    .OrderExport .form-list .tc-15-simulate-select {
      width: 176px; }

.OrderDetail .detail {
  overflow: auto;
  margin-bottom: 20px; }
  .OrderDetail .detail .form-list:first-child {
    float: left;
    margin-right: 60px; }

.OrderDetail .check .form-input {
  word-break: break-all;
  word-wrap: break-word; }

.OrderRemark {
  width: 424px; }

.OrderDetailOperate {
  width: 420px; }
