.commonModal.CODE_INFORMATION {
  width: 425px !important;
  min-width: 425px !important; }
  .commonModal.CODE_INFORMATION .blue {
    color: #006eff;
    margin-left: 10px;
    cursor: pointer;
    line-height: 30px; }
  .commonModal.CODE_INFORMATION .access-modal-freedom {
    margin-top: 20px; }
    .commonModal.CODE_INFORMATION .access-modal-freedom .form-list {
      padding-left: 20px;
      display: flex; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .tc-input-group {
        min-width: 80px; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .tc-15-calendar-select.tc-15-calendar-single {
        width: 290px;
        margin-left: -20px; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .datavalue .form-label,
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .enum-input .form-label {
        display: none; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .numbervalue .form-label {
        display: none; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .numbervalue .icon-valid-flag {
        display: none; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .numbervalue1 .icon-valid-flag {
        display: none; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .numbervalue1 .form-label label {
        padding: 0 14px; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value1 {
        padding-left: 10px; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value .icon-valid-flag,
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value1 .icon-valid-flag {
        display: none; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value .tc-15-calendar-select.tc-15-calendar-single,
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value1 .tc-15-calendar-select.tc-15-calendar-single {
        width: 140px;
        margin-left: -20px; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value .tc-15-calendar2-hook .tc-15-simulate-select,
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value1 .tc-15-calendar2-hook .tc-15-simulate-select {
        white-space: nowrap;
        padding: 0 5px; }
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value .tc-15-simulate-select:after,
      .commonModal.CODE_INFORMATION .access-modal-freedom .form-list .value1 .tc-15-simulate-select:after {
        right: 0; }

.commonModal.ACCESS_BANQUET_HOLDING_TIME {
  width: 400px !important;
  min-width: 400px !important; }
  .commonModal.ACCESS_BANQUET_HOLDING_TIME .blue {
    color: #006eff;
    margin-left: 10px;
    cursor: pointer;
    line-height: 30px; }
