.create-contract .tc-15-rich-dialog-bd > .form-list {
  margin-right: 20px; }

.create-contract .tc-15-calendar-select {
  width: 330px; }

.create-contract .form-list .form-list {
  background-color: #fff; }

.create-contract .select-address-box.pure-text-row.padding20 > .form-input {
  padding-bottom: 20px; }

.create-contract .select-address-box.pure-text-row > .form-input, .create-contract .input-longitude-box > .form-input {
  padding-bottom: 0; }

.create-contract .select-address-box.pure-text-row .form-list, .create-contract .input-longitude-box .form-list {
  padding: 0;
  margin: 0; }
  .create-contract .select-address-box.pure-text-row .form-list li, .create-contract .input-longitude-box .form-list li {
    float: left; }
    .create-contract .select-address-box.pure-text-row .form-list li .form-label, .create-contract .input-longitude-box .form-list li .form-label {
      display: none; }
    .create-contract .select-address-box.pure-text-row .form-list li select, .create-contract .input-longitude-box .form-list li select {
      width: 105px;
      min-width: 105px; }
    .create-contract .select-address-box.pure-text-row .form-list li input, .create-contract .input-longitude-box .form-list li input {
      width: 155px; }
  .create-contract .select-address-box.pure-text-row .form-list li .icon-valid-flag, .create-contract .input-longitude-box .form-list li .icon-valid-flag {
    display: none; }
  .create-contract .select-address-box.pure-text-row .form-list li:last-child .is-error .icon-valid-flag, .create-contract .input-longitude-box .form-list li:last-child .is-error .icon-valid-flag {
    display: inline-block; }

.create-contract .select-address-box .form-list li:first-child, .create-contract .select-address-box .form-list li:nth-child(2) {
  margin-right: 8px; }

.create-contract .input-longitude-box .form-list li:first-child {
  margin-right: 20px; }
