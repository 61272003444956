.manualtag-container {
  min-width: 680px;
  margin: 10px auto 20px;
  padding: 20px;
  background: #fff;
  border: 1px solid #e2e2e2; }
  .manualtag-container > div {
    margin: 0 20px 20px 0; }

.item-content > div.content {
  margin-bottom: 20px; }
  .item-content > div.content:last-child {
    margin-bottom: 0; }

.item-content .cont-box {
  font-size: 12px;
  line-height: 25px; }

.footer-cont span {
  font-size: 12px; }

.footer-cont span.footer-btn {
  color: #006EFF;
  cursor: pointer; }

.cont-box {
  display: flex;
  justify-content: space-between; }

.no-data {
  background: #fff;
  border: 1px solid #ddd; }
