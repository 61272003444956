.min-program .develop-hint {
  font-size: 12px;
  color: #003B80;
  padding: 12px 0 12px 14px;
  background: #E5F0FF;
  border: 1px solid #97C7FF;
  margin-top: 20px; }

.min-program .search-bar a {
  color: #fff; }

.min-program .container {
  margin-top: 0; }

.min-program .operate {
  font-size: 12px;
  padding-right: 8px; }

.trial-version {
  width: 466px; }
  .trial-version .v-hint {
    font-size: 12px;
    color: #B17616;
    width: 410px;
    line-height: 40px;
    background: #FCF4E4;
    border: 1px solid #F4D28F;
    padding-left: 11px; }
  .trial-version .file-name {
    font-size: 12px;
    color: #333;
    padding-left: 10px; }

.min-qrcode {
  width: 465px; }
  .min-qrcode .tc-15-rich-dialog-ft {
    text-align: center; }
  .min-qrcode .qrcode {
    text-align: center;
    padding-top: 20px; }
    .min-qrcode .qrcode img {
      width: 147px;
      height: 147px; }

.set-experience {
  width: 300px; }
  .set-experience .tc-15-rich-dialog-ft {
    text-align: center; }

.formal-version {
  width: 495px; }
  .formal-version .v-hint {
    font-size: 12px;
    color: #B17616;
    width: 415px;
    line-height: 40px;
    background: #FCF4E4;
    border: 1px solid #F4D28F;
    padding-left: 11px; }
  .formal-version .block {
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid #ddd; }
    .formal-version .block .cursorp {
      width: 375px;
      text-align: right; }
      .formal-version .block .cursorp div {
        display: table-cell;
        padding-bottom: 10px; }
  .formal-version select.tc-15-select,
  .formal-version input.tc-15-input-text {
    width: 283px; }
  .formal-version .PopEditor {
    font-size: 12px;
    text-align: left;
    position: relative;
    top: -180px; }
    .formal-version .PopEditor > div {
      z-index: 10; }
    .formal-version .PopEditor .tc-15-edit-in-place {
      min-width: 330px;
      border: 1px solid #ddd;
      border-radius: 0;
      background-color: #fff;
      z-index: 1000;
      padding: 20px;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      max-width: inherit;
      right: 40px; }
      .formal-version .PopEditor .tc-15-edit-in-place p {
        margin-bottom: 20px; }
      .formal-version .PopEditor .tc-15-edit-in-place strong {
        font-size: 14px;
        color: #000;
        display: inline-block;
        margin-bottom: 10px; }
