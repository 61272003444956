.label-step2Form-container {
  width: 100%; }
  .label-step2Form-container h4 {
    font-size: 12px;
    color: #000000;
    margin: 10px auto; }
  .label-step2Form-container .label-step2Form-content {
    width: 100%;
    min-height: 100px; }
    .label-step2Form-container .label-step2Form-content .form-list {
      margin-bottom: 10px; }
      .label-step2Form-container .label-step2Form-content .form-list .single .form-input .form-unit {
        vertical-align: top; }
      .label-step2Form-container .label-step2Form-content .form-list .form-label label {
        padding-right: 0;
        font-weight: bold; }
      .label-step2Form-container .label-step2Form-content .form-list .form-input .form-unit {
        display: inline-block;
        vertical-align: middle; }
        .label-step2Form-container .label-step2Form-content .form-list .form-input .form-unit .form-ctrl-label {
          margin-right: 10px; }
        .label-step2Form-container .label-step2Form-content .form-list .form-input .form-unit .icon-valid-flag {
          display: none; }
        .label-step2Form-container .label-step2Form-content .form-list .form-input .form-unit .form-input-help {
          font-weight: normal; }
      .label-step2Form-container .label-step2Form-content .form-list .form-input strong {
        display: inline-block;
        margin-right: 10px; }
      .label-step2Form-container .label-step2Form-content .form-list .form-input .gray {
        color: #9B9B9B; }
      .label-step2Form-container .label-step2Form-content .form-list .dispaly-inlineblock {
        display: inline-block; }
        .label-step2Form-container .label-step2Form-content .form-list .dispaly-inlineblock .form-input,
        .label-step2Form-container .label-step2Form-content .form-list .dispaly-inlineblock .form-label {
          padding-bottom: 0 !important;
          display: inline-block;
          font-weight: bold; }
      .label-step2Form-container .label-step2Form-content .form-list .cycle-check {
        display: inline-block;
        vertical-align: top; }
        .label-step2Form-container .label-step2Form-content .form-list .cycle-check .form-label {
          display: none; }
        .label-step2Form-container .label-step2Form-content .form-list .cycle-check .form-unit {
          margin-right: 10px; }
    .label-step2Form-container .label-step2Form-content .cycle-end {
      display: inline-block;
      margin-left: 20px; }
      .label-step2Form-container .label-step2Form-content .cycle-end .form-label label {
        padding-right: 10px;
        color: #000; }
      .label-step2Form-container .label-step2Form-content .cycle-end .form-input .form-unit {
        margin-right: 10px; }
        .label-step2Form-container .label-step2Form-content .cycle-end .form-input .form-unit .tc-15-input-text {
          width: 117px; }
  .label-step2Form-container .btnBar {
    margin-top: 20px; }
