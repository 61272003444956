.link-draft-container .head-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }
  .link-draft-container .head-wrapper .head-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px; }
    .link-draft-container .head-wrapper .head-right .ml {
      margin-left: 10px; }
    .link-draft-container .head-wrapper .head-right .tc-15-dropdown-btn-style .tc-15-dropdown-link {
      width: inherit; }
