.create-one-level-modal {
  display: flex;
  width: 100%; }
  .create-one-level-modal .label {
    width: 120px; }
  .create-one-level-modal .add {
    color: #006eff;
    font-size: 12px;
    cursor: pointer; }
  .create-one-level-modal .mb20:last-of-type {
    margin-bottom: 10px !important; }
  .create-one-level-modal .mb20 {
    margin-bottom: 20px; }
  .create-one-level-modal .form {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap; }
    .create-one-level-modal .form .tc-15-input-text {
      width: auto;
      flex-grow: 1;
      margin-right: 10px; }
    .create-one-level-modal .form .record {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 30px; }
    .create-one-level-modal .form .del-btn {
      color: #006eff;
      font-size: 12px;
      cursor: pointer; }

.tree-node-del-modal .title {
  font-weight: bold;
  color: #333;
  margin-top: -10px;
  margin-bottom: 10px; }

.tree-node-del-modal .tips {
  color: #666; }

.tree-node-add-modal .flex {
  display: flex; }

.tree-node-add-modal .label {
  width: 120px; }

.tree-node-add-modal .add {
  color: #006eff;
  font-size: 12px;
  cursor: pointer; }

.tree-node-add-modal .mb20:last-of-type {
  margin-bottom: 10px !important; }

.tree-node-add-modal .mb20 {
  margin-bottom: 20px; }

.tree-node-add-modal .left {
  flex-basis: 80px;
  text-align: left;
  flex-shrink: 0; }

.tree-node-add-modal .right {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap; }
  .tree-node-add-modal .right .name {
    width: 100%;
    margin-bottom: 10px;
    color: #333; }
  .tree-node-add-modal .right .form {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap; }
    .tree-node-add-modal .right .form .tc-15-input-text {
      width: auto;
      flex-grow: 1;
      margin-right: 10px; }
    .tree-node-add-modal .right .form .record {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 30px; }
    .tree-node-add-modal .right .form .del-btn {
      color: #006eff;
      font-size: 12px;
      cursor: pointer; }
