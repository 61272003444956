.search-box-custom-coupon .search-box {
  width: 330px;
  display: inline-block; }
  .search-box-custom-coupon .search-box .search-btn {
    border-left: 1px solid #ddd; }
  .search-box-custom-coupon .search-box .btn-rm-txt {
    display: none; }

.search-box-custom-coupon .icon-valid-flag {
  margin-top: -30px; }

.card-info-box {
  font-size: 12px;
  line-height: 30px; }
  .card-info-box span {
    color: #888888;
    width: 80px;
    display: inline-block; }
  .card-info-box strong {
    color: #000000;
    font-weight: normal; }

.export-dialog {
  font-size: 12px; }
  .export-dialog .tc-15-input-text {
    width: 330px; }
  .export-dialog .extra-text {
    color: #666666;
    line-height: 18px;
    margin-top: 5px; }

.coupon-button-after-text2 {
  width: 100px; }
