.productManual .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .productManual .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .productManual .tc-15-table-panel .tc-15-table-box td > div.media-url .media2 {
    max-height: 100%;
    max-width: 100%; }

.productManual .media-url2 div {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-right: 10px;
  height: 60px; }

.productManual .noExpand .icon-arrow-right {
  display: none; }

.productManual-form .prize-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #F2F2F2;
  border: 1px solid #D9D9D9;
  text-align: center;
  vertical-align: middle; }
  .productManual-form .prize-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .productManual-form .prize-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }
  .productManual-form .prize-image span {
    width: 156px;
    color: #666666;
    display: inline-block;
    position: absolute;
    left: 90px;
    top: -6px; }

.productManual-form .prize-image-upload {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom; }

.productManual-form .cblue {
  color: #006eff !important; }

.productManual-form .cursorp {
  cursor: pointer; }
