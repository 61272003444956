.CashbackManagement {
  width: 100%;
  height: 100%; }
  .CashbackManagement .tabList {
    height: 100%; }
  .CashbackManagement .svgClass {
    width: 16px;
    height: 16px;
    color: aqua;
    fill: aliceblue; }
  .CashbackManagement .dataTable {
    height: 450px;
    margin-top: 10px; }
  .CashbackManagement .tc-15-table-panel {
    max-width: 100%; }
  .CashbackManagement .dialog-select {
    width: 100%;
    margin-top: 10px; }
    .CashbackManagement .dialog-select .selectClass {
      width: 100% !important; }
  .CashbackManagement .table-tbody tr td div {
    min-height: 36px !important; }
