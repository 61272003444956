.codeSourceCreateOrEdit {
  margin-top: 20px;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #E2E2E2; }
  .codeSourceCreateOrEdit .title {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 20px; }
  .codeSourceCreateOrEdit .extraText {
    color: #666666;
    line-height: 18px;
    margin-top: 5px; }
  .codeSourceCreateOrEdit .line {
    width: 100%;
    height: 1px;
    background: #DDDDDD; }
  .codeSourceCreateOrEdit .button-block {
    margin-top: 20px; }
