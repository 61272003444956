.wechat-dialog .title {
  color: #333333; }

.wechat-dialog input,
.wechat-dialog select {
  width: 330px;
  color: #000000; }

.wechat-dialog .v-hint {
  font-size: 12px;
  color: #C07400;
  background: #FFF4E3;
  border: 1px solid #FFD18B;
  padding: 12px 15px 12px 20px;
  width: 446px; }

.wechat-dialog.redpack {
  width: 508px; }

.wechat-dialog.developer {
  width: 468px; }

.wechat-dialog.entrance {
  width: 524px; }
