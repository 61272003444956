.tc-15-rich-dialog.ModifyTemplateCode {
  width: 843px; }

.MinProgramTemplate .flow-chart-look {
  width: 60%; }

.ModifyTemplateCode .section {
  height: 282px;
  font-size: 12px;
  color: #333;
  border: 1px solid #dddddd;
  display: flex;
  margin-bottom: 30px; }
  .ModifyTemplateCode .section .title {
    font-weight: 600;
    color: #333333;
    line-height: 38px; }
  .ModifyTemplateCode .section .left {
    display: flex;
    flex-direction: column;
    flex: 0 0 292px;
    padding: 0 17px;
    border-right: 1px solid #DDD; }
    .ModifyTemplateCode .section .left .hint {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1; }
    .ModifyTemplateCode .section .left .block {
      border: 1px solid #d1d2d3;
      padding: 10px 26px 20px 10px; }
      .ModifyTemplateCode .section .left .block ul {
        display: table; }
        .ModifyTemplateCode .section .left .block ul li {
          display: table-row; }
          .ModifyTemplateCode .section .left .block ul li label,
          .ModifyTemplateCode .section .left .block ul li div {
            display: table-cell;
            padding-bottom: 20px; }
          .ModifyTemplateCode .section .left .block ul li label {
            color: #888;
            padding-right: 20px; }
      .ModifyTemplateCode .section .left .block a {
        float: right; }
  .ModifyTemplateCode .section .right .title {
    padding-left: 20px; }
  .ModifyTemplateCode .section .right .tc-15-table-panel {
    border-left: none;
    border-right: none; }
  .ModifyTemplateCode .section .right > a {
    line-height: 38px;
    padding-left: 20px; }
