.whitelist-container .top-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px; }
  .whitelist-container .top-box .left,
  .whitelist-container .top-box .right {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .whitelist-container .top-box .right {
    display: flex;
    justify-content: flex-end; }
  .whitelist-container .top-box .f-item {
    margin-right: 10px;
    margin-top: 10px; }
    .whitelist-container .top-box .f-item .tc-15-calendar {
      right: 0 !important;
      left: inherit; }
    .whitelist-container .top-box .f-item .tc-15-dropdown {
      min-width: 180px; }

.whitelist-container .blue span {
  color: #006eff;
  margin-right: 10px;
  cursor: pointer; }
