.CategoryName .section {
  display: flex; }

.CategoryName .table-block {
  margin-right: 20px;
  min-height: 504px; }

.CategoryName .tree-block {
  min-width: 300px;
  min-height: 504px;
  background: #fff;
  padding: 10px; }
  .CategoryName .tree-block .ant-tree-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
    display: block; }
