.register-form .export .hint {
  font-size: 12px;
  color: #003B80;
  padding: 12px 0 12px 14px;
  background: #E5F0FF;
  border: 1px solid #97C7FF;
  margin-bottom: 20px; }

.register-form .export .form-list {
  display: block; }
  .register-form .export .form-list > li {
    display: block; }
  .register-form .export .form-list .pure-text-row .form-label {
    padding-bottom: 10px; }
  .register-form .export .form-list .time-section {
    display: flex; }
    .register-form .export .form-list .time-section > div {
      padding: 0 10px;
      line-height: 30px; }
    .register-form .export .form-list .time-section .form-label label {
      padding-right: 0; }
  .register-form .export .form-list .checkbox-inline .form-label {
    display: none; }
  .register-form .export .form-list .checkbox-inline .form-unit {
    float: left; }
    .register-form .export .form-list .checkbox-inline .form-unit .form-ctrl-label {
      margin-right: 5px; }
  .register-form .export .form-list .tc-15-simulate-select {
    width: 176px; }

.register-form .export .form-ctrl-label .tc-15-checkbox {
  margin-right: 5px !important; }

.register-form .tc-15-input-text,
.register-form .tc-15-select,
.register-form .tc-15-dropdown {
  width: 330px; }

.register-form .search-input {
  width: 100%; }

.register-form .form-unit ~ .form-input-help {
  line-height: 30px;
  color: #888; }

.register-form .is-error ~ .form-input-help {
  line-height: 0; }

.register-form .register-form-btn {
  margin-top: 20px;
  text-align: center; }
  .register-form .register-form-btn .weak {
    margin-left: 10px; }

.register-form .export-form li {
  margin-bottom: 18px;
  float: left; }
  .register-form .export-form li:nth-child(3) {
    padding: 0 10px;
    line-height: 30px; }
  .register-form .export-form li .tc-time-picker .tc-15-input-text {
    width: 75px !important; }

.register-form .export-title, .register-form .form-ctrl-label {
  font-family: PingFangSC-Semibold;
  font-size: 12px;
  color: #000000; }

.register-form .export-title {
  margin-bottom: 20px; }

.register-form .form-ctrl-label .tc-15-checkbox {
  margin-right: 10px; }
