.code-application .tc-15-rich-radio {
  max-width: 717px; }

.code-application .tc-15-rich-radio .tc-15-btn {
  font-size: 14px;
  color: #474647;
  height: 35px;
  line-height: 35px; }

.code-application .tc-15-rich-radio .tc-15-btn.checked,
.code-application .tc-15-rich-radio .tc-15-btn.checked:hover {
  border-color: #1E7BDB;
  color: #1E7BDB; }

.ModifyService .form-list {
  background-color: #fff; }
  .ModifyService .form-list .form-list {
    background-color: #fff; }

.ModifyService .upload {
  width: 330px;
  display: inline-block;
  font-size: 12px; }
  .ModifyService .upload .block {
    margin-bottom: 5px; }
    .ModifyService .upload .block > div:first-child {
      width: 100%;
      float: left;
      background: #F2F2F2;
      margin-right: 5px; }
    .ModifyService .upload .block .hint .weak {
      color: #006eff;
      background: #fff; }
  .ModifyService .upload img,
  .ModifyService .upload video {
    width: 100%;
    height: 300px; }

.liveEdit {
  margin-top: 20px; }
  .liveEdit .tc-15-btn {
    margin-right: 15px; }

.ModifyServiceMedia {
  width: 520px; }
  .ModifyServiceMedia .tc-15-simulate-select,
  .ModifyServiceMedia .tc-15-dropdown {
    width: 330px; }
  .ModifyServiceMedia .upload {
    width: 330px;
    display: inline-block;
    font-size: 12px;
    color: #666; }
    .ModifyServiceMedia .upload .block {
      margin-bottom: 5px; }
      .ModifyServiceMedia .upload .block > div:first-child {
        width: 80px;
        height: 80px;
        float: left;
        background: #F2F2F2;
        margin-right: 5px; }
    .ModifyServiceMedia .upload img,
    .ModifyServiceMedia .upload video {
      width: 80px;
      height: 80px; }

.live-manage-list .container {
  margin: 0; }

.live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }

.live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url .media-multipe-div {
    width: 100%;
    display: flex; }
    .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url .media-multipe-div .media-multipe {
      height: 100%;
      max-width: 50px;
      margin-right: 10px; }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.media-text-shiyongjiany {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }
