.LeaveType-form {
  display: inline-block; }
  .LeaveType-form .LeaveType-more-label-checkbox > .form-input .form-label label {
    display: none; }
  .LeaveType-form .LeaveType-more-label-checkbox .form-ctrl-label {
    margin-bottom: 20px;
    display: inline-block;
    word-break: keep-all; }
  .LeaveType-form .LeaveType-more-label-checkbox .form-input {
    padding-bottom: 5px; }
  .LeaveType-form .LeaveType-more-label-checkbox .icon-valid-flag {
    display: none; }
  .LeaveType-form .LeaveType-more-label-checkbox .form-input-help {
    margin-top: -15px; }
  .LeaveType-form .cblue {
    color: #006eff !important; }
  .LeaveType-form .cursorp {
    cursor: pointer; }
  .LeaveType-form .boxOut {
    width: 680px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .LeaveType-form .box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    display: inline-block;
    width: 330px;
    padding: 0;
    border: 1px solid #ddd;
    border-bottom: none;
    vertical-align: top; }
    .LeaveType-form .box * {
      font-size: 12px; }
    .LeaveType-form .box .box-hidden {
      height: 235px;
      overflow: auto; }
    .LeaveType-form .box .box-head,
    .LeaveType-form .box .box-body {
      border-bottom: 1px solid #ddd; }
      .LeaveType-form .box .box-head > div,
      .LeaveType-form .box .box-body > div {
        padding: 0 10px;
        width: 70%;
        vertical-align: middle;
        line-height: 48px;
        display: inline-block;
        min-height: 50px;
        vertical-align: top;
        color: #444;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .LeaveType-form .box .box-head > div:last-child,
      .LeaveType-form .box .box-body > div:last-child {
        width: 30%; }
      .LeaveType-form .box .box-head .form-input-help,
      .LeaveType-form .box .box-body .form-input-help {
        line-height: 20px; }
      .LeaveType-form .box .box-head .form-input,
      .LeaveType-form .box .box-body .form-input {
        padding-bottom: 10px; }
    .LeaveType-form .box .box-head > div {
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
    .LeaveType-form .box .box-body:hover {
      background-color: #f7f7f7; }
    .LeaveType-form .box .box-body > div {
      font-weight: normal; }
  .LeaveType-form .LeaveType-more-label > .form-input {
    padding-bottom: 0; }
    .LeaveType-form .LeaveType-more-label > .form-input .form-label label {
      padding-right: 0; }
  .LeaveType-form .LeaveType-more-label .tc-15-rich-radio {
    margin-bottom: 0; }
  .LeaveType-form .LeaveType-more-label .tc-15-dropdown-link {
    max-width: 180px !important; }
  .LeaveType-form .LeaveType-more-label .tc-input-group {
    min-width: 0 !important; }
  .LeaveType-form .edit-box {
    width: 330px;
    min-height: 150px;
    border: 1px solid #DDDDDD;
    font-size: 12px;
    color: #888888;
    display: inline-block; }
    .LeaveType-form .edit-box .box-header {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #DDDDDD; }
      .LeaveType-form .edit-box .box-header .header-text {
        margin-left: 17px;
        font-size: 12px;
        color: #000000; }
      .LeaveType-form .edit-box .box-header .on-off {
        height: 100%;
        float: right;
        margin-right: 20px; }
    .LeaveType-form .edit-box .line-block-view {
      margin-top: 10px; }
    .LeaveType-form .edit-box .line-block-padding {
      padding-left: 15px;
      margin-top: 10px; }
    .LeaveType-form .edit-box .itemClass {
      font-size: 12px;
      color: #888888 !important;
      display: inline-table; }
      .LeaveType-form .edit-box .itemClass .form-ctrl-label {
        margin-right: 10px; }
      .LeaveType-form .edit-box .itemClass .form-unit {
        color: #888888 !important; }
      .LeaveType-form .edit-box .itemClass .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
        background: #000;
        color: #FFF; }
        .LeaveType-form .edit-box .itemClass .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
          border-top-color: #000; }
    .LeaveType-form .edit-box .check-box {
      margin-left: 15px; }
    .LeaveType-form .edit-box .input {
      width: 55px;
      height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 2px 2px;
      border: 1px solid #DDDDDD;
      font-size: 12px;
      text-align: center; }
    .LeaveType-form .edit-box .radio {
      font-size: 12px;
      color: #888888;
      display: inline-block; }
    .LeaveType-form .edit-box .form-input {
      padding-bottom: 20px !important; }
    .LeaveType-form .edit-box .icon-valid-flag {
      display: none; }
    .LeaveType-form .edit-box .form-input-help {
      margin-left: 10px; }
  .LeaveType-form .box2 {
    width: 680px;
    border: 1px solid #ddd;
    padding: 5px 20px;
    margin-bottom: 15px;
    line-height: 33px;
    color: #262626; }
    .LeaveType-form .box2 .box3 {
      padding: 5px 10px;
      border: 1px solid #ddd;
      display: inline-block;
      height: 26px;
      line-height: 14px; }
  .LeaveType-form .ml10 {
    margin-left: 10px; }
