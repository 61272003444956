.PrizeDropConfig {
  margin-top: 30px; }

.PrizeDropConfig-rule-config {
  margin-bottom: 20px; }
  .PrizeDropConfig-rule-config > div:first-child {
    margin-bottom: 10px; }
  .PrizeDropConfig-rule-config > div:nth-child(2) {
    padding: 20px;
    border: 1px solid #dddddd; }
    .PrizeDropConfig-rule-config > div:nth-child(2) > div {
      display: flex;
      align-items: center; }
      .PrizeDropConfig-rule-config > div:nth-child(2) > div:first-child {
        margin-bottom: 20px; }
      .PrizeDropConfig-rule-config > div:nth-child(2) > div > div:first-child {
        width: 100px; }

.PrizeDropConfig-drop-prize {
  margin-bottom: 20px; }
  .PrizeDropConfig-drop-prize > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .PrizeDropConfig-drop-prize > div:nth-child(2),
  .PrizeDropConfig-drop-prize > div:nth-child(3) {
    border: 1px solid #dddddd;
    margin-top: 20px; }
    .PrizeDropConfig-drop-prize > div:nth-child(2) > div:first-child,
    .PrizeDropConfig-drop-prize > div:nth-child(3) > div:first-child {
      padding: 12px 20px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      align-items: center; }
      .PrizeDropConfig-drop-prize > div:nth-child(2) > div:first-child:nth-last-child(1),
      .PrizeDropConfig-drop-prize > div:nth-child(3) > div:first-child:nth-last-child(1) {
        border-bottom: none; }
      .PrizeDropConfig-drop-prize > div:nth-child(2) > div:first-child > div:nth-child(1),
      .PrizeDropConfig-drop-prize > div:nth-child(3) > div:first-child > div:nth-child(1) {
        width: 80px; }
      .PrizeDropConfig-drop-prize > div:nth-child(2) > div:first-child > div:nth-child(2),
      .PrizeDropConfig-drop-prize > div:nth-child(3) > div:first-child > div:nth-child(2) {
        margin-right: 20px; }
      .PrizeDropConfig-drop-prize > div:nth-child(2) > div:first-child > div:nth-child(4),
      .PrizeDropConfig-drop-prize > div:nth-child(3) > div:first-child > div:nth-child(4) {
        margin-left: 10px; }
      .PrizeDropConfig-drop-prize > div:nth-child(2) > div:first-child > div:last-child,
      .PrizeDropConfig-drop-prize > div:nth-child(3) > div:first-child > div:last-child {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0; }

.PrizeDropConfig-dealer-prize {
  padding: 20px; }
  .PrizeDropConfig-dealer-prize > div:first-child {
    color: #888888;
    margin-bottom: 10px;
    display: flex;
    align-items: center; }

.SelectPrize {
  border: 1px solid #dddddd; }
  .SelectPrize > div:first-child {
    border-bottom: 1px solid #dddddd;
    padding: 16px 20px;
    display: flex;
    align-items: center; }
    .SelectPrize > div:first-child > div:last-child {
      flex-grow: 1; }
  .SelectPrize > div:nth-child(2) > div {
    display: flex;
    align-items: center;
    padding: 10px 20px; }
    .SelectPrize > div:nth-child(2) > div > div:last-child {
      flex-grow: 1;
      flex-shrink: 0; }
      .SelectPrize > div:nth-child(2) > div > div:last-child > button {
        color: #006eff;
        background-color: transparent; }
  .SelectPrize .add-more-prize {
    padding: 16px 20px; }
    .SelectPrize .add-more-prize > button {
      color: #006eff;
      background-color: transparent; }
  .SelectPrize .prize-name-column {
    width: 300px;
    margin-right: 20px;
    word-break: break-all;
    flex-shrink: 0; }
  .SelectPrize .prize-chance-column {
    width: 220px;
    margin-right: 20px;
    flex-shrink: 0; }
