.promition_checkDialog * {
  font-size: 12px; }

.promition_checkDialog .checkList {
  line-height: 30px;
  margin-bottom: 10px; }

.promition_checkDialog .checkName {
  display: inline-block;
  width: 350px;
  margin-right: 20px;
  word-break: break-all; }

.promition_checkDialog .checkType {
  position: relative;
  display: inline-block; }

.promition_checkDialog .check-icon {
  position: absolute;
  top: -12px; }
