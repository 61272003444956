.codeinfo-searchbar-box {
  overflow: visible !important; }
  .codeinfo-searchbar-box .tc-15-input-text {
    width: 110px !important; }
  .codeinfo-searchbar-box .tc-15-input-text.search-input {
    width: 100% !important; }

.codeinfo-box {
  min-width: 1000px;
  width: 100%;
  border: 1px solid #dddddd;
  background: #fff;
  padding-top: 10px; }
  .codeinfo-box .tc-15-tablist {
    border-bottom: none; }
  .codeinfo-box .tc-15-table-panel {
    border: 1px solid #d1d5de;
    border-bottom: none; }

.table-search-select100 .tc-15-search-words {
  width: 100% !important; }
