.MinProgramServiceMedia .container {
  margin: 0; }

.MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.ModifyServiceMedia {
  width: 520px; }
  .ModifyServiceMedia .tc-15-simulate-select,
  .ModifyServiceMedia .tc-15-dropdown {
    width: 330px; }
  .ModifyServiceMedia .upload {
    width: 330px;
    display: inline-block;
    font-size: 12px;
    color: #666; }
    .ModifyServiceMedia .upload .block {
      margin-bottom: 5px; }
      .ModifyServiceMedia .upload .block > div:first-child {
        width: 80px;
        height: 80px;
        float: left;
        background: #F2F2F2;
        margin-right: 5px; }
      .ModifyServiceMedia .upload .block .hint {
        padding-bottom: 32px; }
    .ModifyServiceMedia .upload img,
    .ModifyServiceMedia .upload video {
      width: 80px;
      height: 80px; }
