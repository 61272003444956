.DownloadForm .form-label {
  display: none; }

.DownloadForm .form-input {
  display: block; }

.DownloadForm .is-error .icon-valid-flag {
  display: none; }

.DownloadForm .dialog-select {
  width: 100%; }
  .DownloadForm .dialog-select .selectClass {
    width: 100% !important; }

.DownloadForm .form-unit .form-input-help {
  text-align: left; }
