.userDefinedTagDetail-container1 * {
  font-size: 12px !important;
  box-sizing: border-box; }

.userDefinedTagDetail-container1 {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px 0;
  padding-top: 17px; }
  .userDefinedTagDetail-container1 .tc-15-step {
    padding-left: 20px;
    padding-bottom: 5px; }
  .userDefinedTagDetail-container1 .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .userDefinedTagDetail-container1 .tc-15-step li.current .tc-15-step-num:before,
  .userDefinedTagDetail-container1 .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .userDefinedTagDetail-container1 .step1form-container .btnBar {
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px; }
  .userDefinedTagDetail-container1 .mb10 {
    margin-bottom: 10px; }
  .userDefinedTagDetail-container1 .mb20 {
    margin-bottom: 20px; }
  .userDefinedTagDetail-container1 .ml10 {
    margin-left: 10px; }
  .userDefinedTagDetail-container1 .ml20 {
    margin-left: 20px; }
  .userDefinedTagDetail-container1 .mr10 {
    margin-right: 10px; }
  .userDefinedTagDetail-container1 .mr20 {
    margin-right: 20px; }
  .userDefinedTagDetail-container1 .flr {
    float: right; }
  .userDefinedTagDetail-container1 .valignm {
    vertical-align: middle; }
  .userDefinedTagDetail-container1 .w100 {
    width: 100% !important; }
  .userDefinedTagDetail-container1 .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .userDefinedTagDetail-container1 .cblue {
    color: #006eff !important; }
  .userDefinedTagDetail-container1 .cursorp {
    cursor: pointer; }
  .userDefinedTagDetail-container1 .dinline b.icon-valid-flag {
    display: none; }
  .userDefinedTagDetail-container1 .dinline li {
    vertical-align: top;
    display: inline-block;
    min-height: 50px; }
    .userDefinedTagDetail-container1 .dinline li > .form-label {
      display: none; }
    .userDefinedTagDetail-container1 .dinline li .form-ctrl-label {
      margin-right: 0; }
  .userDefinedTagDetail-container1 .dinline li + div {
    display: inline-block;
    vertical-align: top; }
    .userDefinedTagDetail-container1 .dinline li + div > div {
      display: inline-block;
      vertical-align: top; }
