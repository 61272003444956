.CreateCodePrintingConfig {
  background: #fff;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #DDDDDD; }
  .CreateCodePrintingConfig .extraText {
    color: #666666;
    line-height: 18px;
    margin-top: 5px; }
  .CreateCodePrintingConfig .pdf-block {
    width: 500px;
    height: 384px;
    border: 1px solid #DDDDDD; }
  .CreateCodePrintingConfig .line {
    margin: 20px 0 20px 0;
    height: 1px;
    background: #dddddd; }
  .CreateCodePrintingConfig .button-margin {
    margin-left: 10px; }
  .CreateCodePrintingConfig .wechat-config .form-unit {
    display: inline-block; }
  .CreateCodePrintingConfig .wechat-config .more {
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    height: 30px;
    line-height: 30px; }

.step0 .icon-valid-flag {
  display: none;
  margin-left: 0px; }

.step0 .no-group-addon .tc-input-group-addon {
  background: none !important;
  border: none !important; }

.step0 .file-package {
  display: inline-block; }
  .step0 .file-package select,
  .step0 .file-package input {
    width: 160px; }
  .step0 .file-package select {
    min-width: 160px;
    margin-right: 10px; }
  .step0 .file-package span {
    font-size: 12px;
    vertical-align: middle;
    padding-left: 5px; }

.step2 .Audit {
  position: relative;
  border: 1px solid #DDDDDD;
  width: 100%;
  padding: 20px 20px 0 20px; }
  .step2 .Audit .Audit-delete {
    position: absolute;
    font-size: 12px;
    color: #006EFF;
    top: 20px;
    right: 20px;
    cursor: pointer; }

.step2 .form-label label {
  font-size: 12px;
  color: #888;
  padding-right: 20px;
  white-space: nowrap;
  box-sizing: border-box; }

.step2 .form-select {
  min-width: 110px;
  width: 110px;
  margin-right: 20px; }

.step2 .addBlock {
  font-size: 12px;
  color: #006EFF;
  cursor: pointer; }

.step2 .specClass {
  margin-right: 20px; }
  .step2 .specClass .form-unit .form-input-help {
    margin-left: 11px; }
  .step2 .specClass .tc-15-input-text {
    width: 110px; }
  .step2 .specClass .search-input.tc-15-input-text {
    width: 100%; }

.step2 .specClass2 {
  margin-right: 20px; }
  .step2 .specClass2 .tc-15-input-text {
    width: 110px; }
  .step2 .specClass2 .search-input.tc-15-input-text {
    width: 100%; }

.step3 .is-error .icon-valid-flag {
  display: none; }

.step3 .form-unit .form-input-help {
  margin-left: 10px; }

.step3 table {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #dddddd;
  font-size: 12px; }
  .step3 table thead {
    color: #888888; }
  .step3 table tbody {
    color: #262626; }
  .step3 table th {
    font-weight: unset; }
  .step3 table tr {
    height: 40px;
    border-bottom: 1px solid #dddddd; }
  .step3 table div {
    display: inline-table; }
  .step3 table .table-padding {
    padding-left: 20px; }
