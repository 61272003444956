.NewsManagement ol, .NewsManagement ul, .NewsManagement dl {
  padding: 0;
  margin: 0;
  margin-bottom: 0 !important; }

.NewsManagement .float-right {
  float: right; }

.NewsManagement .tc-15-filtrateu {
  z-index: 10; }

.NewsManagement .date-title {
  font-size: 12px;
  margin-right: 5px; }

.NewsManagement .tc-time-picker {
  margin-left: 0; }

.NewsManagement .tc-15-calendar-select-wrap .tc-time-picker {
  margin-left: 10px; }

.fail {
  padding-bottom: 15px; }
  .fail .tc-15-rich-dialog-hd {
    height: 30px; }
  .fail .button-box {
    padding-top: 15px;
    text-align: center; }

.reach {
  padding-bottom: 5px; }
  .reach .hint .tc-15-bubble-icon {
    margin-left: 2px !important; }
    .reach .hint .tc-15-bubble-icon .tc-icon {
      margin-top: 2px; }
  .reach .tc-15-page {
    border: none;
    border-top: 1px solid #ddd; }
