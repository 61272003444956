.labelBatchQuery-container {
  padding-top: 20px;
  padding-bottom: 20px; }

.LabelBatchQuery-dialog .wrapper {
  font-size: 12px; }
  .LabelBatchQuery-dialog .wrapper .item {
    display: flex;
    margin-bottom: 10px; }
    .LabelBatchQuery-dialog .wrapper .item .key {
      width: 72px;
      margin-right: 20px;
      text-align: end;
      font-weight: bold; }
    .LabelBatchQuery-dialog .wrapper .item .value {
      width: 450px; }
      .LabelBatchQuery-dialog .wrapper .item .value .value-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
