.MemberSystemConditionsDialog * {
  font-size: 12px; }

.MemberSystemConditionsDialog .tc-15-rich-radio {
  vertical-align: top;
  width: 300px;
  line-height: 30px; }

.MemberSystemConditionsDialog .mb20 {
  margin-bottom: 20px; }

.MemberSystemConditionsDialog .mr20 {
  margin-right: 20px; }

.MemberSystemConditionsDialog .ml20 {
  margin-left: 20px; }
