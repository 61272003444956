.dialog {
  font-size: 12px;
  width: 750px; }
  .dialog .left {
    height: 340px;
    border: 1px solid lightgray; }
    .dialog .left .consumptionItems {
      padding: 0 20px 20px;
      height: 310px;
      overflow-y: auto;
      overflow-x: auto; }
      .dialog .left .consumptionItems .consumptionItem {
        margin-top: 20px; }
        .dialog .left .consumptionItems .consumptionItem .item-name {
          display: inline-block;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
        .dialog .left .consumptionItems .consumptionItem .item-btns {
          float: right; }
          .dialog .left .consumptionItems .consumptionItem .item-btns .btn-del {
            margin-left: 10px; }
    .dialog .left .weak-button {
      width: 100%;
      border-top: 1px solid #ddd;
      color: #000;
      border-bottom: 1px solid #ddd;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent; }
  .dialog .right {
    padding: 20px;
    height: 340px;
    border: 1px solid lightgray;
    overflow-y: auto;
    overflow-x: hidden; }
  .dialog .right-make-sure {
    position: absolute;
    bottom: 20px;
    right: 20px; }
  .dialog .bottom-bar {
    text-align: center; }
    .dialog .bottom-bar .btn-ok {
      margin-top: 20px; }

.sendway-form .is-error .icon-valid-flag {
  display: none; }

.sendway-form .form-unit .form-input-help {
  margin-left: 10px; }

.sendway-form .tc-15-input-text,
.sendway-form .tc-15-select, .sendway-form .tc-15-dropdown {
  width: 191px; }

.sendway-form .tc-15-dropdown-link {
  width: 191px; }

.sendway-form .details {
  display: flex; }
  .sendway-form .details .tc-15-select {
    width: 90px;
    min-width: 90px; }
  .sendway-form .details .detailType {
    margin-right: 10px; }
  .sendway-form .details .tc-15-input-text {
    width: 50px;
    min-width: 50px; }
  .sendway-form .details .btn-del {
    float: right; }

.sendway-form .form-input {
  position: relative; }

.sendway-form .bubble-title-box {
  margin: 0;
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #DDDDDD;
  color: #888888;
  font-family: PingFangSC-Semibold;
  /* bere-style */ }
  .sendway-form .bubble-title-box .all-checkbox .form-ctrl-label {
    margin-right: 3px; }
  .sendway-form .bubble-title-box i.icon-what {
    margin: 0 0 3px 4px; }
  .sendway-form .bubble-title-box .bubble-inner {
    background: #000;
    border: 1px solid #DDDDDD;
    box-shadow: 1px 1px 4px 0 rgba(187, 187, 187, 0.5); }

.sendway-form .bubble-style {
  position: absolute;
  top: 4px;
  right: -25px; }
  .sendway-form .bubble-style .tc-15-bubble-bottom .tc-15-bubble-inner:after {
    border-top-color: #000; }
  .sendway-form .bubble-style .tc-15-bubble-right .tc-15-bubble-inner:after {
    border-left-color: #000; }
  .sendway-form .bubble-style .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000; }
  .sendway-form .bubble-style .tc-15-bubble-inner {
    color: #FFF; }
