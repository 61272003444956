.invitation-code {
  padding-top: 138px; }
  .invitation-code .title {
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    font-weight: bold;
    color: #262626;
    letter-spacing: 5px;
    line-height: 41px;
    padding-bottom: 31px;
    text-align: center; }
    .invitation-code .title svg {
      vertical-align: text-bottom; }
  .invitation-code .block {
    width: 673px;
    height: 323px;
    margin: auto;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    display: flex;
    justify-content: center;
    align-items: center; }
    .invitation-code .block .form-body {
      display: flex;
      justify-content: center; }
  .invitation-code .form-input-invitationCode {
    float: left;
    margin-right: 10px; }

.promotionUploadModel .box {
  font-size: 12px;
  padding: 0;
  border: 1px solid #ddd;
  border-bottom: none;
  width: 450px; }
  .promotionUploadModel .box .box-head,
  .promotionUploadModel .box .box-body {
    border-bottom: 1px solid #ddd;
    position: relative; }
    .promotionUploadModel .box .box-head > div,
    .promotionUploadModel .box .box-body > div {
      padding: 0 10px;
      width: 33%;
      vertical-align: middle;
      line-height: 48px;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .promotionUploadModel .box .box-head > div:first-child,
    .promotionUploadModel .box .box-body > div:first-child {
      width: 33%; }
    .promotionUploadModel .box .box-head > div:last-child,
    .promotionUploadModel .box .box-body > div:last-child {
      width: 33%; }
  .promotionUploadModel .box .box-head2,
  .promotionUploadModel .box .box-body2 {
    display: flex;
    justify-content: space-between; }
    .promotionUploadModel .box .box-head2 > div,
    .promotionUploadModel .box .box-body2 > div {
      flex: 1; }
  .promotionUploadModel .box .box-head > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    min-height: 40px; }
  .promotionUploadModel .box .box-body:hover {
    background-color: #f7f7f7; }
  .promotionUploadModel .box .box-body > div {
    font-weight: normal; }
  .promotionUploadModel .box .is-error .form-ctrl-label,
  .promotionUploadModel .box .is-error .form-input-help {
    line-height: 0;
    margin-bottom: 12px; }
  .promotionUploadModel .box .form-input,
  .promotionUploadModel .box .form-label,
  .promotionUploadModel .box .form-output {
    padding-bottom: 0; }
  .promotionUploadModel .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 130px; }
  .promotionUploadModel .box .icon-valid-flag {
    display: none; }
  .promotionUploadModel .box .tc-input-group {
    line-height: 28px; }
