.code-label-step1form-container {
  width: 100%;
  min-width: 970px; }
  .code-label-step1form-container .form-input {
    vertical-align: top; }
  .code-label-step1form-container .pure-text-row {
    padding-left: 10px; }
  .code-label-step1form-container .errmsg {
    color: #e1504a;
    position: absolute;
    left: 130px;
    top: 27px;
    width: 100px; }
  .code-label-step1form-container b.icon-valid-flag {
    display: none; }
  .code-label-step1form-container .step1-sidebar {
    width: 160px;
    min-height: 300px;
    border-right: 1px solid #e5e5e5; }
  .code-label-step1form-container .step1Form-content {
    display: flex;
    justify-content: space-between; }
    .code-label-step1form-container .step1Form-content .step1-content {
      width: 100%;
      border-right: 1px solid #e5e5e5; }
      .code-label-step1form-container .step1Form-content .step1-content .step1-content-head {
        width: 100%; }
      .code-label-step1form-container .step1Form-content .step1-content h4 {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #000000;
        margin: 10px auto; }
      .code-label-step1form-container .step1Form-content .step1-content .labels-attribute {
        padding: 0 20px; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .form-list {
          width: 100%; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box {
          border: 1px solid #e5e5e5;
          display: inline-block;
          width: 100%;
          margin-top: 0;
          padding: 5px 20px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box .form-label {
            padding: 0; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box .form-label label {
              padding: 0; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box .form-input {
            padding: 0;
            vertical-align: top; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box .form-input .tc-15-rich-radio {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              margin-right: 40px; }
              .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box .form-input .tc-15-rich-radio .form-ctrl-label {
                width: 90px;
                height: 30px;
                line-height: 30px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box:first-of-type {
            padding: 10px 20px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute .attr-box:not(:first-of-type) {
            border-top: none;
            margin-top: -10px; }
      .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content {
        padding: 0 20px;
        padding-top: 20px;
        border-top: 1px solid #e5e5e5; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .icon-valid-flag {
          display: none; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 {
          padding: 20px;
          padding-bottom: 0;
          margin-bottom: 10px;
          border: 1px solid #e5e5e5; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 span {
            font-size: 12px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .promotion-remark {
            position: relative;
            left: -70px;
            color: #888; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .w120 {
            margin-left: 10px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .attributeRange {
            display: flex;
            position: relative; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .attributeRange .form-label label {
              padding-right: 0; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .attributeRange .form-input {
              display: flex;
              flex-wrap: wrap; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .conditionSymbol-INTERVAL {
            display: flex; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .conditionSymbol-INTERVAL .form-label {
              display: none; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .conditionSymbol-INTERVAL span {
              margin-top: 5px;
              margin-right: 10px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .labelCondition {
            display: flex; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .labelCondition .form-input .form-unit {
              margin-right: 10px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .form-input .sign-label {
            line-height: 28px;
            border: none;
            padding: 0 5px;
            display: inline-block; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .form-input .sign-label span {
              font-size: 10px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .sign-label {
            line-height: 28px;
            border: none;
            padding: 0 5px;
            display: inline-block; }
            .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .sign-label span {
              font-size: 10px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .promotions-dimension-item2 .content-isedit {
            border: 1px solid #e5e5e5 !important;
            white-space: nowrap;
            margin-bottom: 10px;
            margin-left: 10px;
            margin: 0 10px 5px;
            height: 30px; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .combinations .labelAttribute-content {
          padding-left: 70px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .combinations .labelAttribute-content li {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .combinations .labelAttribute-content li:first-child {
            margin-left: -70px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .combinations .labelAttribute-content .labelAttribute .form-label {
            width: 70px; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .combinations .labelAttribute-content .attributeRange {
            margin-top: -20px; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .member-dimension-container label {
          width: 100%; }
        .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .labels-attribute-btnBar {
          padding-left: 0; }
          .code-label-step1form-container .step1Form-content .step1-content .labels-attribute-content .labels-attribute-btnBar .tc-15-btn {
            margin-right: 10px; }
  .code-label-step1form-container .labelAttribute {
    min-width: 120px; }
    .code-label-step1form-container .labelAttribute .form-label {
      min-width: 70px; }
  .code-label-step1form-container .dimension {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-top: 5px;
    position: relative; }
    .code-label-step1form-container .dimension .labelAttribute-edit {
      font-size: 12px;
      color: #000000;
      margin-left: 10px;
      vertical-align: middle; }
      .code-label-step1form-container .dimension .labelAttribute-edit .form-label {
        display: none; }
      .code-label-step1form-container .dimension .labelAttribute-edit .form-input {
        display: inline-block;
        padding: 0; }
  .code-label-step1form-container .btnBar {
    margin-top: 20px; }
  .code-label-step1form-container .btnAdd {
    cursor: pointer;
    color: #006eff;
    display: inline-block; }
