.OpenBottleRebateRecord-advance-search {
  background-color: #ffffff;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between; }
  .OpenBottleRebateRecord-advance-search > div:first-child {
    display: flex;
    flex-wrap: wrap; }
    .OpenBottleRebateRecord-advance-search > div:first-child > div {
      margin-bottom: 20px; }
      .OpenBottleRebateRecord-advance-search > div:first-child > div .tc-15-dropdown.date-dropdown {
        width: 300px; }
      .OpenBottleRebateRecord-advance-search > div:first-child > div:not(:last-child) {
        margin-right: 20px; }
  .OpenBottleRebateRecord-advance-search > div:last-child {
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 150px;
    margin-left: 20px;
    align-self: flex-start;
    display: flex;
    justify-content: flex-end; }
  .OpenBottleRebateRecord-advance-search .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 100%; }
