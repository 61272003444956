.Live-conntent {
  background-color: #fff;
  padding: 20px; }
  .Live-conntent .Live-title div {
    font-size: 12px;
    padding: 10px 0; }
    .Live-conntent .Live-title div span {
      margin-right: 20px; }
    .Live-conntent .Live-title div .Live-title-color {
      color: #006eff;
      cursor: pointer; }

.live-manage-list .container {
  margin: 0; }

.live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }

.live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url .media-multipe-div {
    width: 100%;
    display: flex; }
    .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url .media-multipe-div .media-multipe {
      height: 100%;
      max-width: 50px;
      margin-right: 10px; }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .live-manage-list .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }
