.userDefinedTagDetail-container * {
  font-size: 12px !important;
  box-sizing: border-box; }

.userDefinedTagDetail-container {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px 0;
  padding-top: 17px;
  min-width: 1200px; }
  .userDefinedTagDetail-container .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0px; }
  .userDefinedTagDetail-container .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .userDefinedTagDetail-container .tc-15-step li.current .tc-15-step-num:before,
  .userDefinedTagDetail-container .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .userDefinedTagDetail-container .tc-15-calendar-select-wrap {
    vertical-align: top; }
  .userDefinedTagDetail-container .tc-15-simulate-select {
    width: 180px; }
  .userDefinedTagDetail-container .tc-time-picker {
    margin-left: 0; }
  .userDefinedTagDetail-container .tc-15-calendar .tc-time-picker {
    margin-left: 10px; }
  .userDefinedTagDetail-container .step0form-container {
    width: 100%; }
    .userDefinedTagDetail-container .step0form-container .pure-text-row {
      padding-left: 10px; }
    .userDefinedTagDetail-container .step0form-container .errmsg {
      color: #E1504A;
      position: absolute;
      left: 130px;
      top: 27px;
      width: 100px; }
    .userDefinedTagDetail-container .step0form-container b.icon-valid-flag {
      display: none; }
    .userDefinedTagDetail-container .step0form-container .step0-view {
      min-height: 600px;
      display: table;
      height: 100%; }
      .userDefinedTagDetail-container .step0form-container .step0-view .left-view {
        padding-top: 10px;
        width: 160px;
        min-width: 160px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 2px solid #e5e5e5;
        vertical-align: top; }
        .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list {
          width: 158px;
          margin-bottom: 14px;
          cursor: pointer; }
          .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list .list-icon {
            display: inline-block;
            width: 4px;
            height: 23px;
            vertical-align: middle; }
          .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list .list-icon2 {
            overflow: hidden;
            margin-left: 4px;
            width: 18px;
            text-align: center;
            vertical-align: middle;
            border: 1px solid #aaa;
            border-radius: 2px;
            color: #aaa;
            line-height: 16px;
            display: inline-block; }
          .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list .list-title {
            margin-left: 6px;
            line-height: 23px;
            vertical-align: middle;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px !important;
            width: calc(100% - 34px); }
          .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list .list-close {
            color: #bbb; }
          .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list .icon-select {
            background-color: #006eff; }
          .userDefinedTagDetail-container .step0form-container .step0-view .left-view .list .title-select {
            font-weight: bold;
            color: #006eff; }
      .userDefinedTagDetail-container .step0form-container .step0-view .right-view {
        position: relative;
        padding-top: 10px;
        height: 100%;
        width: 100%;
        display: table-cell;
        vertical-align: top; }
        .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate-title {
          padding-top: 10px;
          padding-left: 25px;
          margin-bottom: 15px !important; }
        .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate {
          padding-bottom: 10px; }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .tc-15-rich-radio {
            line-height: 25px;
            width: calc(100% - 100px);
            vertical-align: top; }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .boxLeft {
            font-size: 12px;
            color: #888;
            display: inline-block;
            width: 20px;
            vertical-align: middle; }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box {
            vertical-align: middle;
            display: inline-block;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 20px 20px;
            padding-top: 15px;
            line-height: 23px;
            color: #262626; }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .boxMore {
            width: calc(100% - 66px); }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .boxRight {
            margin-left: 10px;
            display: inline-block;
            width: 30px;
            vertical-align: middle; }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 {
            vertical-align: middle;
            display: inline-block;
            width: calc(100%);
            border: 1px solid #ddd;
            padding: 20px 20px;
            padding-bottom: 0;
            line-height: 33px;
            color: #262626; }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 ul {
              width: 100%; }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .form-input,
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .form-label,
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .form-output {
              padding-bottom: 0 !important; }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .form-input-help {
              line-height: 18px; }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3 {
              display: inline-block;
              min-height: 53px;
              width: calc(100%);
              vertical-align: text-top; }
              .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3 .box4 {
                padding: 5px 10px;
                border: 1px solid #ddd;
                display: inline-block;
                height: 26px;
                line-height: 14px;
                vertical-align: top;
                margin-top: 5px; }
                .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3 .box4 span {
                  display: inline-block;
                  vertical-align: top; }
                .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3 .box4 span:first-child {
                  max-width: 150px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap; }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3More {
              width: calc(100% - 66px); }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3Index0 {
              width: calc(100% - 268px); }
            .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2 .box3More.box3Index0 {
              width: calc(100% - 294px) !important; }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2More {
            width: calc(100% - 66px); }
          .userDefinedTagDetail-container .step0form-container .step0-view .right-view .accurate .box2-view {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 10px 20px;
            margin-bottom: 15px;
            line-height: 23px;
            color: #262626;
            background: #F8F8F8;
            padding-bottom: 0;
            border-radius: 2px; }
    .userDefinedTagDetail-container .step0form-container .btnBar {
      margin-left: 20px;
      margin-right: 20px;
      border-top: 1px solid #ddd;
      padding-top: 20px; }
  .userDefinedTagDetail-container .mb10 {
    margin-bottom: 10px; }
  .userDefinedTagDetail-container .mb20 {
    margin-bottom: 20px; }
  .userDefinedTagDetail-container .ml10 {
    margin-left: 10px; }
  .userDefinedTagDetail-container .ml20 {
    margin-left: 20px; }
  .userDefinedTagDetail-container .mr10 {
    margin-right: 10px; }
  .userDefinedTagDetail-container .mr20 {
    margin-right: 20px; }
  .userDefinedTagDetail-container .flr {
    float: right; }
  .userDefinedTagDetail-container .valignm {
    vertical-align: middle; }
  .userDefinedTagDetail-container .w100 {
    width: 100% !important; }
  .userDefinedTagDetail-container .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .userDefinedTagDetail-container .cblue {
    color: #006eff !important; }
  .userDefinedTagDetail-container .cursorp {
    cursor: pointer; }
  .userDefinedTagDetail-container .dinline li {
    vertical-align: top;
    display: inline-block;
    min-height: 50px; }
    .userDefinedTagDetail-container .dinline li > .form-label {
      display: none; }
    .userDefinedTagDetail-container .dinline li .form-ctrl-label {
      margin-right: 0; }
  .userDefinedTagDetail-container .dinline li + div {
    display: inline-block;
    vertical-align: top; }
    .userDefinedTagDetail-container .dinline li + div > div {
      display: inline-block;
      vertical-align: top; }
