.presents-management .search-bar {
  display: flex;
  justify-content: flex-end; }
  .presents-management .search-bar .tc-15-input-text,
  .presents-management .search-bar .tc-15-select {
    width: 180px;
    margin-right: 10px; }

.presents-modify .presents-name .form-input {
  display: flex;
  justify-content: flex-start; }

.presents-modify .presents-name .button-after-text {
  font-size: 12px;
  line-height: 30px;
  padding-left: 10px;
  min-width: 30px;
  color: #006eff; }

.presents-modify .form-list .form-input {
  position: relative; }

.presents-modify .form-list .tc-15-btn {
  margin-right: 10px; }

.presents-modify .prize-overview .form-input {
  width: 100%; }

.presents-modify .prize-overview .item-list {
  display: flex;
  flex-wrap: wrap;
  min-width: 920px; }

.presents-modify .prize-overview .item-box {
  height: 110px;
  min-width: 410px;
  font-size: 12px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding: 0 20px;
  margin-right: 20px;
  margin-bottom: 36px;
  border: 1px solid #dddddd; }
  .presents-modify .prize-overview .item-box > div:first-child span {
    font-size: 16px; }
  .presents-modify .prize-overview .item-box span {
    font-size: 14px;
    font-weight: 600;
    color: #FF7800;
    padding: 0 20px; }
  .presents-modify .prize-overview .item-box .progress-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .presents-modify .prize-overview .item-box .progress-box .progress {
      height: 5px;
      flex: 1;
      background: #e2e5ec;
      position: relative;
      margin-right: 8px; }
      .presents-modify .prize-overview .item-box .progress-box .progress .progress-bar {
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        background: #006eff; }

.presents-modify .tc-15-table-panel {
  margin-bottom: 20px;
  min-height: 300px;
  border-bottom: 1px solid #ddd; }
  .presents-modify .tc-15-table-panel .form-ctrl-label {
    margin-right: 5px; }
  .presents-modify .tc-15-table-panel .form-label {
    display: none; }
  .presents-modify .tc-15-table-panel .form-input {
    padding-bottom: 0; }
  .presents-modify .tc-15-table-panel .checkbox-inline .form-label {
    display: none; }
  .presents-modify .tc-15-table-panel .checkbox-inline .form-input {
    font-size: 12px; }
  .presents-modify .tc-15-table-panel .checkbox-inline .form-unit {
    float: left; }
    .presents-modify .tc-15-table-panel .checkbox-inline .form-unit .form-ctrl-label {
      margin-right: 5px; }
  .presents-modify .tc-15-table-panel table.tc-15-table-box > tr > th:last-child,
  .presents-modify .tc-15-table-panel table.tc-15-table-box > tr > td:last-child {
    width: 240px; }
  .presents-modify .tc-15-table-panel.history table.tc-15-table-box > tr tr > th:last-child,
  .presents-modify .tc-15-table-panel.history table.tc-15-table-box > tr tr > th:first-child,
  .presents-modify .tc-15-table-panel.history table.tc-15-table-box > tr tr > th:last-child label,
  .presents-modify .tc-15-table-panel.history table.tc-15-table-box > tr tr > th:last-child,
  .presents-modify .tc-15-table-panel.history table.tc-15-table-box > tr tr > td:first-child,
  .presents-modify .tc-15-table-panel.history table.tc-15-table-box > tr tr > td:last-child {
    visibility: hidden; }
  .presents-modify .tc-15-table-panel .form-input-help {
    margin-top: 0;
    line-height: 14px; }

.presents-modify .confirm-popEditor {
  position: absolute;
  width: 330px;
  bottom: 33px;
  height: 144px; }

.presents-modify .confirm-popEditor-prize-right {
  right: -10px; }

.presents-modify .tc-15-calendar-select-wrap {
  white-space: nowrap; }

.PresentsLimit .inline {
  display: flex; }
  .PresentsLimit .inline > span {
    line-height: 30px;
    padding-right: 8px; }
  .PresentsLimit .inline > li {
    margin-right: 8px; }

.PresentsLimit .form-list .form-label label {
  padding-right: 0; }

.PresentsLimit .tc-input-group {
  min-width: 0; }

.Presents-add-to {
  min-width: 300px; }
  .Presents-add-to .Presents-add-to-tip {
    padding: 10px 20px;
    margin-bottom: 60px; }

.Presents-timing {
  min-width: 350px; }
  .Presents-timing .button-box {
    text-align: center;
    padding-top: 20px; }
  .Presents-timing .row span {
    color: #888;
    font-size: 12px; }

.Presents-automatic .row {
  padding-bottom: 20px; }

.Presents-automatic .row:last-child {
  padding-bottom: 0; }

.Presents-automatic .icon-valid-flag {
  display: none !important; }

.Presents-automatic .Presents-automatic-text {
  color: '#666666';
  line-height: '18px';
  margin-top: '5px';
  font-size: 12px; }

.Presents-automatic .button-box {
  text-align: center;
  padding-top: 20px; }

.Presents-automatic .tc-15-rich-dialog-hd {
  margin-bottom: 0; }
  .Presents-automatic .tc-15-rich-dialog-hd strong {
    margin-bottom: 10px; }

.Presents-automatic .tc-15-rich-radio {
  margin: 20px 0 15px;
  box-sizing: border-box; }

.Presents-automatic .Presents-automatic-form .form-list {
  display: flex;
  flex-wrap: wrap; }
  .Presents-automatic .Presents-automatic-form .form-list label {
    padding-right: 10px; }
  .Presents-automatic .Presents-automatic-form .form-list .Presents-automatic-form-lable label {
    padding-right: 20px; }
  .Presents-automatic .Presents-automatic-form .form-list .Presents-automatic-form-lable .tc-15-dropdown-link {
    max-width: 300px; }

.Presents-automatic .Presents-automatic-form .Presents-automatic-form-number {
  font-size: 12px;
  color: #888;
  line-height: 30px; }
  .Presents-automatic .Presents-automatic-form .Presents-automatic-form-number span:first-child {
    padding-right: 20px; }

.Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box {
  font-size: 12px;
  color: #888;
  display: flex; }
  .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .Presents-automatic-timing-sent-box-time .form-label {
    display: none; }
  .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .Presents-automatic-timing-sent-box-input .form-label {
    padding: 0 10px; }
    .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .Presents-automatic-timing-sent-box-input .form-label label {
      padding: 0; }
  .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .tc-15-calendar-select-wrap {
    display: block;
    width: 200px; }
    .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .tc-15-calendar-select-wrap .tc-15-calendar-select {
      display: block;
      width: 200px; }
  .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .Presents-automatic-timing-sent-text {
    margin: 0 10px;
    line-height: 30px; }
  .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-timing-sent-box .operate {
    line-height: 30px;
    margin-left: 20px; }

.Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-form-number {
  font-size: 12px;
  color: #888;
  line-height: 30px; }
  .Presents-automatic .Presents-automatic-timing-sent .Presents-automatic-form-number span:first-child {
    padding-right: 10px; }

.tc-15-table-panel .calendar-inline .tc-15-calendar table tbody tr td {
  line-height: 26px;
  max-width: 26px;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
  border-bottom: 2px solid #fff;
  font-size: 12px; }
  .tc-15-table-panel .calendar-inline .tc-15-calendar table tbody tr td.tc-15-calendar-today {
    color: #fff; }
    .tc-15-table-panel .calendar-inline .tc-15-calendar table tbody tr td.tc-15-calendar-today:hover:before {
      background: #006eff;
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 25px; }
  .tc-15-table-panel .calendar-inline .tc-15-calendar table tbody tr td:hover:before {
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #f2f2f2;
    border-radius: 25px; }

.tc-15-table-panel .calendar-inline .tc-15-calendar table tbody tr:first-child td {
  position: inherit; }

.tc-15-table-panel .calendar-inline .tc-15-calendar table thead tr th {
  font-size: 12px;
  line-height: 17px;
  padding: 4px 0;
  color: #000;
  text-align: center;
  width: 25px;
  box-sizing: border-box;
  font-weight: 400; }

.dis-tablist .tc-15-tablist li a {
  border-bottom: none;
  cursor: default;
  color: #888; }

.dis-tablist .tc-15-tablist li.tc-cur a {
  border-bottom: 2px solid #006eff;
  cursor: default;
  color: #888; }
