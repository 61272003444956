.CodeScrap {
  width: 100%;
  height: 100%; }
  .CodeScrap .search-bar button {
    float: right;
    margin-right: 10px; }
  .CodeScrap .main-block {
    background: #fff;
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #e2e2e2; }
