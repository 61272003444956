.TaskConditionsDialog * {
  font-size: 12px; }

.TaskConditionsDialog .tc-15-rich-radio {
  vertical-align: top;
  width: 300px;
  line-height: 30px; }

.TaskConditionsDialog .mb20 {
  margin-bottom: 20px; }

.TaskConditionsDialog .mr20 {
  margin-right: 20px; }

.TaskConditionsDialog .ml20 {
  margin-left: 20px; }
