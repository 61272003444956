.terminal-box {
  margin: 20px 0;
  padding: 20px 0 20px 20px;
  background: #fff;
  min-width: 999px; }
  .terminal-box .flex-box {
    display: flex; }
    .terminal-box .flex-box .form-list:first-child {
      margin-right: 20px; }
  .terminal-box .form-list .form-list {
    background-color: #fff; }
  .terminal-box .tc-15-dropdown {
    width: 330px; }
    .terminal-box .tc-15-dropdown a {
      width: 330px; }
  .terminal-box .tc-15-filtrate-menu {
    width: 330px; }
  .terminal-box .select-address-box.pure-text-row > .form-input {
    padding-bottom: 0; }
  .terminal-box .select-address-box.pure-text-row .form-list {
    padding: 0;
    margin: 0; }
    .terminal-box .select-address-box.pure-text-row .form-list li {
      float: left;
      float: left; }
      .terminal-box .select-address-box.pure-text-row .form-list li:first-child, .terminal-box .select-address-box.pure-text-row .form-list li:nth-child(2) {
        margin-right: 8px; }
      .terminal-box .select-address-box.pure-text-row .form-list li .form-label {
        display: none; }
      .terminal-box .select-address-box.pure-text-row .form-list li select {
        width: 105px;
        min-width: 105px; }
    .terminal-box .select-address-box.pure-text-row .form-list li:first-child .icon-valid-flag, .terminal-box .select-address-box.pure-text-row .form-list li:nth-child(2) .icon-valid-flag {
      display: none; }
