.code-info-box {
  width: 100%; }

.tip-text {
  height: 51px;
  line-height: 51px;
  text-align: center;
  color: #888;
  font-family: PingFang-SC-Regular; }

.plan-info {
  font-size: 12px; }
  .plan-info .planinfo-title {
    padding: 0 10px;
    width: 100%;
    border: 1px solid #ddd;
    height: 45px;
    line-height: 45px;
    color: #888;
    margin: 0 20px;
    margin-left: 0;
    margin-top: 20px;
    overflow: hidden; }
  .plan-info .planinfo-box {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 20px;
    padding-top: 0px;
    line-height: 23px;
    color: #262626;
    margin-bottom: 20px;
    margin-top: -1px; }
  .plan-info .planinfo-boxnext {
    margin-left: 10px;
    margin-right: 10px; }
    .plan-info .planinfo-boxnext > span {
      color: rgba(0, 0, 0, 0.65);
      margin-left: 8px;
      margin-right: 8px;
      line-height: 30px; }
    .plan-info .planinfo-boxnext .inline {
      display: inline-block;
      margin-top: 15px; }
  .plan-info .planinfo-boxhead,
  .plan-info .planinfo-boxbody {
    border-bottom: 1px solid #ddd; }
    .plan-info .planinfo-boxhead > div,
    .plan-info .planinfo-boxbody > div {
      padding: 0 10px;
      vertical-align: middle;
      line-height: 48px;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 25%; }
  .plan-info .planinfo-boxhead {
    border-top: 1px solid #ddd; }
    .plan-info .planinfo-boxhead > div {
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      color: #888;
      min-height: 40px; }
  .plan-info .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .plan-info .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .plan-info .planinfo-boxhead-award > div,
  .plan-info .planinfo-boxbody-award > div {
    width: 33.3%; }
  .plan-info .planinfo-boxhead-award {
    border-top: none; }

.table-list {
  width: 50%;
  min-height: 40px;
  line-height: 40px;
  text-align: left;
  border-collapse: collapse;
  margin: 20px auto 0;
  margin-bottom: 20px; }

.table-list,
.table-list tr th,
.table-list tr td {
  border: 1px solid #e9eaec; }

.table-list tr th,
.table-list tr td {
  padding: 0 20px;
  word-break: break-all; }

.AcquisitionInfo-modal .table-list {
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: left;
  border-collapse: collapse;
  margin: 20px auto 0;
  margin-bottom: 20px; }

.AcquisitionInfo-modal .table-list,
.AcquisitionInfo-modal .table-list tr th,
.AcquisitionInfo-modal .table-list tr td {
  border: 1px solid #e9eaec; }

.AcquisitionInfo-modal .table-list tr th,
.AcquisitionInfo-modal .table-list tr td {
  padding: 0 20px;
  word-break: break-all; }
