.OpenBottleRebateEdit {
  background: white;
  margin: 20px 0;
  padding: 20px; }
  .OpenBottleRebateEdit .step-footer {
    padding-top: 20px;
    border-top: 1px solid #e2e2e2; }

.OpenBottleRebateEdit.readonly {
  margin: 0;
  padding: 0;
  background-color: transparent; }

.Step1Form .tc-15-dropdown,
.Step1Form .tc-15-input-text,
.Step1Form .tc-15-calendar-select {
  width: 330px; }

.Step1Form .tc-15-switch {
  overflow: hidden; }

.Step1Form .form-input {
  position: relative; }

.Step1Form-long-term {
  position: absolute;
  left: 340px;
  top: 5px;
  width: 70px; }

.Step1Form-more-conditions {
  position: absolute;
  top: 0;
  left: 55px; }

.Step1Form-readonly {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  padding: 20px;
  background-color: #ffffff; }
  .Step1Form-readonly > div {
    display: flex;
    align-items: center; }
    .Step1Form-readonly > div > div:first-child {
      width: 60px;
      margin-right: 20px; }
    .Step1Form-readonly > div:not(:last-child) {
      margin-bottom: 20px; }
