.promotionRule-container .searchbar-box .tc-15-dropdown-link {
  width: 180px; }

.promotionRule-container .blue span {
  color: #006eff;
  margin-right: 10px;
  cursor: pointer; }

.promotionRule-container .promotionRule-box {
  min-width: 1000px;
  width: 100%;
  border: 1px solid #dddddd;
  background: #fff;
  padding-top: 10px; }
  .promotionRule-container .promotionRule-box .tc-15-tablist {
    border-bottom: none; }

.promotionRule-container .tip-text {
  height: 51px;
  line-height: 51px;
  text-align: center;
  color: #888;
  font-family: PingFang-SC-Regular;
  font-size: 12px; }

.promotionRule-container .code-info {
  font-family: PingFangSC-;
  display: flex;
  padding: 0 20px;
  padding-top: 10px;
  font-size: 12px;
  color: #333333; }
  .promotionRule-container .code-info > ul {
    flex: 1; }
    .promotionRule-container .code-info > ul li {
      padding: 10px 0; }
    .promotionRule-container .code-info > ul li > span {
      display: inline-block; }
    .promotionRule-container .code-info > ul li > span:first-child {
      width: 128px;
      color: #888888; }
