.tagform .button-after .tc-15-dropdown-btn-style .tc-15-dropdown-link {
  width: 350px; }

.tagform .flex {
  display: flex;
  justify-content: flex-start;
  position: relative; }
  .tagform .flex .form-label {
    min-width: 70px; }
  .tagform .flex li:not(:first-of-type) .form-label {
    display: none; }
  .tagform .flex li {
    margin-right: 10px; }
    .tagform .flex li .form-label {
      width: 70px;
      padding-right: 10px; }
