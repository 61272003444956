.AllocationISVUserForm .all {
  display: none; }

.LinkRolesForm {
  width: 450px; }
  .LinkRolesForm .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 330px; }

.EnterpriseAllocation .confirm-popEditor .tc-15-edit-in-place {
  left: 0;
  right: initial; }
