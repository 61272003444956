.ty-amount-bill-panel {
  padding: 15px;
  margin: 15px 0px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff; }
  .ty-amount-bill-panel h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0 0 0; }
  .ty-amount-bill-panel div[class^="ant-col-"] {
    border-left: 1px solid #DDDDDD;
    padding-left: 10px; }
    .ty-amount-bill-panel div[class^="ant-col-"] b {
      font-family: MicrosoftYaHei-Bold;
      font-size: 12px;
      color: #888888;
      margin-top: 10px;
      display: block; }
    .ty-amount-bill-panel div[class^="ant-col-"]:first-child {
      padding-left: 0;
      border-left: none; }
    .ty-amount-bill-panel div[class^="ant-col-"] .num {
      font-family: MicrosoftYaHei;
      font-size: 28px;
      color: #444444;
      display: block; }
  .ty-amount-bill-panel .ant-row {
    margin-top: 15px; }

.tc-15-btn.btn-primary {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  color: #000;
  margin-right: 10px; }

.AmountBillManagement ul {
  margin-bottom: 0; }

.AmountBillManagement > ul.tc-15-tablist {
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #fff; }

.ty-ellipsis-80.tc-15-dropdown {
  width: 80px; }
  .ty-ellipsis-80.tc-15-dropdown .tc-15-dropdown-link {
    max-width: 80px; }

.ty-ellipsis-120.tc-15-dropdown {
  width: 120px; }
  .ty-ellipsis-120.tc-15-dropdown .tc-15-dropdown-link {
    max-width: 120px; }

.ty-reset-select.tc-15-select {
  min-width: 90px !important; }

.margin-t-10 {
  margin-top: 10px !important; }

.margin-t-15 {
  margin-top: 15px !important; }

.AmountBillManagement .menu-tablist {
  margin-top: 20px; }
  .AmountBillManagement .menu-tablist .m-l-10 {
    margin-left: 0 !important; }

.AmountBillManagement .linkCirculation-config {
  display: flex;
  flex-wrap: wrap; }
  .AmountBillManagement .linkCirculation-config > * {
    vertical-align: bottom;
    margin-bottom: 10px; }

.AmountBillManagement .sel-box > div > div, .AmountBillManagement .sel-box > div > button {
  margin-bottom: 10px; }

.AmountBillManagement .sel-box > div .tc-15-rich-radio {
  height: 30px; }

.AmountBillManagement .sel-box > div button {
  vertical-align: middle; }

.AmountBillManagement .tc-15-rich-radio {
  margin-right: 10px; }

.AmountBillManagement .ty-amount-number-input input {
  font-size: 12px; }

.AmountBillManagement .tc-15-dropdown.tc-15-dropdown-btn-style a.tc-15-dropdown-link {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px; }

.asynDown-wrap {
  width: 100%;
  padding: 10px 0 20px; }

.asynDown-wrap li.listDom {
  width: 50%;
  float: left;
  padding: 10px 0; }

.asynDown-wra li.lastOne {
  width: 100%;
  padding: 10px 0; }

.asynDown-wrap li.listDom span.lableName {
  display: inline-block;
  width: 120px;
  text-align: left; }

.AmountBillManagement .tc-15-table-panel {
  overflow: auto; }

.autoScrollWarp .autoScrollHead .tc-15-dropdown .tc-15-dropdown-menu li a {
  overflow: inherit;
  width: 100%;
  padding-right: 10px;
  box-sizing: content-box; }
