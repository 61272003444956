.ScrapDialog .input-block {
  margin-top: 20px; }
  .ScrapDialog .input-block input {
    width: 250px; }
  .ScrapDialog .input-block button {
    margin-left: 10px; }

.ScrapDialog .table {
  margin-top: 20px; }

.ScrapDialog .tc-15-input-textarea {
  width: 547px; }

.ScrapDialog .icon-valid-flag {
  display: none; }
