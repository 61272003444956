.MoreActive .tc-15-table-box .template-status {
  display: flex; }
  .MoreActive .tc-15-table-box .template-status span {
    color: #D0021B;
    padding-left: 20px; }

.moreActiveForm .cblue {
  color: #006eff !important; }

.moreActiveForm .cursorp {
  cursor: pointer; }

.moreActiveForm .clear {
  clear: both; }

.moreActiveForm .tc-15-dropdown,
.moreActiveForm .tc-15-input-text,
.moreActiveForm .tc-15-simulate-select,
.moreActiveForm .tc-15-select {
  width: 330px; }

.moreActiveForm .fz12 {
  font-size: 12px; }

.moreActiveForm .form-input-nolabel {
  padding-bottom: 0; }
  .moreActiveForm .form-input-nolabel .form-label {
    display: none; }

.moreActiveForm .form-list {
  margin-bottom: 20px !important; }

.moreActiveForm .formlistLast .form-list {
  margin-bottom: 0px !important; }

.moreActiveForm .tc-15-select,
.moreActiveForm .tc-input-group {
  min-width: 0px; }

.moreActiveForm .inline {
  display: inline-block;
  float: left; }
  .moreActiveForm .inline .form-label label {
    display: none; }
  .moreActiveForm .inline .icon-valid-flag {
    display: none; }

.moreActiveForm .inline.checkbox {
  width: 20px; }
  .moreActiveForm .inline.checkbox .form-list {
    height: 30px;
    margin-bottom: 15px !important; }

.moreActiveForm .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
  background: #000;
  color: #FFF;
  border: none; }
  .moreActiveForm .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
    border-top-color: #000; }
