.edit-tree-wrap {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  flex-direction: column; }
  .edit-tree-wrap .edit-tree-content-wrap-box {
    overflow: auto; }
  .edit-tree-wrap .edit-tree-content-wrap {
    padding-bottom: 20px; }
  .edit-tree-wrap .title-wrap {
    padding: 8px; }
    .edit-tree-wrap .title-wrap .title-text {
      font-weight: 700;
      font-size: 14px; }
    .edit-tree-wrap .title-wrap .add-btn {
      color: #006eff;
      font-size: 12px;
      float: right;
      margin-top: 2px;
      cursor: pointer; }
  .edit-tree-wrap .tree-node {
    line-height: 30px; }
    .edit-tree-wrap .tree-node .hidden {
      display: none; }
    .edit-tree-wrap .tree-node .tree-node-name {
      margin-right: 10px;
      display: inline-block;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .edit-tree-wrap .tree-node .tree-content-wrap {
      display: flex;
      align-items: center;
      position: relative; }
    .edit-tree-wrap .tree-node .tree-node .tree-content-wrap::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 1px;
      background-color: #d1d5db;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
    .edit-tree-wrap .tree-node > div:nth-child(1):hover {
      background: #f2f2f2; }
      .edit-tree-wrap .tree-node > div:nth-child(1):hover .float-right {
        display: block; }
    .edit-tree-wrap .tree-node .tree-node-active {
      background: #f2f2f2; }
    .edit-tree-wrap .tree-node img {
      width: 16px;
      height: 16px;
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      cursor: pointer; }
      .edit-tree-wrap .tree-node img + span {
        display: inline-block;
        vertical-align: middle;
        color: #000;
        font-size: 12px;
        cursor: pointer; }
    .edit-tree-wrap .tree-node .float-right {
      float: right;
      margin-right: 8px;
      display: none; }
      .edit-tree-wrap .tree-node .float-right span {
        color: #006eff;
        font-size: 12px;
        cursor: pointer; }
      .edit-tree-wrap .tree-node .float-right .edit,
      .edit-tree-wrap .tree-node .float-right .del {
        margin-right: 10px; }
