.special-activity .step0 {
  height: 100%;
  font-size: 12px; }
  .special-activity .step0 .outside {
    background: white;
    height: 100%;
    margin-top: 15px;
    padding: 20px; }
    .special-activity .step0 .outside .inside {
      background: #f8f8f8;
      padding-left: 33px;
      padding-top: 19px;
      padding-bottom: 19px; }
      .special-activity .step0 .outside .inside .prize-image {
        width: 80px;
        height: 80px;
        position: relative;
        display: inline-block;
        background: #f2f2f2;
        border: 1px solid #d9d9d9;
        text-align: center;
        vertical-align: middle; }
        .special-activity .step0 .outside .inside .prize-image img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .special-activity .step0 .outside .inside .prize-image .n-loading-icon {
          position: relative;
          margin-top: 30px;
          z-index: 10; }
        .special-activity .step0 .outside .inside .prize-image span {
          width: 156px;
          color: #666666;
          display: inline-block;
          position: absolute;
          left: 90px;
          top: -6px; }
      .special-activity .step0 .outside .inside .row {
        margin-top: 19px;
        display: flex; }
        .special-activity .step0 .outside .inside .row > span:first-child {
          margin-right: 20px; }
        .special-activity .step0 .outside .inside .row .widthSpan {
          width: calc(100% - 88px);
          word-break: break-all; }
      .special-activity .step0 .outside .inside > div {
        display: flex; }
        .special-activity .step0 .outside .inside > div:not(:last-child) {
          margin-bottom: 20px; }
        .special-activity .step0 .outside .inside > div > div:first-child {
          margin-right: 20px;
          flex-shrink: 0; }
  .special-activity .step0 .rightSpan50 {
    margin-left: 10px; }

.special-activity .step0Edit {
  font-size: 12px;
  background: white;
  margin: 20px 0;
  padding: 20px; }
  .special-activity .step0Edit .tc-15-switch input {
    width: 35px !important; }
  .special-activity .step0Edit .button-after > .form-input {
    position: relative; }
    .special-activity .step0Edit .button-after > .form-input .tc-15-select {
      width: 330px; }
    .special-activity .step0Edit .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .special-activity .step0Edit .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .special-activity .step0Edit .button-after > .form-input .button-after-text2:nth-of-type(2) {
      left: 375px; }
    .special-activity .step0Edit .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
  .special-activity .step0Edit a:focus {
    text-decoration: none !important; }
  .special-activity .step0Edit .tc-15-dropdown,
  .special-activity .step0Edit .tc-15-input-text,
  .special-activity .step0Edit .tc-15-simulate-select,
  .special-activity .step0Edit .tc-15-select {
    width: 330px; }
  .special-activity .step0Edit .tc-15-dropdown-link {
    max-width: 330px; }
  .special-activity .step0Edit .form-input {
    position: relative; }
    .special-activity .step0Edit .form-input .btnCreate {
      right: -64px;
      position: absolute;
      top: 7px; }
  .special-activity .step0Edit .btnBar {
    border-top: 1px solid #ddd;
    padding-top: 15px; }
    .special-activity .step0Edit .btnBar .weak-button {
      margin-left: 10px; }
  .special-activity .step0Edit .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    text-align: center;
    vertical-align: middle; }
    .special-activity .step0Edit .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .special-activity .step0Edit .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .special-activity .step0Edit .prize-image span {
      width: 150px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .special-activity .step0Edit .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .special-activity .step0Edit .checked-array-box {
    width: 900px;
    min-width: 900px;
    margin-bottom: 20px; }
    .special-activity .step0Edit .checked-array-box .form-list {
      background: #fff;
      margin: 0;
      padding: 0;
      display: flex; }
      .special-activity .step0Edit .checked-array-box .form-list .form-label label {
        padding-right: 0; }
      .special-activity .step0Edit .checked-array-box .form-list .tc-time-picker {
        margin-left: 0; }
      .special-activity .step0Edit .checked-array-box .form-list .tc-15-input-text {
        width: 165px !important; }
    .special-activity .step0Edit .checked-array-box .add-btn {
      width: 30%;
      color: #006eff;
      cursor: pointer; }
    .special-activity .step0Edit .checked-array-box .set-color {
      color: #006eff;
      cursor: pointer;
      line-height: 30px;
      margin-left: 10px; }

.special-activity .BanquetRewardEdit .BanquetRewardEdit-set {
  position: absolute;
  left: 50px;
  top: 0px; }
