.flowdetail-wrap .ty-reset-select.tc-15-select {
  min-width: 90px !important; }

.flowdetail-wrap .tc-15-dropdown-btn-style .tc-15-dropdown-link em {
  color: #000; }

.flowdetail-wrap .tc-15-table-panel {
  overflow: auto; }

.flowdetail-wrap input.tc-15-input-text {
  width: 60px; }

.flowdetail-wrap .tc-15-dropdown-btn-style a.tc-15-dropdown-link {
  width: 60px; }

.flowdetail-wrap .ty-reset-date-range-picker {
  margin-left: 0;
  margin-right: 10px; }

.flowdetail-wrap .order-param > div {
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle; }

.flowdetail-wrap .mt0 {
  margin-top: 0 !important; }

.flowdetail-wrap .mb20 {
  margin-bottom: 20px; }

.flowdetail-wrap .echarts-wrap {
  padding: 20px;
  margin-right: 0;
  background: #fff;
  overflow: hidden; }
  .flowdetail-wrap .echarts-wrap .map {
    width: 50%;
    float: left;
    height: 500px; }
  .flowdetail-wrap .echarts-wrap .area {
    width: 50%;
    float: left;
    height: 500px; }
  .flowdetail-wrap .echarts-wrap .title {
    font-weight: bold;
    color: #000; }

.flowdetail-wrap .fleeingTrend {
  width: 100%;
  float: left;
  height: 500px; }

.flowdetail-wrap .mt20 {
  margin-top: 20px; }

.flowdetail-wrap .area-title {
  margin-top: 20px; }
