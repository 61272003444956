.special-activity .programme-ruleModal {
  border-top: 1px solid #ddd;
  margin-top: 20px; }
  .special-activity .programme-ruleModal .programme-rule-box2 {
    border: 1px solid #ddd;
    color: #262626;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 46px;
    margin-bottom: 15px; }
    .special-activity .programme-ruleModal .programme-rule-box2 .programme-rule-box2-symbol {
      margin: 3px 0 3px 10px; }
      .special-activity .programme-ruleModal .programme-rule-box2 .programme-rule-box2-symbol ul {
        margin-bottom: 0; }
  .special-activity .programme-ruleModal .BanquetPolicyEdit-rule-box3 {
    padding: 0 22px 0 10px;
    border: 1px solid #ddd;
    display: inline-block;
    border: 1px solid #ddd;
    min-height: 28px;
    line-height: 28px;
    margin-left: 10px;
    max-width: 300px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .special-activity .programme-ruleModal .BanquetPolicyEdit-rule-box3 .cursorp {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 10px; }

.special-activity .programme-rule-box2-title {
  margin-bottom: 15px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between; }

.special-activity .programme-throw-box {
  background-color: #fff;
  border: 1px solid #ddd; }
  .special-activity .programme-throw-box .programme-throw-title-box {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 10px; }
  .special-activity .programme-throw-box .programme-throw-content-box {
    padding: 10px; }
  .special-activity .programme-throw-box .programme-throw-content-prizes {
    border: 1px solid #ddd; }
    .special-activity .programme-throw-box .programme-throw-content-prizes .programme-throw-content-prizes-row-title {
      display: flex;
      padding: 10px 40px 10px 20px;
      font-size: 12px; }
    .special-activity .programme-throw-box .programme-throw-content-prizes .programme-throw-content-prizes-row {
      border-top: 1px solid #ddd;
      display: flex;
      align-items: center;
      padding: 10px 40px 10px 20px; }
      .special-activity .programme-throw-box .programme-throw-content-prizes .programme-throw-content-prizes-row ul {
        margin-bottom: 0; }

.special-activity .IntervalConfig {
  font-size: 12px; }
  .special-activity .IntervalConfig button {
    background-color: transparent;
    color: #006eff; }

.special-activity .IntervalConfig-section {
  border: 1px solid #ddd;
  margin-bottom: 10px; }
  .special-activity .IntervalConfig-section .-head {
    display: flex; }
  .special-activity .IntervalConfig-section > div {
    padding: 10px 20px;
    display: flex;
    align-items: center; }
    .special-activity .IntervalConfig-section > div:not(:last-child) {
      border-bottom: 1px solid #ddd; }
  .special-activity .IntervalConfig-section .tc-15-input-num .minus,
  .special-activity .IntervalConfig-section .tc-15-input-num .minus.disabled,
  .special-activity .IntervalConfig-section .tc-15-input-num .plus {
    display: none; }
  .special-activity .IntervalConfig-section .tc-15-input-num .num {
    text-align: left;
    padding: 0 5px; }
  .special-activity .IntervalConfig-section .tc-15-input-num .num.disabled {
    cursor: not-allowed; }

.special-activity .prize-name-column {
  width: 300px;
  margin-right: 20px;
  word-break: break-all;
  flex-shrink: 0; }

.special-activity .prize-chance-column {
  width: 220px;
  margin-right: 20px;
  flex-shrink: 0; }
