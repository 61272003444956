.modify-template.step3 .magic-sketchpad {
  background: #ededed; }
  .modify-template.step3 .magic-sketchpad .bg-share {
    width: 281px;
    margin: 30px 0 0 11px; }
  .modify-template.step3 .magic-sketchpad .share-title {
    font-size: 15px;
    font-family: PingFangSC;
    font-weight: 600;
    color: #232323;
    line-height: 17px;
    position: absolute;
    top: 70px;
    left: 72px;
    width: 210px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all; }
  .modify-template.step3 .magic-sketchpad .share-img {
    width: 210px;
    height: 168px;
    background: #d8d8d8;
    position: absolute;
    top: 105px;
    left: 72px; }

.modify-template.step3 .upload .image {
  margin-right: 0; }

.modify-template.step3 .section.auth {
  flex-direction: column; }

.modify-template.step3 .section .form-ctrl-label {
  display: block;
  margin-bottom: 20px; }
