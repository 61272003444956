ul, li {
  padding: 0;
  margin: 0; }

.subScriber-dialog ::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */ }

.subScriber-dialog .tc-15-rich-dialog-hd {
  height: 30px; }

.subScriber-dialog .button-box {
  text-align: center;
  padding-top: 20px; }

.NewsDetails {
  width: 100%;
  height: auto;
  max-height: 222px;
  overflow: hidden; }
  .NewsDetails .NewsDetailsUl {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 222px;
    -ms-overflow-style: none;
    /* IE 10+ */
    scrollbar-width: none;
    /* Firefox */
    margin: 0; }
    .NewsDetails .NewsDetailsUl li {
      height: 22px;
      margin-bottom: 18px;
      box-sizing: border-box; }
      .NewsDetails .NewsDetailsUl li .newsTitle {
        display: inline-block;
        margin-right: 20px;
        width: 60px;
        color: #888888;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .NewsDetails .NewsDetailsUl li .newsText {
        display: inline-block;
        width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .NewsDetails .NewsDetailsUl li:last-child {
      margin: 0; }
    .NewsDetails .NewsDetailsUl .NewsDetailsUlNone {
      text-align: center;
      color: #888; }
