.InteractiveActivityCode .container {
  margin: 0; }

.InteractiveActivityCode .operate span {
  padding-right: 10px;
  vertical-align: middle; }

.PromotionCode {
  width: 373px;
  min-width: 350px; }
