.label-tag-siderbox {
  margin-left: -20px;
  width: 160px; }
  .label-tag-siderbox .sider-item {
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    line-height: 25px;
    border-left: 4px solid #fff;
    padding-left: 16px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    cursor: pointer; }
  .label-tag-siderbox .active {
    color: #006EFF;
    border-left: 4px solid #006EFF; }

.promotions-dimension-item2 .w120 {
  margin-left: 10px; }
  .promotions-dimension-item2 .w120 .tc-15-select,
  .promotions-dimension-item2 .w120 .tc-15-input-text {
    width: 120px;
    min-width: auto; }

.promotions-dimension-item2 .notShowCheckbox input {
  display: none !important; }

.promotions-dimension-item2 .notShowCheckbox .checkbox-label {
  left: 0 !important; }

.promotions-dimension-item2 .checkboxNone .checkbox-label {
  left: 0 !important;
  display: none !important; }

.promotions-dimension-item2 .checkbox-style {
  width: 180px;
  height: 30px;
  line-height: 30px; }
  .promotions-dimension-item2 .checkbox-style .checkbox-label {
    position: absolute;
    top: 0;
    left: 24px;
    width: calc(100% - 24px); }
  .promotions-dimension-item2 .checkbox-style > div {
    display: inline-block; }
  .promotions-dimension-item2 .checkbox-style .form-label {
    line-height: 0; }
    .promotions-dimension-item2 .checkbox-style .form-label label {
      padding-right: 0; }
  .promotions-dimension-item2 .checkbox-style .form-input {
    position: relative;
    width: 100%; }
  .promotions-dimension-item2 .checkbox-style .form-input, .promotions-dimension-item2 .checkbox-style .form-label, .promotions-dimension-item2 .checkbox-style .form-output {
    padding-bottom: 10px; }
  .promotions-dimension-item2 .checkbox-style input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    vertical-align: middle;
    outline: 0;
    -webkit-appearance: none;
    border: 1px solid #bbb;
    box-sizing: border-box;
    background-color: #fff; }
  .promotions-dimension-item2 .checkbox-style input:checked {
    background-image: url(https://imgcache.qq.com/open_proj/proj_qcloud_v2/bee-v2/css/sprite/bee-201904181019.svg);
    background-color: #006eff;
    border-color: #006eff;
    background-position: 59.709999999999994% 98.48%;
    background-size: 1571.4285714285713% 1514.2857142857142%;
    background-repeat: no-repeat; }

.labelCondition ul, .labelCondition li {
  flex-shrink: 0; }

.labelCondition > li:nth-child(3) {
  flex-shrink: 1; }

.labelCondition > ul:nth-child(1) .form-unit > div > div {
  white-space: nowrap; }
