.user-form-bg {
  min-width: 800px;
  margin: 20px 0;
  padding: 20px 20px 40px;
  background: #fff; }
  .user-form-bg .tc-15-dropdown-btn-style .tc-15-filtrateu {
    width: 330px; }
  .user-form-bg .tc-15-dropdown-link {
    width: 330px; }
  .user-form-bg .form-input {
    position: relative; }
  .user-form-bg .is-error ~ .modify-poolname-btn {
    left: 365px; }
  .user-form-bg .modify-poolname-btn {
    width: 100px; }
