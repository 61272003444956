.Data-Distribution .title {
  font-size: 30px;
  font-weight: bold; }

.Data-Distribution .main-block {
  background: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 20px; }

.special-treatment-dialog .ml-20 {
  margin-left: -20px !important; }

.special-treatment-dialog .tc-15-table-panel {
  height: 512px; }

.special-treatment-dialog .tc-15-table-fixed-body {
  height: 470px; }

.special-treatment-dialog .search-wrap {
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px; }
  .special-treatment-dialog .search-wrap span {
    color: #006eff;
    font-size: 12px;
    cursor: pointer; }
  .special-treatment-dialog .search-wrap .tc-15-search .tc-15-btn.search {
    padding: 0; }

.special-treatment-dialog .form-list {
  display: block; }

.special-treatment-dialog .form-list .form-list-item, .special-treatment-dialog .form-list > li {
  display: block; }

.special-treatment-dialog .tc-15-dropdown-btn-style {
  width: 640px; }
  .special-treatment-dialog .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    max-width: 640px; }

.special-treatment-dialog .button-after > .form-input {
  position: relative; }
  .special-treatment-dialog .button-after > .form-input .button-after-text2 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 650px;
    min-width: 30px;
    color: #006eff; }
    .special-treatment-dialog .button-after > .form-input .button-after-text2 span {
      color: #000000; }
  .special-treatment-dialog .button-after > .form-input .button-after-text3 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 680px;
    min-width: 60px;
    color: #006eff; }
    .special-treatment-dialog .button-after > .form-input .button-after-text3 span {
      color: #000000; }
  .special-treatment-dialog .button-after > .form-input .is-error ~ .button-after-text2 {
    left: 225px; }
  .special-treatment-dialog .button-after > .form-input .is-error ~ .button-after-text3 {
    left: 255px; }
  .special-treatment-dialog .button-after > .form-input .is-error ~ .rc-upload .button-after-text2 {
    left: 225px; }
