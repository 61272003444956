.screenActiveManage-lottery-btn {
  z-index: 1;
  position: absolute;
  top: 55px;
  background-color: rgba(255, 255, 255, 0.3);
  right: 20px;
  text-align: center;
  color: #fff;
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 50px 0 0 50px;
  width: 110px; }

.screenActiveManage-lottery.fullscreen {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0; }

.screenActiveManage-lottery {
  background: #ffffff;
  min-width: 1400px;
  margin: 20px auto;
  padding: 20px 0px;
  position: relative;
  height: calc(100vh - 90px);
  min-height: 700px; }
  .screenActiveManage-lottery * {
    position: relative;
    color: #fff;
    -webkit-user-select: none;
    user-select: none; }
  .screenActiveManage-lottery .lottery-top {
    margin: auto;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center; }
  .screenActiveManage-lottery .lottery-btn {
    cursor: pointer;
    position: absolute;
    top: 105px;
    background-color: rgba(255, 255, 255, 0.3);
    right: 0;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 50px 0 0 50px;
    width: 110px; }
  .screenActiveManage-lottery .lottery-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .screenActiveManage-lottery .lottery-left {
    scrollbar-color: #F1B541 #ffef65;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    width: 300px;
    position: absolute;
    height: 80%;
    overflow: auto;
    top: 10%;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/27fefa9b2b29af38dc7ec44a15b417e7.png);
    background-size: 100% 100%; }
    .screenActiveManage-lottery .lottery-left .list {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/184b5120651f21720708ed15a368d1d3.png);
      width: 100%;
      height: 120px;
      background-size: 100% 100%;
      cursor: pointer; }
    .screenActiveManage-lottery .lottery-left .list-select {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/5d07eea6122bbd97100d9f3657562ec8.png);
      background-size: 100% 100%; }
    .screenActiveManage-lottery .lottery-left .prize-image {
      margin-left: 5px;
      width: 80px;
      height: 80px;
      margin-top: 20px;
      margin-left: 20px;
      position: relative;
      display: inline-block;
      text-align: center;
      vertical-align: text-top;
      min-width: 60px; }
      .screenActiveManage-lottery .lottery-left .prize-image img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .screenActiveManage-lottery .lottery-left .list-title {
      font-size: 18px;
      font-weight: bold;
      margin-left: 20px;
      width: calc(100% - 135px);
      margin-top: 15px; }
      .screenActiveManage-lottery .lottery-left .list-title > div {
        margin-top: 10px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
    .screenActiveManage-lottery .lottery-left .list-title-three {
      margin-top: 10px; }
      .screenActiveManage-lottery .lottery-left .list-title-three > div {
        margin-top: 5px; }
    .screenActiveManage-lottery .lottery-left .list-select .list-title > div {
      color: #FECF15; }
  .screenActiveManage-lottery .lottery-left::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  .screenActiveManage-lottery .lottery-left::-webkit-scrollbar-thumb {
    background-color: #F1B541;
    border-radius: 30px; }
  .screenActiveManage-lottery .lottery-left::-webkit-scrollbar-track {
    background-color: #ffef65;
    border-radius: 30px; }
  .screenActiveManage-lottery .lottery-center {
    width: 1110px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 29%;
    overflow: hidden;
    height: 75%; }
    .screenActiveManage-lottery .lottery-center .lottery-center-content {
      width: 1110px; }
      .screenActiveManage-lottery .lottery-center .lottery-center-content > div {
        width: 111000px; }
      .screenActiveManage-lottery .lottery-center .lottery-center-content .image-bg {
        background: url(https://mztuat-cdn.jnc.cn/front-end/a3e30c3efd198c7dfef8e2d4673695e4.png);
        background-size: 100% 100%;
        width: 433px;
        height: 350px;
        position: absolute;
        top: 0;
        left: -41.5px; }
      .screenActiveManage-lottery .lottery-center .lottery-center-content .prize-image {
        margin-left: 5px;
        width: 350px;
        height: 350px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        text-align: center;
        vertical-align: text-top;
        min-width: 350px; }
        .screenActiveManage-lottery .lottery-center .lottery-center-content .prize-image img {
          max-width: 75%;
          max-height: 75%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .screenActiveManage-lottery .lottery-center .lottery-center-content p {
        color: #FFEC87;
        font-size: 18px;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center; }
    .screenActiveManage-lottery .lottery-center .lottery-bottom {
      position: absolute;
      width: 100%;
      bottom: 15%; }
      .screenActiveManage-lottery .lottery-center .lottery-bottom > div {
        display: inline-block;
        font-size: 18px;
        color: #FFEC87; }
        .screenActiveManage-lottery .lottery-center .lottery-bottom > div .tc-15-input-text {
          height: 40px;
          border: none;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 0.5);
          color: #FFEC87;
          font-size: 18px;
          font-weight: bold; }
      .screenActiveManage-lottery .lottery-center .lottery-bottom .lottery-start {
        margin: auto;
        width: 300px;
        height: 112.5px;
        text-align: center;
        color: #8A0202;
        font-size: 45px;
        font-weight: bold;
        line-height: 100px;
        background: url(https://mztuat-cdn.jnc.cn/front-end/063c59fe9fbe129030f6acda920d0526.png);
        background-size: 100% 100%;
        cursor: pointer; }
  .screenActiveManage-lottery .lottery-right {
    text-align: center;
    position: relative;
    padding-top: 10px;
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: top; }
    .screenActiveManage-lottery .lottery-right .prize-image {
      margin-left: 5px;
      width: 200px;
      height: 200px;
      position: relative;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.3);
      text-align: center;
      vertical-align: text-top;
      min-width: 200px; }
      .screenActiveManage-lottery .lottery-right .prize-image img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

.screenActiveManage-screen {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  overflow: auto; }
  .screenActiveManage-screen .element {
    width: 125px;
    height: 125px;
    /* border: 1px solid rgba(249, 192, 94); */
    border-radius: 10px;
    text-align: center;
    cursor: default;
    transition: background-color 0.3s ease-in;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/1bb71f82ccdadaa10b69770c533908a8.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center; }
  .screenActiveManage-screen .element:hover {
    /* box-shadow: 0 0 12px rgba(249, 192, 94, 0.75);
      border: 1px solid rgba(249, 192, 94, 0.75); */ }
  .screenActiveManage-screen .element .lotteryTicket {
    text-align: center;
    width: 100%;
    font-size: 30px;
    color: #8A0202;
    font-weight: bold;
    padding: 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    vertical-align: middle; }
  .screenActiveManage-screen .change-text {
    font-size: 35px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 5px;
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .screenActiveManage-screen .back-btn {
    background: url(https://mztuat-cdn.jnc.cn/front-end/063c59fe9fbe129030f6acda920d0526.png);
    background-size: 100% 100%;
    width: 247px;
    height: 93px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 50px;
    text-align: center;
    color: #8A0202;
    font-size: 35px;
    font-weight: bold;
    line-height: 83px;
    cursor: pointer; }
  .screenActiveManage-screen .flyScreen .flycontainer {
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /*rotation can be added for additional affect
        animation: rotation 15s linear infinite;*/ }
  .screenActiveManage-screen .flyScreen .wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    -webkit-perspective: 25px;
    perspective: 25px; }
  .screenActiveManage-screen .flyScreen .hole {
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(-150px);
    transform: translateZ(-150px);
    background: transparent; }
  .screenActiveManage-screen .flyScreen .space-bottom {
    position: absolute;
    width: 500px;
    height: 500px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background: transparent;
    z-index: 2;
    -webkit-transform: rotateX(-80deg) translateZ(250px);
    transform: rotateX(-80deg) translateZ(250px); }
  .screenActiveManage-screen .flyScreen .space-top {
    position: absolute;
    width: 500px;
    height: 500px;
    background: transparent;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    -webkit-transform: rotateX(80deg) translateZ(250px);
    transform: rotateX(80deg) translateZ(250px); }
  .screenActiveManage-screen .flyScreen .space-left {
    position: absolute;
    width: 500px;
    height: 500px;
    border: solid 0px;
    border-radius: 50%;
    -webkit-transform: rotateY(-80deg) translateZ(250px);
    transform: rotateY(-80deg) translateZ(250px); }
  .screenActiveManage-screen .flyScreen .space-right {
    position: absolute;
    width: 500px;
    height: 500px;
    border: solid 0px;
    border-radius: 50%;
    -webkit-transform: rotateY(80deg) translateZ(250px);
    transform: rotateY(80deg) translateZ(250px); }
  .screenActiveManage-screen .flyScreen .star-top {
    position: absolute;
    left: 0px;
    width: 1px;
    height: 20px;
    opacity: 0;
    border-radius: 50%;
    background: #fff066;
    box-shadow: 0 0 2px 1px #fff066;
    -webkit-animation: stars 1.25s linear infinite;
    animation: stars 1.25s linear infinite; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(1) {
    top: calc(50% - -22px);
    left: calc(80% - 123px);
    -webkit-animation-delay: 4838ms;
    animation-delay: 4838ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(2) {
    top: calc(50% - 3px);
    left: calc(80% - 204px);
    -webkit-animation-delay: 2616ms;
    animation-delay: 2616ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(3) {
    top: calc(50% - -87px);
    left: calc(80% - 296px);
    -webkit-animation-delay: 2716ms;
    animation-delay: 2716ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(4) {
    top: calc(50% - 120px);
    left: calc(80% - 272px);
    -webkit-animation-delay: 1488ms;
    animation-delay: 1488ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(5) {
    top: calc(50% - -175px);
    left: calc(80% - 23px);
    -webkit-animation-delay: 2774ms;
    animation-delay: 2774ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(6) {
    top: calc(50% - 177px);
    left: calc(80% - 194px);
    -webkit-animation-delay: 3842ms;
    animation-delay: 3842ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(7) {
    top: calc(50% - 115px);
    left: calc(80% - 249px);
    -webkit-animation-delay: 4274ms;
    animation-delay: 4274ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(8) {
    top: calc(50% - -34px);
    left: calc(80% - 72px);
    -webkit-animation-delay: 4737ms;
    animation-delay: 4737ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(9) {
    top: calc(50% - 3px);
    left: calc(80% - 106px);
    -webkit-animation-delay: 1619ms;
    animation-delay: 1619ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(10) {
    top: calc(50% - 167px);
    left: calc(80% - 119px);
    -webkit-animation-delay: 3586ms;
    animation-delay: 3586ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(11) {
    top: calc(50% - -112px);
    left: calc(80% - 165px);
    -webkit-animation-delay: 754ms;
    animation-delay: 754ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(12) {
    top: calc(50% - -93px);
    left: calc(80% - 182px);
    -webkit-animation-delay: 406ms;
    animation-delay: 406ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(13) {
    top: calc(50% - -16px);
    left: calc(80% - 132px);
    -webkit-animation-delay: 4937ms;
    animation-delay: 4937ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(14) {
    top: calc(50% - 104px);
    left: calc(80% - 89px);
    -webkit-animation-delay: 2163ms;
    animation-delay: 2163ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(15) {
    top: calc(50% - -194px);
    left: calc(80% - 90px);
    -webkit-animation-delay: 2392ms;
    animation-delay: 2392ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(16) {
    top: calc(50% - -181px);
    left: calc(80% - 285px);
    -webkit-animation-delay: 2347ms;
    animation-delay: 2347ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(17) {
    top: calc(50% - -168px);
    left: calc(80% - 147px);
    -webkit-animation-delay: 4612ms;
    animation-delay: 4612ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(18) {
    top: calc(50% - 56px);
    left: calc(80% - 189px);
    -webkit-animation-delay: 2507ms;
    animation-delay: 2507ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(19) {
    top: calc(50% - -187px);
    left: calc(80% - 149px);
    -webkit-animation-delay: 3519ms;
    animation-delay: 3519ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(20) {
    top: calc(50% - 10px);
    left: calc(80% - 188px);
    -webkit-animation-delay: 208ms;
    animation-delay: 208ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(21) {
    top: calc(50% - 187px);
    left: calc(80% - 233px);
    -webkit-animation-delay: 922ms;
    animation-delay: 922ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(22) {
    top: calc(50% - 175px);
    left: calc(80% - 204px);
    -webkit-animation-delay: 3516ms;
    animation-delay: 3516ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(23) {
    top: calc(50% - 26px);
    left: calc(80% - 235px);
    -webkit-animation-delay: 1092ms;
    animation-delay: 1092ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(24) {
    top: calc(50% - 58px);
    left: calc(80% - 190px);
    -webkit-animation-delay: 3880ms;
    animation-delay: 3880ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(25) {
    top: calc(50% - 137px);
    left: calc(80% - 274px);
    -webkit-animation-delay: 3567ms;
    animation-delay: 3567ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(26) {
    top: calc(50% - -125px);
    left: calc(80% - 250px);
    -webkit-animation-delay: 4780ms;
    animation-delay: 4780ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(27) {
    top: calc(50% - 12px);
    left: calc(80% - 296px);
    -webkit-animation-delay: 4489ms;
    animation-delay: 4489ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(28) {
    top: calc(50% - 153px);
    left: calc(80% - 88px);
    -webkit-animation-delay: 3393ms;
    animation-delay: 3393ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(29) {
    top: calc(50% - -18px);
    left: calc(80% - 70px);
    -webkit-animation-delay: 4076ms;
    animation-delay: 4076ms; }
  .screenActiveManage-screen .flyScreen .star-top:nth-child(30) {
    top: calc(50% - 127px);
    left: calc(80% - 229px);
    -webkit-animation-delay: 1215ms;
    animation-delay: 1215ms; }
  .screenActiveManage-screen .flyScreen .star-bottom {
    position: absolute;
    left: 0px;
    width: 1px;
    height: 20px;
    opacity: 0;
    border-radius: 50%;
    background: #fff066;
    box-shadow: 0 0 2px 1px #fff066;
    animation: stars 1.25s linear infinite reverse; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(1) {
    top: calc(20% - 109px);
    left: calc(80% - 192px);
    -webkit-animation-delay: 698ms;
    animation-delay: 698ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(2) {
    top: calc(20% - 153px);
    left: calc(80% - 32px);
    -webkit-animation-delay: 2172ms;
    animation-delay: 2172ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(3) {
    top: calc(20% - -14px);
    left: calc(80% - 139px);
    -webkit-animation-delay: 2937ms;
    animation-delay: 2937ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(4) {
    top: calc(20% - 138px);
    left: calc(80% - 93px);
    -webkit-animation-delay: 259ms;
    animation-delay: 259ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(5) {
    top: calc(20% - -125px);
    left: calc(80% - 208px);
    -webkit-animation-delay: 476ms;
    animation-delay: 476ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(6) {
    top: calc(20% - 151px);
    left: calc(80% - 144px);
    -webkit-animation-delay: 1686ms;
    animation-delay: 1686ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(7) {
    top: calc(20% - -121px);
    left: calc(80% - 169px);
    -webkit-animation-delay: 3991ms;
    animation-delay: 3991ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(8) {
    top: calc(20% - -105px);
    left: calc(80% - 254px);
    -webkit-animation-delay: 286ms;
    animation-delay: 286ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(9) {
    top: calc(20% - 44px);
    left: calc(80% - 250px);
    -webkit-animation-delay: 3073ms;
    animation-delay: 3073ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(10) {
    top: calc(20% - 85px);
    left: calc(80% - 135px);
    -webkit-animation-delay: 2114ms;
    animation-delay: 2114ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(11) {
    top: calc(20% - -109px);
    left: calc(80% - 19px);
    -webkit-animation-delay: 3669ms;
    animation-delay: 3669ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(12) {
    top: calc(20% - 180px);
    left: calc(80% - 155px);
    -webkit-animation-delay: 1466ms;
    animation-delay: 1466ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(13) {
    top: calc(20% - 147px);
    left: calc(80% - 138px);
    -webkit-animation-delay: 4316ms;
    animation-delay: 4316ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(14) {
    top: calc(20% - 162px);
    left: calc(80% - 125px);
    -webkit-animation-delay: 3889ms;
    animation-delay: 3889ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(15) {
    top: calc(20% - 54px);
    left: calc(80% - 202px);
    -webkit-animation-delay: 256ms;
    animation-delay: 256ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(16) {
    top: calc(20% - 182px);
    left: calc(80% - 141px);
    -webkit-animation-delay: 102ms;
    animation-delay: 102ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(17) {
    top: calc(20% - -126px);
    left: calc(80% - 104px);
    -webkit-animation-delay: 4704ms;
    animation-delay: 4704ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(18) {
    top: calc(20% - -178px);
    left: calc(80% - 253px);
    -webkit-animation-delay: 125ms;
    animation-delay: 125ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(19) {
    top: calc(20% - -100px);
    left: calc(80% - 229px);
    -webkit-animation-delay: 996ms;
    animation-delay: 996ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(20) {
    top: calc(20% - 197px);
    left: calc(80% - 259px);
    -webkit-animation-delay: 1269ms;
    animation-delay: 1269ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(21) {
    top: calc(20% - -36px);
    left: calc(80% - 131px);
    -webkit-animation-delay: 2193ms;
    animation-delay: 2193ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(22) {
    top: calc(20% - 38px);
    left: calc(80% - 139px);
    -webkit-animation-delay: 3415ms;
    animation-delay: 3415ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(23) {
    top: calc(20% - -57px);
    left: calc(80% - 68px);
    -webkit-animation-delay: 506ms;
    animation-delay: 506ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(24) {
    top: calc(20% - -169px);
    left: calc(80% - 259px);
    -webkit-animation-delay: 3556ms;
    animation-delay: 3556ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(25) {
    top: calc(20% - 75px);
    left: calc(80% - 210px);
    -webkit-animation-delay: 383ms;
    animation-delay: 383ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(26) {
    top: calc(20% - 26px);
    left: calc(80% - 165px);
    -webkit-animation-delay: 707ms;
    animation-delay: 707ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(27) {
    top: calc(20% - -83px);
    left: calc(80% - 251px);
    -webkit-animation-delay: 2302ms;
    animation-delay: 2302ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(28) {
    top: calc(20% - 31px);
    left: calc(80% - 32px);
    -webkit-animation-delay: 314ms;
    animation-delay: 314ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(29) {
    top: calc(20% - -167px);
    left: calc(80% - 53px);
    -webkit-animation-delay: 4086ms;
    animation-delay: 4086ms; }
  .screenActiveManage-screen .flyScreen .star-bottom:nth-child(30) {
    top: calc(20% - -1px);
    left: calc(80% - 121px);
    -webkit-animation-delay: 2717ms;
    animation-delay: 2717ms; }
  .screenActiveManage-screen .flyScreen .star-left {
    position: absolute;
    width: 20px;
    height: 1px;
    opacity: 0;
    border-radius: 50%;
    background: #fff066;
    box-shadow: 0 0 2px 1px #fff066;
    animation: stars2 1.25s linear infinite reverse; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(1) {
    top: calc(50% - -21px);
    left: calc(100% - 181px);
    -webkit-animation-delay: 1428ms;
    animation-delay: 1428ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(2) {
    top: calc(50% - 172px);
    left: calc(100% - 25px);
    -webkit-animation-delay: 4557ms;
    animation-delay: 4557ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(3) {
    top: calc(50% - -198px);
    left: calc(100% - 183px);
    -webkit-animation-delay: 4076ms;
    animation-delay: 4076ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(4) {
    top: calc(50% - 145px);
    left: calc(100% - 29px);
    -webkit-animation-delay: 1043ms;
    animation-delay: 1043ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(5) {
    top: calc(50% - -89px);
    left: calc(100% - 79px);
    -webkit-animation-delay: 1287ms;
    animation-delay: 1287ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(6) {
    top: calc(50% - -129px);
    left: calc(100% - 156px);
    -webkit-animation-delay: 3601ms;
    animation-delay: 3601ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(7) {
    top: calc(50% - -150px);
    left: calc(100% - 162px);
    -webkit-animation-delay: 1555ms;
    animation-delay: 1555ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(8) {
    top: calc(50% - 93px);
    left: calc(100% - 17px);
    -webkit-animation-delay: 124ms;
    animation-delay: 124ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(9) {
    top: calc(50% - -85px);
    left: calc(100% - 137px);
    -webkit-animation-delay: 2076ms;
    animation-delay: 2076ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(10) {
    top: calc(50% - -165px);
    left: calc(100% - 63px);
    -webkit-animation-delay: 229ms;
    animation-delay: 229ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(11) {
    top: calc(50% - 62px);
    left: calc(100% - 57px);
    -webkit-animation-delay: 4027ms;
    animation-delay: 4027ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(12) {
    top: calc(50% - -136px);
    left: calc(100% - 144px);
    -webkit-animation-delay: 3768ms;
    animation-delay: 3768ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(13) {
    top: calc(50% - 149px);
    left: calc(100% - 110px);
    -webkit-animation-delay: 728ms;
    animation-delay: 728ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(14) {
    top: calc(50% - 132px);
    left: calc(100% - 5px);
    -webkit-animation-delay: 1256ms;
    animation-delay: 1256ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(15) {
    top: calc(50% - -23px);
    left: calc(100% - 123px);
    -webkit-animation-delay: 769ms;
    animation-delay: 769ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(16) {
    top: calc(50% - 154px);
    left: calc(100% - 127px);
    -webkit-animation-delay: 2195ms;
    animation-delay: 2195ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(17) {
    top: calc(50% - 102px);
    left: calc(100% - 27px);
    -webkit-animation-delay: 4300ms;
    animation-delay: 4300ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(18) {
    top: calc(50% - -77px);
    left: calc(100% - 13px);
    -webkit-animation-delay: 1861ms;
    animation-delay: 1861ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(19) {
    top: calc(50% - 40px);
    left: calc(100% - 195px);
    -webkit-animation-delay: 1611ms;
    animation-delay: 1611ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(20) {
    top: calc(50% - -142px);
    left: calc(100% - 104px);
    -webkit-animation-delay: 2476ms;
    animation-delay: 2476ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(21) {
    top: calc(50% - -139px);
    left: calc(100% - 199px);
    -webkit-animation-delay: 1022ms;
    animation-delay: 1022ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(22) {
    top: calc(50% - 34px);
    left: calc(100% - 10px);
    -webkit-animation-delay: 4826ms;
    animation-delay: 4826ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(23) {
    top: calc(50% - 35px);
    left: calc(100% - 97px);
    -webkit-animation-delay: 2348ms;
    animation-delay: 2348ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(24) {
    top: calc(50% - -12px);
    left: calc(100% - 65px);
    -webkit-animation-delay: 1588ms;
    animation-delay: 1588ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(25) {
    top: calc(50% - 105px);
    left: calc(100% - 81px);
    -webkit-animation-delay: 1121ms;
    animation-delay: 1121ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(26) {
    top: calc(50% - -153px);
    left: calc(100% - 11px);
    -webkit-animation-delay: 2255ms;
    animation-delay: 2255ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(27) {
    top: calc(50% - 50px);
    left: calc(100% - 149px);
    -webkit-animation-delay: 4613ms;
    animation-delay: 4613ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(28) {
    top: calc(50% - 89px);
    left: calc(100% - 140px);
    -webkit-animation-delay: 4143ms;
    animation-delay: 4143ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(29) {
    top: calc(50% - -171px);
    left: calc(100% - 48px);
    -webkit-animation-delay: 4714ms;
    animation-delay: 4714ms; }
  .screenActiveManage-screen .flyScreen .star-left:nth-child(30) {
    top: calc(50% - -62px);
    left: calc(100% - 37px);
    -webkit-animation-delay: 1157ms;
    animation-delay: 1157ms; }
  .screenActiveManage-screen .flyScreen .star-right {
    position: absolute;
    top: 0px;
    width: 20px;
    height: 1px;
    opacity: 0;
    border-radius: 50%;
    background: #fff066;
    box-shadow: 0 0 2px 1px #fff066;
    -webkit-animation: stars2 1.25s linear infinite;
    animation: stars2 1.25s linear infinite; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(1) {
    top: calc(50% - -10px);
    left: calc(90% - 39px);
    -webkit-animation-delay: 3743ms;
    animation-delay: 3743ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(2) {
    top: calc(50% - -149px);
    left: calc(90% - 101px);
    -webkit-animation-delay: 4070ms;
    animation-delay: 4070ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(3) {
    top: calc(50% - 121px);
    left: calc(90% - 53px);
    -webkit-animation-delay: 4564ms;
    animation-delay: 4564ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(4) {
    top: calc(50% - 185px);
    left: calc(90% - 256px);
    -webkit-animation-delay: 4105ms;
    animation-delay: 4105ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(5) {
    top: calc(50% - 198px);
    left: calc(90% - 214px);
    -webkit-animation-delay: 940ms;
    animation-delay: 940ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(6) {
    top: calc(50% - 13px);
    left: calc(90% - 149px);
    -webkit-animation-delay: 3510ms;
    animation-delay: 3510ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(7) {
    top: calc(50% - -141px);
    left: calc(90% - 221px);
    -webkit-animation-delay: 3433ms;
    animation-delay: 3433ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(8) {
    top: calc(50% - 138px);
    left: calc(90% - 298px);
    -webkit-animation-delay: 735ms;
    animation-delay: 735ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(9) {
    top: calc(50% - 147px);
    left: calc(90% - 155px);
    -webkit-animation-delay: 3009ms;
    animation-delay: 3009ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(10) {
    top: calc(50% - -112px);
    left: calc(90% - 235px);
    -webkit-animation-delay: 4561ms;
    animation-delay: 4561ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(11) {
    top: calc(50% - -37px);
    left: calc(90% - 258px);
    -webkit-animation-delay: 3067ms;
    animation-delay: 3067ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(12) {
    top: calc(50% - -133px);
    left: calc(90% - 26px);
    -webkit-animation-delay: 1734ms;
    animation-delay: 1734ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(13) {
    top: calc(50% - 10px);
    left: calc(90% - 160px);
    -webkit-animation-delay: 3160ms;
    animation-delay: 3160ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(14) {
    top: calc(50% - 101px);
    left: calc(90% - 46px);
    -webkit-animation-delay: 854ms;
    animation-delay: 854ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(15) {
    top: calc(50% - -141px);
    left: calc(90% - 97px);
    -webkit-animation-delay: 2447ms;
    animation-delay: 2447ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(16) {
    top: calc(50% - -7px);
    left: calc(90% - 162px);
    -webkit-animation-delay: 1185ms;
    animation-delay: 1185ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(17) {
    top: calc(50% - 191px);
    left: calc(90% - 82px);
    -webkit-animation-delay: 2801ms;
    animation-delay: 2801ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(18) {
    top: calc(50% - -186px);
    left: calc(90% - 207px);
    -webkit-animation-delay: 670ms;
    animation-delay: 670ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(19) {
    top: calc(50% - -11px);
    left: calc(90% - 61px);
    -webkit-animation-delay: 1683ms;
    animation-delay: 1683ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(20) {
    top: calc(50% - -120px);
    left: calc(90% - 91px);
    -webkit-animation-delay: 3682ms;
    animation-delay: 3682ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(21) {
    top: calc(50% - -2px);
    left: calc(90% - 44px);
    -webkit-animation-delay: 61ms;
    animation-delay: 61ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(22) {
    top: calc(50% - -51px);
    left: calc(90% - 80px);
    -webkit-animation-delay: 4173ms;
    animation-delay: 4173ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(23) {
    top: calc(50% - 15px);
    left: calc(90% - 220px);
    -webkit-animation-delay: 4630ms;
    animation-delay: 4630ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(24) {
    top: calc(50% - 29px);
    left: calc(90% - 204px);
    -webkit-animation-delay: 4624ms;
    animation-delay: 4624ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(25) {
    top: calc(50% - 101px);
    left: calc(90% - 48px);
    -webkit-animation-delay: 2090ms;
    animation-delay: 2090ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(26) {
    top: calc(50% - -138px);
    left: calc(90% - 75px);
    -webkit-animation-delay: 3050ms;
    animation-delay: 3050ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(27) {
    top: calc(50% - 59px);
    left: calc(90% - 19px);
    -webkit-animation-delay: 1236ms;
    animation-delay: 1236ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(28) {
    top: calc(50% - -98px);
    left: calc(90% - 252px);
    -webkit-animation-delay: 1573ms;
    animation-delay: 1573ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(29) {
    top: calc(50% - -160px);
    left: calc(90% - 112px);
    -webkit-animation-delay: 2353ms;
    animation-delay: 2353ms; }
  .screenActiveManage-screen .flyScreen .star-right:nth-child(30) {
    top: calc(50% - -117px);
    left: calc(90% - 287px);
    -webkit-animation-delay: 3153ms;
    animation-delay: 3153ms; }

@-webkit-keyframes stars {
  0% {
    opacity: 1;
    -webkit-transform: translatex(0px) translatey(0xp) translatez(0px);
    transform: translatex(0px) translatey(0xp) translatez(0px); }
  100% {
    opacity: 1;
    -webkit-transform: translatex(25px) translatey(250px) translatez(-1000px);
    transform: translatex(25px) translatey(250px) translatez(-1000px); } }

@keyframes stars {
  0% {
    opacity: 1;
    -webkit-transform: translatex(0px) translatey(0xp) translatez(0px);
    transform: translatex(0px) translatey(0xp) translatez(0px); }
  100% {
    opacity: 1;
    -webkit-transform: translatex(25px) translatey(250px) translatez(-1000px);
    transform: translatex(25px) translatey(250px) translatez(-1000px); } }

@-webkit-keyframes stars2 {
  0% {
    opacity: 1;
    -webkit-transform: translatex(0px) translatey(0xp) translatez(0px);
    transform: translatex(0px) translatey(0xp) translatez(0px); }
  100% {
    opacity: 1;
    -webkit-transform: translatex(-250px) translatey(25px) translatez(-1000px);
    transform: translatex(-250px) translatey(25px) translatez(-1000px); } }

@keyframes stars2 {
  0% {
    opacity: 1;
    -webkit-transform: translatex(0px) translatey(0xp) translatez(0px);
    transform: translatex(0px) translatey(0xp) translatez(0px); }
  100% {
    opacity: 1;
    -webkit-transform: translatex(-250px) translatey(25px) translatez(-1000px);
    transform: translatex(-250px) translatey(25px) translatez(-1000px); } }

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.screenActiveManage-modal {
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .screenActiveManage-modal * {
    color: #000; }
  .screenActiveManage-modal > div {
    width: 596px;
    height: 511px;
    background: url(https://mztuat-cdn.jnc.cn/front-end/6c5498ac4a5947b869056a0e38421ce4.png);
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; }
  .screenActiveManage-modal .record-title {
    background: url(https://mztuat-cdn.jnc.cn/front-end/e0f9cb42602ee60f5996ae41a93cda10.png);
    background-size: 100% 100%;
    width: 487px;
    height: 97px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -40px; }
  .screenActiveManage-modal .screen-content {
    margin: 0 20px;
    margin-top: 70px; }
    .screenActiveManage-modal .screen-content .content-th > div {
      text-align: center;
      width: 25%;
      display: inline-block;
      color: #D45255;
      font-weight: bold;
      font-size: 20px; }
    .screenActiveManage-modal .screen-content .content-td > div {
      margin-top: 10px;
      text-align: center;
      width: 25%;
      display: inline-block;
      color: #D45255;
      font-weight: bold;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .screenActiveManage-modal .screen-content .content-overflow {
      overflow: auto;
      scrollbar-color: #BE8927 #F1B541;
      scrollbar-width: thin;
      -ms-overflow-style: none;
      height: 340px; }
    .screenActiveManage-modal .screen-content .content-overflow::-webkit-scrollbar {
      width: 10px;
      height: 10px; }
    .screenActiveManage-modal .screen-content .content-overflow::-webkit-scrollbar-thumb {
      background-color: #BE8927;
      border-radius: 30px; }
    .screenActiveManage-modal .screen-content .content-overflow::-webkit-scrollbar-track {
      background-color: #F1B541;
      border-radius: 30px; }
  .screenActiveManage-modal .back-btn {
    background: url(https://mztuat-cdn.jnc.cn/front-end/063c59fe9fbe129030f6acda920d0526.png);
    background-size: 100% 100%;
    width: 247px;
    height: 93px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -44px;
    text-align: center;
    color: #8A0202;
    font-size: 35px;
    font-weight: bold;
    line-height: 83px;
    cursor: pointer; }
  .screenActiveManage-modal .dialog--open,
  .screenActiveManage-modal .dialog--close {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .screenActiveManage-modal .dialog--open {
    -webkit-animation-name: anim-open;
    animation-name: anim-open;
    -webkit-animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1); }
  .screenActiveManage-modal .dialog--open-max {
    width: 1310px;
    height: 714px;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/37c280ea7c877c405f02cd34e16c23fd.png); }
    .screenActiveManage-modal .dialog--open-max .screen-content .content-th > div {
      font-size: 60pt !important; }
    .screenActiveManage-modal .dialog--open-max .screen-content .content-td > div {
      font-size: 48pt !important; }
    .screenActiveManage-modal .dialog--open-max .dialog--open-max-body {
      padding-bottom: 69.69%;
      position: relative; }
      .screenActiveManage-modal .dialog--open-max .dialog--open-max-body > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(https://mztuat-cdn.jnc.cn/front-end/37c280ea7c877c405f02cd34e16c23fd.png);
        background-size: 100% 100%; }
  .screenActiveManage-modal .dialog--open-name-max {
    width: 1570px;
    height: 714px;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/5ae44d9ed2b38a4be24e09c3d9aea60b.png); }
  .screenActiveManage-modal .dialog--open-max {
    width: 1310px;
    height: 714px;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/37c280ea7c877c405f02cd34e16c23fd.png); }
    .screenActiveManage-modal .dialog--open-max .dialog--open-max-body {
      padding-bottom: 69.69%;
      position: relative; }
      .screenActiveManage-modal .dialog--open-max .dialog--open-max-body > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(https://mztuat-cdn.jnc.cn/front-end/37c280ea7c877c405f02cd34e16c23fd.png);
        background-size: 100% 100%; }
  .screenActiveManage-modal .dialog--close {
    -webkit-animation-name: anim-close;
    animation-name: anim-close; }

.screenActiveManage-modalok > div {
  background-image: url(https://mztuat-cdn.jnc.cn/front-end/a8f67952d74adb5a1c86816712b9804f.png);
  background-size: 100% 100%;
  width: 800px;
  height: 551px; }

.screenActiveManage-modalok .screen-content .content-th > div {
  text-align: center;
  width: 25%;
  display: inline-block;
  color: #D45255;
  font-weight: bold;
  font-size: 35px; }

.screenActiveManage-modalok .screen-content .content-td > div {
  margin-top: 10px;
  text-align: center;
  width: 25%;
  display: inline-block;
  color: #D45255;
  font-weight: bold;
  font-size: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.screenActiveManage-modalok .screen-content .content-td-name {
  width: 1516px;
  font-size: 36pt; }
  .screenActiveManage-modalok .screen-content .content-td-name * {
    color: #D45255; }
  .screenActiveManage-modalok .screen-content .content-td-name .content-td-name-part {
    width: 496px;
    margin-bottom: 30px;
    display: inline-block;
    padding-left: 20px;
    box-sizing: border-box; }
    .screenActiveManage-modalok .screen-content .content-td-name .content-td-name-part .ticket,
    .screenActiveManage-modalok .screen-content .content-td-name .content-td-name-part .name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 54px; }

.screenActiveManage-modalError > div {
  width: 432px;
  height: 370px;
  background: url(https://mztuat-cdn.jnc.cn/front-end/2716cb79bcdcf9e190937166960e1d9c.png);
  background-size: 100% 100%; }

.screenActiveManage-modalError .record-title {
  width: 464px;
  height: 113px;
  left: -15px;
  background: url(https://mztuat-cdn.jnc.cn/front-end/54a2abc2a23a8abf3da7fd5b757bad42.png);
  background-size: 100% 100%; }

.screenActiveManage-modalError .screen-content {
  width: 350px;
  text-align: center;
  position: absolute;
  min-height: 100px;
  height: 100px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #8A0202;
  font-size: 35px;
  font-weight: bold; }

.screenActiveManage-modalError2 > div {
  width: 432px;
  height: 100px;
  background: none;
  background-size: 100% 100%; }

@-webkit-keyframes anim-open {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 1); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1); } }

@keyframes anim-open {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes anim-close {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 1); } }

@keyframes anim-close {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1); } }

@media only screen and (max-height: 900px) {
  .lottery-left {
    transform: scale(0.7); }
  .lottery-center {
    top: 20% !important;
    height: 85% !important; }
  .lottery-center-content {
    transform: scale(0.7); }
  .lottery-bottom {
    transform: scale(0.7); }
  .manualLotteryButton {
    transform: scale(0.7); } }
