.codelabelhome-wrap {
  min-width: 800px;
  position: absolute;
  bottom: 20px;
  top: 0px;
  overflow: hidden;
  width: 100%; }
  .codelabelhome-wrap .search-box {
    margin-top: 20px;
    overflow: hidden; }
    .codelabelhome-wrap .search-box .left {
      float: left; }
    .codelabelhome-wrap .search-box .right {
      float: right; }
    .codelabelhome-wrap .search-box .input {
      width: 180px;
      margin-right: 10px; }
  .codelabelhome-wrap .content {
    display: flex;
    margin-top: 10px;
    margin-right: 20px;
    position: absolute;
    bottom: 20px;
    top: 50px; }
    .codelabelhome-wrap .content .left {
      min-width: 35%;
      flex-shrink: 0; }
    .codelabelhome-wrap .content .right {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      background: #fff;
      display: flex;
      flex-direction: column;
      overflow: hidden; }
      .codelabelhome-wrap .content .right .tablist-synchronizaiton {
        display: flex;
        justify-content: space-between; }
        .codelabelhome-wrap .content .right .tablist-synchronizaiton .synchronizeLabel {
          line-height: 29px; }
          .codelabelhome-wrap .content .right .tablist-synchronizaiton .synchronizeLabel .span {
            color: #006eff;
            cursor: pointer; }
      .codelabelhome-wrap .content .right .codelabel_PopEditor {
        font-size: 12px; }
        .codelabelhome-wrap .content .right .codelabel_PopEditor button {
          display: none; }
        .codelabelhome-wrap .content .right .codelabel_PopEditor .tc-15-edit-in-place {
          padding: 0px 10px;
          width: 72px;
          left: -6px; }
  .codelabelhome-wrap .mr20 {
    margin-right: 20px; }
  .codelabelhome-wrap .mt10 {
    margin-top: 10px; }
