.searchbar-box {
  margin: 20px 0 10px;
  overflow: hidden; }
  .searchbar-box .search-input-block {
    width: 180px;
    height: 30px;
    float: right; }

.btn-opration {
  color: #006eff;
  margin: 5px;
  cursor: pointer;
  position: relative; }

.table-remark .form-input div {
  height: 30px; }

.table-remark .form-input .is-error {
  margin-bottom: 10px; }
