.PublishForm .nolabel {
  display: inline-block;
  padding: 0px 5px;
  vertical-align: text-top; }
  .PublishForm .nolabel .form-input {
    padding-bottom: 0; }
  .PublishForm .nolabel .icon-valid-flag {
    display: none; }
  .PublishForm .nolabel .form-label {
    display: none; }
  .PublishForm .nolabel .form-input-help {
    margin-left: 12px; }
