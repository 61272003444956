.NucleusManage .NucleusManage-high-search {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  line-height: 30px;
  margin-right: 10px;
  font-size: 14px; }

.NucleusManage .NucleusManage-high-search-box {
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 10px 20px;
  background-color: #fff; }
  .NucleusManage .NucleusManage-high-search-box .NucleusManage-high-search-row {
    display: flex;
    flex-wrap: wrap; }

.NucleusManage .button-after-text {
  font-size: 12px; }

.NucleusManage-policy {
  display: inline-block;
  width: 85px;
  margin-right: 10px;
  font-size: 12px; }

.NucleusManage-policy-radio .tc-15-rich-radio {
  margin-bottom: 0 !important; }

.NucleusManage-policy-store {
  max-width: 300px;
  height: auto;
  word-wrap: break-word; }

.NucleusManage-store {
  padding: 15px 0;
  border-bottom: 1px solid #ddd; }
  .NucleusManage-store .tc-15-dropdown-link {
    display: block !important; }

.NucleusManage-reset {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  line-height: 30px; }

.NucleusManage-replenishment {
  border: 1px solid #ddd; }
  .NucleusManage-replenishment .NucleusManage-replenishment-row {
    border-bottom: 1px solid #ddd;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px; }
    .NucleusManage-replenishment .NucleusManage-replenishment-row .NucleusManage-replenishment-row-th {
      display: flex; }
      .NucleusManage-replenishment .NucleusManage-replenishment-row .NucleusManage-replenishment-row-th .form-input {
        padding-bottom: 10px; }
        .NucleusManage-replenishment .NucleusManage-replenishment-row .NucleusManage-replenishment-row-th .form-input ul {
          margin-bottom: 0 !important; }

.NucleusManage-policy-box .tc-15-dropdown-link {
  display: block !important; }

.import-tmverifyTerminal-dialog {
  position: relative !important; }
  .import-tmverifyTerminal-dialog .button-after {
    height: 30px;
    position: relative !important; }
  .import-tmverifyTerminal-dialog .form-list {
    position: relative !important; }
  .import-tmverifyTerminal-dialog .button-after > .form-input {
    position: relative !important; }
  .import-tmverifyTerminal-dialog .button-after > .form-input .button-after-text2 {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 100px;
    min-width: 30px;
    color: #006eff; }
