.activeMatch-container .top-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px; }
  .activeMatch-container .top-box .left,
  .activeMatch-container .top-box .right {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .activeMatch-container .top-box .right {
    display: flex;
    justify-content: flex-end; }
  .activeMatch-container .top-box .f-item {
    margin-right: 10px;
    margin-top: 10px; }
    .activeMatch-container .top-box .f-item .tc-15-calendar {
      right: 0 !important;
      left: inherit; }
    .activeMatch-container .top-box .f-item .tc-15-dropdown {
      min-width: 180px;
      margin-right: 10px; }

.activeMatch-container .blue span {
  color: #006eff;
  margin-right: 10px;
  cursor: pointer; }

.activeMatch-container .activeMatch-box {
  min-width: 1000px;
  width: 100%;
  border: 1px solid #dddddd;
  background: #fff;
  padding-top: 3px; }

.activeMatch-container .tip-text {
  height: 51px;
  line-height: 51px;
  text-align: center;
  color: #888;
  font-family: PingFang-SC-Regular;
  font-size: 12px; }

.activeMatch-container .code-info {
  font-family: PingFangSC-;
  display: flex;
  padding: 0 20px;
  padding-top: 10px;
  font-size: 12px;
  color: #333333; }
  .activeMatch-container .code-info > ul {
    flex: 1; }
    .activeMatch-container .code-info > ul li {
      padding: 10px 0; }
    .activeMatch-container .code-info > ul li > span {
      display: inline-block; }
    .activeMatch-container .code-info > ul li > span:first-child {
      width: 128px;
      color: #888888; }

.activeMatch-container .cblue {
  color: #006eff !important; }

.activeMatch-container .cursorp {
  cursor: pointer; }
