.BanquetRecord-ExecutiveAwardsToolbar .-advance {
  margin-bottom: 10px;
  padding: 20px;
  padding-bottom: 0;
  border: solid 1px #e2e2e2;
  display: flex;
  flex-wrap: wrap; }
  .BanquetRecord-ExecutiveAwardsToolbar .-advance > div {
    margin-right: 20px;
    margin-bottom: 20px; }

.BanquetOrder-WatchDialog .-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  margin-bottom: 10px; }
  .BanquetOrder-WatchDialog .-list .-list-item {
    width: 50%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px; }
    .BanquetOrder-WatchDialog .-list .-list-item > div:first-child {
      width: 6em;
      flex-shrink: 0;
      color: #888888; }
    .BanquetOrder-WatchDialog .-list .-list-item > div:last-child {
      word-break: break-all;
      padding: 0 8px; }

.BanquetOrder-WatchDialog table tr th {
  font-weight: normal;
  border: solid 1px #dddddd;
  padding: 5px; }

.BanquetOrder-WatchDialog table tr td {
  border: solid 1px #dddddd;
  padding: 5px; }
