.factory-container {
  padding: 20px auto;
  margin-top: 20px; }
  .factory-container .factory-topbar {
    min-height: 30px;
    margin-bottom: 10px;
    overflow: hidden; }
    .factory-container .factory-topbar .factory-search-input {
      width: 180px;
      float: right; }
  .factory-container .moreline-text-ellipsis {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }
  .factory-container .cursor-handle {
    color: #006EFF;
    cursor: pointer; }
  .factory-container .gray-row {
    color: #BEBEBE; }
  .factory-container .normal-row {
    color: #000; }

.btn-oprationPosition .tc-15-confirm-popout {
  left: 85px;
  top: -30px;
  border: none;
  box-shadow: none;
  padding: 30px;
  background: none; }
  .btn-oprationPosition .tc-15-confirm-popout .form-unit .form-input-help {
    margin-top: 13px;
    margin-bottom: 0;
    max-width: 394px; }
  .btn-oprationPosition .tc-15-confirm-popout .tc-15-confirm-popout-bd {
    padding: 20px; }
    .btn-oprationPosition .tc-15-confirm-popout .tc-15-confirm-popout-bd .form-list .form-label.required label:after {
      content: " ";
      color: #fff; }
  .btn-oprationPosition .tc-15-confirm-popout .tc-15-edit-in-place .tc-15-btn {
    float: left;
    /* margin-right: 24px; */
    margin: 0 5px 0 40px; }

.button-boxcertificate {
  position: relative;
  padding: 20px; }
  .button-boxcertificate .weak {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
