.is-error .wangeditorNew {
  display: inline-block; }
  .is-error .wangeditorNew .w-e-toolbar {
    border: 1px solid red !important;
    border-bottom: 1px solid #EEE !important; }
  .is-error .wangeditorNew .w-e-text-container {
    border: 1px solid red !important; }

.is-error .wangeditorNew + .icon-valid-flag {
  vertical-align: bottom !important; }

.wangeditorNew #div1 {
  font-size: 12px;
  margin-bottom: 0; }
