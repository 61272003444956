.code-application .tc-15-rich-radio {
  max-width: 717px; }

.code-application .tc-15-rich-radio .tc-15-btn {
  font-size: 14px;
  color: #474647;
  height: 35px;
  line-height: 35px; }

.code-application .tc-15-rich-radio .tc-15-btn.checked,
.code-application .tc-15-rich-radio .tc-15-btn.checked:hover {
  border-color: #1E7BDB;
  color: #1E7BDB; }
