.screenActiveManage-container2 * {
  font-size: 12px !important;
  box-sizing: border-box; }

.screenActiveManage-container2 {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px 0;
  padding-top: 17px; }
  .screenActiveManage-container2 .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0px; }
  .screenActiveManage-container2 .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .screenActiveManage-container2 .tc-15-step li.current .tc-15-step-num:before,
  .screenActiveManage-container2 .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .screenActiveManage-container2 .step2form-container .tc-15-dropdown,
  .screenActiveManage-container2 .step2form-container .tc-15-input-text,
  .screenActiveManage-container2 .step2form-container .tc-15-simulate-select,
  .screenActiveManage-container2 .step2form-container .tc-15-select {
    width: 330px; }
  .screenActiveManage-container2 .step2form-container .searchbar-box-input {
    width: 180px;
    float: right; }
  .screenActiveManage-container2 .step2form-container .step2-view {
    margin-bottom: 20px;
    height: 100%; }
    .screenActiveManage-container2 .step2form-container .step2-view .tc-15-table-panel {
      border: 1px solid #ddd;
      width: calc(100% - 40px);
      max-width: inherit;
      border-bottom: none; }
    .screenActiveManage-container2 .step2form-container .step2-view .tc-15-page {
      font-size: 0 !important; }
      .screenActiveManage-container2 .step2form-container .step2-view .tc-15-page .tc-15-page-select {
        font-size: 0 !important; }
  .screenActiveManage-container2 .step2form-container .btnBar {
    margin-left: 20px;
    margin-right: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px; }
  .screenActiveManage-container2 .mb10 {
    margin-bottom: 10px; }
  .screenActiveManage-container2 .mb20 {
    margin-bottom: 20px; }
  .screenActiveManage-container2 .ml10 {
    margin-left: 10px; }
  .screenActiveManage-container2 .ml20 {
    margin-left: 20px; }
  .screenActiveManage-container2 .mr10 {
    margin-right: 10px; }
  .screenActiveManage-container2 .mr20 {
    margin-right: 20px; }
  .screenActiveManage-container2 .flr {
    float: right; }
  .screenActiveManage-container2 .valignm {
    vertical-align: middle; }
  .screenActiveManage-container2 .w100 {
    width: 100% !important; }
  .screenActiveManage-container2 .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .screenActiveManage-container2 .cblue {
    color: #006eff !important; }
  .screenActiveManage-container2 .cursorp {
    cursor: pointer; }
  .screenActiveManage-container2 .dinline b.icon-valid-flag {
    display: none; }
  .screenActiveManage-container2 .dinline li {
    vertical-align: top;
    display: inline-block;
    min-height: 50px; }
    .screenActiveManage-container2 .dinline li > .form-label {
      display: none; }
    .screenActiveManage-container2 .dinline li .form-ctrl-label {
      margin-right: 0; }
  .screenActiveManage-container2 .dinline li + div {
    display: inline-block;
    vertical-align: top; }
    .screenActiveManage-container2 .dinline li + div > div {
      display: inline-block;
      vertical-align: top; }

.addAward-screenActive-dialog .more-label > .form-input {
  padding-bottom: 0; }
  .addAward-screenActive-dialog .more-label > .form-input .form-label label {
    padding-right: 0; }

.addAward-screenActive-dialog .more-label .tc-15-rich-radio {
  margin-bottom: 0; }

.addAward-screenActive-dialog .more-label .tc-15-dropdown-link {
  max-width: 180px !important; }

.addAward-screenActive-dialog .more-label .tc-input-group {
  min-width: 0 !important; }

.addAward-screenActive-dialog .box {
  padding: 0;
  border: 1px solid #ddd;
  border-bottom: none;
  width: 500px; }
  .addAward-screenActive-dialog .box .box-head,
  .addAward-screenActive-dialog .box .box-body {
    border-bottom: 1px solid #ddd;
    position: relative; }
    .addAward-screenActive-dialog .box .box-head > div,
    .addAward-screenActive-dialog .box .box-body > div {
      padding: 0 10px;
      width: 28%;
      margin-top: 10px;
      vertical-align: middle;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .addAward-screenActive-dialog .box .box-head > div:last-child,
    .addAward-screenActive-dialog .box .box-body > div:last-child {
      width: 15%;
      margin-top: 16px; }
  .addAward-screenActive-dialog .box .box-bodyLast {
    line-height: 48px;
    margin-top: 0; }
    .addAward-screenActive-dialog .box .box-bodyLast > div:last-child {
      margin-top: 0; }
  .addAward-screenActive-dialog .box .box-head2,
  .addAward-screenActive-dialog .box .box-body2 {
    display: flex;
    justify-content: space-between; }
    .addAward-screenActive-dialog .box .box-head2 > div,
    .addAward-screenActive-dialog .box .box-body2 > div {
      flex: 1; }
  .addAward-screenActive-dialog .box .box-head > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    min-height: 40px; }
  .addAward-screenActive-dialog .box .box-body:hover {
    background-color: #f7f7f7; }
  .addAward-screenActive-dialog .box .box-body > div {
    font-weight: normal; }
  .addAward-screenActive-dialog .box .form-unit ~ .form-input-help {
    line-height: 14px;
    color: #888;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 10px; }
  .addAward-screenActive-dialog .box .is-error .form-ctrl-label,
  .addAward-screenActive-dialog .box .is-error .form-input-help {
    line-height: 14px;
    margin-bottom: 0px; }
  .addAward-screenActive-dialog .box .form-input,
  .addAward-screenActive-dialog .box .form-label,
  .addAward-screenActive-dialog .box .form-output {
    padding-bottom: 0; }
  .addAward-screenActive-dialog .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 130px; }
  .addAward-screenActive-dialog .box .icon-valid-flag {
    display: none; }
  .addAward-screenActive-dialog .box .tc-input-group {
    line-height: 28px; }

.addAward-screenActive-dialog .cblue {
  color: #006eff !important; }

.addAward-screenActive-dialog .cursorp {
  cursor: pointer; }

.screenActiveManage-view2 * {
  font-size: 12px; }

.screenActiveManage-view2 .outside {
  background: white;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 20;
  padding: 20px 0; }

.screenActiveManage-view2 .tc-15-page {
  font-size: 0 !important; }
  .screenActiveManage-view2 .tc-15-page .tc-15-page-select {
    font-size: 0 !important; }

.screenActiveManage-view2 .tc-15-table-panel {
  border: 1px solid #ddd;
  width: calc(100% - 40px);
  max-width: inherit;
  border-bottom: none; }
