.prize-form-box {
  background: #ffffff;
  min-width: 650px;
  margin: 20px auto;
  padding: 20px; }
  .prize-form-box .form-unit .tc-15-input-text {
    width: 330px; }
  .prize-form-box .tc-15-select {
    width: 330px; }
  .prize-form-box .tc-15-select[disabled] {
    background: #f2f2f2; }
  .prize-form-box .form-label label {
    width: 100px; }
  .prize-form-box .extra-text {
    color: #888888;
    line-height: 18px;
    margin-top: 5px;
    display: inline-block; }
  .prize-form-box .button-after > .form-input {
    position: relative; }
    .prize-form-box .button-after > .form-input .tc-15-select {
      width: 330px; }
    .prize-form-box .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .prize-form-box .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .prize-form-box .button-after > .form-input .button-after-text2:nth-of-type(2) {
      left: 375px; }
    .prize-form-box .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
  .prize-form-box .amount-input .tc-input-group {
    width: 100px;
    min-width: 100px; }
    .prize-form-box .amount-input .tc-input-group .tc-15-input-text {
      width: 70px;
      min-width: 70px; }
    .prize-form-box .amount-input .tc-input-group .tc-input-group-addon:last-child {
      width: 30px; }
  .prize-form-box .inline-block1 {
    display: inline-block;
    vertical-align: text-top; }
    .prize-form-box .inline-block1 .form-label {
      padding-bottom: 5px; }
    .prize-form-box .inline-block1 .form-input {
      padding-bottom: 10px;
      vertical-align: middle; }
  .prize-form-box .inline-block2 {
    display: inline-block;
    vertical-align: text-top; }
    .prize-form-box .inline-block2 .form-label {
      display: none; }
    .prize-form-box .inline-block2 .form-input {
      vertical-align: middle;
      position: relative; }
      .prize-form-box .inline-block2 .form-input input {
        width: 150px; }
    .prize-form-box .inline-block2 .button-after-text3 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 160px;
      min-width: 60px;
      color: #006eff; }
      .prize-form-box .inline-block2 .button-after-text3 span {
        color: #000000; }
    .prize-form-box .inline-block2 .button-after-text3:nth-of-type(2) {
      left: 205px; }
    .prize-form-box .inline-block2 .is-error ~ .button-after-text3 {
      left: 195px; }
  .prize-form-box .inline-block5 {
    display: inline-block;
    vertical-align: text-top;
    position: relative; }
    .prize-form-box .inline-block5 .tc-15-switch-input {
      width: 0; }
  .prize-form-box .btn-seeGuide {
    color: #006EFF;
    position: absolute;
    width: 60px;
    top: 6px;
    left: 150px; }
  .prize-form-box .btn-brandRedpackConfig {
    color: #006EFF;
    position: absolute;
    width: 35px;
    top: 8px;
    left: 445px; }
  .prize-form-box .btn-brandRedpackConfig1 {
    color: #006EFF;
    position: absolute;
    width: 35px;
    top: 8px;
    left: 480px; }
  .prize-form-box .is-error ~ .btn-brandRedpackConfig {
    left: 470px; }
  .prize-form-box .button-after > .form-input {
    position: relative; }
  .prize-form-box .point-input .tc-input-group {
    width: 120px;
    min-width: 120px; }
    .prize-form-box .point-input .tc-input-group .tc-15-input-text {
      width: 80px;
      min-width: 80px; }
    .prize-form-box .point-input .tc-input-group .tc-input-group-addon:last-child {
      width: 40px; }
  .prize-form-box .base-box .prize-type .tc-15-rich-radio {
    min-width: 600px; }
    .prize-form-box .base-box .prize-type .tc-15-rich-radio .tc-15-btn {
      width: 100px; }
  .prize-form-box .base-box .button-after .base-bubble {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 335px;
    min-width: 30px;
    color: #006eff; }
    .prize-form-box .base-box .button-after .base-bubble span {
      color: #000000; }
    .prize-form-box .base-box .button-after .base-bubble i.icon-what {
      margin: 0 0 3px 4px; }
    .prize-form-box .base-box .button-after .base-bubble .bubble-inner {
      background: #000;
      border: 1px solid #DDDDDD;
      box-shadow: 1px 1px 4px 0 rgba(187, 187, 187, 0.5); }
    .prize-form-box .base-box .button-after .base-bubble .tc-15-bubble-bottom .tc-15-bubble-inner:after {
      border-top-color: #000; }
    .prize-form-box .base-box .button-after .base-bubble .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
      background: #000; }
      .prize-form-box .base-box .button-after .base-bubble .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
        border-right-color: #000; }
    .prize-form-box .base-box .button-after .base-bubble .tc-15-bubble-inner {
      color: #FFF; }
  .prize-form-box .base-box .button-after .form-input .is-error .icon-valid-flag {
    display: none !important; }
  .prize-form-box .base-box .prize-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: inline-block;
    background: #F2F2F2;
    border: 1px solid #D9D9D9;
    text-align: center;
    vertical-align: middle; }
    .prize-form-box .base-box .prize-image img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .prize-form-box .base-box .prize-image .n-loading-icon {
      position: relative;
      margin-top: 30px;
      z-index: 10; }
    .prize-form-box .base-box .prize-image span {
      width: 150px;
      color: #666666;
      display: inline-block;
      position: absolute;
      left: 90px;
      top: -6px; }
  .prize-form-box .base-box .prize-image-upload {
    display: inline-block;
    margin-left: 10px;
    vertical-align: bottom; }
  .prize-form-box .redpacket-box .form-list {
    position: relative; }
  .prize-form-box .redpacket-box .inline-block3 .form-input {
    padding-top: 3px; }
    .prize-form-box .redpacket-box .inline-block3 .form-input .form-unit {
      padding-bottom: 5px; }
  .prize-form-box .redpacket-box .inline-block4 .form-input {
    padding-top: 9px; }
  .prize-form-box .redpacket-box .randomPrecision .tc-15-select {
    width: 100px;
    min-width: 100px; }
  .prize-form-box .redpacket-box .randomAmountBegin {
    display: inline-block; }
    .prize-form-box .redpacket-box .randomAmountBegin .icon-valid-flag {
      display: none; }
    .prize-form-box .redpacket-box .randomAmountBegin .form-input {
      padding-bottom: 0; }
  .prize-form-box .redpacket-box .randomAmountEnd {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 214px; }
    .prize-form-box .redpacket-box .randomAmountEnd .form-label label {
      width: 25px; }
  .prize-form-box .redpacket-box .randomEndError .tc-15-input-text {
    border: 1px solid #e1504a;
    color: #e1504a; }
  .prize-form-box .changeTransfer-box .form-list {
    position: relative; }
  .prize-form-box .changeTransfer-box .inline-block3 .form-input {
    padding-top: 3px; }
    .prize-form-box .changeTransfer-box .inline-block3 .form-input .form-unit {
      padding-bottom: 5px; }
  .prize-form-box .changeTransfer-box .inline-block4 .form-input {
    padding-top: 9px; }
  .prize-form-box .changeTransfer-box .randomPrecision .tc-15-select {
    width: 100px;
    min-width: 100px; }
  .prize-form-box .changeTransfer-box .randomAmountBegin {
    display: inline-block; }
    .prize-form-box .changeTransfer-box .randomAmountBegin .icon-valid-flag {
      display: none; }
    .prize-form-box .changeTransfer-box .randomAmountBegin .form-input {
      padding-bottom: 0; }
  .prize-form-box .changeTransfer-box .randomAmountEnd {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 214px; }
    .prize-form-box .changeTransfer-box .randomAmountEnd .form-label label {
      width: 25px; }
  .prize-form-box .changeTransfer-box .randomEndError .tc-15-input-text {
    border: 1px solid #e1504a;
    color: #e1504a; }
  .prize-form-box .changeTransfer-box .button-after .base-bubble {
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    top: 0;
    left: 110px;
    min-width: 30px;
    color: #006eff; }
    .prize-form-box .changeTransfer-box .button-after .base-bubble span {
      color: #000000; }
    .prize-form-box .changeTransfer-box .button-after .base-bubble i.icon-what {
      margin: 0 0 3px 4px; }
    .prize-form-box .changeTransfer-box .button-after .base-bubble .bubble-inner {
      background: #000;
      border: 1px solid #DDDDDD;
      box-shadow: 1px 1px 4px 0 rgba(187, 187, 187, 0.5); }
    .prize-form-box .changeTransfer-box .button-after .base-bubble .tc-15-bubble-bottom .tc-15-bubble-inner:after {
      border-top-color: #000; }
    .prize-form-box .changeTransfer-box .button-after .base-bubble .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
      background: #000; }
      .prize-form-box .changeTransfer-box .button-after .base-bubble .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
        border-right-color: #000; }
    .prize-form-box .changeTransfer-box .button-after .base-bubble .tc-15-bubble-inner {
      color: #FFF; }
  .prize-form-box .changeTransfer-box .button-after .form-input .is-error .icon-valid-flag {
    display: none !important; }
  .prize-form-box .point-box .form-list {
    position: relative; }
  .prize-form-box .point-box .inline-block3 .form-input {
    padding-top: 3px; }
    .prize-form-box .point-box .inline-block3 .form-input .form-unit {
      padding-bottom: 5px; }
  .prize-form-box .point-box .inline-block4 .form-input {
    padding-top: 9px; }
  .prize-form-box .point-box .randomPrecision .tc-15-select {
    width: 100px;
    min-width: 100px; }
  .prize-form-box .point-box .randomPointBegin {
    display: inline-block; }
    .prize-form-box .point-box .randomPointBegin .icon-valid-flag {
      display: none; }
    .prize-form-box .point-box .randomPointBegin .form-input {
      padding-bottom: 0; }
  .prize-form-box .point-box .randomPointEnd {
    display: inline-block;
    vertical-align: top;
    margin-left: -80px; }
    .prize-form-box .point-box .randomPointEnd .form-label label {
      width: 25px; }
  .prize-form-box .point-box .randomEndError .tc-15-input-text {
    border: 1px solid #e1504a; }
  .prize-form-box .coupon-box .prizeCoupon-surplusNum {
    padding-left: 10px;
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    color: #bbb;
    width: 330px;
    height: 30px;
    display: inline-block; }
  .prize-form-box .register-box {
    margin-top: -10px; }
    .prize-form-box .register-box .nolabel {
      display: inline-block;
      vertical-align: text-top; }
      .prize-form-box .register-box .nolabel .form-input {
        padding-bottom: 0; }
      .prize-form-box .register-box .nolabel .icon-valid-flag {
        display: none; }
      .prize-form-box .register-box .nolabel .form-label {
        display: none; }
    .prize-form-box .register-box .form-list {
      margin-bottom: 0; }
    .prize-form-box .register-box .check-item {
      display: inline-block;
      vertical-align: top; }
      .prize-form-box .register-box .check-item .form-input {
        width: 90px;
        line-height: 30px;
        padding-bottom: 0px; }
        .prize-form-box .register-box .check-item .form-input .form-unit {
          display: inline-block;
          vertical-align: top; }
    .prize-form-box .register-box .form-label {
      padding-bottom: 0; }
    .prize-form-box .register-box .check-item:not(:first-child) .form-label label {
      width: 0px;
      padding-right: 0; }
    .prize-form-box .register-box .check-item:nth-of-type(6) .form-label {
      width: 100px;
      padding-right: 20px; }
    .prize-form-box .register-box .check-item:nth-of-type(1) .form-input,
    .prize-form-box .register-box .check-item:nth-of-type(5) .form-input,
    .prize-form-box .register-box .check-item:nth-of-type(6) .form-input {
      width: 66px; }
    .prize-form-box .register-box .check-item:nth-of-type(4) .form-input,
    .prize-form-box .register-box .check-item:nth-of-type(9) .form-input {
      width: 91px; }
    .prize-form-box .register-box .info-register {
      font-size: 12px;
      margin-left: 100px;
      display: block;
      margin-bottom: 20px;
      margin-top: 0px; }
    .prize-form-box .register-box .tc-15-dropdown-link {
      width: 100px; }
  .prize-form-box .register-button {
    position: absolute;
    top: 0;
    left: 485px;
    line-height: 30px;
    color: #006eff; }
  .prize-form-box .verification {
    margin-top: -5px; }
    .prize-form-box .verification .form-input {
      padding-bottom: 0px; }
      .prize-form-box .verification .form-input .tc-15-rich-radio {
        margin-left: -180px; }

.button-box .btn-submit {
  margin-right: 10px; }

.create-prize-button-box {
  border-top: 1px solid #ddd;
  padding-top: 20px; }

.prize-form-box.prize-form-eighty .form-label label {
  width: 80px; }

.prize-form-box.prize-form-eighty .register-box .check-item:nth-of-type(6) .form-label {
  width: 80px !important;
  padding-right: 20px; }

.prize-form-box.prize-form-eighty .register-box .info-register {
  margin-left: 80px; }

.prize-form-box.prize-form-eighty .register-box .register-button {
  left: 485px; }
