.event-templates {
  width: 486px; }
  .event-templates .default-text {
    max-width: 330px; }
  .event-templates .checkbox-after .form-unit {
    display: inline-block;
    vertical-align: middle; }

.set-frequency {
  width: 540px; }
  .set-frequency .item {
    color: #888888;
    padding-right: 10px;
    display: inline-table; }
  .set-frequency input,
  .set-frequency input.tc-15-input-text {
    width: 60px; }
  .set-frequency .form-list .form-label .label,
  .set-frequency .form-list .form-label label {
    padding-right: 10px; }
  .set-frequency .tc-15-rich-radio {
    margin-bottom: 2px;
    color: #888888; }
    .set-frequency .tc-15-rich-radio .form-ctrl-label {
      margin-right: 5px; }
