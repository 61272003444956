.MinProgramServiceMedia .container {
  margin: 0; }

.MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url {
  height: 60px;
  position: relative; }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .MinProgramServiceMedia .tc-15-table-panel .tc-15-table-box td > div.media-url .media {
    height: 100%;
    max-width: 100px; }

.CodingMedia {
  width: 520px; }
  .CodingMedia .form-list {
    background: #fff; }
  .CodingMedia .tc-15-simulate-select,
  .CodingMedia .tc-15-dropdown {
    width: 330px; }
  .CodingMedia .amount-input-awardNumber {
    display: block;
    margin-top: -40px;
    margin-left: 120px; }
  .CodingMedia .upload {
    width: 330px;
    display: inline-block;
    font-size: 12px;
    color: #666; }
    .CodingMedia .upload .block {
      width: 100%;
      height: 100%;
      margin-bottom: 5px; }
      .CodingMedia .upload .block .prize-image {
        display: flex; }
        .CodingMedia .upload .block .prize-image .prize-image1 {
          width: 80px;
          height: 80px;
          background: #F2F2F2;
          margin-right: 5px; }
          .CodingMedia .upload .block .prize-image .prize-image1 img {
            width: 100%;
            height: 100%; }
      .CodingMedia .upload .block .hint {
        padding-bottom: 16px; }
      .CodingMedia .upload .block .hint1 {
        padding-bottom: 33px; }
    .CodingMedia .upload img,
    .CodingMedia .upload video {
      width: 80px;
      height: 80px; }

.codingRegister {
  position: relative; }
  .codingRegister .delayMinutes-input {
    position: absolute;
    top: 48%;
    left: 426px; }

.specify-label .form-label label {
  width: 92px; }

.specify-label .tc-15-rich-radio .form-ctrl-label {
  display: block;
  height: 40px;
  line-height: 40px; }
