.LinkDetails-container {
  padding-top: 20px; }
  .LinkDetails-container .head {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px; }
    .LinkDetails-container .head .date-dropdown {
      margin-left: 10px; }
  .LinkDetails-container .datatable-box {
    width: 100%;
    overflow: auto;
    background: #fff;
    text-align: center; }
    .LinkDetails-container .datatable-box .tc-15-table-fixed-head {
      border: none; }
    .LinkDetails-container .datatable-box .tc-15-table-box tr {
      border-bottom: 1px solid #ddd; }
