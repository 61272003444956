.new-codelabel-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px; }
  .new-codelabel-wrap .width90 {
    width: 90px; }
  .new-codelabel-wrap .scroll {
    overflow: auto;
    margin-bottom: 20px; }
  .new-codelabel-wrap .tc-15-tablist {
    margin-bottom: 10px !important; }
  .new-codelabel-wrap .mb10 {
    margin-bottom: 10px; }
  .new-codelabel-wrap .label-operation .label-btn {
    color: #006eff;
    margin-right: 10px;
    cursor: pointer; }
  .new-codelabel-wrap .label-name-hover:hover {
    color: #006eff;
    cursor: pointer; }
  .new-codelabel-wrap .codelabel-field-name-box {
    position: relative; }
    .new-codelabel-wrap .codelabel-field-name-box span {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer; }
    .new-codelabel-wrap .codelabel-field-name-box .codelabel-icon {
      width: 18px;
      margin-left: 5px;
      cursor: pointer; }
  .new-codelabel-wrap .calculate-label-history {
    cursor: pointer; }
    .new-codelabel-wrap .calculate-label-history:hover {
      color: #006eff; }
  .new-codelabel-wrap .children-label-box span {
    color: #006eff;
    margin-right: 10px;
    cursor: pointer; }

.label-field-edit-modal .label-wrap .l-box {
  display: flex; }
  .label-field-edit-modal .label-wrap .l-box .name {
    font-size: 12px;
    color: #888;
    flex-basis: 50px;
    flex-shrink: 0;
    margin-right: 20px; }
  .label-field-edit-modal .label-wrap .l-box .content {
    flex-grow: 1; }
  .label-field-edit-modal .label-wrap .l-box .mr10 {
    margin-right: 10px; }
  .label-field-edit-modal .label-wrap .l-box .ml10 {
    margin-left: 10px; }

.label-field-edit-modal .label-inp {
  width: 180px; }

.label-field-edit-modal .labelfield-name {
  width: 370px;
  margin-right: 10px; }

.label-field-edit-modal .labelfield-code {
  width: 180px; }

.label-field-edit-modal .label-field-del {
  color: #006eff;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer; }

.label-field-edit-modal .mt10 {
  margin-top: 10px; }

.label-field-edit-modal .bottom-add {
  margin-left: 70px;
  color: #006eff;
  cursor: pointer; }

.label-field-edit-modal .labelfield-wrap {
  margin-bottom: 10px; }

.codelabel-del-modal-tips {
  color: #000;
  font-size: 12px; }
