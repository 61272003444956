.modify-template.step1 .section {
  display: flex; }
  .modify-template.step1 .section .cover {
    padding: 0 45px 0 24px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .modify-template.step1 .section .cover img {
      width: 133px;
      margin-bottom: 20px; }
    .modify-template.step1 .section .cover svg {
      vertical-align: middle;
      margin-left: 4px; }
    .modify-template.step1 .section .cover span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-weight: bold; }
      .modify-template.step1 .section .cover span.prev {
        left: 0; }
      .modify-template.step1 .section .cover span.next {
        right: 20px; }
  .modify-template.step1 .section .process {
    border: 1px solid #dddddd;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    max-width: 800px; }
    .modify-template.step1 .section .process span {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #40a9ff;
      cursor: pointer; }
    .modify-template.step1 .section .process img {
      width: 100%; }
