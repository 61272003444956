.MinTemplate .tc-15-table-box .template-status {
  display: flex; }
  .MinTemplate .tc-15-table-box .template-status span {
    color: #D0021B;
    padding-left: 20px; }

.SetEntrance {
  width: 465px; }
  .SetEntrance .tc-15-rich-dialog-ft {
    text-align: center; }
  .SetEntrance .qrcode {
    text-align: center;
    padding-top: 20px; }
    .SetEntrance .qrcode img {
      width: 147px;
      height: 147px; }
