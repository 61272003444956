.dealergroup-container {
  padding: 20px auto;
  margin-top: 20px; }
  .dealergroup-container .table-thead th div span.text-overflow {
    display: block; }
  .dealergroup-container .tc-15-filtrate-btn .filtrate-icon {
    left: 24px !important; }
  .dealergroup-container .ml20 {
    margin-left: 20px; }
  .dealergroup-container .dealergroup-topbar {
    min-height: 30px;
    margin-bottom: 10px;
    overflow: hidden; }
    .dealergroup-container .dealergroup-topbar .dealergroup-search-input {
      width: 492px;
      float: right; }
  .dealergroup-container .moreline-text-ellipsis {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }
  .dealergroup-container .cursor-handle {
    color: #006EFF;
    cursor: pointer; }
  .dealergroup-container .gray-row {
    color: #BEBEBE; }
  .dealergroup-container .normal-row {
    color: #000; }
