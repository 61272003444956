.authminprogramtools {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  text-align: left;
  line-height: 18px;
  margin-bottom: 20px; }
  .authminprogramtools .content {
    padding: 20px 5px 0 20px;
    margin-top: 20px;
    background-color: #fff;
    overflow: auto;
    color: #888888;
    border: 1px solid #E2E2E2; }
    .authminprogramtools .content .item {
      width: 436px;
      text-align: left;
      padding: 20px 20px 20px 20px;
      margin: 0 15px 20px 0;
      border: 1px solid #DDDDDD;
      float: left;
      overflow: auto; }
      .authminprogramtools .content .item .headImage {
        width: 75px;
        height: 75px;
        display: inline-block;
        vertical-align: middle;
        border: 1px solid #ddd; }
        .authminprogramtools .content .item .headImage img {
          width: 100%;
          height: 100%; }
      .authminprogramtools .content .item .authminprogramtools-content {
        width: calc(100%-85px);
        vertical-align: middle;
        display: inline-block;
        margin-left: 20px;
        color: #000; }
        .authminprogramtools .content .item .authminprogramtools-content .item-group {
          margin-bottom: 20px;
          overflow: auto; }
          .authminprogramtools .content .item .authminprogramtools-content .item-group label {
            width: 55px;
            float: left; }
          .authminprogramtools .content .item .authminprogramtools-content .item-group .item-control {
            float: left;
            font-weight: bold; }
        .authminprogramtools .content .item .authminprogramtools-content button {
          width: 90px;
          height: 30px;
          margin-right: 10px; }

.qrcode-authminprogramtools .form-label {
  width: 65px;
  text-align: left; }
  .qrcode-authminprogramtools .form-label label {
    color: #888; }

.qrcode-authminprogramtools .qrcode {
  width: 120px;
  height: 120px; }

.qrcode-authminprogramtools .form-unit .form-input-help {
  text-align: left; }

.qrcode-authminprogramtools .form-input, .qrcode-authminprogramtools .form-label, .qrcode-authminprogramtools .form-output {
  padding-bottom: 0; }
