.wechat-auth {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  margin-top: 20px;
  padding: 20px;
  font-size: 12px; }
  .wechat-auth .content .title {
    font-size: 16px;
    font-weight: bold; }
    .wechat-auth .content .title svg {
      vertical-align: bottom;
      margin-right: 10px; }
  .wechat-auth .content .body {
    padding-left: 37px; }
    .wechat-auth .content .body > strong {
      color: #333;
      font-weight: bold; }
  .wechat-auth .btn-group {
    border-top: 1px solid #DDDDDD;
    padding-top: 20px; }
    .wechat-auth .btn-group .tc-15-btn {
      margin-right: 10px; }
  .wechat-auth input,
  .wechat-auth select {
    width: 330px;
    color: #000000; }
  .wechat-auth .operationalGuide {
    border: 1px solid #EAEAEA;
    width: 330px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 117px;
    margin-bottom: 20px;
    position: relative; }
  .wechat-auth .rowGuide {
    width: 300px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .wechat-auth .circle {
    width: 20px;
    height: 20px;
    border: 2px solid #006EFF;
    border-radius: 50%;
    text-align: center;
    z-index: 2;
    position: relative; }
  .wechat-auth .blackFont {
    margin-left: 10px;
    color: #000; }
  .wechat-auth .greyFont {
    color: #888888; }
  .wechat-auth .tips {
    color: #006EFF;
    display: inline-block;
    margin-left: 30px; }
  .wechat-auth .tips1 {
    color: #006EFF;
    margin-left: 18px; }
  .wechat-auth .guideBox {
    display: flex;
    flex-direction: column; }
  .wechat-auth .greyLine1 {
    border-left: 1px solid #ccc;
    width: 0px;
    height: 42px;
    position: absolute;
    top: 40px;
    left: 24px; }
  .wechat-auth .greyLine2 {
    border-left: 1px solid #ccc;
    width: 0px;
    height: 42px;
    position: absolute;
    top: 100px;
    left: 24px; }
  .wechat-auth .freeAmountBox {
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: row; }
  .wechat-auth .tips2 {
    color: #006EFF;
    position: absolute;
    top: 47.5%;
    left: 37%; }
  .wechat-auth .inline-block5 .tc-15-switch-input {
    width: 0; }
