.CodePrintingConfig .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
  background: #000;
  color: #FFF; }
  .CodePrintingConfig .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
    border-bottom-color: #000; }

.macanshuDialog .box {
  font-size: 12px;
  padding: 0;
  border: 1px solid #ddd;
  border-bottom: none;
  width: 360px; }
  .macanshuDialog .box .box-head,
  .macanshuDialog .box .box-body {
    border-bottom: 1px solid #ddd;
    position: relative; }
    .macanshuDialog .box .box-head > div,
    .macanshuDialog .box .box-body > div {
      padding: 0 10px;
      width: 55%;
      vertical-align: middle;
      line-height: 48px;
      display: inline-block;
      min-height: 50px;
      vertical-align: top;
      color: #444;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .macanshuDialog .box .box-head > div:first-child,
    .macanshuDialog .box .box-body > div:first-child {
      width: 30%; }
    .macanshuDialog .box .box-head > div:last-child,
    .macanshuDialog .box .box-body > div:last-child {
      width: 15%; }
  .macanshuDialog .box .box-head2,
  .macanshuDialog .box .box-body2 {
    display: flex;
    justify-content: space-between; }
    .macanshuDialog .box .box-head2 > div,
    .macanshuDialog .box .box-body2 > div {
      flex: 1; }
  .macanshuDialog .box .box-head > div {
    height: 40px;
    line-height: 40px;
    color: #888;
    min-height: 40px; }
  .macanshuDialog .box .box-body:hover {
    background-color: #f7f7f7; }
  .macanshuDialog .box .box-body > div {
    font-weight: normal; }
  .macanshuDialog .box .is-error .form-ctrl-label,
  .macanshuDialog .box .is-error .form-input-help {
    line-height: 0;
    margin-bottom: 12px; }
  .macanshuDialog .box .form-input,
  .macanshuDialog .box .form-label,
  .macanshuDialog .box .form-output {
    padding-bottom: 0; }
  .macanshuDialog .box .tc-15-dropdown-btn-style .tc-15-dropdown-link {
    width: 130px; }
  .macanshuDialog .box .icon-valid-flag {
    display: none; }
  .macanshuDialog .box .tc-input-group {
    line-height: 28px; }

.mabaohuoquren {
  display: flex; }
