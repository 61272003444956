.CreateOrEdit {
  margin-top: 20px;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #E2E2E2; }
  .CreateOrEdit .title {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 20px; }
  .CreateOrEdit .extraText {
    color: #666666;
    line-height: 18px;
    margin-top: 5px; }
  .CreateOrEdit .button-after > .form-input {
    position: relative; }
    .CreateOrEdit .button-after > .form-input .tc-15-select {
      width: 330px; }
    .CreateOrEdit .button-after > .form-input .button-after-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 340px;
      min-width: 30px;
      color: #006eff; }
      .CreateOrEdit .button-after > .form-input .button-after-text2 span {
        color: #000000; }
    .CreateOrEdit .button-after > .form-input .button-after-text2:nth-of-type(2) {
      left: 375px; }
    .CreateOrEdit .button-after > .form-input .is-error ~ .button-after-text2 {
      left: 365px; }
  .CreateOrEdit .line {
    width: 100%;
    height: 1px;
    background: #DDDDDD; }
  .CreateOrEdit .button-block {
    margin-top: 20px; }

.fieldModal {
  width: 770px !important;
  min-width: 770px !important; }
