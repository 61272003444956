.digitalScreen {
  min-width: 1600px;
  background-image: url(https://mztuat-cdn.jnc.cn/front-end/843e94bc8747f0a88acf2c5458de4e92.png);
  width: 100%;
  background-size: 100%; }
  .digitalScreen .dc-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; }
  .digitalScreen .model_title {
    width: 100%;
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-weight: 600;
    position: relative;
    top: 4%;
    line-height: 50px;
    background: linear-gradient(180deg, #50fff9 0%, #0789ca 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .digitalScreen .model {
    width: 419px;
    height: 258px;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/f5aae92be23055af6df33385ca8463ac.png);
    background-size: 100%;
    position: absolute; }
    .digitalScreen .model .title {
      font-size: 12px;
      font-weight: 400;
      color: #FFF;
      position: absolute;
      top: 11.1%;
      left: 11%; }
    .digitalScreen .model .model_1_jingji {
      top: 38%;
      position: absolute;
      left: 8.3%;
      width: 165px;
      height: 92px;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/ccd26cc94cfcfe9ee74347b594aec721.png);
      cursor: pointer;
      background-size: 100% 100%; }
    .digitalScreen .model .model_1_num {
      position: absolute;
      width: 65px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      cursor: pointer; }
    .digitalScreen .model .model_1_p {
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      cursor: pointer; }
    .digitalScreen .model .model_1_zhongyao {
      width: 40px;
      height: 40px;
      left: 60%;
      top: 18%;
      position: absolute;
      background-size: 100%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/6ad819fb99c0abc898fe6245894dfd35.png);
      cursor: pointer; }
    .digitalScreen .model .model_1_ciyao {
      width: 40px;
      height: 40px;
      left: 60%;
      top: 47%;
      position: absolute;
      background-size: 100%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/5e04b145773b467dc3844e463974ebac.png);
      cursor: pointer; }
    .digitalScreen .model .model_1_tishi {
      background-size: 100%;
      width: 40px;
      height: 40px;
      left: 60%;
      top: 76%;
      position: absolute;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/3a2bc42d3af585f5e16e0e97ade85121.png);
      cursor: pointer; }
    .digitalScreen .model .model_2_table {
      height: 77%;
      color: #fff;
      position: absolute;
      width: 100%;
      top: 22.8%; }
      .digitalScreen .model .model_2_table span {
        display: inline-block;
        text-align: center;
        width: 20%;
        font-size: 12px;
        vertical-align: middle;
        color: #02BEF4;
        padding: 0 5px; }
      .digitalScreen .model .model_2_table .span2 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .digitalScreen .model .model_2_table .noblue span {
        color: #fff; }
      .digitalScreen .model .model_2_table .box3 {
        height: 85%;
        width: 100%;
        overflow: hidden; }
      .digitalScreen .model .model_2_table .border3 {
        top: 0px;
        height: 100%; }
      .digitalScreen .model .model_2_table .border3 > div {
        color: #fff;
        height: 20%; }
      .digitalScreen .model .model_2_table .border3 > div:nth-child(even) {
        background: rgba(255, 255, 255, 0.05); }
      .digitalScreen .model .model_2_table .border3:hover {
        animation-play-state: paused;
        -webkit-animation-play-state: paused; }
      .digitalScreen .model .model_2_table .qiuInside {
        display: inline-block;
        text-align: center;
        width: 20%;
        font-size: 12px;
        vertical-align: middle; }
      .digitalScreen .model .model_2_table .qiuInside div {
        width: 14px;
        height: 14px;
        margin: auto;
        border-radius: 100px; }
      .digitalScreen .model .model_2_table .qiuInsideEXIGENCE {
        background-color: #E7474B; }
      .digitalScreen .model .model_2_table .qiuInsideIMPORTANT {
        background-color: #E18236; }
      .digitalScreen .model .model_2_table .qiuInsideMINOR {
        background-color: #E3C837; }
      .digitalScreen .model .model_2_table .qiuInsideHINT {
        background-color: #539DFF; }
    .digitalScreen .model .model_3_p {
      font-weight: 400;
      font-size: 12px;
      border-bottom: 1px dashed #fff;
      left: 6.4%;
      top: 24%;
      position: absolute;
      color: #fff; }
    .digitalScreen .model .model_3_div {
      margin-left: 25px;
      margin-top: 65px; }
      .digitalScreen .model .model_3_div label {
        color: #02BEF4 !important; }
      .digitalScreen .model .model_3_div .form-ctrl-label {
        margin-right: 8px; }
    .digitalScreen .model .model_3_p1 {
      right: 5%;
      top: 12%;
      position: absolute;
      font-size: 12px;
      color: #43A8CB;
      border: 1px solid #02BEF4;
      border-radius: 5px; }
      .digitalScreen .model .model_3_p1 div {
        padding: 1px 10px;
        display: inline-block;
        cursor: pointer; }
      .digitalScreen .model .model_3_p1 div:first-child {
        border-right: 1px solid #02BEF4; }
      .digitalScreen .model .model_3_p1 .model_3_p1_select {
        color: #fff;
        background-color: #02BEF4; }
    .digitalScreen .model .model_3_p2 {
      font-size: 12px;
      left: 6.4%;
      display: inline-block;
      position: absolute;
      color: #02BEF4;
      width: 93%;
      vertical-align: middle; }
    .digitalScreen .model .model_3_p2_img_1 {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/a1ce73bb47f2f925dfc4c88773dc6a97.png); }
    .digitalScreen .model .model_3_p2_img_2 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/0331985a2f35535c008d246638f69935.png); }
    .digitalScreen .model .model_3_p2_img_3 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/a4df36a3e7e71f9e1f20af3f6c4f6f8b.png); }
    .digitalScreen .model .model_3_p2_img_4 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/e60ec7ab610c3438d0e44d2b1b49f003.png); }
    .digitalScreen .model .model_3_progress {
      margin-left: 6px;
      display: inline-block;
      height: 10px;
      background: #05497E;
      width: 38%;
      border-radius: 8px;
      vertical-align: middle; }
      .digitalScreen .model .model_3_progress span {
        color: #000;
        float: left;
        font-size: 12px;
        width: 0px;
        height: 10px;
        line-height: 10px;
        background: linear-gradient(90deg, #0eabff 0%, #01fcf5 100%);
        border-radius: 8px;
        text-align: center;
        transition: width 2s; }
    .digitalScreen .model .model_3_progress_2 {
      width: 25%; }
    .digitalScreen .model .model_3_line {
      height: 120px;
      width: 1px;
      position: absolute;
      background: #205CBC;
      left: 55%;
      top: 31%; }
    .digitalScreen .model .model_3_pan {
      width: 162px;
      height: 162px;
      left: 57%;
      top: 18%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/70d7e325fcc952f79c8511ac1fd80bf5.png);
      background-size: 100%;
      position: absolute; }
      .digitalScreen .model .model_3_pan .model_3_zhizhen {
        width: 14px;
        height: 36px;
        left: 0;
        right: 0;
        margin: auto;
        top: calc(50% - 26px);
        position: absolute;
        transition: transform 1s;
        transform: rotate(-180deg);
        transform-origin: 50% 80%;
        background-image: url(https://mztuat-cdn.jnc.cn/front-end/b5cf51f34b60d79e25d7cd8f321ffbab.png); }
  .digitalScreen .modelMap {
    position: absolute; }
  .digitalScreen .model2 {
    width: 653px;
    height: 258px;
    background-image: url(https://mztuat-cdn.jnc.cn/front-end/adbc1c52d8df8c890dac16f1260a5523.png);
    background-size: 100%;
    position: absolute; }
    .digitalScreen .model2 .model2_title {
      position: absolute;
      width: 100%;
      text-align: center;
      height: 21px;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      line-height: 17px;
      top: 3%; }

@-webkit-keyframes model2_rotate {
  0% {
    -webkit-transform: rotate(0deg); }
  20% {
    -webkit-transform: rotate(72deg); }
  40% {
    -webkit-transform: rotate(144deg); }
  60% {
    -webkit-transform: rotate(216deg); }
  80% {
    -webkit-transform: rotate(288deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes model2_rotate {
  0% {
    transform: rotate(0deg); }
  20% {
    transform: rotate(72deg); }
  40% {
    transform: rotate(144deg); }
  60% {
    transform: rotate(216deg); }
  80% {
    transform: rotate(288deg); }
  100% {
    transform: rotate(360deg); } }
    .digitalScreen .model2 .model2_bg {
      background-size: 100% 100%;
      width: 77px;
      height: 77px;
      position: absolute;
      top: 30%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/fa9a1e8f1aa11798d30e3a768fb8712d.png);
      -webkit-animation: model2_rotate 6s infinite linear;
      animation: model2_rotate 6s infinite linear; }
    .digitalScreen .model2 .model2_bg1 {
      left: 4%; }
    .digitalScreen .model2 .model2_bg2 {
      left: 30.8%; }
    .digitalScreen .model2 .model2_bg3 {
      right: 30.8%; }
    .digitalScreen .model2 .model2_bg4 {
      right: 4%; }
    .digitalScreen .model2 .model2_bg_1 {
      position: absolute;
      top: 30%;
      background-position: center;
      background-repeat: no-repeat; }
    .digitalScreen .model2 .model2_bg1_1 {
      left: 4%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/64bca4a77ab097cfa4c4cbd6a4a4b161.png); }
    .digitalScreen .model2 .model2_bg1_2 {
      left: 30.8%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/5a4f491576c28f533cf826e1c59dc50b.png); }
    .digitalScreen .model2 .model2_bg1_3 {
      right: 30.8%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/b43c42330f76d9325e6cd274c0cca293.png); }
    .digitalScreen .model2 .model2_bg1_4 {
      right: 4%;
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/0dd102919ffc356c77011a728a695217.png); }
    .digitalScreen .model2 .model2_bg_2 {
      background-size: 100% 100%;
      width: 59px;
      height: 2px;
      position: absolute;
      top: 41%; }
    .digitalScreen .model2 .model2_bg5 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/07d8fd869203e0d769236ffd9d1792c0.png);
      left: 18.5%; }
    .digitalScreen .model2 .model2_bg6 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/07d8fd869203e0d769236ffd9d1792c0.png);
      left: 0;
      right: 0;
      margin: auto; }
    .digitalScreen .model2 .model2_bg7 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/07d8fd869203e0d769236ffd9d1792c0.png);
      right: 18.5%; }
    .digitalScreen .model2 .model2_bg_3 {
      background-size: 100% 100%;
      width: 59px;
      height: 2px;
      position: absolute;
      top: 45.8%; }
    .digitalScreen .model2 .model2_bg8 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/07d8fd869203e0d769236ffd9d1792c0.png);
      left: 18.5%; }
    .digitalScreen .model2 .model2_bg9 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/07d8fd869203e0d769236ffd9d1792c0.png);
      left: 0;
      right: 0;
      margin: auto; }
    .digitalScreen .model2 .model2_bg10 {
      background-image: url(https://mztuat-cdn.jnc.cn/front-end/07d8fd869203e0d769236ffd9d1792c0.png);
      right: 18.5%; }
    .digitalScreen .model2 .model2_div {
      width: 100px;
      position: absolute;
      top: 72%;
      text-align: center;
      color: #02BEF4;
      font-size: 14px;
      font-weight: 600; }
    .digitalScreen .model2 .model2_div1 {
      left: 2.5%; }
    .digitalScreen .model2 .model2_div2 {
      left: 30%; }
    .digitalScreen .model2 .model2_div3 {
      right: 30%; }
    .digitalScreen .model2 .model2_div4 {
      right: 2.5%; }
    .digitalScreen .model2 .model2_div_2,
    .digitalScreen .model2 .model2_div_3 {
      width: 120px;
      position: absolute;
      top: 21.7%;
      text-align: center;
      color: #02BEF4;
      font-size: 12px;
      font-weight: 600; }
    .digitalScreen .model2 .model2_div_3 {
      top: 55%; }
    .digitalScreen .model2 .model2_div5,
    .digitalScreen .model2 .model2_div8 {
      left: 14.5%;
      color: #02BEF4; }
    .digitalScreen .model2 .model2_div6,
    .digitalScreen .model2 .model2_div9 {
      left: 0;
      right: 0;
      margin: auto;
      color: #02BEF4; }
    .digitalScreen .model2 .model2_div7,
    .digitalScreen .model2 .model2_div10 {
      right: 14.5%; }
  .digitalScreen .qiu {
    position: absolute;
    border-radius: 100px;
    top: 12%;
    left: 5%;
    width: 14px;
    height: 14px;
    background: linear-gradient(180deg, #3de0ec 0%, #16a1d3 100%); }

.digitalScreenP {
  padding: 1px 10px;
  display: inline-block;
  cursor: pointer;
  color: #43A8CB;
  border: 1px solid #02BEF4;
  position: absolute;
  right: 40px;
  top: 10px;
  border-radius: 5px; }

.genWarp {
  position: relative; }
  .genWarp .titleRen, .genWarp .titleGe {
    position: absolute;
    top: 20%;
    right: 29%;
    width: 16px;
    height: 20px;
    background: rgba(1, 252, 245, 0);
    z-index: 9999999; }
  .genWarp .titleGe {
    top: 30%; }

.dongchaWarp {
  position: relative; }
  .dongchaWarp .lengedTitle {
    position: absolute;
    width: 50%;
    display: flex;
    justify-content: space-between;
    bottom: 5px; }
    .dongchaWarp .lengedTitle li {
      height: 20px;
      width: 23%;
      background: rgba(0, 0, 0, 0); }
