.thirdtag-container {
  border: 1px solid #e2e2e2;
  margin: 10px auto 20px;
  background: #fff;
  padding: 10px 0; }
  .thirdtag-container .itemboxbox {
    width: 465px;
    height: 360px;
    border: 1px solid #e2e2e2;
    display: inline-block;
    margin: 0 20px 20px 0;
    position: relative; }
    .thirdtag-container .itemboxbox > p {
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 12px;
      border-bottom: 1px solid #e2e2e2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative; }
      .thirdtag-container .itemboxbox > p .on-off {
        position: absolute;
        top: 0;
        right: 10px; }
    .thirdtag-container .itemboxbox .itemboxbox_body {
      width: calc(100% - 40px);
      height: calc(100% - 120px);
      overflow: auto;
      word-break: break-all;
      word-wrap: break-word;
      border: 1px solid #ddd;
      margin: 20px; }
    .thirdtag-container .itemboxbox table {
      width: 100%;
      font-size: 12px; }
    .thirdtag-container .itemboxbox tr {
      border-bottom: 1px solid #ddd; }
    .thirdtag-container .itemboxbox td {
      padding: 10px 20px; }
    .thirdtag-container .itemboxbox .itemboxbox_footer {
      font-size: 12px;
      border-top: 1px solid #ddd;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      left: 0; }
      .thirdtag-container .itemboxbox .itemboxbox_footer > div {
        display: flex;
        justify-content: space-between;
        font-size: 12px; }
  .thirdtag-container .cblue {
    color: #006eff !important; }
  .thirdtag-container .cursorp {
    cursor: pointer; }

.item-content > div.content {
  margin-bottom: 20px; }
  .item-content > div.content:last-child {
    margin-bottom: 0; }

.item-content .cont-box {
  font-size: 12px;
  line-height: 25px; }

.footer-cont span {
  font-size: 12px; }

.footer-cont span.footer-btn {
  color: #006EFF;
  cursor: pointer; }

.cont-box {
  display: flex;
  justify-content: space-between; }

.no-data {
  background: #fff;
  border: 1px solid #ddd; }
