.blue {
  color: #1469FB;
  cursor: pointer; }

.addinput-box {
  width: 190px;
  background: #fff;
  margin-right: 10px;
  border: 1px solid #ddd;
  height: 30px; }

.opration span {
  margin-left: 2px;
  cursor: pointer; }

.opration span:first-of-type {
  margin-left: 0; }

.codedata-list .searchbar-box .tc-15-select,
.codedata-list .searchbar-box .tc-15-input-text,
.codedata-list .searchbar-box .tc-15-dropdown,
.codedata-list .searchbar-box .tc-15-btn {
  margin-right: 10px;
  height: 30px;
  line-height: 30px; }

.codedata-list .input .icon-valid-flag {
  display: none; }
