.Step0EditPageMemberSystem {
  background-color: #fff;
  margin-top: 20px;
  padding-top: 18px;
  min-width: 900px; }
  .Step0EditPageMemberSystem .tab-block {
    margin-left: 20px;
    width: calc(100% - 40px);
    margin-bottom: 20px; }
  .Step0EditPageMemberSystem .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .Step0EditPageMemberSystem .tc-15-step {
    padding-left: 20px;
    padding-bottom: 0; }
    .Step0EditPageMemberSystem .tc-15-step * {
      font-size: 12px !important; }
  .Step0EditPageMemberSystem .tc-15-step li.current .tc-15-step-num:before,
  .Step0EditPageMemberSystem .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .Step0EditPageMemberSystem .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
    background: #000;
    color: #FFF; }
    .Step0EditPageMemberSystem .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
      border-top-color: #000; }
  .Step0EditPageMemberSystem .step2-view {
    min-height: 600px;
    display: table;
    height: 100%; }
    .Step0EditPageMemberSystem .step2-view .left-view {
      padding-top: 10px;
      width: 160px;
      min-width: 160px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 2px solid #e5e5e5;
      vertical-align: top; }
      .Step0EditPageMemberSystem .step2-view .left-view .list {
        width: 158px;
        margin-bottom: 14px;
        cursor: pointer; }
        .Step0EditPageMemberSystem .step2-view .left-view .list .list-icon {
          display: inline-block;
          width: 4px;
          height: 23px;
          vertical-align: middle; }
        .Step0EditPageMemberSystem .step2-view .left-view .list .list-icon2 {
          overflow: hidden;
          margin-left: 4px;
          width: 18px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid #aaa;
          border-radius: 2px;
          color: #aaa;
          line-height: 16px;
          display: inline-block; }
        .Step0EditPageMemberSystem .step2-view .left-view .list .list-title {
          margin-left: 6px;
          line-height: 23px;
          vertical-align: middle;
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px !important;
          width: calc(100% - 34px); }
        .Step0EditPageMemberSystem .step2-view .left-view .list .list-close {
          color: #bbb; }
        .Step0EditPageMemberSystem .step2-view .left-view .list .icon-select {
          background-color: #006eff; }
        .Step0EditPageMemberSystem .step2-view .left-view .list .title-select {
          font-weight: bold;
          color: #006eff; }
    .Step0EditPageMemberSystem .step2-view .poolPager {
      position: relative;
      border-right: 2px solid #ddd; }
      .Step0EditPageMemberSystem .step2-view .poolPager * {
        font-size: auto !important; }
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-operate {
        float: inherit !important; }
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-state {
        margin-left: 10px;
        margin-top: 4px; }
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-first {
        margin-left: 10px; }
      .Step0EditPageMemberSystem .step2-view .poolPager .page-num {
        padding-left: 6px;
        padding-right: 6px;
        width: 35px !important; }
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-num {
        padding-left: 6px;
        padding-right: 6px; }
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-first,
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-pre,
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-pre + .tc-15-page-select,
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-next,
      .Step0EditPageMemberSystem .step2-view .poolPager .tc-15-page-last {
        margin-top: 10px; }
    .Step0EditPageMemberSystem .step2-view .right-view {
      position: relative;
      padding-top: 10px;
      height: 100%;
      width: 100%;
      display: table-cell;
      vertical-align: top; }
      .Step0EditPageMemberSystem .step2-view .right-view * {
        font-size: 12px !important; }
      .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit {
        color: #888;
        margin: 20px;
        margin-top: 0; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .memberSystemLabel {
          margin-top: 0px;
          margin-bottom: 20px;
          color: #000000; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .br {
          margin-bottom: 20px;
          width: 100%;
          border-top: 1px solid #ddd; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .prize-image {
          width: 80px;
          height: 80px;
          position: relative;
          display: inline-block;
          background: #F2F2F2;
          border: 1px solid #D9D9D9;
          text-align: center;
          vertical-align: middle; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .prize-image img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .prize-image .n-loading-icon {
            position: relative;
            margin-top: 30px;
            z-index: 10; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .prize-image span {
            width: 156px;
            color: #666666;
            display: inline-block;
            position: absolute;
            left: 90px;
            top: -6px; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .prize-image-upload {
          display: inline-block;
          margin-left: 10px;
          vertical-align: bottom; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .extra-text {
          color: #666666;
          line-height: 30px;
          min-width: 276px; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .ant-upload {
          vertical-align: bottom; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline {
          display: inline-block;
          float: left; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline .form-label label {
            display: none; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline .form-input-help {
            float: left;
            margin-bottom: 0; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline .icon-valid-flag {
            display: none; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline .form-ctrl-label {
            display: block;
            height: 40px;
            line-height: 40px; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline .tc-15-select,
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .inline .tc-input-group {
            min-width: 0px; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
          background: #000;
          color: #FFF; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
            border-top-color: #000; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .form-list {
          margin-bottom: 20px !important; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .formlistLast .form-list {
          margin-bottom: 0px !important; }
        .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box {
          width: 100%;
          border: 1px solid #ddd;
          border-top: none;
          line-height: 23px;
          color: #262626; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head,
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body {
            border-bottom: 1px solid #ddd; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head > div,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body > div {
              padding: 0 10px;
              width: 33%;
              vertical-align: middle;
              line-height: 48px;
              display: inline-block;
              min-height: 50px;
              vertical-align: top;
              color: #444;
              font-weight: bold;
              text-overflow: ellipsis;
              white-space: nowrap; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .form-input,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .form-label,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .form-output,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .form-input,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .form-label,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .form-output {
              display: block;
              /* vertical-align: top; */
              padding-bottom: 0px; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .icon-valid-flag,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .icon-valid-flag {
              display: none; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .tc-15-select,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .tc-15-select {
              min-width: 0; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .tc-input-group,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .tc-input-group {
              width: 100%;
              min-width: 0; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head .tc-input-group .tc-input-group-addon,
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body .tc-input-group .tc-input-group-addon {
              width: 33px; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-head > div {
            height: 40px;
            line-height: 40px;
            color: #888;
            min-height: 40px; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body:hover {
            background-color: #f7f7f7; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .wd50 > div {
            width: 50%; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body > div {
            font-weight: normal; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .box-body:last-child {
            border-bottom: none; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .form-input {
            padding-bottom: 0; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .form-input-help {
            line-height: 0;
            margin-bottom: 12px; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .tc-input-group {
            line-height: 28px; }
          .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY {
            margin-left: 10px;
            margin-right: 10px; }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY span {
              color: rgba(0, 0, 0, 0.65); }
            .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline {
              display: inline-block;
              margin-top: 20px;
              margin-left: 10px; }
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline .form-label label {
                display: none; }
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline .form-input-help {
                float: left;
                margin-bottom: 0;
                line-height: inherit !important; }
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline.inline3 .form-input-help {
                position: absolute;
                width: 200px; }
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline .icon-valid-flag {
                display: none; }
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline .form-ctrl-label {
                display: block;
                height: 40px;
                line-height: 40px; }
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline .tc-15-select,
              .Step0EditPageMemberSystem .step2-view .right-view .right-view-edit .box .boxPROBABILITY .inline .tc-input-group {
                min-width: 0px; }
      .Step0EditPageMemberSystem .step2-view .right-view .btn-group {
        margin-bottom: 20px;
        padding-left: 20px; }
    .Step0EditPageMemberSystem .step2-view .clear {
      clear: both; }
  .Step0EditPageMemberSystem .memberSystem-more-label > .form-input {
    padding-bottom: 0; }
    .Step0EditPageMemberSystem .memberSystem-more-label > .form-input .form-label label {
      padding-right: 0; }
  .Step0EditPageMemberSystem .memberSystem-more-label .tc-15-rich-radio {
    margin-bottom: 0; }
  .Step0EditPageMemberSystem .memberSystem-more-label .tc-15-dropdown-link {
    max-width: 180px !important; }
  .Step0EditPageMemberSystem .memberSystem-more-label .tc-input-group {
    min-width: 0 !important; }
  .Step0EditPageMemberSystem .ml20 {
    margin-left: 20px; }
  .Step0EditPageMemberSystem .mr20 {
    margin-right: 20px; }
  .Step0EditPageMemberSystem .cblue {
    color: #006eff !important; }
  .Step0EditPageMemberSystem .cursorp {
    cursor: pointer; }

.memberSystemRights {
  padding: 20px 0 20px; }
  .memberSystemRights .benefits-table-box {
    border: 1px solid #E2E2E2;
    background: #fff;
    padding: 20px; }
    .memberSystemRights .benefits-table-box .tc-15-table-panel {
      border: 1px solid #E2E2E2;
      border-bottom: none; }
  .memberSystemRights .searchbar-box {
    margin-top: 0; }
