.operationtagDetail-containerstep1 * {
  font-size: 12px !important;
  box-sizing: border-box; }

.operationtagDetail-containerstep1 {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px 0;
  padding-top: 17px; }
  .operationtagDetail-containerstep1 .tc-15-step li.succeed .tc-15-step-num {
    font-size: 0 !important; }
  .operationtagDetail-containerstep1 .tc-15-step li.current .tc-15-step-num:before,
  .operationtagDetail-containerstep1 .tc-15-step li.current ~ li .tc-15-step-num:before {
    font-size: 0 !important; }
  .operationtagDetail-containerstep1 .step {
    padding: 0 20px; }
  .operationtagDetail-containerstep1 .step1form-container {
    width: 100%;
    min-width: 970px; }
    .operationtagDetail-containerstep1 .step1form-container .pure-text-row {
      padding-left: 10px; }
    .operationtagDetail-containerstep1 .step1form-container .errmsg {
      color: #E1504A;
      position: absolute;
      left: 130px;
      top: 27px;
      width: 100px; }
    .operationtagDetail-containerstep1 .step1form-container b.icon-valid-flag {
      display: none; }
    .operationtagDetail-containerstep1 .step1form-container .step1-view {
      min-height: 600px;
      display: table;
      height: 100%; }
      .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view {
        padding-top: 10px;
        width: 160px;
        min-width: 160px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 2px solid #e5e5e5;
        vertical-align: top; }
        .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list {
          width: 158px;
          margin-bottom: 14px;
          cursor: pointer; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list .list-icon {
            display: inline-block;
            width: 4px;
            height: 23px;
            vertical-align: middle; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list .list-icon2 {
            overflow: hidden;
            margin-left: 4px;
            min-width: 18px;
            text-align: center;
            vertical-align: middle;
            border: 1px solid #aaa;
            border-radius: 2px;
            color: #aaa;
            line-height: 16px;
            display: inline-block;
            height: 18px;
            margin-top: 2px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list .list-title {
            margin-left: 6px;
            line-height: 23px;
            vertical-align: middle;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px !important;
            width: calc(100% - 34px); }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list .list-close {
            color: #bbb; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list .icon-select {
            background-color: #006eff; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .left-view .list .title-select {
            font-weight: bold;
            color: #006eff; }
      .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view {
        position: relative;
        padding-top: 10px;
        height: 100%;
        width: 100%;
        display: table-cell;
        vertical-align: top; }
        .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .title {
          padding-bottom: 20px;
          border-bottom: 1px solid #e5e5e5; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .title > p {
            line-height: 23px;
            padding-left: 25px;
            margin-bottom: 5px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .title .box {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 5px 20px;
            line-height: 23px;
            color: #262626; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .title .box2 {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 5px 20px;
            margin-bottom: 15px;
            line-height: 33px;
            color: #262626; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .title .box2 .box3 {
              padding: 5px 10px;
              border: 1px solid #ddd;
              display: inline-block;
              height: 26px;
              line-height: 14px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .title .box2-view {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 10px 20px;
            margin-bottom: 15px;
            line-height: 23px;
            color: #262626;
            background: #F8F8F8;
            padding-bottom: 0;
            border-radius: 2px; }
        .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal {
          padding-top: 15px;
          border-bottom: none; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box {
            padding: 0; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head,
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body {
              border-bottom: 1px solid #ddd; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head > div,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body > div {
                padding: 0 10px;
                width: 20%;
                vertical-align: middle;
                line-height: 48px;
                display: inline-block;
                min-height: 50px;
                vertical-align: top;
                color: #444;
                font-weight: bold;
                text-overflow: ellipsis;
                white-space: nowrap; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .form-input,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .form-label,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .form-output,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .form-input,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .form-label,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .form-output {
                display: block;
                /* vertical-align: top; */
                padding-bottom: 0px; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .icon-valid-flag,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .icon-valid-flag {
                display: none; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .tc-15-select,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .tc-15-select {
                min-width: 0; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .tc-input-group,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .tc-input-group {
                width: 100%;
                min-width: 0; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head .tc-input-group .tc-input-group-addon,
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body .tc-input-group .tc-input-group-addon {
                width: 33px; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-head > div {
              height: 40px;
              line-height: 40px;
              color: #888;
              min-height: 40px; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body:hover {
              background-color: #f7f7f7; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .wd25 > div {
              width: 25%; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body > div {
              font-weight: normal; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .box-body:last-child {
              border-bottom: none; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .form-input {
              padding-bottom: 0; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .form-input-help {
              line-height: 0;
              margin-bottom: 12px; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .tc-input-group {
              line-height: 28px; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY {
              margin-left: 10px;
              margin-right: 10px; }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY span {
                color: rgba(0, 0, 0, 0.65); }
              .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline {
                display: inline-block;
                float: left;
                margin-top: 20px;
                margin-left: 10px; }
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline .form-label label {
                  display: none; }
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline .form-input-help {
                  float: left;
                  margin-bottom: 0;
                  line-height: inherit !important; }
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline.inline3 .form-input-help {
                  position: absolute;
                  width: 200px; }
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline .icon-valid-flag {
                  display: none; }
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline .form-ctrl-label {
                  display: block;
                  height: 40px;
                  line-height: 40px; }
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline .tc-15-select,
                .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .box .boxPROBABILITY .inline .tc-input-group {
                  min-width: 0px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .second-title {
            padding-left: 20px;
            font-size: 14px !important;
            margin-top: 20px; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .second-title span {
              font-size: 14px !important; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .second-title .span2 {
              color: #EE6F1E;
              font-size: 24px !important; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .second-title .span3 {
              color: #EE6F1E; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .normal .second-title-new {
            border: 1px solid #ddd;
            height: 40px;
            line-height: 40px;
            color: #888;
            margin: 0 20px;
            padding: 0 10px; }
        .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate-title {
          padding-top: 10px;
          padding-left: 25px;
          margin-bottom: 15px !important; }
        .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate {
          padding-bottom: 10px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .tc-15-rich-radio {
            line-height: 25px;
            width: calc(100% - 100px);
            vertical-align: top; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .box {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 5px 20px;
            padding-top: 15px;
            line-height: 23px;
            color: #262626; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .btn-group {
            margin-bottom: 10px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .accurate-title2 {
            margin-bottom: 15px;
            padding-top: 10px;
            padding-left: 25px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .box2 {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 5px 20px;
            margin-bottom: 15px;
            line-height: 33px;
            color: #262626; }
            .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .box2 .box3 {
              padding: 5px 10px;
              border: 1px solid #ddd;
              display: inline-block;
              height: 26px;
              line-height: 14px; }
          .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .accurate .box2-view {
            margin-left: 20px;
            width: calc(100% - 40px);
            border: 1px solid #ddd;
            padding: 10px 20px;
            margin-bottom: 15px;
            line-height: 23px;
            color: #262626;
            background: #F8F8F8;
            padding-bottom: 0;
            border-radius: 2px; }
        .operationtagDetail-containerstep1 .step1form-container .step1-view .right-view .btn-group {
          margin-bottom: 20px;
          padding-left: 20px; }
    .operationtagDetail-containerstep1 .step1form-container .btnBar {
      margin-left: 20px;
      margin-right: 20px;
      border-top: 1px solid #ddd;
      padding-top: 20px; }
  .operationtagDetail-containerstep1 .mb10 {
    margin-bottom: 10px; }
  .operationtagDetail-containerstep1 .mb20 {
    margin-bottom: 20px; }
  .operationtagDetail-containerstep1 .ml10 {
    margin-left: 10px; }
  .operationtagDetail-containerstep1 .ml20 {
    margin-left: 20px; }
  .operationtagDetail-containerstep1 .mr10 {
    margin-right: 10px; }
  .operationtagDetail-containerstep1 .mr20 {
    margin-right: 20px; }
  .operationtagDetail-containerstep1 .flr {
    float: right; }
  .operationtagDetail-containerstep1 .valignm {
    vertical-align: middle; }
  .operationtagDetail-containerstep1 .w100 {
    width: 100% !important; }
  .operationtagDetail-containerstep1 .ofh {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .operationtagDetail-containerstep1 .cblue {
    color: #006eff !important; }
  .operationtagDetail-containerstep1 .cursorp {
    cursor: pointer; }
  .operationtagDetail-containerstep1 .dinline li {
    vertical-align: top;
    display: inline-block;
    min-height: 50px; }
    .operationtagDetail-containerstep1 .dinline li > .form-label {
      display: none; }
    .operationtagDetail-containerstep1 .dinline li .form-ctrl-label {
      margin-right: 0; }
  .operationtagDetail-containerstep1 .dinline li + div {
    display: inline-block;
    vertical-align: top; }
    .operationtagDetail-containerstep1 .dinline li + div > div {
      display: inline-block;
      vertical-align: top; }
