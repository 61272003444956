.presents-modify-news .form-list .form-list > div li {
  display: inline-block; }

.presents-modify-news .titleExplain {
  font-size: 14px;
  padding-bottom: 15px;
  color: black; }

.presents-modify-news .deleteLable .form-label {
  width: 0;
  height: 0; }
  .presents-modify-news .deleteLable .form-label label {
    padding: 0 !important; }

.presents-modify-news .deleteLable .tc-time-picker {
  margin: 0 10px 0 0; }

.presents-modify-news .data-radio {
  margin-left: 10px; }

.presents-modify-news .form-list .appPage .form-label {
  line-height: 30px; }

.presents-modify-news .form-list .appPage .form-input ul.form-list {
  display: flex; }

.presents-modify-news .form-list .appPage .form-input li:first-child {
  margin-right: 20px; }
  .presents-modify-news .form-list .appPage .form-input li:first-child .form-label {
    min-width: 0 !important;
    max-width: 0 !important; }

.presents-modify-news .form-list .form-list {
  background: #fff;
  margin: 0;
  padding: 0 !important;
  border: none !important; }
  .presents-modify-news .form-list .form-list li div.form-input {
    padding-bottom: 5px; }
  .presents-modify-news .form-list .form-list li div.form-label {
    padding-bottom: 5px;
    min-width: 0 !important; }
  .presents-modify-news .form-list .form-list .div-list {
    vertical-align: top; }

.presents-modify-news .form-list .form-label {
  min-width: 80px !important; }

.presents-modify-news .form-list .send-out-box .form-input {
  position: relative; }
  .presents-modify-news .form-list .send-out-box .form-input .time-box {
    position: absolute; }
    .presents-modify-news .form-list .send-out-box .form-input .time-box .form-list {
      margin: 0;
      padding: 0;
      display: flex; }
      .presents-modify-news .form-list .send-out-box .form-input .time-box .form-list .form-label label {
        padding-right: 10px; }
  .presents-modify-news .form-list .send-out-box .form-input .timing {
    left: 22px;
    top: 50px; }
  .presents-modify-news .form-list .send-out-box .form-input .timing-2 {
    min-width: 600px;
    left: 22px;
    top: 96px; }

.presents-modify-news .form-list .news-user {
  position: relative; }
  .presents-modify-news .form-list .news-user .news-user-file {
    position: absolute;
    left: 68px; }

.presents-modify-news .form-list .row > span {
  width: 80px;
  color: #888;
  padding-right: 20px;
  white-space: nowrap;
  box-sizing: border-box; }

.presents-modify-news .tc-15-rich-radio .form-ctrl-label {
  display: block;
  height: 40px;
  line-height: 40px;
  margin-right: 10px; }
  .presents-modify-news .tc-15-rich-radio .form-ctrl-label ul {
    background-color: #fff;
    display: inline;
    padding-left: 0; }
    .presents-modify-news .tc-15-rich-radio .form-ctrl-label ul li {
      display: inline; }
      .presents-modify-news .tc-15-rich-radio .form-ctrl-label ul li .form-label {
        padding-bottom: 0; }
        .presents-modify-news .tc-15-rich-radio .form-ctrl-label ul li .form-label label {
          padding-right: 10px;
          color: black;
          width: auto; }
      .presents-modify-news .tc-15-rich-radio .form-ctrl-label ul li .tc-time-picker {
        margin-left: 0; }
      .presents-modify-news .tc-15-rich-radio .form-ctrl-label ul li .form-input {
        padding-bottom: 0; }

.presents-modify-news .format {
  position: relative;
  padding: 55px 0 15px 0; }
  .presents-modify-news .format .format-box {
    width: 500px;
    height: 40px;
    border: 1px solid #E2E2E2;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0; }
    .presents-modify-news .format .format-box ul {
      display: flex; }
      .presents-modify-news .format .format-box ul li {
        text-align: center;
        line-height: 20px;
        border: 1px solid #e2e2e2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80px;
        min-width: 80px;
        padding: 0 15px;
        box-sizing: border-box;
        margin: 10px 0 0 10px;
        position: relative; }
        .presents-modify-news .format .format-box ul li .close-icon {
          width: 8px;
          height: 8px;
          position: absolute;
          right: 2px;
          top: 2px;
          cursor: pointer; }
  .presents-modify-news .format .add-screen {
    color: #2870ce;
    cursor: pointer; }

.presents-modify-news .grouping .tc-15-rich-dialog-bd {
  text-align: center; }

.presents-modify-news .grouping .tc-15-rich-dialog-ft .weak {
  margin-left: 40px; }

.presents-modify-news .btnBar {
  border-top: 1px solid #ddd;
  padding-top: 15px; }
  .presents-modify-news .btnBar .weak-button {
    margin-left: 10px; }
  .presents-modify-news .btnBar .newsAddBtn {
    position: relative;
    padding: 0 20px;
    background-color: #006eff;
    color: #fff;
    border: 1px solid #006eff;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 30px;
    display: inline-block;
    height: 30px; }

.presents-modify-news .radio-box .div-list {
  display: flex;
  height: 40px; }
  .presents-modify-news .radio-box .div-list .send-out-box .form-label {
    position: absolute;
    z-index: -1; }
  .presents-modify-news .radio-box .div-list .radio-li {
    margin-top: 5px;
    display: flex; }
    .presents-modify-news .radio-box .div-list .radio-li .deleteLable .form-label {
      padding-right: 5px; }
    .presents-modify-news .radio-box .div-list .radio-li .deleteLableToday .form-label {
      padding-left: 5px; }
    .presents-modify-news .radio-box .div-list .radio-li label {
      padding-right: 10px; }

.presents-modify-news .radio-box .div-list:first-child {
  height: 35px; }
  .presents-modify-news .radio-box .div-list:first-child .form-ctrl-label {
    line-height: 20px;
    height: 20px; }

.presents-modify-news .user-radio-box .form-input {
  position: relative; }
  .presents-modify-news .user-radio-box .form-input .form-list {
    position: absolute;
    left: -60px;
    top: -44px; }

.presents-modify-news .user-radio-box .div-list .radio-li {
  display: block;
  position: relative; }
  .presents-modify-news .user-radio-box .div-list .radio-li .extra-text {
    color: #666666;
    line-height: 18px;
    margin-top: 5px;
    position: absolute;
    left: 0;
    top: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.presents-modify-news .form-list-template {
  position: absolute;
  left: -2000px; }

.presents-modify-news ::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */ }

.presents-modify-news .book-box {
  display: flex; }
  .presents-modify-news .book-box .form-list {
    background: #fff;
    margin: 0;
    padding: 0 !important;
    border: none !important; }
  .presents-modify-news .book-box .deleteLable .form-label {
    width: 0;
    height: 0; }
    .presents-modify-news .book-box .deleteLable .form-label label {
      padding: 0 !important; }
  .presents-modify-news .book-box .deleteLable .form-input-help {
    line-height: 20px;
    height: auto;
    margin: 0; }
  .presents-modify-news .book-box .book {
    width: 320px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2; }
    .presents-modify-news .book-box .book .book-title {
      border-bottom: 1px solid #E2E2E2;
      display: flex;
      line-height: 50px;
      font-size: 12px;
      font-weight: bold; }
      .presents-modify-news .book-box .book .book-title .book-title-radius {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #E2E2E2;
        color: #fff;
        font-weight: bold;
        text-align: center;
        margin: 5px 20px 5px 5px; }
    .presents-modify-news .book-box .book .book-content .book-content-title {
      font-size: 14px;
      font-weight: bold;
      padding: 15px 10px; }
    .presents-modify-news .book-box .book .book-content ul {
      max-height: 205px;
      overflow-y: auto;
      overflow-x: hidden;
      -ms-overflow-style: none;
      /* IE 10+ */
      scrollbar-width: none;
      /* Firefox */ }
      .presents-modify-news .book-box .book .book-content ul li {
        padding: 0 10px 15px;
        display: flex;
        line-height: 20px; }
        .presents-modify-news .book-box .book .book-content ul li .book-li-title {
          color: #CACACA;
          width: 60px;
          margin-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .presents-modify-news .book-box .book .book-content ul li .book-li-text {
          width: 228px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
    .presents-modify-news .book-box .book .details {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      border-top: 1px solid #E2E2E2; }
      .presents-modify-news .book-box .book .details .details-icon {
        width: 8px;
        height: 8px;
        border-top: 1px solid #595959;
        border-left: 1px solid #595959;
        transform: rotate(135deg);
        margin: auto 0; }
    .presents-modify-news .book-box .book .label-item {
      width: 60px; }
    .presents-modify-news .book-box .book .check-item {
      margin-left: 40px;
      color: #888; }
  .presents-modify-news .book-box .book-form {
    margin-left: 30px;
    width: 500px;
    border-bottom: none;
    border-top: 1px solid #E2E2E2; }
    .presents-modify-news .book-box .book-form ul {
      display: flex;
      padding: 0 20px;
      box-sizing: border-box;
      line-height: 40px;
      border: 1px solid #E2E2E2;
      border-top: none; }
      .presents-modify-news .book-box .book-form ul li:nth-child(1) {
        width: 150px; }
      .presents-modify-news .book-box .book-form ul li:nth-child(2) {
        width: 100px; }
      .presents-modify-news .book-box .book-form ul li:last-child {
        width: 230px; }
    .presents-modify-news .book-box .book-form .book-form-title {
      border-bottom: 1px solid #E2E2E2; }
      .presents-modify-news .book-box .book-form .book-form-title li {
        font-weight: bold; }
  .presents-modify-news .book-box .loading-center {
    text-align: center;
    line-height: 40px; }
  .presents-modify-news .book-box .field-table-bor {
    border: 1px solid #E2E2E2;
    border-top: none; }
