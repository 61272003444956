.blue {
  color: #1469FB;
  margin-right: 5px;
  cursor: pointer; }

.dmlinkUploadlist-box {
  width: 100%;
  overflow-x: auto;
  background: #fff;
  text-align: center; }

.dict-tabbar {
  height: 31px; }
  .dict-tabbar .tc-15-tablist {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0 20px; }
    .dict-tabbar .tc-15-tablist li {
      margin-right: 10px; }

.checkbox .form-label {
  display: none; }

.checkbox .form-input {
  margin-top: 5px; }
  .checkbox .form-input .form-ctrl-label {
    margin-right: 0; }
    .checkbox .form-input .form-ctrl-label .tc-15-checkbox {
      margin-top: 3px; }

.reTrydialog .radio-style .form-input {
  position: relative;
  left: 0;
  top: -2px; }

.reTrydialog .form-label label {
  margin-right: 10px; }

.reTrydialog .checkbox .form-input {
  display: flex;
  justify-content: flex-start; }

.reTrydialog .form-unit .form-ctrl-label {
  margin-top: 0px;
  margin-left: -20px; }

.reTrydialog .form-ctrl-label {
  position: absolute;
  left: 50%;
  margin-top: -20px; }

.reTrydialog .tc-15-rich-radio {
  max-height: 50px;
  position: relative;
  left: -57px;
  top: 3px; }
  .reTrydialog .tc-15-rich-radio .tc-15-simulate-select {
    margin-top: 10px; }

.reTrydialog .tc-15-rich-radio:first .form-ctrl-label {
  width: 50px; }

.reTrydialog .tc-15-rich-radio .form-ctrl-label {
  position: relative; }

.links-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 20px 0;
  margin: 20px 0 10px;
  background: #fff;
  overflow-x: auto; }
  .links-box .links-item {
    border: 1px solid #dddddd;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
    cursor: pointer; }
    .links-box .links-item.active {
      background: #1469FB;
      color: #fff; }

.search-box {
  margin-bottom: 10px; }
  .search-box .tc-15-select,
  .search-box .tc-15-input-text,
  .search-box .tc-15-dropdown,
  .search-box .tc-15-btn {
    margin-right: 10px;
    height: 30px;
    line-height: 30px; }
