.warningBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.titleWarning {
  font-size: 17px;
  font-weight: bold; }

.operationalGuide {
  border: 1px solid #EAEAEA;
  width: 330px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative; }

.rowGuide {
  width: 300px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.circle {
  width: 20px;
  height: 20px;
  border: 2px solid #006EFF;
  border-radius: 50%;
  text-align: center;
  z-index: 2;
  position: relative; }

.blackFont {
  margin-left: 10px;
  color: #000;
  font-size: 12px; }

.greyFont {
  color: #888888; }

.tips {
  color: #006EFF;
  display: inline-block;
  margin-left: 30px;
  font-size: 12px; }

.tips1 {
  color: #006EFF;
  margin-left: 18px; }

.guideBox {
  display: flex;
  flex-direction: column; }

.greyLine1 {
  border-left: 1px solid #ccc;
  width: 0px;
  height: 42px;
  position: absolute;
  top: 40px;
  left: 24px; }

.greyLine2 {
  border-left: 1px solid #ccc;
  width: 0px;
  height: 42px;
  position: absolute;
  top: 100px;
  left: 24px; }

.freeAmountBox {
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: row; }

.tips2 {
  color: #006EFF;
  position: absolute;
  top: 47.5%;
  left: 37%; }
