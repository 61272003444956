.badge-form .badge-form-wrap .badge-image {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  background: #f2f2f2;
  border: 1px solid #d9d9d9;
  text-align: center;
  vertical-align: middle; }
  .badge-form .badge-form-wrap .badge-image img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .badge-form .badge-form-wrap .badge-image .n-loading-icon {
    position: relative;
    margin-top: 30px;
    z-index: 10; }

.badge-form .badge-form-wrap .badge-image-upload {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom; }

.badge-form .badge-form-wrap .badge-group .form-input .is-error .badge-g-w {
  display: inline-block; }
  .badge-form .badge-form-wrap .badge-group .form-input .is-error .badge-g-w .wrap-display-none {
    display: none; }

.badge-form .badge-form-wrap .badge-group .form-input .wrap {
  color: #006eff;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle; }
  .badge-form .badge-form-wrap .badge-group .form-input .wrap .create,
  .badge-form .badge-form-wrap .badge-group .form-input .wrap .edit {
    margin-left: 10px;
    cursor: pointer; }

.badge-form .badge-form-wrap .user-label-wrap {
  font-size: 12px;
  margin-top: 20px;
  margin-left: 78px; }
  .badge-form .badge-form-wrap .user-label-wrap .record-wrap {
    margin-bottom: 20px; }
    .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record {
      border: 1px solid #ddd;
      width: 500px;
      min-height: 30px;
      display: flex;
      flex-wrap: wrap; }
      .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record .label {
        line-height: 30px;
        margin: 5px; }
      .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record .selected-wrap {
        border: 1px solid #ddd;
        margin: 5px;
        padding: 5px 10px; }
        .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record .selected-wrap .selected-w-text {
          margin-right: 5px; }
        .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record .selected-wrap .selected-w-del {
          cursor: pointer;
          padding: 5px; }
      .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record .add {
        color: #006eff;
        margin: 5px;
        padding: 5px 10px;
        cursor: pointer; }
      .badge-form .badge-form-wrap .user-label-wrap .record-wrap .record .del {
        margin: 5px;
        padding: 5px 10px;
        cursor: pointer;
        color: #006eff; }
  .badge-form .badge-form-wrap .user-label-wrap .add-label {
    cursor: pointer;
    color: #006eff; }
