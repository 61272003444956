.palette {
  position: relative; }
  .palette .color-picker {
    width: 180px;
    height: 30px;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); }
  .palette .cover {
    position: absolute;
    z-index: 2;
    top: 35px; }
  .palette .cover-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
