.addressbooks-form .deptId-box {
  margin-top: 20px; }
  .addressbooks-form .deptId-box .form-unit {
    position: relative; }
    .addressbooks-form .deptId-box .form-unit .deptId-icon {
      border: none;
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      right: 1px;
      top: 1px; }
    .addressbooks-form .deptId-box .form-unit .tc-15-select.deptId-icon:focus,
    .addressbooks-form .deptId-box .form-unit .tc-15-select.deptId-icon:hover {
      color: #000;
      background-color: #fff; }
  .addressbooks-form .deptId-box .is-error .ant-input {
    border-color: #E1504A;
    box-shadow: 0 0 0 0 transparent; }
  .addressbooks-form .deptId-box .is-error .deptId-icon {
    right: 41px; }
  .addressbooks-form .deptId-box .is-error .icon-valid-flag {
    width: 16px;
    height: 16px;
    margin-top: -10px; }

.addressbooks-form .form-label label {
  width: 70px; }

.addressbooks-form .btn-box {
  text-align: center;
  padding-top: 20px; }
  .addressbooks-form .btn-box button {
    margin-left: 10px; }

.deptIdsError-red {
  color: #E1504A; }
