.headtop-box {
  position: relative;
  height: 30px;
  margin: 20px auto 10px; }
  .headtop-box .addressbooks-searchkey {
    width: 230px;
    vertical-align: middle;
    position: absolute;
    right: 0;
    top: 0; }
    .headtop-box .addressbooks-searchkey .tc-15-search-words {
      width: 230px; }
