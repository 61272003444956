.screenActive_PublishForm .icon-valid-flag {
  display: none;
  margin-left: 0px; }

.screenActive_PublishForm .form-unit .form-input-help {
  text-align: left; }

.screenActive_PublishForm .form-input {
  padding-bottom: 0; }

.screenActive_PublishForm .main-block {
  text-align: center;
  padding-left: 20px; }
