.third-import-setting-style {
  min-width: 700px; }
  .third-import-setting-style .form-list {
    width: 100%;
    display: inline-block; }
    .third-import-setting-style .form-list .form-label label {
      width: 102px; }
    .third-import-setting-style .form-list .form-input select {
      width: 167px; }
  .third-import-setting-style .tag-group {
    display: inline-block;
    vertical-align: top; }
  .third-import-setting-style .label0 .form-label label {
    width: 0; }
  .third-import-setting-style .form-input {
    position: relative; }
  .third-import-setting-style .import-tit {
    font-family: MicrosoftYaHei-Bold;
    font-size: 12px;
    display: inline-block;
    line-height: 30px;
    margin-bottom: 0; }
  .third-import-setting-style .tc-15-tablist {
    border-bottom: none; }
  .third-import-setting-style .tc-15-table-panel {
    border-bottom: none; }
  .third-import-setting-style .upload-btn button.default {
    color: #0063e5; }
  .third-import-setting-style .upload-btn .tc-15-btn {
    padding: 0;
    border: none;
    vertical-align: middle; }
    .third-import-setting-style .upload-btn .tc-15-btn:hover, .third-import-setting-style .upload-btn .tc-15-btn:focus, .third-import-setting-style .upload-btn .tc-15-btn:active {
      background-color: transparent; }
  .third-import-setting-style .upload-btn .file-name {
    font-size: 12px;
    padding-right: 10px;
    vertical-align: middle; }
  .third-import-setting-style .line-clamp {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden; }
