@charset "UTF-8";
.pointType-form {
  position: relative; }
  .pointType-form .noform-label .form-input {
    padding-bottom: 0 !important; }
  .pointType-form .noform-label .form-list {
    background-color: #fff;
    padding: 0;
    margin-top: 0; }
    .pointType-form .noform-label .form-list .form-label {
      display: none; }
    .pointType-form .noform-label .form-list .expire .form-input {
      width: 90px; }
  .pointType-form .check-item {
    display: inline-block;
    vertical-align: top; }
    .pointType-form .check-item .form-label {
      padding-bottom: 0px; }
    .pointType-form .check-item .form-input {
      width: 90px;
      line-height: 30px;
      padding-bottom: 0px; }
      .pointType-form .check-item .form-input .form-unit {
        display: inline-block;
        vertical-align: top; }
    .pointType-form .check-item .form-ctrl-label {
      vertical-align: top; }
    .pointType-form .check-item .tc-15-radio-wrap {
      vertical-align: top; }
  .pointType-form .check-item:not(:first-child) .form-label label {
    width: 0px;
    padding-right: 0; }
  .pointType-form .check-item:nth-of-type(6) .form-label {
    width: 116px;
    padding-right: 20px; }
  .pointType-form .check-item:nth-of-type(1) .form-input,
  .pointType-form .check-item:nth-of-type(5) .form-input,
  .pointType-form .check-item:nth-of-type(6) .form-input {
    width: 66px; }
  .pointType-form .check-item:nth-of-type(4) .form-input,
  .pointType-form .check-item:nth-of-type(9) .form-input {
    width: 91px; }
  .pointType-form .info-register {
    font-size: 12px;
    display: block;
    margin-bottom: 20px;
    margin-top: 0px;
    color: #888888;
    line-height: 18px;
    margin-top: 5px;
    display: inline-block; }
    .pointType-form .info-register .form-ctrl-label {
      vertical-align: top; }
    .pointType-form .info-register .tc-15-radio-wrap {
      vertical-align: top; }
    .pointType-form .info-register .itemClass {
      font-size: 12px;
      color: #000 !important;
      display: inline-table; }
      .pointType-form .info-register .itemClass label {
        word-break: keep-all !important; }
      .pointType-form .info-register .itemClass .is-error .icon-valid-flag {
        display: none; }
      .pointType-form .info-register .itemClass .form-unit .form-input-help {
        margin-left: 10px; }
      .pointType-form .info-register .itemClass .form-ctrl-label {
        margin-right: 0px; }
      .pointType-form .info-register .itemClass .form-unit {
        color: #888888 !important; }
      .pointType-form .info-register .itemClass .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner {
        background: #000;
        color: #FFF; }
        .pointType-form .info-register .itemClass .tc-15-bubble-icon .tc-15-bubble .tc-15-bubble-inner:after {
          border-top-color: #000; }
  .pointType-form .tc-15-input-text {
    width: 320px; }
  .pointType-form .form-input {
    position: relative;
    width: 100%; }
    .pointType-form .form-input .extra-text {
      font-size: 12px;
      line-height: 18px;
      margin-top: 5px;
      color: #888888;
      display: inline-block; }
    .pointType-form .form-input .tc-15-checkbox {
      margin-top: 8px; }
    .pointType-form .form-input .checkbox-text2 {
      font-size: 12px;
      position: absolute;
      top: 10px;
      left: 20px;
      color: #888888; }
      .pointType-form .form-input .checkbox-text2 span {
        color: #000000;
        margin: auto 5px; }
  .pointType-form .allowHandsel .form-label,
  .pointType-form .allowHandsel .form-input {
    padding-bottom: 5px; }
  .pointType-form .changeWin-form {
    width: calc(100% - 210px);
    position: relative;
    left: 91px;
    border: 1px solid #dddddd;
    padding: 20px;
    margin-top: 20px; }
    .pointType-form .changeWin-form .form-input .tc-15-select {
      width: 180px; }
    .pointType-form .changeWin-form .tc-15-input-text {
      width: 180px; }
    .pointType-form .changeWin-form .changeWin-item-text2 {
      font-size: 12px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 190px;
      color: #000000; }
      .pointType-form .changeWin-form .changeWin-item-text2 span {
        color: #000000;
        margin: auto 5px; }
    .pointType-form .changeWin-form .button-after-text2 {
      color: #006eff; }
    .pointType-form .changeWin-form .button-after-text2-disabled {
      cursor: not-allowed;
      color: #ddd; }
    .pointType-form .changeWin-form .button-after-text2:nth-of-type(2) {
      left: 230px; }
    .pointType-form .changeWin-form .is-error ~ .changeWin-item-text2 {
      left: 215px; }
    .pointType-form .changeWin-form .tc-input-group-addon {
      width: 30px; }
    .pointType-form .changeWin-form .conversionRatio-item .form-label label::after {
      content: '  1\79EF\5206=';
      color: #000000;
      font-size: 12px; }
    .pointType-form .changeWin-form .conversionRatio-item .tc-input-group {
      width: 100px;
      min-width: 100px; }
      .pointType-form .changeWin-form .conversionRatio-item .tc-input-group .tc-15-input-text {
        width: 70px;
        min-width: 70px; }
    .pointType-form .changeWin-form .blessing-box {
      margin-top: 20px;
      margin-left: -116px; }
    .pointType-form .changeWin-form .pointType-redPacketScene .form-label,
    .pointType-form .changeWin-form .pointType-redPacketScene .form-input {
      padding-top: 20px; }
  .pointType-form .button-box {
    width: 100%;
    height: 30px;
    text-align: center;
    margin-top: 15px; }
    .pointType-form .button-box button {
      margin: 5px; }
  .pointType-form .inline-block1 {
    position: relative; }
  .pointType-form .inline-block2 {
    width: 240px;
    position: absolute;
    top: 5px;
    left: 250px; }
  .pointType-form .inline-block5 {
    display: inline-block;
    vertical-align: text-top;
    position: relative; }
    .pointType-form .inline-block5 .tc-15-switch-input {
      width: 0; }
  .pointType-form .btn-brandRedpackConfig {
    color: #006EFF;
    position: absolute;
    width: 35px;
    top: 6px;
    left: 345px; }
  .pointType-form .btn-brandRedpackConfig1 {
    color: #006EFF;
    position: absolute;
    width: 35px;
    top: 6px;
    left: 380px; }
  .pointType-form .is-error ~ .btn-brandRedpackConfig {
    left: 370px; }

.poolData .tc-15-select[disabled] {
  background-color: #f2f2f2; }
